import {useEffect, useState} from "react";
import moment from "moment/moment";

import {useReloadComponent} from "../../../../../Core/hooks";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../Core/Store/Reload/Actions";
import {
    createClientPaymentAction,
    deleteClientPaymentAction,
    editClientPaymentAction,
    viewClientPaymentAction,
} from "./ClientPaymentsGridHelpers";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {deleteClientPayment, getClientPayments} from "../../../../Api/Payments/ClientPayments";

import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import ClientPaymentModal from "../ClientPaymentModal";
import ClientPaymentFilters from "../ClientPaymentFilters";
import PreparedPrompt from "../../../../../Core/Components/Modal/PreparedPrompt";

import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {ClientPaymentsGridPropsInterface} from "./ClientPaymentsGridInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {ClientPaymentInterface} from "../../../../Api/Payments/ClientPayments/ClientPaymentsInterface";


export default function ClientPaymentsGrid(props: ClientPaymentsGridPropsInterface) {
    const {
        filters = {},
        formControl,
        mode,
        data,
        isHideFilters,
        isHideNavigation,
        isHidePadding,
        searchParams,
        setSearchParams,
    } = props;


    const isReload = useReloadComponent("ClientPaymentsGrid");
    const [permission] = useLynxPermission();

    const [clientPayments, setClientPayments] = useState<any>({});
    const [clientPaymentId, setClientPaymentId] = useState<number | string>();
    const [pagination, setPagination] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState<formMode | null>(null);
    const [isPromptOpen, setIsPromptOpen] = useState(false);


    const gridTitle: GridTitleInterface[] = [
        {
            title: "Id",
            code: "id",
            width: "60px"
        },
        {
            title: "Номер платежу",
            code: "code",
            width: "140px",
        },
        {
            title: "Дата Платежу",
            code: "date",
            width: "120px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY"),
        },
        {
            title: "Стан",
            code: "stateName",
            width: "200px",
        },
        {
            title: "Клієнт",
            code: "clientName",
            width: "270px",
        },
        {
            title: "Тип платежу",
            code: "typeName"
        },
        {
            title: "Тип оплати",
            code: "formName",
        },
    ];


    useEffect(() => {
        if (isReload) {
            getClientPayments({filters: {...filters, ...searchParams}, pagination})
                .then(response => {
                    response?.status === "success" && setClientPayments(response);

                    setReloadComponent("ClientPaymentsGrid", false);
                });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    // гарантуємо, що при наступному монтуванні isReload = true
    useEffect(() => () => setReloadComponent("ClientPaymentsGrid", true), []);


    const onFilterHandler = (data: ClientPaymentInterface) => {
        setSearchParams && setSearchParams(data);
        setReloadComponent("ClientPaymentsGrid", true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setReloadComponent("ClientPaymentsGrid", true);
        setPagination(pagination);
    };

    const onSuccessHandler = () => setReloadComponent("ClientPaymentsGrid", true);

    const onApproveDelete = async (id: number | string) => {
        const response = await deleteClientPayment(id);

        if (response?.status === "success") {
            addAlert({
                type: response.status,
                message: response.message,
            });
            onSuccessHandler();
            setIsPromptOpen(false);
        }
    };

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "viewClientPayment",
            isShow: permission.isView("clientPayment"),
            functions: {setModalMode, setIsModalOpen, setClientPaymentId},
            action: viewClientPaymentAction,
        },
        {
            title: "Редагувати",
            name: "editClientPayment",
            classNameItem: "lynx-button-action-warning",
            isShow: permission.isEdit("clientPayment"),
            functions: {setModalMode, setIsModalOpen, setClientPaymentId},
            action: editClientPaymentAction,
        },
        {
            title: "Видалити",
            name: "deleteClientPayment",
            classNameItem: "lynx-button-action-danger",
            isShow: permission.isDelete("clientPayment"),
            functions: {setIsPromptOpen, setClientPaymentId},
            action: deleteClientPaymentAction,
        },
    ];

    const navActions: Actions[] = [
        {
            title: "Створити платіж",
            name: "createClientPayment",
            isShow: permission.isCreate("clientPayment") && mode !== formMode.view,
            functions: {setModalMode, setIsModalOpen},
            action: createClientPaymentAction,
        },
    ];


    const gridMarkup = (
        <Grid
            data={clientPayments?.data ?? []}
            title={gridTitle}
            pagination={clientPayments?.pagination || {}}
            onLoadDataHandler={onLoadDataHandler}
            actions={actions}
            navActions={navActions}
            isHideNavigation={isHideNavigation}
            isHidePadding={isHidePadding}
        />
    );


    return (
        <>
            {permission.isView("clientPayment") &&
                <>
                    {!isHideFilters &&
                        <div className="row">
                            <div className="col-12 order-2 col-xl-9 order-xl-1">
                                {gridMarkup}
                            </div>
                            <div className="col-12 order-1 col-xl-3 order-xl-2">
                                <ClientPaymentFilters
                                    searchParams={searchParams}
                                    onFilterHandler={onFilterHandler}
                                />
                            </div>
                        </div>
                    }

                    {isHideFilters && gridMarkup}

                    {modalMode &&
                        <ClientPaymentModal
                            isOpen={isModalOpen}
                            mode={modalMode}
                            data={data}
                            clientPaymentId={clientPaymentId}
                            formControl={formControl}
                            onAfterCloseHandle={() => {
                                setIsModalOpen(false);
                                setModalMode(null);
                            }}
                            onAfterSuccessHandle={onSuccessHandler}
                        />
                    }

                    {clientPaymentId &&
                        <PreparedPrompt
                            isOpen={isPromptOpen}
                            code="delete"
                            onCancel={() => setIsPromptOpen(false)}
                            onApprove={() => onApproveDelete(clientPaymentId)}
                        />
                    }
                </>
            }
        </>
    );
}