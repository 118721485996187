import React, {useState} from "react";
import moment from "moment";

import {setReloadComponent, setReloadPage} from "../../../../../../../Core/Store/Reload/Actions";
import {findClientById} from "../../../../../../Api/CRM/Clients";

import FieldsetWithElements from "../../../../../../../Core/Components/Forms/Elements/FieldsetWithElements";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormHR from "../../../../../../../Core/Components/Forms/Elements/FormHR";
import NamedContent from "../../../../../../../Core/Components/DataVisualization/NamedContent";
import ClientModal from "../../../ClientModal";

import {ClientGeneralInfoBlockPropsInterface} from "./ClientGeneralInfoBlockInterface";
import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {ClientInterface} from "../../../../../../Api/CRM/Clients/ClientsInterface";


const ClientGeneralInfoBlock = ({client}: ClientGeneralInfoBlockPropsInterface) => {
    const [clientData, setClient] = useState<ClientInterface | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const onClickEditBtn = () => {
        setIsModalOpen(true);

        findClientById(client?.id).then(response => {
            if (response?.data) {
                setClient(response.data);
            }
        });
    };

    const onAfterSuccessHandle = () => {
        setIsModalOpen(false);
        setReloadPage("clients", true);
        setReloadPage("FeedsPage", true);
        setReloadComponent("clientModalView", true);
        setReloadComponent("CalendarsContainer", true);
    };


    return (
        <>
            <FieldsetWithElements title="Загальна інформація" elementInTheEnd element={
                <Button
                    name="editClient"
                    icon="fa-duotone fa-user-pen"
                    className="lynx-button lynx-btn__button-xs"
                    onClick={onClickEditBtn}
                />
            }/>

            <FormHR type="dashed" isPaddingOut/>

            {client &&
                <div className="lynx-client__block-content">
                    <NamedContent items={[
                        {
                            name: "ПІБ",
                            content: <>{client.lastName} {client.firstName} {client.middleName}</>,
                        },
                        {
                            name: "Стать",
                            content: <>{client.genderName}</>,
                        },
                        {
                            name: "Дата народження",
                            content: <>{moment(client.birthday).format("DD.MM.YYYY")} - {client.age} років</>,
                        },
                        {
                            name: "Номер телефону",
                            content: <>{client.numberPhone}</>,
                        },
                        {
                            name: "Джерело клієнта",
                            content: <>{client.clientSourceName}</>,
                        },
                        {
                            name: "Адреса",
                            content: <>{client.address}</>,
                        },
                        {
                            name: "Філія",
                            content: <>{client.branchName}</>,
                        },
                        {
                            name: "Перший візит",
                            content: <>{moment(client.firstVisit).format("DD.MM.YYYY HH:mm")}</>,
                        },
                        {
                            name: "Останній візит",
                            content: <>{moment(client.lastVisit).format("DD.MM.YYYY HH:mm")}</>,
                        },
                        {
                            name: "Створено",
                            content: <>{moment(client.createdAt).format("DD.MM.YYYY HH:mm")}</>,
                        },
                    ]}/>
                </div>
            }

            <ClientModal
                isOpen={isModalOpen}
                mode={formMode.edit}
                clientData={clientData}
                onAfterCloseHandle={() => setIsModalOpen(false)}
                onAfterSuccessHandle={onAfterSuccessHandle}
            />
        </>
    );
};

export default ClientGeneralInfoBlock;