import React, {useEffect, useState} from 'react';
import './Input.css';
import HelperInfo from "../../../HelperInfo";
import {InputPropsInterface} from './InputInterface';

export default function Input(props: InputPropsInterface) {

    const {
        disabled,
        required,
        name,
        title,
        value,
        type: typeProp,
        initComponent,
        changeHandler,
        blurHandler,
        beforeChangeHandler,
        afterChangeHandler,
        className,
        tooltip,
    } = props;


    const [typeState, setTypeState] = useState(typeProp);


    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            })
        }
    }, [initComponent, required]); // eslint-disable-line react-hooks/exhaustive-deps


    const passwordVisibilityToggle = () => {
        setTypeState(typeState === "password" ? "text" : "password");
    };

    let inputDownClass = ['lynx-input'];

    if (disabled) {
        inputDownClass.push('disabled')
    }

    if (required) {
        inputDownClass.push('required')
    }

    if (className) {
        inputDownClass.push(className)
    }

    const onChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        if (changeHandler) {
            const value = event.currentTarget.value
            changeHandler({
                name,
                value
            })
        }
    };

    const onBlurHandler = (event: React.FormEvent<HTMLInputElement>) => {
        if (blurHandler) {
            const value = event.currentTarget.value;

            blurHandler({
                name,
                value,
            });
        }
    };


    return (
        <div className={inputDownClass.join(' ')}>
            <div className="place-title">
                <label htmlFor={name} className="lynx-input__title">{title}</label>
                {tooltip && <HelperInfo {...tooltip}/>}
            </div>
            <div className="lynx-input__inner">
                <input
                    data-testid={'input-' + name}
                    id={name}
                    type={typeState}
                    data-name={name}
                    value={value ?? ""}
                    disabled={disabled}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                />

                {typeProp === "password" &&
                    <div className="lynx-input__password-visibility" onClick={passwordVisibilityToggle}>
                        {typeState === "password"
                            ? <i className="fa-duotone fa-eye"/>
                            : <i className="fa-duotone fa-eye-slash"/>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

