import React, {useState} from 'react';
import "./ExpandableLink.scss"
import {ExpandableLinkInterface} from "./ExpandableLinkInterface";


export default function ExpandableLink({title, isVisibleDefault = false, className, children}: ExpandableLinkInterface) {
    const [isVisible, setIsVisible] = useState(isVisibleDefault);

    const onBtnClick = () => {
        setIsVisible(prevState => !prevState)
    }

    return (
        <div className="lynx-expandable-link">
            <button
                className={
                    className
                        ? `lynx-expandable-link__btn lynx-expandable-link__btn--${className}`
                        : "lynx-expandable-link__btn"}
                onClick={onBtnClick}>
                <span className="lynx-expandable-link__title">{title}</span>
                <i className={`fa-solid ${isVisible ? "fa-angle-up" : "fa-angle-down"} fa-sm`}></i>
            </button>

            {isVisible &&
                <div className="lynx-expandable-link__content">
                    {children}
                </div>}
        </div>
    );
};
