import {TabsLinkActions, TabsLinkInterface} from './Types'
import {AnyAction} from "redux";

const initialState: Partial<TabsLinkInterface[]> = [];

const tabsLink = (state: Partial<TabsLinkInterface[]> = initialState, action: AnyAction): Partial<TabsLinkInterface[]> | object => {
    const {type, ...rest} = action;

    switch (type) {

        case TabsLinkActions.SET_ACTIVE_TAB :
            return {
                ...state,
                [rest.payload.name]: rest.payload.tab
            }
        case TabsLinkActions.CLEAR_ACTIVE_TAB:
            return {};
    }

    return state;
}
export default tabsLink;
