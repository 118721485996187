import {AnyAction} from "redux";
import {BranchesStateType, BranchesTypes} from "./Types";

const initialState: BranchesStateType = [];

/**
 *
 * @param state
 * @param action
 */

const branches = (state: BranchesStateType = initialState, action: AnyAction): BranchesStateType => {
    const {type, payload} = action;
    switch (type) {
        case  BranchesTypes.GET_BRANCHES:
            return [...payload];
        default:
            return state;
    }
};

export default branches;