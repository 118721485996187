import {ClientGeneralTabPropsInterface} from "./ClientGeneralTabInterface";
import ClientGeneralInfoBlock from "../../ClientBlocks/ClientGeneralInfoBlock";


export default function ClientGeneralTab({client}: ClientGeneralTabPropsInterface) {

    return (
        <div className="row">
            <div className="col-xl-6">
                <div className="lynx-client__block">
                    <ClientGeneralInfoBlock client={client}/>
                </div>
            </div>
        </div>
    );
}