import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {ServiceInterface} from "../../../../Api/Administration/ServiceManagement/Services/ServicesInterface";
import {
    restoreDeletedService,
    findDeletedServiceById
} from "../../../../Api/Administration/ServiceManagement/DeletedServices";


export async function viewDeletedServiceAction(data: ServiceInterface, functions: {
    setIsModalViewOpen(isOpen: boolean): void,
    setDeletedServiceData(deletedService: ServiceInterface): void,
}) {
    const {setIsModalViewOpen, setDeletedServiceData} = functions;

    setIsModalViewOpen(true);

    const response = await findDeletedServiceById(data.id);
    response?.data && setDeletedServiceData(response?.data);
}

export async function restoreDeletedServiceAction(data: ServiceInterface, functions: {
    onSuccessHandler(): void,
}) {
    const response = await restoreDeletedService(data.id);
    if (response?.message) {
        addAlert({
            type: "success",
            message: response.message
        });
        functions.onSuccessHandler();
    }
}