import "./ComponentErrorMessage.css";
import {ComponentErrorMessagePropsInterface} from "./ComponentErrorMessageInterface";

export default function ComponentErrorMessage(props: ComponentErrorMessagePropsInterface) {
    const {
        title,
        height,
        width,
        className,
        icon,
    } = props;


    const componentErrorMessageClassName = ["lynx-component-error-message"];
    className && componentErrorMessageClassName.push(className);


    return (
        <div
            className={componentErrorMessageClassName.join(" ")}
            style={{
                minHeight: height,
                width: width,
            }}
        >
            <span className="lynx-component-error-message__icon">
                <i className={icon ? icon : "fa-duotone fa-memo-circle-info"}/>
            </span>
            <span className="lynx-component-error-message__text">
               {title}
            </span>
        </div>
    );
}