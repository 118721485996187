import {useEffect, useState} from "react";
import {authenticationConfirmCode} from "../../Api/SignIn/SignIn";
import {setAuthCookie} from "../../Utils/Sign";
import {Navigate, useParams} from "react-router-dom";
import {getUserPermission} from "../../../AdministrationUsers/Api/Permissions/UserPermission";
import {
    UserPermissionResponseInterface
} from "../../../AdministrationUsers/Api/Permissions/UserPermission/UserPermissionInterface";
import {configLoader} from "../../../../Core/Utils/ConfigLoader";
import {removeAuthCookie} from "../../Utils/Sign/Sign";

export default function AuthenticationSuccessPage() {
    const {code} = useParams();

    const [link, setLink] = useState('');

    useEffect(() => {
        if (code) {
            removeAuthCookie().then(() => {
                authenticationConfirmCode({code}).then(async (dataAuth) => {
                    if (dataAuth.status === 'success' && dataAuth.data !== undefined) {
                        setAuthCookie(dataAuth.data.accessToken, dataAuth.data.expiresIn)
                            .then(async () => {
                                await getUserPermission().then(async (data: UserPermissionResponseInterface) => {
                                    if (data.data) {
                                        localStorage.setItem('userPermission', JSON.stringify(data.data));
                                        const {successAuth} = configLoader().authConfig;
                                        setLink(successAuth.link)
                                    } else {
                                        setLink('/authentication-error')
                                    }
                                });
                            });
                    } else {
                        setLink('/authentication-error')
                    }
                    return dataAuth;
                });
            })

        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return link ? <Navigate to={link} replace/> : <></>;
}
