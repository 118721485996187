import React from "react";
import './GridPagination.css';
import {GridPaginationPropsInterface} from "./GridPaginationInterface";

export default function GridPagination(props: GridPaginationPropsInterface) {
    let {pagination, onLoadDataHandler} = props;
    let {currentPage, totalPages} = pagination;

    if (currentPage === 1) {
        return (
            <div className="lynx-grid-pagination">
                <span className="lynx-grid-bottom-navigation__button lynx-grid-bottom-navigation--disable">
                    <i className="fa-regular fa-chevrons-left"/>
                </span>
                <span className="lynx-grid-bottom-navigation__prev lynx-grid-bottom-navigation--disable">
                    <i className="fa-regular fa-chevron-left"/>
                </span>
                <span className="lynx-grid-bottom-navigation__button lynx-grid-bottom-navigation__button--active">
                    {currentPage}
                </span>
                {totalPages >= 2 && (
                    <span
                        className="lynx-grid-bottom-navigation__button"
                        onClick={() => onLoadDataHandler({...pagination, currentPage: 2})}
                    >
                        2
                    </span>
                )}
                {totalPages >= 3 && (
                    <span
                        className="lynx-grid-bottom-navigation__button"
                        onClick={() => onLoadDataHandler({...pagination, currentPage: 3})}
                    >
                        3
                    </span>
                )}
                {totalPages > currentPage && (
                    <span
                        className="lynx-grid-bottom-navigation__next"
                        onClick={() => onLoadDataHandler({...pagination, currentPage: currentPage + 1})}
                    >
                        <i className="fa-regular fa-chevron-right"/>
                    </span>
                )}
                {totalPages === currentPage && (
                    <span className="lynx-grid-bottom-navigation__next lynx-grid-bottom-navigation--disable">
                        <i className="fa-regular fa-chevron-right"/>
                    </span>
                )}
                <span
                    className="lynx-grid-bottom-navigation__button"
                    onClick={() => onLoadDataHandler({...pagination, currentPage: totalPages})}
                >
                    <i className="fa-regular fa-chevrons-right"/>
                </span>
            </div>
        );
    }
    if (currentPage === totalPages) {
        return (
            <div className="lynx-grid-pagination">
                <span
                    className="lynx-grid-bottom-navigation__button"
                    onClick={() => onLoadDataHandler({...pagination, currentPage: 1})}
                >
                    <i className="fa-regular fa-chevrons-left"/>
                </span>
                <span
                    className="lynx-grid-bottom-navigation__prev"
                    onClick={() => onLoadDataHandler({...pagination, currentPage: currentPage - 1})}
                >
                    <i className="fa-regular fa-chevron-left"/>
                </span>
                {totalPages >= currentPage - 2 && currentPage - 2 > 0 && (
                    <span
                        className="lynx-grid-bottom-navigation__button"
                        onClick={() => onLoadDataHandler({...pagination, currentPage: currentPage - 2})}
                    >
                        {currentPage - 2}
                    </span>
                )}
                {totalPages >= currentPage - 1 && (
                    <span
                        className="lynx-grid-bottom-navigation__button"
                        onClick={() => onLoadDataHandler({...pagination, currentPage: currentPage - 1})}
                    >
                        {currentPage - 1}
                    </span>
                )}
                <span className="lynx-grid-bottom-navigation__button lynx-grid-bottom-navigation__button--active">
                    {currentPage}
                </span>
                <span className="lynx-grid-bottom-navigation__next lynx-grid-bottom-navigation--disable">
                    <i className="fa-regular fa-chevron-right"/>
                </span>
                <span className="lynx-grid-bottom-navigation__button lynx-grid-bottom-navigation--disable">
                    <i className="fa-regular fa-chevrons-right"/>
                </span>
            </div>
        );
    }

    return (
        <div className="lynx-grid-pagination">
            <span
                className="lynx-grid-bottom-navigation__button"
                onClick={() => onLoadDataHandler({...pagination, currentPage: 1})}
            >
                <i className="fa-regular fa-chevrons-left"/>
            </span>
            <span
                className="lynx-grid-bottom-navigation__prev"
                onClick={() => onLoadDataHandler({...pagination, currentPage: currentPage - 1})}
            >
                <i className="fa-regular fa-chevron-left"/>
            </span>
            <span
                className="lynx-grid-bottom-navigation__button"
                onClick={() => onLoadDataHandler({...pagination, currentPage: currentPage - 1})}
            >
                {currentPage - 1}
            </span>
            <span className="lynx-grid-bottom-navigation__button lynx-grid-bottom-navigation__button--active">
                {currentPage}
            </span>
            <span
                className="lynx-grid-bottom-navigation__button"
                onClick={() => onLoadDataHandler({...pagination, currentPage: currentPage + 1})}
            >
                {currentPage + 1}
            </span>
            <span
                className="lynx-grid-bottom-navigation__next"
                onClick={() => onLoadDataHandler({...pagination, currentPage: currentPage + 1})}
            >
                <i className="fa-regular fa-chevron-right"/>
            </span>
            <span
                className="lynx-grid-bottom-navigation__button"
                onClick={() => onLoadDataHandler({...pagination, currentPage: totalPages})}
            >
                <i className="fa-regular fa-chevrons-right"/>
            </span>
        </div>
    );
}
