import {useEffect, useState} from "react";

import {getServiceGroups} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups";

import ExpandableLink from "../../../../../../Core/Components/ExpandableLink";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import FiltersForm from "../../../../../../Core/Components/Forms/Filters/FiltersForm";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";

import {ServiceFiltersPropsInterface} from "./ServiceFiltersInterface";
import {
    ServiceGroupInterface
} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";


export default function ServiceFilters(props: ServiceFiltersPropsInterface) {
    const {searchParams, onFilterHandler} = props;
    const [
        serviceGroups, setServiceGroups
    ] = useState<ServiceGroupInterface[]>([]);


    useEffect(() => {
        getServiceGroups().then(response => {
            if (response?.status === "success" && response.data) {
                setServiceGroups(response.data);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <FiltersForm code="services" searchParams={searchParams} filterHandler={onFilterHandler}>
            <div className="row">
                <ExpandableLink title="Основні фільтри" isVisibleDefault>
                    <div className="col-12">
                        <Input
                            type="text"
                            name="name"
                            title="Назва послуги"
                        />
                    </div>
                    <div className="col-12">
                        <Input
                            type="text"
                            name="code"
                            title="Код послуги"
                        />
                    </div>
                    <div className="col-12">
                        <DropDown
                            name="groupId"
                            title="Група послуги"
                            options={prepareDropDownOptions(serviceGroups, "id", "name")}
                        />
                    </div>
                    <div className="col-12">
                        <Input
                            type="text"
                            name="minPrice"
                            title="Вартість послуги(від)"
                        />
                    </div>
                   <div className="row">
                       <div className="col-12">
                           <Input
                               type="text"
                               name="maxPrice"
                               title="Вартість послуги(до)"
                           />
                       </div>
                   </div>
                </ExpandableLink>
            </div>
        </FiltersForm>
    );
};
