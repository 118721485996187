import Prompt from "../Prompt";
import {PreparedPromptPropsInterface} from './PreparedPromptInterface';
import {promptConfig} from "../../../../Config/Prompt/PromptConfig";


export default function PreparedPrompt(props: PreparedPromptPropsInterface) {
    const {
        code,
        isOpen,
        approveTextButton,
        cancelTextButton ,
        className,
        onApprove,
        onCancel,
    } = props;

    const configs = promptConfig.find(config => config.code === code);

    return (
        <Prompt
            title={configs!.title}
            description={configs!.description}
            isOpen={isOpen}
            approveTextButton={approveTextButton}
            onApprove={onApprove}
            cancelTextButton={cancelTextButton}
            onCancel={onCancel}
            className={className}
        />
    );
};
