import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

import {setReloadPage} from "../../../../../Core/Store/Reload/Actions";
import {usePageTitle, useReloadPage} from "../../../../../Core/hooks";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {findClientById} from "../../../../Api/CRM/Clients";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import ClientCard from "../../../../Components/CRM/Clients/ClientCard/ClientCard";

import {ClientInterface} from "../../../../Api/CRM/Clients/ClientsInterface";


export default function ClientDetailsPage() {
    const [permission] = useLynxPermission();
    const {clientId} = useParams();
    const isReload = useReloadPage("clients");

    const [client, setClient] = useState<ClientInterface | null>(null);

    const getClientName = () => {
        if (client) {
            return `${client.lastName} ${client.firstName}`;
        }

        return "Клієнт";
    };

    usePageTitle(getClientName());

    useEffect(() => {
        if (clientId && isReload) {
            findClientById(clientId).then(response => {
                if (response?.data) {
                    setClient(response.data);
                }

                setReloadPage("clients", false);
            });
        }
    }, [clientId, isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    const clientIsNotEmptyObject = client !== null && typeof client === 'object' && Object.keys(client).length !== 0;


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title={getClientName()} tree={['CRM', 'Клієнти']}/>
            </BreadCrumbContainer>

            <br/>

            {clientIsNotEmptyObject && permission.isView('client') &&
                <ClientCard client={client}/>}
        </>
    );
};