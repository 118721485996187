import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getBranchesAction} from "../Store/Branches/Actions";

import {StoreInterface} from "../../Config/Redux/StoreInterface";
import {BranchInterface} from "../Api/Branches/Branch/BranchInterface";


export default function useBranch() {
    const dispatch = useDispatch();
    const branches = useSelector((store: StoreInterface) => store.shortCache.branches);
    const employeeWorkingBranches = useSelector((store: StoreInterface) => store.users.employeeWorkingBranches);
    const selectedEmployeeWorkingBranch = useSelector(
        (store: StoreInterface) => store.longTermCache.users.selectedEmployeeWorkingBranch
    );


    useEffect(() => {
        // перевіряємо тільки branches,
        // так як employeeWorkingBranches та selectedEmployeeWorkingBranch оброблються під час завантаження додатку
        !branches?.length && dispatch(getBranchesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    let availableBranches: BranchInterface[] | [] = [];

    if (!!branches.length && !!employeeWorkingBranches.length) {
        availableBranches = branches
            .filter(branch => employeeWorkingBranches
                .some(workingBranch => branch.id === workingBranch.id));
    }


    return {
        availableBranches,
        selectedEmployeeWorkingBranch,
    };
};