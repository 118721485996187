import {AlertActions} from './Types'
import {AnyAction} from "redux";
import {IAlert} from "../../Components/Alert/Alert/IAlert";

/**
 * Инициализация базового state
 */
const initialState: Partial<IAlert[]> = [];

/**
 * Основная функция, где размещены все обработчики Reducers
 *
 * @param state
 * @param action
 */
const alerts = (state: Partial<IAlert[]> = initialState, action: AnyAction): Partial<IAlert[]> => {
    const {type, ...rest} = action;
    switch (type) {
        /**
         * Добавление нового сообщения
         */
        case AlertActions.ADD_ALERT :
            return [...state, rest.payload];
        /**
         * Удаление нового сообщения
         */
        case AlertActions.REMOVE_ALERT :
            return [...state.filter((alert):any => {
                if (alert && alert.token) {
                    return alert.token !== rest.payload.token;
                }

                return false;
            })];
        /**
         * Очистка всех сообщений
         */
        case AlertActions.CLEAR_ALERT:
            return [];
    }

    return state;
}
export default alerts;