import {request} from "../../../../../Core/Utils/Request/Request";
import {
    ProfileEditRequestInterface,
    ProfileRequestInterface,
    ProfileResponseInterface,
    LastAuthorizationsResponseInterface,
} from "./ProfileInterface";


export const getProfile = async (params?: ProfileRequestInterface): Promise<ProfileResponseInterface> => {
    return await request({
        url: '/v1/users/profile',
        method: 'get',
        options: params?.options,
    });
};

export const getLastAuthorizations = async (): Promise<LastAuthorizationsResponseInterface> => {
    return await request({
        url: 'v1/users/auth-logs',
        method: 'get',
    });
}

export const getProfileById = async (id: number ): Promise<ProfileResponseInterface> => {
    return await request({
        url: '/v1/users/profile/' + id,
        method: 'get',
    });
};

export const editProfile = async (params: ProfileEditRequestInterface): Promise<ProfileResponseInterface> => {
    return await request({
        url: 'v1/users/profile-update',
        method: 'patch',
        data: params,
    });
}
