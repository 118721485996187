import {request} from "../../../../Core/Utils/Request/Request";
import {SignInRequestInterface, SignInResponseInterface, AuthenticationConfirmCodeRequestInterface} from "./SignInInterface";

/**
 * Авторизация в систему по Email
 *
 * @param params
 */
export const auth = async (params: SignInRequestInterface): Promise<SignInResponseInterface> => {
    return await request({
        url: '/v1/auth/sign-in-by-email',
        method: 'post',
        data: params,
    });
}

/**
 * Logout of system
 */
export const logout = async () => {
    return await request({
        url: '/logout',
        method: 'post',
    });
}

/**
 * Подтверждение авторизации пользователя через код с сервиса авторизации
 *
 * @param params
 */
export const authenticationConfirmCode = async (params: AuthenticationConfirmCodeRequestInterface): Promise<SignInResponseInterface> => {
    return await request({
        url: '/v1/auth/authentication/confirm/code/'+ params.code,
        method: 'get'
    });
}