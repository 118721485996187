import {getTimetableTypes} from "../../../Api/Directories/TimetableTypes";
import {TimetableTypes} from "./Types";
import {TimetableTypesInterface} from "../../../Api/Directories/TimetableTypes/TimetableTypesInterface";


export const setTimetableTypes = (timetableTypes: TimetableTypesInterface[] | undefined) => {
    return {
        type: TimetableTypes.GET_TIMETABLE_TYPES,
        payload: timetableTypes,
    };
};

export const getTimetableTypesAction = () => async (dispatch: Function) => {
    const timetableTypes = await getTimetableTypes();
    if (timetableTypes.status === "success") {
        dispatch(setTimetableTypes(timetableTypes?.data));
    }
};