import {AnyAction} from "redux";
import {ServicesPerformedCertificatesStatesStateType, ServicesPerformedCertificatesStatesType} from "./Types";

const initialStates: ServicesPerformedCertificatesStatesStateType = [];

const servicesPerformedCertificatesStates = (
    states: ServicesPerformedCertificatesStatesStateType = initialStates, action: AnyAction
): ServicesPerformedCertificatesStatesStateType => {
    const {type, payload} = action;
    switch (type) {
        case ServicesPerformedCertificatesStatesType.GET_SERVICES_PERFORMED_CERTIFICATES_STATES:
            return [...payload];
        default:
            return states;
    }
};

export default servicesPerformedCertificatesStates;