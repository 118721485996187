import React from 'react';
import './BottomMenu.css';
import {NavLink} from "react-router-dom";
import {BottomMenuPropsInterface} from "./BottomMenuInterface";

export default function BottomMenu(props: BottomMenuPropsInterface) {

    const {items} = props;

    return (<div className="lynx-menu-footer-nav">
            <ul>
                {items?.map((item, index) => (
                    <li key={index}>
                        {item.handler && <i className={item.icon} onClick={item.handler}/>}
                        {!item.handler && <NavLink to={item.link} className={item.icon}/>}
                    </li>
                ))}
            </ul>
        </div>
    );
}

