import {useState} from "react";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {toggleMobileMenu} from "../../../../../Store/ControlPanelMenu/Actions";
import {MobileNestedLevelPropsInterface} from "./MobileNestedLevelInterface";

export default function MobileNestedLevel(props: MobileNestedLevelPropsInterface) {
    const {icon, title, items, level} = props;
    const dispatch = useDispatch();
    const [isOpen, setStateOpen] = useState(false);

    const onClickHandler = () => setStateOpen(!isOpen);

    let row;
    const padding = 20 * level;

    if (items) {
        row = items.map((item) => {
            if (item.items) {
                return <MobileNestedLevel
                    key={item.title}
                    link={item.link}
                    title={item.title}
                    icon={item.icon}
                    items={item.items}
                    level={level + 1}
                />
            } else {
                return (
                    <NavLink
                        to={item.link}
                        key={item.link}
                        style={{paddingLeft: 20 + padding}}
                        onClick={() => dispatch(toggleMobileMenu())}
                    >
                        <span>{item.title}</span>
                    </NavLink>
                );
            }

        })
    }

    const subMenuCss = "lynx-mobile-submenu " + (isOpen ? " lynx-mobile-submenu-active " : " ") + (level === 1 ? "lynx-mobile-submenu__first-level" : ' ');


    return (
        <li>
            <div onClick={onClickHandler} className={subMenuCss} style={{paddingLeft: padding}}>
                {icon && <i className={icon}/>}<span>{title}</span>
            </div>
            {isOpen && <ul className="lynx-mobile-submenu-items">{row}</ul>}
        </li>
    );
}