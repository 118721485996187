import {ChangeEvent, useEffect, useState} from "react";
import {Scrollbars} from "react-custom-scrollbars-2";
import Big from "big.js"
import "./CertificatesServicesBlock.css";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {
    addService,
    calculationTotal, onInputChange, onInputPriceBlur,
    prepareDualListSelectorData,
    prepareSelectedData,
    removeService,
} from "./CertificatesServicesBlockHelpers";

import DualListSelectorBlockContainer
    from "../../../../../Core/Components/DualListSelector/DualListSelectorBlock/DualListSelectorBlockContainer";
import DualListSelectorBlockItemContainer
    from "../../../../../Core/Components/DualListSelector/DualListSelectorBlock/DualListSelectorBlockItemContainer";
import Fieldset from "../../../../../Core/Components/Forms/Elements/Fieldset";
import DualListSelector from "../../../../../Core/Components/DualListSelector/DualListSelector";

import {
    CertificatesServicesBlockPropsInterface,
    PrepareSelectedDataGroupItemInterface,
    PrepareSelectedDataReturnInterface,
} from "./CertificatesServicesBlockInterface";


export default function CertificatesServicesBlock(props: CertificatesServicesBlockPropsInterface) {
    const {
        services,
        serviceGroups,
        certificatesServices,
        discount,
        setDiscount,
        setSelectedServices,
    } = props;

    const [permission] = useLynxPermission();

    const [selectedCertificatesServices, setSelectedCertificatesServices] = useState<PrepareSelectedDataReturnInterface[]>([]);


    useEffect(() => {
        if (!!serviceGroups.length) {
            const preparedSelectedData = prepareSelectedData(certificatesServices, serviceGroups);
            setSelectedCertificatesServices(preparedSelectedData);
        }
    }, [certificatesServices, serviceGroups]);

    useEffect(() => {
        setSelectedServices(selectedCertificatesServices)
    }, [selectedCertificatesServices]); // eslint-disable-line react-hooks/exhaustive-deps


    const removeServiceHandler = (
        group: PrepareSelectedDataReturnInterface,
        groupItem: PrepareSelectedDataGroupItemInterface,
    ) => {
        const groupData = {
            groupId: group.groupId,
            name: group.name,
            color: group.color,
        };

        setSelectedCertificatesServices(removeService(
            selectedCertificatesServices,
            {
                item: groupItem,
                group: groupData,
            },
        ))
    };


    return (
        <DualListSelector
            data={prepareDualListSelectorData(services, serviceGroups)}
            titleFields={[
                {
                    name: "code",
                    title: "Код"
                },
                {
                    name: "price",
                    title: "Вартість"
                },
            ]}
            handleSelectionChange={(data) => {
                setSelectedCertificatesServices(addService(selectedCertificatesServices, data));
            }}
            searchPlaceholder="Пошук позиції по назві або по коду послуги"
            additionalSearchFields={["code"]}
        >
            <div className="lynx-certificates-services-block__fieldset-wrap">
                <Fieldset title="Послуги, які входять в акт:"/>
                <div className="lynx-certificates-services-block__titles">
                    <span>Кількість</span>
                    <span>Вартість</span>
                    <span>Сума</span>
                </div>
            </div>

            <Scrollbars className="lynx-certificates-services-block__scrollbars">
                <div className="lynx-certificates-services-block__list">
                    {selectedCertificatesServices.map(group => {
                        if (group.groupList.length === 0) return null;

                        return (
                            <DualListSelectorBlockContainer
                                color={group.color}
                                name={group.name}
                                key={group.groupId}
                            >
                                {group.groupList.map(groupItem => {
                                    return (
                                        <DualListSelectorBlockItemContainer
                                            color={group.color}
                                            key={groupItem.code}
                                        >
                                            <span>{groupItem.name}</span>
                                            <span>
                                                <input
                                                    className="lynx-certificates-services-block__input"
                                                    type="text"
                                                    name="quantity"
                                                    value={groupItem.quantity}
                                                    onChange={(e) => {
                                                        onInputChange(
                                                            e,
                                                            selectedCertificatesServices,
                                                            setSelectedCertificatesServices,
                                                            group.groupId,
                                                            groupItem.serviceId
                                                        );
                                                    }}
                                                />
                                            </span>
                                            <span>
                                                {permission
                                                    .isFeatureFlag("servicesPerformedCertificate", "isEditServicePrice")
                                                    ? <input
                                                        className="lynx-certificates-services-block__input"
                                                        type="text"
                                                        name="price"
                                                        value={groupItem.price}
                                                        onChange={(e) => {
                                                            onInputChange(
                                                                e,
                                                                selectedCertificatesServices,
                                                                setSelectedCertificatesServices,
                                                                group.groupId,
                                                                groupItem.serviceId
                                                            );
                                                        }}
                                                        onBlur={(e) => {
                                                            onInputPriceBlur(
                                                                e,
                                                                selectedCertificatesServices,
                                                                setSelectedCertificatesServices,
                                                                group.groupId,
                                                                groupItem.serviceId
                                                            );
                                                        }}
                                                    />
                                                    : <span>{groupItem.price}</span>
                                                }
                                            </span>
                                            <span>
                                                {Big(groupItem.quantity).times(Big(groupItem.price)).toString()}
                                            </span>
                                            <div
                                                className="lynx-certificates-services-block__x-icon"
                                                onClick={() => removeServiceHandler(group, groupItem)}
                                            >
                                                <i className="fa-solid fa-xmark"></i>
                                            </div>
                                        </DualListSelectorBlockItemContainer>
                                    );
                                })}
                            </DualListSelectorBlockContainer>
                        );
                    })}
                </div>
            </Scrollbars>

            <div className="lynx-certificates-services-block__total">
                <div className="lynx-certificates-services-block__total-discount">
                    <span>Знижка:</span>
                    <span>
                        <input
                            className="lynx-certificates-services-block__input lynx-certificates-services-block__input--discount"
                            type="text"
                            name="discount"
                            value={discount}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const valueToNumber = Number(e.target.value);
                                if (Number.isInteger(valueToNumber) && valueToNumber >= 0 && valueToNumber <= 100) {
                                    setDiscount(valueToNumber);
                                }
                            }}
                        />
                   </span>
                    <span><i className="fa-duotone fa-percent"></i></span>
                </div>
                <div>
                    <i className="fa-duotone fa-sigma"></i>
                    <span>{calculationTotal(selectedCertificatesServices, discount)} грн</span>
                </div>
            </div>
        </DualListSelector>
    );
}