import React, {useEffect, useState} from 'react';
import {CheckboxPropsInterface} from "./CheckboxInterface";
import './Checkbox.css'
import HelperInfo from "../../../HelperInfo";

export default function Checkbox(props: CheckboxPropsInterface) {
    const {
        disabled,
        required,
        className,
        name,
        title,
        value,
        initComponent,
        tooltip,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler
    } = props;


    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            })
        }
    }, [initComponent, name, value, required, beforeChangeHandler, afterChangeHandler]);


    const [checked, setChecked] = useState(0);


    useEffect(() => {
        setChecked(value ? 1 : 0);
    }, [value]);


    const clickHandler = (e: any) => {
        if (e.target.classList.contains("lynx-helper-info__button-icon")) {
            return;
        }

        if (!disabled) {
            setChecked(Number(!checked));
            if (changeHandler) {
                changeHandler({
                    name,
                    value: Number(!checked)
                });
            }
        }
    };

    let checkboxClasses = ['lynx-checkbox'];

    if (disabled) {
        checkboxClasses.push('disabled')
    }

    if (required) {
        checkboxClasses.push('required')
    }

    if (checked) {
        checkboxClasses.push('checked')
    }

    if (className) {
        checkboxClasses.push(className);
    }


    return (
        <div className={checkboxClasses.join(' ')} data-name={name} onClick={clickHandler}>
            <div className="lynx-checkbox__check"/>
            {title && (typeof title === "string"
                ? <div className="lynx-checkbox__title">
                    <span>{title}</span>
                    {tooltip && <HelperInfo {...tooltip}/>}
                </div>
                : title)
            }
        </div>
    )
}
