import React, {useEffect, useState} from "react";
import "./GroupPermissionForm.css";

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {setReloadPage} from "../../../../../../Core/Store/Reload/Actions";
import {flattenArrayOfObject, restoreArrayOfObject} from "./GroupPermissionFormHelpers";
import {
    createPermissionGroup,
    deletePermissionGroup,
    editPermissionGroup,
    findPermissionGroupById,
    getPermissionGroupStructure,
} from "../../../../Api/Permissions/GroupPermission";
import {useLynxPermission} from "../../../../hooks";

import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Fieldset from "../../../../../../Core/Components/Forms/Elements/Fieldset";
import FormHR from "../../../../../../Core/Components/Forms/Elements/FormHR";
import Checkbox from "../../../../../../Core/Components/Forms/Elements/Checkbox";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import PreparedPrompt from "../../../../../../Core/Components/Modal/PreparedPrompt";

import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {GroupPermissionFormPropsInterface} from "./GroupPermissionFormInterface";
import {
    PermissionGroupStructureItemInterface,
} from "../../../../Api/Permissions/GroupPermission/GroupPermissionInterface";


export default function GroupPermissionForm(props: GroupPermissionFormPropsInterface) {
    const {
        permissionGroupId,
        setPermissionGroupId,
        mode,
        setMode,
        onSuccessHandler,
    } = props;

    const [permission] = useLynxPermission();

    // структура для побудови полів форми
    const [structure, setStructure] = useState<PermissionGroupStructureItemInterface[]>([]);
    // дозволи у вигляді одного плоского об'єкта
    const [flatPermissionGroup, setFlatPermissionGroup] = useState<{ [key: string]: any }>();
    const [codeIdData, setCodeIdData] = useState<{
        // по sections буде відбуватись перебір при збиранні об'єкта для сабміта
        // також обидві властивості дозволять підкидати правильні id до того ж самого об'єкта
        sections: { [key: string]: number | string },
        featureFlags: { [key: string]: number | string },
    }>();

    const [isPromptOpen, setIsPromptOpen] = useState(false);


    useEffect(() => {
        mode === formMode.edit && permissionGroupId && findPermissionGroupById(permissionGroupId)
            .then(response => {
                if (response?.data) {
                    const {id, name, permission, structure} = response?.data;

                    const {formData, codeId} = flattenArrayOfObject(permission, structure);

                    setFlatPermissionGroup({
                        ...formData,
                        id,
                        name,
                    });

                    setCodeIdData(codeId);
                }
            });
    }, [permissionGroupId]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        mode === formMode.edit && getPermissionGroupStructure().then(response => {
            response?.data?.structure && setStructure(response.data.structure);
        });
    }, [mode]);


    const onSubmit = (data: any, name: string) => {

        if (name === formMode.create) {
            createPermissionGroup({name: data.name}).then(response => {
                if (response?.data) {
                    setMode(formMode.edit);
                    setPermissionGroupId(response.data.id);
                    addAlert({
                        type: "success",
                        message: response.message,
                    });
                }

                setReloadPage("GroupPermissionPages", true);
            });
        }


        if (name === formMode.edit && codeIdData) {
            const params = {
                id: data.id as (number | string),
                name: data.name as string,
                permission: restoreArrayOfObject(data, codeIdData),
            };


            editPermissionGroup(params).then(response => {
                if (response?.status === "success") {
                    addAlert({
                        type: "success",
                        message: response.message,
                    });
                }

                setReloadPage("GroupPermissionPages", true);
            });
        }
    };

    const onApproveDelete = async (id: number | string) => {
        deletePermissionGroup(id).then(response => {
            if (response?.status === "success") {
                addAlert({
                    type: "success",
                    message: response.message
                });
                onSuccessHandler && onSuccessHandler();
            }

            setReloadPage("GroupPermissionPages", true);
        });
    };

    const checkBoxWrapperClassname = "col-3 col-sm-2 px-0 d-flex justify-content-center";


    return (
        <div className="lynx-group-permission-form-row">
            <Form onSubmit={onSubmit} data={flatPermissionGroup}>
                <Fieldset title="Інформація про роль"/>
                <div className="row">
                    <div className="col-12">
                        <Input
                            type="text"
                            name="name"
                            title="Назва групи"
                            required
                        />
                    </div>
                </div>

                {mode === formMode.edit &&
                    <>
                        <br/>

                        <Fieldset title="Дозволи ролі"/>

                        <div className="row lynx-group-permission-form-row">
                            <div className="col-6 col-sm-5  lynx-group-permission-form-row__title">
                                Назва розділу
                            </div>
                            <div className="col-6 col-sm-7 ">
                                <div className="row justify-content-end">
                                    <div className={checkBoxWrapperClassname}>
                                        <i className="fa-duotone fa-eye lynx-group-permission-form__icon-view"
                                           title="Перегляд"/>
                                    </div>
                                    <div className={checkBoxWrapperClassname}>
                                        <i className="fa-duotone fa-plus-large lynx-group-permission-form__icon-create"
                                           title="Створення"/>
                                    </div>
                                    <div className={checkBoxWrapperClassname}>
                                        <i className="fa-duotone fa-pencil lynx-group-permission-form__icon-edit"
                                           title="Редагування"/>
                                    </div>
                                    <div className={checkBoxWrapperClassname}>
                                        <i className="fa-duotone fa-trash-can-list lynx-group-permission-form__icon-delete"
                                           title="Видалення"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {structure?.map((section: any) => {
                            const {name, code} = section;

                            return (
                                <div key={name}>
                                    <FormHR type="dashed"/>

                                    <div className="row lynx-group-permission-form-row__group">
                                        <div className="lynx-group-permission-form-row__title col-6 col-sm-5 mr-1">
                                            {name}
                                        </div>
                                        <div className="col-6 col-sm-7">
                                            <div className="row justify-content-end">
                                                <div className={checkBoxWrapperClassname}>
                                                    <Checkbox name={`${code}-isView`}
                                                              className="justify-content-center"/>
                                                </div>
                                                <div className={checkBoxWrapperClassname}>
                                                    <Checkbox name={`${code}-isCreate`}
                                                              className="justify-content-center"/>
                                                </div>
                                                <div className={checkBoxWrapperClassname}>
                                                    <Checkbox name={`${code}-isEdit`}
                                                              className="justify-content-center"/>
                                                </div>
                                                <div className={checkBoxWrapperClassname}>
                                                    <Checkbox name={`${code}-isDelete`}
                                                              className="justify-content-center"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lynx-group-permission-form-row__flags">
                                        {!!section?.featureFlags?.length && section.featureFlags.map((feature: any) => {
                                            return (
                                                <div key={feature.id} className="lynx-group-permission-form-row__flag-wrap">
                                                    <FormHR type="dotted"/>
                                                    <div className="lynx-group-permission-form-row__flag">
                                                        <Checkbox
                                                            name={`${code}-featureFlag-${feature.code}`}
                                                            title=""
                                                            className="lynx-group-permission-form-row__flag-checkbox"
                                                        />
                                                        <div
                                                            className="lynx-group-permission-form-row__flag-text">
                                                            {feature.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                }

                <br/>

                <FormStickyContainer>
                    <div className="lynx-group-permission-form__buttons">
                        <Button
                            type="submit"
                            name={mode}
                            title="Зберегти"
                            className="lynx-btn-primary"
                        />
                        {permission.isDelete("applicationSettings") && mode === formMode.edit &&
                            <Button
                                onClick={() => setIsPromptOpen(true)}
                                name="delete"
                                icon="fa-duotone fa-trash-can"
                            />
                        }
                    </div>
                </FormStickyContainer>

            </Form>

            {permissionGroupId &&
                <PreparedPrompt
                    isOpen={isPromptOpen}
                    code="delete"
                    onCancel={() => setIsPromptOpen(false)}
                    onApprove={() => onApproveDelete(permissionGroupId)}
                />
            }
        </div>
    );
}

