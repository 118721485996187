import ClientDocumentsBlock from "../../ClientBlocks/ClientDocumentsBlock";
import {ClientDocumentsTabPropsInterface} from "./ClientDocumentsTabInterface";


export default function ClientDocumentsTab({clientId}: ClientDocumentsTabPropsInterface) {
    return (
        <div className="row">
            <div className="col-xl-4">
            </div>
            <div className="col-xl-8">
                <div className="lynx-client__block">
                    <ClientDocumentsBlock clientId={clientId}/>
                </div>
            </div>
        </div>
    );
}