import "./MainMobileMenu.css";
import MobileFirstLevel from "../MobileFirstLevel";
import {MainMobileMenuPropsInterface} from "./MainMobileMenuInterface";
import {Scrollbars} from "react-custom-scrollbars-2";


export default function MainMobileMenu({items}: MainMobileMenuPropsInterface) {
    return (
        <Scrollbars className="lynx-menu-nav__scrollbars">
            <div className="lynx-menu-nav">
                <ul>
                    {items?.map((item, index) => {
                        return <MobileFirstLevel
                            key={index}
                            link={item.link}
                            icon={item.icon}
                            title={item.title}
                            items={item.items ? item.items : null}
                            handler={item.handler}
                        />
                    })}
                </ul>
            </div>
        </Scrollbars>
    );
}

