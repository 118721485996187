import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import "./VisitFilters.css";

import {useBranch} from "../../../../../hooks";
import {getClientSourcesAction} from "../../../../../Store/Directories/ClientSources/Actions";
import {getWorkplacesAction} from "../../../../../Store/Directories/Workplaces/Actions";
import {getVisitStatuses} from "../../../../../Api/CRM/RecordManagement/Visits/VisitStatuses";
import {getEmployees} from "../../../../../Api/Administration/Employees";
import {getLiveSearchClient} from "../../../../../Api/CRM/Clients";
import {getTimesAction} from "../../../../../Store/Directories/Times/Actions";
import {getFilteredWorkplaces} from "../../../../../Api/Directories/Workplaces";

import ExpandableLink from "../../../../../../Core/Components/ExpandableLink";
import DateTime from '../../../../../../Core/Components/Forms/Elements/DateTime';
import FiltersForm from "../../../../../../Core/Components/Forms/Filters/FiltersForm";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";
import LiveSearch, {prepareLiveSearchOptions} from "../../../../../../Core/Components/Forms/Elements/LiveSearch";

import {StoreInterface} from "../../../../../../Config/Redux/StoreInterface";
import {VisitFiltersPropsInterface} from "./VisitFiltersInterface";
import {
    VisitStatusInterface
} from "../../../../../Api/CRM/RecordManagement/Visits/VisitStatuses/VisitStatusesInterface";
import {EmployeeInterface} from "../../../../../Api/Administration/Employees/EmployeesInterface";
import {
    LiveSearchOptionInterface
} from "../../../../../../Core/Components/Forms/Elements/LiveSearch/LiveSearchInterface";
import {FormControlStateInterface} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {WorkplaceInterface} from "../../../../../Api/Directories/Workplaces/WorkplacesInterface";


export default function VisitFilters(props: VisitFiltersPropsInterface) {
    const {searchParams, onFilterHandler} = props;

    const {availableBranches} = useBranch();

    const dispatch = useDispatch();
    const clientSources = useSelector((store: StoreInterface) => store.shortCache.clientSources);
    const times = useSelector((store: StoreInterface) => store.longTermCache.times);

    const [visitStatuses, setVisitStatuses] = useState<VisitStatusInterface[]>([]);
    const [workplaces, setWorkplaces] = useState<WorkplaceInterface[]>([]);
    const [branchEmployees, setBranchEmployees] = useState<EmployeeInterface[]>([]);

    const [isFirstRender, setIsFirstRender] = useState(true);

    // simple form
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        branchId: null,
        jobPositionId: null,
        employeeId: null,
    });
    const [formControlState, setFormControlState] = useState<FormControlStateInterface>({
        fields: {
            workplaceId: {
                disabled: true,
            },
            employeeId: {
                disabled: true,
            },
        },
    });


    useEffect(() => {
        dispatch(getClientSourcesAction());
        dispatch(getWorkplacesAction());
        !times?.length && dispatch(getTimesAction());

        getVisitStatuses().then(response => {
            if (response?.status === "success" && response.data) {
                setVisitStatuses(response.data);
            }
        });

        // на 1му рендері branchId беремо з searchParams, на всіх інших з filterData
        if (searchParams.branchId) {
            toggleFieldsDisabled(false);
            fetchBranchDataById(searchParams.branchId);
        }

        setIsFirstRender(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // на 1му рендері branchId беремо з searchParams, на всіх інших з filterData
        if (isFirstRender) return;

        toggleFieldsDisabled(!formData.branchId);
        formData.branchId && fetchBranchDataById(formData.branchId);
    }, [formData.branchId]); // eslint-disable-line react-hooks/exhaustive-deps


    const loadClientOptions = async (inputValue: string): Promise<LiveSearchOptionInterface[] | []> => {
        return getLiveSearchClient(inputValue).then(response => {
            return prepareLiveSearchOptions(response?.data, "id", "clientName");
        });
    };

    const afterBranchIdChangeHandler = ({value}: { value: number }) => {
        toggleFieldsDisabled(!value);

        if (value) {
            setFormData({
                branchId: value,
                workplaceId: null,
                employeeId: null,
            });
        } else {
            setFormData({
                branchId: null,
                workplaceId: null,
                employeeId: null,
            });
        }
    };

    // змінює disabled полів, які залежать від branchId
    function toggleFieldsDisabled(disabled: boolean) {
        setFormControlState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                workplaceId: {disabled},
                employeeId: {disabled},
            },
        }));
    }

    function fetchBranchDataById(branchId: number | string) {
        getFilteredWorkplaces({filters: {branchId}})
            .then(response => {
                response?.data && setWorkplaces(response?.data);
            });
        getEmployees({filters: {branchId}})
            .then(response => {
                response?.data && setBranchEmployees(response?.data);
            });
    }


    return (
        <FiltersForm
            code="visits"
            searchParams={searchParams}
            filterHandler={onFilterHandler}
            formPropsFilters={{
                data: formData,
                controlState: formControlState,
            }}
        >
            <div className="row">
                <div className="clients-filters__inner">
                    <ExpandableLink title="Основні фільтри" isVisibleDefault>
                        <div className="col-12">
                            <LiveSearch
                                name="clientId"
                                title="Клієнт"
                                loadOptions={loadClientOptions}
                            />
                        </div>
                        <div className="col-12">
                            <DateTime name="dateStart" title="Дата візиту(з)"/>
                        </div>
                        <div className="col-12">
                            <DateTime name="dateEnd" title="Дата візиту(по)"/>
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="timeStart"
                                options={prepareDropDownOptions(times, "time", "time")}
                                title="Час початку візиту"
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="statusId"
                                title="Стан візиту"
                                options={prepareDropDownOptions(visitStatuses, "id", "name")}
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="branchId"
                                title="Філія"
                                options={availableBranches}
                                afterChangeHandler={afterBranchIdChangeHandler}
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="workplaceId"
                                title="Місце обслуговування"
                                options={prepareDropDownOptions(workplaces, "id", "unitedName")}
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="employeeId"
                                title="Співробіник"
                                options={prepareDropDownOptions(branchEmployees, "id", "userName")}
                            />
                        </div>
                    </ExpandableLink>

                    <ExpandableLink title="Додаткові фільтри">
                        <div className="col-12">
                            <DropDown
                                name="duration"
                                title="Тривалість"
                                options={prepareDropDownOptions(times, "time", "time")}
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="sourceId"
                                title="Джерело клієнта"
                                options={prepareDropDownOptions(clientSources, "id", "name")}
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="userId"
                                title="Ким створено"
                                options={prepareDropDownOptions(branchEmployees, "userId", "userName")}
                            />
                        </div>

                    </ExpandableLink>
                </div>
            </div>
        </FiltersForm>
    );
};
