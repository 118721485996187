import {request} from "../../../../../Core/Utils/Request/Request";
import {
    CreatePermissionGroupRequestInterface,
    PermissionGroupResponseInterface,
    PermissionGroupsResponseInterface,
    PermissionGroupStructureResponseInterface,
} from "./GroupPermissionInterface";
import {ResponseInterface} from "../../../../../Core/Utils/Request/ResponseInterface";

export const getPermissionGroups = async (): Promise<PermissionGroupsResponseInterface> => {
    return await request({
        url: '/v1/administration/permissions/permission-groups',
        method: 'get',
    });
};

export const getPermissionGroupStructure = async (): Promise<PermissionGroupStructureResponseInterface> => {
    return await request({
        url: "/v1/administration/permissions/structure",
        method: "get",
    });
};

export const createPermissionGroup = async (
    params: CreatePermissionGroupRequestInterface
): Promise<PermissionGroupResponseInterface> => {
    return await request({
        url: "/v1/administration/permissions/permission-groups",
        method: "post",
        data: params,
    });
};

export const editPermissionGroup = async (
    params: { name: string; permission: any[]; id: number | string }
): Promise<PermissionGroupResponseInterface> => {
    return await request({
        url: "/v1/administration/permissions/permission-groups/update",
        method: "post",
        data: params,
    });
};

export const findPermissionGroupById = async (id: number | string): Promise<PermissionGroupResponseInterface> => {
    return await request({
        url: `/v1/administration/permissions/permission-groups/${id}`,
        method: "get",
    });
};

export const deletePermissionGroup = async (id: number | string): Promise<ResponseInterface> => {
    return await request({
        url: `/v1/administration/permissions/permission-groups/${id}`,
        method: "delete",
    });
};