import Form from "../../../../../Core/Components/Forms/Form";
import {ApplicationSettingsGroupFormPropsInterface} from "./ApplicationSettingsGroupFormInterface";
import {
    ApplicationSettingInterface,
} from "../../../../Api/Administration/ApplicationSettings/ApplicationSettingsInterface";


export default function ApplicationSettingsGroupForm(props: ApplicationSettingsGroupFormPropsInterface) {
    const {
        children,
        settings
    } = props;


    const prepareSettingsData = (settings: ApplicationSettingInterface[]) => {
        let settingsData: { [p: string]: any } = {};

        settings.forEach(setting => {
            settingsData = {
                ...settingsData,
                [setting.keyName]: setting.value,
            };
        });
        
        return settingsData;
    };


    return (
        <Form data={!!settings?.length ? prepareSettingsData(settings) : {}}>
            {children}
        </Form>
    );
}