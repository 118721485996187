import React from 'react';
import './Menu.css';
import BottomMenu from "./../BottomMenu";
import {MenuPropsInterface} from './MenuInterface';
import Brand from "../Brand";
import MainMenu from "../MenuSupport/MainMenu";


export default function Menu(props: MenuPropsInterface) {
    const {link, title, logo} = props.brand;

    return (
        <div className="lynx-menu">
            <Brand
                link={link}
                logo={logo}
                title={title}
            />
            <MainMenu items={props.menu.mainMenu}/>
            <BottomMenu items={props.menu.bottomMenu}/>
        </div>
    );
}

