import {Core, FilterInterface} from './Types'
import {AnyAction} from "redux";

const initialState: Partial<FilterInterface[]> = [];


const filters = (state: Partial<FilterInterface[]> = initialState, action: AnyAction): Partial<FilterInterface[]> => {
    const {type, ...rest} = action;

    switch (type) {
        case Core.SET_FILTERS :
            return rest.payload;
        default:
            return state;
    }
}
export default filters;