import EmployeeTimetableFormForDay from "../../EmployeeTimetableForms/EmployeeTimetableFormForDay";
import ModalSimple from "../../../../../../../Core/Components/Modal/ModalSimple";

import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {EmployeeTimetableModalForDayInterface} from "./EmployeeTimetableModalForDayInterface";


export default function EmployeeTimetableModalForDay(props: EmployeeTimetableModalForDayInterface) {
    const {
        isOpen,
        mode,
        branchId,
        employeeDayTimetableData,
        onAfterSuccessHandler,
        onAfterCloseHandler,
    } = props;


    return (
        <>
            {mode === "view" &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <EmployeeTimetableFormForDay
                            mode={formMode.view}
                            branchId={branchId}
                            employeeDayTimetableDataProp={employeeDayTimetableData}
                        />
                    }
                    title="Перегляд графіку на день"
                    onAfterCloseHandle={onAfterCloseHandler}
                />
            }

            {mode === "create" &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <EmployeeTimetableFormForDay
                            mode={formMode.create}
                            branchId={branchId}
                            employeeDayTimetableDataProp={employeeDayTimetableData}
                            onAfterSuccessHandler={onAfterSuccessHandler}
                        />
                    }
                    title="Створення графіку на день"
                    onAfterCloseHandle={onAfterCloseHandler}
                />
            }

            {mode === "edit" &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <EmployeeTimetableFormForDay
                            mode={formMode.edit}
                            branchId={branchId}
                            employeeDayTimetableDataProp={employeeDayTimetableData}
                            onAfterSuccessHandler={onAfterSuccessHandler}
                        />
                    }
                    title="Редагування графіку на день"
                    onAfterCloseHandle={onAfterCloseHandler}
                />
            }
        </>
    );
};