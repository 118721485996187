import {ChangeEvent, useRef, useState} from "react";
import {useDebounce, useMediaMatch} from "rooks";
import {Scrollbars} from "react-custom-scrollbars-2";
import "./DualListSelector.css";

import {filterData} from "./DualListSelectorHelpers";
import {useFullScreenParentModal} from "../../../hooks";

import DualListSelectorBlockItemContainer from "../DualListSelectorBlock/DualListSelectorBlockItemContainer";
import DualListSelectorBlockContainer from "../DualListSelectorBlock/DualListSelectorBlockContainer";

import {DualListSelectorPropsInterface} from "./DualListSelectorInterface";


export default function DualListSelector(props: DualListSelectorPropsInterface) {
    const {
        children,
        data,
        titleFields,
        leftBlockWidth = "55%",
        className,
        searchPlaceholder,
        additionalSearchFields,
        handleSelectionChange,
    } = props;

    const ref = useRef<HTMLDivElement | null>(null);
    const isColumn = useMediaMatch("(max-width: 1100px)");
    const isMobile = useMediaMatch('(max-width: 768px)');
    const {isFullScreenParentModal} = useFullScreenParentModal(ref?.current);

    const [filter, setFilter] = useState<string>("");


    const debouncedSetFilter = useDebounce(setFilter, 250);
    const onSearchByNameInputHandler = (e: ChangeEvent<HTMLInputElement>) => debouncedSetFilter(e.target.value);

    const dualListSelectorClassName = ["lynx-dual-list-selector__wrapper"];
    className && dualListSelectorClassName.push(className);
    !isMobile && isFullScreenParentModal && dualListSelectorClassName.push("lynx-dual-list-selector__wrapper--full-screen");


    return (
        <div className={dualListSelectorClassName.join(" ")} ref={ref}>
            <div className="lynx-dual-list-selector" >
                <div
                    className="lynx-dual-list-selector-left"
                    style={{width: isColumn ? "" : leftBlockWidth}}
                >
                    {children}
                </div>

                <div
                    className="lynx-dual-list-selector-right"
                    style={{width: isColumn ? "" : `calc(100% - ${leftBlockWidth})`}}
                >
                    <div className="lynx-dual-list-selector-right__filters">
                        <div className="lynx-dual-list-selector-right__search">
                         <span className="lynx-dual-list-selector-right__search-icon">
                             <i className="fa-duotone fa-magnifying-glass"></i>
                         </span>
                            <input
                                className="lynx-dual-list-selector-right__search-input"
                                type="text"
                                placeholder={searchPlaceholder}
                                onChange={onSearchByNameInputHandler}
                            />
                        </div>
                        <div className="lynx-dual-list-selector-right__titles">
                            {titleFields?.map(field => <span key={field.name}>{field.title}</span>)}
                        </div>
                    </div>
                    <Scrollbars className="lynx-dual-list-selector-right__list-scrollbar">
                        <div className="lynx-dual-list-selector-right__list">
                            {filterData(data, filter, additionalSearchFields).map(group => {
                                if (group.groupList.length === 0) return null;

                                return (
                                    <DualListSelectorBlockContainer
                                        key={group.groupId}
                                        color={group.color}
                                        name={group.name}
                                    >
                                        {group.groupList.map(groupItem => {
                                            const groupData = {
                                                groupId: group.groupId,
                                                name: group.name,
                                                color: group.color,
                                            };

                                            return (
                                                <div
                                                    key={groupItem.id}
                                                    className="lynx-dual-list-selector-block__item-wrapper"
                                                    onDoubleClick={() => handleSelectionChange({
                                                        item: groupItem,
                                                        group: groupData,
                                                    })}
                                                >
                                                    <DualListSelectorBlockItemContainer color={group.color}>
                                                        <span>{groupItem.name}</span>
                                                        {Object
                                                            .values(groupItem.fields)
                                                            .map(field => <span key={field}>{field}</span>)
                                                        }
                                                    </DualListSelectorBlockItemContainer>
                                                </div>
                                            );
                                        })}
                                    </DualListSelectorBlockContainer>
                                );
                            })}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
}