import React, {ReactNode} from 'react';
import './Header.css';
import {useSelector} from "react-redux";
import ToggleMenu from '../ToggleMenu';
import HeaderProfileUser from "../HeaderProfile/HeaderProfileUser";
import NotificationBell from "../NotificationBell";
import {StoreInterface} from "../../../../../../Config/Redux/StoreInterface";
import {screenType} from "../../../../../Store/Conf/Types";
import {HeaderPropsInterface} from "./HeaderInterface";


export default function Header({headerChildren}: HeaderPropsInterface) {
    let screen = useSelector((store: StoreInterface) => store.system.conf.screenType);
    let isTinyMenu = useSelector((store: StoreInterface) => store.controlPanelMenu.tinyMenu);

    let lynxHeader;

    if (screen === screenType.mobile) {
        lynxHeader = 'lynx-header lynx-header--mobile'
    } else {
        lynxHeader = isTinyMenu ? 'lynx-header lynx-header--tiny' : 'lynx-header';
    }

    const renderHeaderChildren = (children: ReactNode[]) => {
       return children.map((child, index) => <div className="lynx-header-item" key={index}>{child}</div>);
    };


    return (
        <div className={lynxHeader}>
            <div className="lynx-header-left">
                <ToggleMenu/>
                {headerChildren?.toLeft && renderHeaderChildren(headerChildren.toLeft)}
            </div>
            <div className="lynx-header-right">
                {headerChildren?.toRight && renderHeaderChildren(headerChildren.toRight)}
                <HeaderProfileUser/>
                <NotificationBell/>
            </div>
        </div>
    );
}
