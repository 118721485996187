import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export enum FeedTagsIdEnum {
    News = 1,
    Renewal = 2,
    Important = 3,
    Visits = 4,
}

export interface FeedTagInterface {
    id: number,
    name: string,
}

export interface FeedTagsResponseInterface extends ResponseInterface {
    data?: FeedTagInterface[] | [],
}