import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";


export async function viewServicesPerformedCertificateAction(data: any, functions: {
    setModalMode(mode: formMode): void,
    setIsModalOpen(isOpen: boolean): void,
    setServicesPerformedCertificateId(id: number | string): void,
}) {
    functions.setModalMode(formMode.view);
    functions.setIsModalOpen(true);
    functions.setServicesPerformedCertificateId(data.id);
}

export async function editServicesPerformedCertificateAction(data: any, functions: {
    setModalMode(mode: formMode): void,
    setIsModalOpen(isOpen: boolean): void,
    setServicesPerformedCertificateId(id: number | string): void,
}) {
    functions.setModalMode(formMode.edit);
    functions.setIsModalOpen(true);
    functions.setServicesPerformedCertificateId(data.id);
}

export async function deleteServicesPerformedCertificateAction(data: any, functions: {
    setIsPromptOpen(isOpen: boolean): void,
    setServicesPerformedCertificateId(id: number | string): void,
}) {
    functions.setIsPromptOpen(true);
    functions.setServicesPerformedCertificateId(data.id);
}