import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export interface SourceInterface {
    clientCount: number,
    source: string,
}

export interface SourceByDateInterface {
    date: string,
    sourceByDate: SourceInterface[],
}

export enum AgeEnum {
    Above45 = "above45",
    AverageAge = "averageAge",
    Between18And30 = "between18And30",
    between30And45 = "between30And45",
    Under18 = "under18",
}

export type AgeInterface = {
   [key in AgeEnum]: number
}

export interface ClientPortraitInterface {
    age: AgeInterface,
    frequencyOfVisits: number,
    gender: {
        men: number,
        women: number,
    },
    multiVisitClientsPercent: number,
    onlyOneVisitClientsPercent: number,
    source: SourceInterface[] | [],
    sourceByDate: SourceByDateInterface[] | [],
}

export interface ClientPortraitFiltersInterface {
    branchId?: number,
    dateCreateStart?: string,
    dateCreateEnd?: string
}

export interface ClientPortraitRequestInterface {
    filters?: ClientPortraitFiltersInterface,
}

export interface ClientPortraitResponseInterface extends ResponseInterface {
   data?: ClientPortraitInterface,
}

