import {request} from "../../../../../../Core/Utils/Request/Request";
import {VisitStatusesResponseInterface} from "./VisitStatusesInterface";


export const getVisitStatuses = async (): Promise<VisitStatusesResponseInterface> => {
    return await request({
        url: '/v1/visit-statuses',
        method: 'get',
    });
};
