import {useLynxPermission} from "../../../Modules/AdministrationUsers/hooks";
import AccessDenied from "../../Components/AccessDenied";
import {PermissionPageOutletPropsInterface} from "./PermissionCheckPageInterface";


function PermissionCheckPage(props: PermissionPageOutletPropsInterface) {
    const {
        permissionCode,
        children,
    } = props;

    const [permission] = useLynxPermission();

    if (!permission.isView(permissionCode)) {
        return <AccessDenied text="У користувача відсутні права на перегляд даного розділу"/>;
    }

    return <>{children}</>;
}

export default PermissionCheckPage;