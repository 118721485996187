import "./SupportForm.css";
import NamedContent from "../../../../Core/Components/DataVisualization/NamedContent";


export default function SupportForm(props?: any) {
    return (
        <div className="lynx-service-form">
            <NamedContent
                items={[
                    {
                        name: "Зателефонуйте нам:",
                        content: (
                            <div className="lynx-service-form__phones">
                                <a href="tel:380500000000">+38 (050) 000 00 00</a>
                                <a href="tel:380670000000">+38 (067) 000 00 00</a>
                            </div>
                        ),
                    },
                    {
                        name: "Напишіть нам:",
                        content: <a href="mailto:support@jetdents.com">support@jetdents.com</a>,
                    },
                ]}
                firstColumnWidth="160px"
            />
        </div>
    );
}