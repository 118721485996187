import {ResponseInterface} from "../../../../../Core/Utils/Request/ResponseInterface";


export enum StateIdEnum {
    Created = 1,
    Signed = 2,
    CreatedByMistake = 3,
    Cancelled = 4,
    Archived = 5,
}

export interface StateInterface {
    id: number,
    name: string,
}

export interface StatesResponseInterface extends ResponseInterface {
    data?: StateInterface[],
}
