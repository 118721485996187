import moment from "moment";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";

export const dateRangeValidation = (dateStart: any, dateEnd: any) => {
    let message: string = "";

    const startDate = moment(dateStart);
    const endDate = moment(dateEnd);

    if (!dateStart) message = "Будь ласка, заповніть поле 'Дата з'";
    else if (!dateEnd) message = "Будь ласка, заповніть поле 'Дата по'";
    else if (dateStart > dateEnd) message = "Дата початку періоду не може бути пізнішою за дату його завершення";
    else if (endDate.diff(startDate, "months", true) > 3) {
        message = "Максимальний діапазон дат - 3 місяці. Будь ласка, оберіть коротший період";
    }

    if (message) {
        addAlert({
            type: "warning",
            message,
        });

        return false;
    }

    return true;
};