import {request} from "../../../../Core/Utils/Request/Request";
import {
    ClientPortraitRequestInterface,
    ClientPortraitResponseInterface,
} from "./ClientPortraitInterface";


export const getClientPortrait = async (
    params?: ClientPortraitRequestInterface
): Promise<ClientPortraitResponseInterface> => {
    return await request({
        url: "/v1/reports/clients/filters",
        method: "post",
        data: params,
    });
};