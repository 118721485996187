import {request} from "../../../../Core/Utils/Request/Request";
import {
    GetTimetablesRequestInterface,
    EditTimetableRequestInterface,
    TimetablesResponseInterface
} from "./TimetablesInterface";


export const getTimetables = async (params?: GetTimetablesRequestInterface): Promise<TimetablesResponseInterface> => {
    return await request({
        url: '/v1/branch-timetable/filters',
        method: 'post',
        data: params,
    });
};

export const editTimetable = async (params: EditTimetableRequestInterface): Promise<TimetablesResponseInterface> => {
    return await request({
        url: `/v1/branch-timetable`,
        method: 'patch',
        data: params,
    });
};