import './Feed.css';
import moment from 'moment';
import FeedHTML from "../FeedType/FeedHTML";
import FeedText from "../FeedType/FeedText";
import {FeedPropsInterface, FeedTags, FeedTypeIdEnum} from "./FeedInterface";
import FeedTypeVisit from "../FeedType/FeedTypeVisit";
import FeedTypeServicesPerformedCertificate from "../FeedType/FeedTypeServicesPerformedCertificate";
import Avatar from "../../../../Core/Components/Avatar";

export default function Feed(props: FeedPropsInterface) {
    const {
        id,
        title,
        description,
        created,
        author,
        authorId,
        avatar,
        tags,
        feedTypeId,
        visit,
        servicesPerformedCertificate,
    } = props;

    const date = moment(created);

    let content: JSX.Element = <></>;

    switch (feedTypeId) {
        case FeedTypeIdEnum.Text:
            content = <FeedText content={description}/>;
            break;
        case FeedTypeIdEnum.HTML:
            content = <FeedHTML content={description}/>;
            break;
        case FeedTypeIdEnum.Visit:
            content = <FeedTypeVisit visit={visit!}/>;
            break;
        case FeedTypeIdEnum.ServicesPerformedCertificate:
            content = (
                <FeedTypeServicesPerformedCertificate
                    servicesPerformedCertificate={servicesPerformedCertificate!}
                />
            );
            break;
    }

    return <div className="lynx-feed-container">
        <div className="lynx-feed-container__title">{title}</div>
        <div className="lynx-feed-container__text">{content}</div>
        <div className="lynx-feed-container-footer">
            <div className="lynx-feed-container-author">
                {avatar
                    ? <img className="lynx-feed-container-author__images" src={avatar} alt={author}/>
                    : <Avatar caller="Feed" seed={authorId} size={30} title={author}/>
                }
                <div className="lynx-feed-container-author-description">
                    <div className="lynx-feed-container-author__name">{author}</div>
                    <div className="lynx-feed-container-author__time">{date.format('DD.MM.Y H:mm')}</div>
                </div>
            </div>
            <div className="lynx-feed-container-footer__right">
                {[
                    FeedTypeIdEnum.Visit,
                    FeedTypeIdEnum.ServicesPerformedCertificate,
                ].includes(feedTypeId) ?
                    <div>
                        <i className="fa-duotone fa-lock-keyhole"></i>
                        <span>Доступно тільки вам</span>
                    </div>
                    :
                    <div>
                        <i className="fa-duotone fa-earth-europe"></i>
                        <span>Доступно всім</span>
                    </div>
                }


                {tags && <div className="lynx-feed-container-tags">
                    {tags.map((item: FeedTags) => {
                        return <div className="lynx-feed-container__tag" key={id + '-' + item.id}>{item.name}</div>
                    })}
                </div>}
            </div>
        </div>
    </div>
}
