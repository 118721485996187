import {request} from "../../../../Core/Utils/Request/Request";
import {
    CreateServicesPerformedCertificateRequestInterface,
    EditServicesPerformedCertificateRequestInterface,
    ServicesPerformedCertificateResponseInterface,
    ServicesPerformedCertificatesRequestInterface,
    ServicesPerformedCertificatesResponseInterface
} from "./ServicesPerformedCertificatesInterface";
import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export const getServicesPerformedCertificates = async (
    params?: ServicesPerformedCertificatesRequestInterface
): Promise<ServicesPerformedCertificatesResponseInterface> => {
    return await request({
        url: "/v1/documents/services-performed-certificates/filters",
        method: "post",
        data: params,
    });
};

export const findServicesPerformedCertificateById = async (
    id: number | string
): Promise<ServicesPerformedCertificateResponseInterface> => {
    return await request({
        url: `/v1/documents/services-performed-certificates/${id}`,
        method: "get"
    });
};

export const createServicesPerformedCertificate = async (
    params: CreateServicesPerformedCertificateRequestInterface
): Promise<any> => {
    return await request({
        url: "/v1/documents/services-performed-certificates",
        method: "post",
        data: params
    });
};

export const editServicesPerformedCertificate = async (
    id: number | string, params: EditServicesPerformedCertificateRequestInterface
): Promise<ServicesPerformedCertificateResponseInterface> => {
    return await request({
        url: `/v1/documents/services-performed-certificates/${id}`,
        method: "patch",
        data: params
    });
};

export const deleteServicesPerformedCertificate = async (
    id: number | string
): Promise<ResponseInterface> => {
    return await request({
        url: `/v1/documents/services-performed-certificates/${id}`,
        method: "delete"
    });
};

export const editServicesPerformedCertificateDiscount = async (
    id: number | string, discount: number
): Promise<ServicesPerformedCertificateResponseInterface> => {
    return await request({
        url: `/v1/documents/certificate-discount-update/${id}`,
        method: "patch",
        data: {discount},
    });
};
