import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useBranch} from "../../../../../hooks";
import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {createOffice, editOffice, findOfficeById} from "../../../../../Api/Administration/PersonnelManagement/Offices";
import {getBranchesAction} from "../../../../../Store/Branches/Actions";

import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";

import {OfficeFormPropsInterface} from "./OfficeFormInterface";
import {formMode as formModeEnum} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {
    TabsLinkSelectorInterface
} from "../../../../../../Core/Components/TabsLink/TabsLinkContent/TabsLinkContentInterface";
import {
    TabsLinkNavigationType
} from "../../../../../../Core/Components/TabsLink/TabsLinkNavigation/TabsLinkNavigationInterface";
import {OfficeInterface} from "../../../../../Api/Administration/PersonnelManagement/Offices/OfficesInterface";


const OfficeForm = ({onAfterSuccessHandle, officeId, mode, setFormMode, setOfficeId}: OfficeFormPropsInterface) => {
    const {availableBranches, selectedEmployeeWorkingBranch} = useBranch();

    const tabsLink: TabsLinkSelectorInterface = useSelector((store: {
        system: { tabsLink: TabsLinkNavigationType[] }
    }) => store.system.tabsLink);
    const dispatch = useDispatch();

    const [office, setOffice] = useState<Partial<OfficeInterface>>({
        branchId: mode === formModeEnum.create ? selectedEmployeeWorkingBranch?.id : "",
    });


    useEffect(() => {
        dispatch(getBranchesAction());
    }, [dispatch]);

    useEffect(() => {
        officeId && findOfficeById(officeId).then(response => response?.data && setOffice(response.data));
    }, [tabsLink.officeForm?.id]);  // eslint-disable-line react-hooks/exhaustive-deps


    const onSubmitHandler = (data: any, name: string) => {
        if (name === "create") {
            createOffice(data)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response?.data) {
                        setOffice(response.data);
                        setOfficeId(response.data.id)
                        setFormMode(formModeEnum.edit);
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                        onAfterSuccessHandle();
                    }
                });
        }

        if (name === "save") {
            editOffice(data.id, data)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === "success") {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }
    };


    return (
        <Form onSubmit={onSubmitHandler} data={office} mode={mode}>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <DropDown
                        options={prepareDropDownOptions(availableBranches, "id", "name")}
                        name="branchId"
                        title="Філія"
                        required
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="name"
                        title="Кабінет"
                        required
                    />
                </div>
            </div>

            {mode !== formModeEnum.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formModeEnum.create ? (
                            <Button
                                type="submit"
                                name="create"
                                title="Створити"
                                className="lynx-btn-primary"
                            />
                        ) : (
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                        )}
                    </FormStickyContainer>
                </>
            }
        </Form>
    )
};

export default OfficeForm;
