import {getTimes} from "../../../Api/Directories/Times";
import {TimesType} from "./Types";
import {TimesInterface} from "../../../Api/Directories/Times/TimesInterface";


export const setTimes = (times: TimesInterface[]) => {
    return {
        type: TimesType.GET_TIMES,
        payload: times,
    };
};

export const getTimesAction = () => async (dispatch: Function) => {
    const times = await getTimes();
    if (times?.data) {
        dispatch(setTimes(times.data));
    }
};