import React from 'react';
import {NavLink} from 'react-router-dom';
import {FirstLevelTinyPropsInterface} from "./FirstLevelTinyInterface";
import './FirstLevelTiny.scss'
import NestedLevelTiny from "../NestedLevelTiny";

export default function FirstLevelTiny(props: FirstLevelTinyPropsInterface) {

    const {link, icon, title, items} = props;

    if (items) {
        return <NestedLevelTiny
            link={link}
            title={title}
            icon={icon}
            items={items}
            level={1}
        />
    } else {
        return <li>
            <NavLink to={link} title={title}>
                <i className={icon}/>
            </NavLink>
        </li>
    }
}
