import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle} from "../../../../../Core/hooks";
import "./ServiceGroupsPage.css";

import {
    getServiceGroups
} from "../../../../Api/Administration/ServiceManagement/ServiceGroups";
import {
    deleteServiceGroupAction,
    editServiceGroupAction,
    viewServiceGroupAction,
} from "./ServiceGroupsPageHelpers";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import ServiceGroupsFilters
    from "../../../../Components/Administration/ServiceManagement/ServiceGroups/ServiceGroupsFilters";
import ServiceGroupsModal
    from "../../../../Components/Administration/ServiceManagement/ServiceGroups/ServiceGroupsModal";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {
    ServiceGroupsResponseInterface
} from "../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";


export default function ServiceGroupsPage() {
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    usePageTitle("Групи послуг");

    const [isReload, setReload] = useState(true);
    const [pagination, setPagination] = useState({});

    const [serviceGroups, setServiceGroups] = useState<ServiceGroupsResponseInterface | null>(null);
    const [serviceGroupId, setServiceGroupId] = useState<number | string>();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mode, setMode] = useState<formMode | null>(null);


    const gridTitles: GridTitleInterface[] = [
        {
            title: 'ID',
            code: 'id',
            width: '50px',
        },
        {
            title: 'Назва',
            code: 'name',
            width: '270px',
        },
        {
            title: 'Код групи послуги',
            code: 'code',
            width: '150px',
        },
        {
            title: 'Батьківська група',
            code: 'parentGroupName',
            width: '270px',
        },
        {
            title: 'Колір',
            code: 'color',
            width: '100px',
            handler: (value: string) => <div style={{
                backgroundColor: value,
                borderRadius: "50%",
                width: "20px",
                height: "20px",
            }}/>,
        },
        {
            title: 'Створено',
            code: 'createdAt',
            width: '130px',
            handler: (value: string) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
        {
            title: 'Ким створено',
            code: 'userName',
            width: '240px',
        },
    ];

    useEffect(() => {
        if (isReload) {
            getServiceGroups({filters: searchParams, pagination}).then(response => {
                if (response?.status === 'success') {
                    setServiceGroups(response);
                }

                setReload(false);
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFilterHandler = (data: any) => {
        setSearchParams(data);
        setReload(true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setReload(true);
        setPagination(pagination);
    };

    const onSuccessHandler = () => {
        setReload(true);
    };

    const actions: Actions[] = [
        {
            title: 'Переглянути',
            name: 'view',
            functions: {setIsModalOpen, setMode, setServiceGroupId},
            isShow: permission.isView('serviceGroup'),
            action: viewServiceGroupAction,
        },
        {
            title: 'Редагувати',
            name: 'edit',
            classNameItem: "lynx-button-action-warning",
            functions: {setIsModalOpen, setMode, setServiceGroupId},
            isShow: permission.isEdit('serviceGroup'),
            action: editServiceGroupAction,
        },
        {
            title: 'Видалити',
            name: 'delete',
            classNameItem: "lynx-button-action-danger",
            functions: {setIsModalOpen, setMode, setServiceGroupId},
            isShow: permission.isDelete('serviceGroup'),
            action: deleteServiceGroupAction,
        },
    ];


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Групи послуг" tree={['Адміністрування', 'Управління послугами']}/>
                <div className="lynx-service-groups-page__buttons">
                    {permission.isCreate('serviceGroup') &&
                        <>
                            <Button
                                name="addServiceGroup"
                                title="Додати групу"
                                className="lynx-btn-primary"
                                onClick={() => {
                                    setMode(formMode.create);
                                    setIsModalOpen(true);
                                }}
                            />
                        </>
                    }
                </div>
            </BreadCrumbContainer>

            <br/>

            {permission.isView('serviceGroup') &&
                <>
                    <div className="row">
                        <div className="col-12 order-2 col-xl-9 order-xl-1">
                            <Grid
                                data={serviceGroups?.data || []}
                                title={gridTitles}
                                pagination={serviceGroups?.pagination}
                                onLoadDataHandler={onLoadDataHandler}
                                actions={actions}
                            />
                        </div>
                        <div className="col-12 order-1 col-xl-3 order-xl-2">
                            <ServiceGroupsFilters onFilterHandler={onFilterHandler} searchParams={searchParams}/>
                        </div>
                    </div>
                </>
            }

            {isModalOpen && mode &&
                <ServiceGroupsModal
                    isOpen={isModalOpen}
                    mode={mode}
                    data={{
                        serviceGroupId,
                    }}
                    onAfterSuccessHandle={onSuccessHandler}
                    onAfterCloseHandle={() => setIsModalOpen(false)}
                />
            }
        </>
    );
};