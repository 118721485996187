import './ErrorPage.css';
import {useParams} from "react-router-dom";

export default function ErrorPage() {
    const {message} = useParams();

    return (<div className="pageNotFound">
        {String(message)}
    </div>);
}
