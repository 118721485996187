import React, {useEffect, useState} from 'react';
import './Dialer.scss'
import {DialerPropsInterface} from './DialerInterface';
import Button from "../Button";
import HelperInfo from "../../../HelperInfo";

export default function Dialer(props: DialerPropsInterface) {
    const {
        disabled,
        required,
        name,
        title,
        value,
        min,
        max,
        step = 1,
        prefix = '',
        initComponent,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler,
        className,
        tooltip,
    } = props;

    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            })
        }
    }, [initComponent]); // eslint-disable-line react-hooks/exhaustive-deps

    const setValue = (minVal: number, maxVal: number, currentVal?: number): number => {
        if (!currentVal) {
            return minVal;
        } else {
            if (currentVal > maxVal) return maxVal;
            if (currentVal < minVal) return minVal;
            return currentVal;
        }
    }

    const [amount, setAmount] = useState(() => setValue(min, max, value))
    const [isChangedValue, setIsChangedValue] = useState(false)

    const increase = (): void => {
        if (changeHandler) {
        let value = parseFloat((amount + step).toFixed(10))
        if (value <= max) {
            setAmount(value)

                changeHandler({
                    name,
                    value
                })

                !isChangedValue && setIsChangedValue(true)
            }
        }
    }

    const decrease = (): void => {
        if (changeHandler) {
            let value = parseFloat((amount - step).toFixed(10))
            if (value >= min) {
                setAmount(value)

                changeHandler({
                    name,
                    value
                })

                !isChangedValue && setIsChangedValue(true)
            }
        }
    }

    let dialerClass = ['lynx-dialer'];

    if (disabled) {
        dialerClass.push('disabled')
    }

    if (!isChangedValue && required) {
        dialerClass.push('required')
    }

    if (className) {
        dialerClass.push(className)
    }

    return (
        <div className={dialerClass.join(' ')}>
            <div className="place-title">
                <label htmlFor={name} className="lynx-dialer__title">{title}</label>
                {tooltip && <HelperInfo {...tooltip}/>}
            </div>
            <div className="lynx-dialer__group">
                <Button
                    className="lynx-btn-secondary lynx-dialer__prev"
                    name="decrement"
                    icon="fa-duotone fa-minus"
                    disabled={disabled}
                    onClick={decrease}
                />
                <input
                    className="lynx-dialer__amount"
                    data-testid={'dialer-' + name}
                    id={name}
                    type="text"
                    data-name={name}
                    value={`${prefix}${amount}`}
                    readOnly={true}
                    disabled={disabled}
                />
                <Button
                    className="lynx-btn-secondary lynx-dialer__next"
                    name="increment"
                    icon="fa-duotone fa-plus"
                    disabled={disabled}
                    onClick={increase}
                />
            </div>
        </div>
    );
}
