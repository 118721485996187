import FormFlexible from "../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormFlexible";
import FormTabsContainer from "../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsContainer";
import TabsLinkNavigation from "../../../../Core/Components/TabsLink/TabsLinkNavigation";
import FormTabsItem from "../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsItem";
import TabsLinkContent from "../../../../Core/Components/TabsLink/TabsLinkContent";
import BranchForm from "../BranchForm";
import TimetableForm from "../TimetableForm";

import {BranchFormContainerInterface} from "./BranchFormContainerInterface";
import {formMode} from "../../../../Core/Components/Forms/Form/FormInterface";


const BranchFormContainer = ({onAfterSuccessHandle, branchId, mode}: BranchFormContainerInterface) => {
    return <>
        <FormFlexible>
            <FormTabsContainer>
                <TabsLinkNavigation
                    isTabsContent
                    tabs={[
                        {
                            id: "1",
                            content:
                                <FormTabsItem>
                                    <i title="Філія" className="fa-duotone fa-house"/>
                                </FormTabsItem>
                        },
                        {
                            id: "2",
                            content: mode !== formMode.create &&
                                <FormTabsItem>
                                    <i title="Графік роботи філії" className="fa-duotone fa-calendar-days"/>
                                </FormTabsItem>
                        }
                    ]}
                    name="branchForm"
                />
            </FormTabsContainer>


            <TabsLinkContent tabFor="branchForm" tabs={[
                {
                    id: "1",
                    component:
                        <BranchForm
                            branchId={branchId}
                            mode={mode}
                            onAfterSuccessHandle={onAfterSuccessHandle}
                        />
                },
                {
                    id: "2",
                    component: mode !== formMode.create && branchId &&
                    <TimetableForm branchId={branchId} mode={mode}/>
                },
            ]}/>
        </FormFlexible>
    </>;
}

export default BranchFormContainer;
