import React, {useEffect, useState} from "react";
import "./WorkplacesPage.css";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle} from "../../../../../Core/hooks";
import {
    deleteOfficeAction,
    editOfficeAction,
    viewOfficeAction,
} from "./WorkplacesPageHelpers";
import {getOffices} from "../../../../Api/Administration/PersonnelManagement/Offices";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import OfficeFilters from "../../../../Components/Administration/PersonnelManagement/Offices/OfficeFilters";
import OfficeFormContainer from "../../../../Components/Administration/PersonnelManagement/Offices/OfficeFormContainer";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {OfficesResponseInterface} from "../../../../Api/Administration/PersonnelManagement/Offices/OfficesInterface";

export default function WorkplacesPage() {
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    const [pagination, setPagination] = useState({});
    usePageTitle("Робочі місця");

    const [offices, setOffices] = useState<OfficesResponseInterface>();
    const [officeId, setOfficeId] = useState<number | string>();

    const [isReload, setIsReload] = useState(true);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [afterSuccessCreateTitle, setAfterSuccessCreateTitle] = useState(false);


    const gridTitles: GridTitleInterface[] = [
        {
            title: "ID",
            code: "id",
            width: "50px",
        },
        {
            title: "Кабінет",
            code: "name",
            width: "270px",
        },
        {
            title: "Філія",
            code: "branchName",
            width: "270px",
        },
    ];

    useEffect(() => {
        if (isReload) {
            getOffices({filters: searchParams, pagination}).then(response => {
                if (response?.status === "success") {
                    setOffices(response);
                }

                setIsReload(false);
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAfterSuccessCreateTitle(false);
    }, [isModalCreateOpen]);


    const onFilterHandler = (data: any) => {
        setSearchParams(data);
        setIsReload(true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setIsReload(true);
        setPagination(pagination);
    };

    const onSuccessCreateHandler = () => {
        setIsReload(true);
        setAfterSuccessCreateTitle(true);
    };

    const onSuccessDeleteHandler = () => setIsReload(true);

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "view",
            functions: {setIsModalViewOpen, setOfficeId},
            isShow: permission.isView("office"),
            action: viewOfficeAction,
        },
        {
            title: "Редагувати",
            name: "edit",
            classNameItem: "lynx-button-action-warning",
            functions: {setIsModalEditOpen, setOfficeId},
            isShow: permission.isEdit("office"),
            action: editOfficeAction,
        },
        {
            title: "Видалити",
            name: "delete",
            classNameItem: "lynx-button-action-danger",
            functions: {onSuccessDeleteHandler},
            isShow: permission.isDelete("office"),
            action: deleteOfficeAction,
        },
    ];


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Робочі місця" tree={["Адміністрування", "Управління персоналом"]}/>
                <div className="lynx-offices-page__buttons">
                    {permission.isCreate("office") &&
                        <>
                            <Button
                                name="addWorkplace"
                                title="Додати"
                                className="lynx-btn-primary"
                                onClick={() => setIsModalCreateOpen(true)}
                            />
                        </>
                    }
                </div>
            </BreadCrumbContainer>

            <br/>

            {permission.isView("office") &&
                <>
                    <div className="row">
                        <div className="col-12 order-2 col-xl-9 order-xl-1">
                            <Grid
                                data={offices?.data || []}
                                title={gridTitles}
                                pagination={offices?.pagination}
                                onLoadDataHandler={onLoadDataHandler}
                                actions={actions}
                            />
                        </div>
                        <div className="col-12 order-1 col-xl-3 order-xl-2">
                            <OfficeFilters onFilterHandler={onFilterHandler} searchParams={searchParams}/>
                        </div>
                    </div>
                </>
            }

            <ModalSimple
                isOpen={isModalViewOpen}
                body={
                    <OfficeFormContainer
                        officeId={officeId}
                        mode={formMode.view}
                    />}
                title="Перегляд кабінету"
                onAfterCloseHandle={() => setIsModalViewOpen(false)}
            />

            <ModalSimple
                isOpen={isModalCreateOpen}
                body={
                    <OfficeFormContainer
                        onAfterSuccessHandle={onSuccessCreateHandler}
                        mode={formMode.create}
                    />}
                title={afterSuccessCreateTitle ? "Редагування кабінету" : "Додавання нового кабінету"}
                onAfterCloseHandle={() => setIsModalCreateOpen(false)}
            />

            <ModalSimple
                isOpen={isModalEditOpen}
                body={
                    <OfficeFormContainer
                        onAfterSuccessHandle={() => setIsReload(true)}
                        officeId={officeId}
                        mode={formMode.edit}
                    />}
                title={"Редагування кабінету"}
                onAfterCloseHandle={() => setIsModalEditOpen(false)}
            />
        </>
    );
};