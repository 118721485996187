import React, {useState} from 'react';
import './BottomMenuTiny.css';
import {NavLink} from "react-router-dom";
import {BottomMenuTinyPropsInterface} from "./BottomMenuTinyInterface";

export default function BottomMenuTiny(props: BottomMenuTinyPropsInterface) {

    const {items} = props;

    const [isOpen, setState] = useState(false);

    const onClickHandler = () => {
        setState(!isOpen);
    }

    let visible = 'lynx-menu-footer-nav-tiny__content ' + (isOpen ? ' active' : '');

    return (
        <div className="lynx-menu-footer-nav-tiny">
            <div className="lynx-menu-footer-nav-tiny__toggle" onClick={onClickHandler}>
                <i className="fa-duotone fa-ellipsis" />
            </div>
            <div className={visible}>
                {items?.map((item, index) => (
                    <div className="lynx-menu-footer-nav-tiny__item" key={index}>
                        {item.handler && <i className={item.icon} onClick={item.handler}/>}
                        {!item.handler && <NavLink to={item.link} className={item.icon}/>}
                    </div>
                ))}
            </div>

        </div>
    );

}
