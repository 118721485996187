import lodash from "lodash";
import {styleVars} from "../../../../../Config/Utils/Style/Vars";
import EChartsReact from "../../EChartsReact/EChartsReact";
import {BasicLineChartOptionInterface, BasicLineChartPropsInterface} from "./BasicLineChartInterface";


export default function BasicLineChart(props: BasicLineChartPropsInterface) {
    const {
        title,
        data,
        option,
        opts,
        className,
        style,
        isPaddingOut,
    } = props;


    const textStyle = {
        fontFamily: "inherit",
        fontSize: styleVars.fontSizeText,
        fontWeight: 500,
        color: styleVars.colorDark65,
    };

    let defaultOption: BasicLineChartOptionInterface = {
        xAxis: {
            type: "category",
            data: data.xAxis,
            axisLabel: {
                textStyle,
                rotate: 7,
            },

        },
        yAxis: {
            type: "value",
            axisLabel: {
                textStyle: {
                    ...textStyle,
                    color: styleVars.colorDark50,
                }
            },
        },
        series: [
            {
                type: "line",
                data: data.series,
                smooth: true,
                lineStyle: {
                    color: styleVars.colorPrimary,
                    width: 2,
                },
                symbolSize: 8,
                itemStyle: {
                    color: styleVars.colorPrimary,
                }
            }
        ],
    };


    return <>
        {!!data.xAxis.length &&
            <EChartsReact
                option={lodash.merge(defaultOption, option)} // оновлюємо дефолтні опції глибоким злиттям об'єктів, заливши в них опції з пропа
                opts={opts}
                className={className}
                title={title}
                style={style}
                isPaddingOut={isPaddingOut}
                quantityColor={1}
            />
        }
    </>;
}