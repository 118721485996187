import chroma from "chroma-js";
import {styleVars} from "../../../../Config/Utils/Style/Vars";
import {DualListSelectorBlockItemContainerPropsInterface} from "./DualListSelectorBlockContainerInterface";

export default function DualListSelectorBlockContainer(props: DualListSelectorBlockItemContainerPropsInterface) {
    const {
        color,
        name,
        children,
    } = props;


    const backgroundColorBlock = chroma(color).alpha(0.4).css();


    return (
        <div
            className="lynx-dual-list-selector-block"
            style={{backgroundColor: backgroundColorBlock}}
        >
            <div className="lynx-dual-list-selector-block__title">{name}</div>
            <div
                className="lynx-dual-list-selector-block__list"
                style={{backgroundColor: styleVars.colorWhite}}
            >
                {children}
            </div>
        </div>
    );
}