import React, {useEffect, useRef, useState} from "react";
import "./DocumentReader.css";
import {DocumentReaderPropsInterface} from "./DocumentReaderInterface";

import tinymce, {Editor} from "tinymce";

// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";
// Langs
import "./langs/uk";

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import "tinymce/plugins/accordion";
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/table";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";

// importing plugin resources
import "tinymce/plugins/emoticons/js/emojis";

export default function DocumentReader(props: DocumentReaderPropsInterface) {
    const {
        name,
        value,
        height = 500,
        disabled,
        initComponent,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler,
    } = props;


    const ref = useRef<HTMLDivElement>(null);
    const editorRef = useRef<Editor | null>(null);
    const [initialized, setInitialized] = useState(false);


    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                beforeChangeHandler,
                afterChangeHandler,
            });
        }
    }, [initComponent]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!ref.current || !initialized || !value) return;

        editorRef.current?.setContent(value);
    }, [value, initialized]);

    useEffect(() => {
        if (!ref.current) return;

        const initTinymce = () => {
            tinymce.init({
                target: ref.current ?? undefined,
                language: "uk",
                height,
                readonly: disabled,
                menubar: true,
                plugins: [
                    // "accordion",
                    "advlist",
                    // "anchor",
                    "autolink",
                    "autosave",
                    "charmap",
                    // "code",
                    // "codesample",
                    "directionality",
                    "emoticons",
                    "fullscreen",
                    // "image",
                    "importcss",
                    "insertdatetime",
                    // "link",
                    "lists",
                    // "media",
                    "nonbreaking",
                    "pagebreak",
                    "preview",
                    "quickbars",
                    "save",
                    "searchreplace",
                    "table",
                    "visualblocks",
                    "visualchars",
                    "wordcount"
                ],
                toolbar: "print | undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat",
                content_style: "body { font-family: Open Sans, sans-serif; font-size: 14px }",
                setup: currentEditor => {
                    currentEditor.on("init", () => {
                        editorRef.current = currentEditor;
                        setInitialized(true);
                    });

                    currentEditor.on("change", () => {
                        changeHandler && changeHandler({
                            name,
                            value: currentEditor.getContent(),
                        });
                    });
                },
            });
        };

        initTinymce();

        return () => {
            editorRef.current && editorRef.current.remove();
        };
    }, [ref.current, disabled]); // eslint-disable-line react-hooks/exhaustive-deps


    return <div ref={ref} data-testid="document-reader"/>;
}
