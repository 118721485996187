import {useSelector} from "react-redux";
import {
    TabsLinkContentInterface,
    TabsLinkContentPropsInterface,
    TabsLinkSelectorInterface
} from "./TabsLinkContentInterface";
import {TabsLinkNavigationType} from "../TabsLinkNavigation/TabsLinkNavigationInterface";

const TabsLinkContent = ({tabFor, tabs}: TabsLinkContentPropsInterface) => {
    const tabsLink: TabsLinkSelectorInterface = useSelector((store: { system: { tabsLink: TabsLinkNavigationType[] } }) => store.system.tabsLink);
    const activeTab = tabsLink[tabFor]

    if (!activeTab) {
        return <></>;
    }

    const tab: TabsLinkContentInterface | undefined = tabs.filter(tab => tab.id === activeTab.id)?.shift()

    return <>{tab?.component}</>;
}

export default TabsLinkContent
