import {request} from "../../../../../Core/Utils/Request/Request";
import {
    ServiceResponseInterface,
    ServicesRequestInterface,
    ServicesResponseInterface,
} from "../Services/ServicesInterface";


export const getDeletedServices = async (
    params?: ServicesRequestInterface
): Promise<ServicesResponseInterface> => {
    return await request({
        url: "/v1/services/deleted/filters",
        method: "post",
        data: params,
    });
};

export const findDeletedServiceById = async (id: number | string): Promise<ServiceResponseInterface> => {
    return await request({
        url: `/v1/services/deleted/${id}`,
        method: "get"
    });
};

export const restoreDeletedService = async (id: number | string): Promise<ServiceResponseInterface> => {
    return await request({
        url: `/v1/services/deleted/${id}`,
        method: "patch",
    });
};