import React, {useEffect, useState} from "react";
import {Scrollbars} from "react-custom-scrollbars-2";
import moment from "moment";
import "moment/locale/uk";
import "./EmployeeTimetableGrid.css";

import {useLynxPermission} from "../../../../../../Modules/AdministrationUsers/hooks";
import {getEmployeeTimetables} from "../../../../../Api/Administration/PersonnelManagement/EmployeeTimetable";
import {getTimetables} from "../../../../../Api/Branches/Timetables";
import {useReloadComponent} from "../../../../../../Core/hooks";
import {setReloadComponent} from "../../../../../../Core/Store/Reload/Actions";
import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {generateDateArray, getAllWorkingHours, isBranchWeekend} from "./EmployeeTimetableGridHelpers";

import EmployeeModal from "../../Employees/EmployeeModal";
import EmployeeTimetableWorkday from "../EmployeeTimetableDayTypes/EmployeeTimetableWorkday";
import EmployeeTimetableGeneralType from "../EmployeeTimetableDayTypes/EmployeeTimetableGeneralType";
import EmployeeTimetableNotType from "../EmployeeTimetableDayTypes/EmployeeTimetableNotType";
import DatePickerSwitcher from "../../../../../../Core/Components/DatePickerSwitcher";

import {EmployeeTimetableGridPropsInterface} from "./EmployeeTimetableGridInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {
    EmployeeTimetableFiltersInterface,
    EmployeeTimetableInterface,
} from "../../../../../Api/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableInterface";
import {TimetableTypesEnum} from "../../../../../Api/Directories/TimetableTypes/TimetableTypesInterface";
import {
    DateTypeEnum,
    OnChangeDatePickerSwitcherParamsInterface,
} from "../../../../../../Core/Components/DatePickerSwitcher/DatePickerSwitcherInterface";


export default function EmployeeTimetableGrid({branchId}: EmployeeTimetableGridPropsInterface) {
    const [permission] = useLynxPermission();

    // для побудови таблиці
    const [branchWeekendId, setBranchWeekendId] = useState<number[]>([]);
    const [employeeTimetables, setEmployeeTimetables] = useState<EmployeeTimetableInterface[]>();
    const [dateRangeVisible, setDateRangeVisible] = useState(() => generateDateArray());
    const [employeeTimetablesFilters, setEmployeeTimetablesFilters] = useState<OnChangeDatePickerSwitcherParamsInterface>();

    // для EmployeeModal
    const [employeeId, setEmployeeId] = useState<number | string>();
    const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
    const [mode, setMode] = useState<formMode>();

    const isReload = useReloadComponent("employeeTimetableGrid", true);


    useEffect(() => {
        branchId && getTimetables({filters: {branchId}}).then(response => {
            const branchWeekendId: number[] = [];

            response?.data?.forEach(({isWeekend, weekDayId}) => {
                isWeekend && branchWeekendId.push(weekDayId);
            });

            setBranchWeekendId(branchWeekendId);
        });
    }, [branchId]);

    useEffect(() => {
        if (isReload && branchId && employeeTimetablesFilters) {
            let filters: EmployeeTimetableFiltersInterface = {branchId};

            if (employeeTimetablesFilters.period === DateTypeEnum.Month) {
                filters = {
                    ...filters,
                    month: dateRangeVisible[0],
                };
            } else if (employeeTimetablesFilters.period === DateTypeEnum.Week) {
                filters = {
                    ...filters,
                    week: {
                        year: employeeTimetablesFilters.year,
                        week_number: Number(employeeTimetablesFilters.value),
                    },
                };
            }

            getEmployeeTimetables({filters}).then((response) => {
                if (response?.data) {
                    setEmployeeTimetables(response.data);
                }

                setReloadComponent("employeeTimetableGrid", false);
            });
        }
    }, [isReload, employeeTimetablesFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    const onEmployeeNameClickHandler = (employeeId: number | string) => {
        let mode: (formMode | null) = null;

        if (permission.isEdit("employee")) {
            mode = formMode.edit;
        } else if (permission.isView("employee")) {
            mode = formMode.view;
        } else {
            addAlert({
                type: "warning",
                message: "Відсутні права на перегляд",
            });
            return;
        }

        setMode(mode);
        setEmployeeId(employeeId);
        setIsEmployeeModalOpen(true);
    };

    const onSuccessHandler = () => {
        setIsEmployeeModalOpen(false);
        setReloadComponent("employeeTimetableGrid", true);
    };

    const onSwitcherChange = (params: OnChangeDatePickerSwitcherParamsInterface) => {
        setDateRangeVisible(generateDateArray(params));
        setReloadComponent("employeeTimetableGrid", true);
        setEmployeeTimetablesFilters(params);
    };

    const dateRangeMarkup = (
        <div className="table__row">
            {dateRangeVisible.map(date => {
                return (
                    <div className="table__header-body-cell" key={date}>
                        <div>{moment(date, "YYYY-MM-DD").format("DD")}</div>
                        <div>{moment(date, "YYYY-MM-DD").format("dd")}</div>
                    </div>
                );
            })}
        </div>
    );


    return (
        <>
            <DatePickerSwitcher
                onChange={onSwitcherChange}
                timeframeButtons={[DateTypeEnum.Month, DateTypeEnum.Week]}
            />

            <br/>

            <div className="lynx-employee-timetable__table">
                <div className="table__first-col">
                    <div className="table__first-col-header-cell">Співробітник</div>
                    {employeeTimetables?.map(employeeTimetable => {
                        const {hours, minutes} = getAllWorkingHours(employeeTimetable);

                        return (
                            <div className="table__first-col-cell" key={employeeTimetable.employeeId}>
                                <div className="table__row-title">
                                    <div onClick={() => onEmployeeNameClickHandler(employeeTimetable.employeeId)}>
                                        {employeeTimetable.employeeName}
                                    </div>
                                    <div>{employeeTimetable.jobPositions.join(", ")}</div>
                                </div>
                                <div className="table__row-total">
                                    <i className="fa-duotone fa-clock"/>
                                    <div>
                                        <div>{hours} год</div>
                                        {minutes > 0 && <div>{minutes} хв</div>}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {!!employeeTimetables?.length && <div className="table__scrolled-col">
                    <Scrollbars>
                        {dateRangeMarkup}

                        {/* ітерація по співробітниках */}
                        {employeeTimetables?.map((employeeTimetable, indexEmployeeTimetable) => {
                            return (
                                <div className="table__row" key={employeeTimetable.employeeId}>
                                    {/* ітерація по діапазону дат, які вибрані (місяць/тиждень) */}
                                    {dateRangeVisible?.map(date => {
                                        const bodyCellClass = ["table__body-cell"];
                                        let bodyCellInnerClass = ["table__body-cell-inner"];

                                        // останній співробітник в графіку
                                        if (employeeTimetables.length === indexEmployeeTimetable + 1) {
                                            bodyCellClass.push("table__body-cell--not-border-bottom");
                                        }
                                        // вихідний в філії
                                        if (isBranchWeekend(date, branchWeekendId)) {
                                            bodyCellClass.push("table__body-cell--branch-is-weekend");
                                        }

                                        // ітерація окремого дня графіку співробітника
                                        for (let i = 0; i < employeeTimetable.timetable.length; i++) {
                                            const dayTimetable = employeeTimetable.timetable[i];

                                            // якщо дата не потрапляє в вибраний діапазон дат, то пропускаємо цю ітерацію
                                            if (dayTimetable.date !== date) {
                                                continue;
                                            }

                                            bodyCellInnerClass = ["table__body-cell-inner"];

                                            // робочий день
                                            if (dayTimetable.dayTypeId === TimetableTypesEnum.Workday) {
                                                bodyCellInnerClass.push("table__body-cell-inner--workday");

                                                return (
                                                    <div className={bodyCellClass.join(" ")} key={date}>
                                                        <EmployeeTimetableWorkday
                                                            className={bodyCellInnerClass.join(" ")}
                                                            branchId={branchId}
                                                            employeeId={employeeTimetable.employeeId}
                                                            employeeName={employeeTimetable.employeeName}
                                                            dayTimetable={dayTimetable}
                                                        />
                                                    </div>
                                                )
                                            }

                                            // вихідний
                                            if (dayTimetable.dayTypeId === TimetableTypesEnum.DayOff) {
                                                bodyCellInnerClass.push("table__body-cell-inner--day-off");

                                                return (
                                                    <div className={bodyCellClass.join(" ")}
                                                         title="У співробітника вихідний"
                                                         key={date}>
                                                        <EmployeeTimetableGeneralType
                                                            className={bodyCellInnerClass.join(" ")}
                                                            branchId={branchId}
                                                            employeeId={employeeTimetable.employeeId}
                                                            employeeName={employeeTimetable.employeeName}
                                                            dayTimetable={dayTimetable}
                                                            iconClassName="fa-duotone fa-calendar-xmark fa-xl"
                                                        />
                                                    </div>
                                                )
                                            }

                                            // відпустка
                                            if (dayTimetable.dayTypeId === TimetableTypesEnum.Vacation) {
                                                bodyCellInnerClass.push("table__body-cell-inner--vacation");

                                                return (
                                                    <div className={bodyCellClass.join(" ")}
                                                         title="Відпустка"
                                                         key={date}>
                                                        <EmployeeTimetableGeneralType
                                                            className={bodyCellInnerClass.join(" ")}
                                                            branchId={branchId}
                                                            employeeId={employeeTimetable.employeeId}
                                                            employeeName={employeeTimetable.employeeName}
                                                            dayTimetable={dayTimetable}
                                                            iconClassName="fa-solid fa-island-tropical"
                                                        />
                                                    </div>
                                                )
                                            }


                                            // лікарняний
                                            if (dayTimetable.dayTypeId === TimetableTypesEnum.SickDay) {
                                                bodyCellInnerClass.push("table__body-cell-inner--sick-day");

                                                return (
                                                    <div className={bodyCellClass.join(" ")}
                                                         title="Лікарняний"
                                                         key={date}>
                                                        <EmployeeTimetableGeneralType
                                                            className={bodyCellInnerClass.join(" ")}
                                                            branchId={branchId}
                                                            employeeId={employeeTimetable.employeeId}
                                                            employeeName={employeeTimetable.employeeName}
                                                            dayTimetable={dayTimetable}
                                                            iconClassName="fa-duotone fa-kit-medical"
                                                        />
                                                    </div>
                                                )
                                            }

                                            // відпустка за власний рахунок
                                            if (dayTimetable.dayTypeId === TimetableTypesEnum.UnpaidLeave) {
                                                bodyCellInnerClass.push("table__body-cell-inner--unpaid-leave");

                                                return (
                                                    <div className={bodyCellClass.join(" ")}
                                                         title="Відпустка за власний рахунок"
                                                         key={date}>
                                                        <EmployeeTimetableGeneralType
                                                            className={bodyCellInnerClass.join(" ")}
                                                            branchId={branchId}
                                                            employeeId={employeeTimetable.employeeId}
                                                            employeeName={employeeTimetable.employeeName}
                                                            dayTimetable={dayTimetable}
                                                            iconClassName="fa-solid fa-circle-dollar"
                                                        />
                                                    </div>
                                                )
                                            }

                                            // відрядження
                                            if (dayTimetable.dayTypeId === TimetableTypesEnum.BusinessTrip) {
                                                bodyCellInnerClass.push("table__body-cell-inner--business-trip");

                                                return (
                                                    <div className={bodyCellClass.join(" ")}
                                                         title="Відрядження"
                                                         key={date}>
                                                        <EmployeeTimetableGeneralType
                                                            className={bodyCellInnerClass.join(" ")}
                                                            branchId={branchId}
                                                            employeeId={employeeTimetable.employeeId}
                                                            employeeName={employeeTimetable.employeeName}
                                                            dayTimetable={dayTimetable}
                                                            iconClassName="fa-sharp fa-solid fa-plane"
                                                        />
                                                    </div>
                                                )
                                            }
                                        }
                                        // якщо графік не створений на цей день
                                        return (
                                            <div
                                                className={bodyCellClass.join(" ")}
                                                title="Створити графік"
                                                key={date}>
                                                <EmployeeTimetableNotType
                                                    className={bodyCellInnerClass.join(" ")}
                                                    branchId={branchId}
                                                    employeeName={employeeTimetable.employeeName}
                                                    employeeId={employeeTimetable.employeeId}
                                                    date={date}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            );
                        })}
                    </Scrollbars>
                </div>}


                {!employeeTimetables?.length &&
                    <div className="not-found-data__wrap">
                        {dateRangeMarkup}
                        <div className="not-found-data">

                            <i className="fa-duotone fa-memo-circle-info not-found-data__icon"/>
                            <span className="not-found-data__text">Дані відсутні</span>
                        </div>
                    </div>
                }
            </div>

            <EmployeeModal
                isOpen={isEmployeeModalOpen}
                employeeId={employeeId}
                mode={mode}
                onAfterSuccessHandler={onSuccessHandler}
                onAfterCloseHandle={() => setIsEmployeeModalOpen(false)}
            />
        </>
    );
};