export const filterMenu = (menu: any): any => {
    let resultItems = [];

    for (const submenu of menu) {
        if (!!submenu.items?.length) {
            submenu.items = filterMenu(submenu.items);

            !!submenu.items.length && resultItems.push(submenu);
        }

        if (submenu.isShow === false) {
            continue;
        }

        if (!submenu.items) {
            resultItems.push(submenu);
        }
    }

    return resultItems;
};