import {useEffect, useState} from "react";
import "./ApplicationSettingsSecurity.css";

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {editApplicationSettings, getApplicationSettings} from "../../../../../Api/Administration/ApplicationSettings";
import {useLynxPermission} from "../../../../../../Modules/AdministrationUsers/hooks";

import FieldsetWithElements from "../../../../../../Core/Components/Forms/Elements/FieldsetWithElements";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import Prompt from "../../../../../../Core/Components/Modal/Prompt";
import ApplicationSettingsGroupForm from "../../ApplicationSettingsGroupForm";

import {
    ApplicationSettingGroupIdsEnum,
    ApplicationSettingInterface,
    ApplicationSettingKeysEnum,
} from "../../../../../Api/Administration/ApplicationSettings/ApplicationSettingsInterface";


export default function ApplicationSettingsSecurity() {
    const [permission] = useLynxPermission();
    const [settingsSecurity, setSettingsSecurity] = useState<ApplicationSettingInterface[]>();
    const [prompt, setPrompt] = useState<{
        title: string,
        description: string,
        onApprove(): void,
    } | null>(null);


    useEffect(() => {
        getApplicationSettings({filters: {groupId: ApplicationSettingGroupIdsEnum.Security}})
            .then(response => {
                response?.data && setSettingsSecurity(response.data);
            });
    }, []);


    const onCheck = (key: ApplicationSettingKeysEnum) => {
        const setting = settingsSecurity?.find(setting => {
            return setting.keyName === key;
        });

        if (!setting) return;

        let params: { [key: string]: any };
        switch (key) {
            case ApplicationSettingKeysEnum.EmergencyBlockingOfTheClinic:
                params = {value: 1};
        }

        editApplicationSettings(setting.id, params).then(response => {
            if (response?.data) {
                addAlert({
                    type: "success",
                    message: response.message,
                });
                setPrompt(null);
            }
        });
    };

    
    return (
        <>
            <div className="lynx-application-settings-security">
                <div className="lynx-application-settings-security__left">
                    <ApplicationSettingsGroupForm settings={settingsSecurity}>
                        {settingsSecurity?.map(setting => {
                            return (
                                <FieldsetWithElements
                                    title={setting.title}
                                    elementInTheEnd
                                    key={setting.keyName}
                                >
                                    <Button
                                        name={setting.keyName}
                                        title="Заблокувати"
                                        className="lynx-btn-primary"
                                        disabled={!permission.isEdit("applicationSettings")}
                                        onClick={() => {
                                            setPrompt({
                                                title: setting.title,
                                                description: "Ви впевнені, що хочете заблокувати клініку?",
                                                onApprove: () => {
                                                    onCheck(setting.keyName)
                                                },
                                            })
                                        }}
                                    />
                                </FieldsetWithElements>
                            );
                        })}
                    </ApplicationSettingsGroupForm>
                </div>
                <div className="lynx-application-settings-security__right"></div>
            </div>

            {prompt &&
                <Prompt
                    isOpen
                    title={prompt?.title || ""}
                    description={prompt?.description || ""}
                    onCancel={() => setPrompt(null)}
                    onApprove={prompt?.onApprove}
                />
            }
        </>
    );
}