import {request} from "../../../../Core/Utils/Request/Request";
import {
    WorkplaceRequestInterface,
    WorkplaceResponseInterface,
    WorkplacesRequestInterface,
    WorkplacesResponseInterface
} from "./WorkplacesInterface";


export const getWorkplaces = async (): Promise<WorkplacesResponseInterface> => {
    return await request({
        url: "/v1/directory/workplaces",
        method: "get",
    });
};

export const getFilteredWorkplaces = async (params?: WorkplacesRequestInterface): Promise<WorkplacesResponseInterface> => {
    return await request({
        url: "/v1/directory/workplaces/filters",
        method: "post",
        data: params,
    });
};

export const findWorkplaceById = async (id: number | string): Promise<WorkplaceResponseInterface> => {
    return await request({
        url: `/v1/directory/workplaces/${id}`,
        method: "get"
    });
};

export const createWorkplace = async (params: WorkplaceRequestInterface): Promise<WorkplaceResponseInterface> => {
    return await request({
        url: "/v1/directory/workplaces",
        method: "post",
        data: params
    });
};

export const editWorkplace = async (id: number, params: WorkplaceRequestInterface): Promise<WorkplaceResponseInterface> => {
    return await request({
        url: `/v1/directory/workplaces/${id}`,
        method: "patch",
        data: params
    });
};

export const deleteWorkplace = async (id: number): Promise<WorkplaceResponseInterface> => {
    return await request({
        url: `/v1/directory/workplaces/${id}`,
        method: "delete"
    });
};
