import {signOut} from "../../../Modules/Auth/Utils/Sign";
import {configLoader} from "../../../Core/Utils/ConfigLoader";
import {
    ProfileNavigationConfigType
} from "../../../Core/Components/ControlPanel/Containers/Headers/HeaderProfile/ProfileNavigation/ProfileNavigationContainer/ProfileNavigationContainerInterface";


export const profileNavigationConfig: ProfileNavigationConfigType = [
    [
        {
            title: "Профіль",
            handler: () => window.open(configLoader().authConfig.redirectToProfile.link, '_blank'),
        }
    ],
    [
        {
            title: "Налаштування",
            link: "/",
        },
        {
            title: "Вихід",
            handler: signOut,
        },
    ],
];