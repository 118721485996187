import {useSearchParams} from "react-router-dom";

export default function useLynxSearchParams(): any {
    const [search, setSearch] = useSearchParams();

    let keys: any[] = [];

    //Проблема с некорректным TS, ждет исправлений TypeScript
    // @ts-ignore
    for (let key of search.keys()) {
        if (!keys.includes(key)) {
            keys.push(key)
        }
    }

    const searchAsObject: {} = {};
    for (let key of keys){
        let element = search.getAll(key);
        if(element.length > 1){
            // @ts-ignore
            searchAsObject[key] = element;
        }else{
            // @ts-ignore
            searchAsObject[key] = search.get(key);
        }
    }

    const setSearchParams = (data: { [key: string]: string }) => setSearch(data ? data : {});

    return [searchAsObject, setSearchParams];
};
