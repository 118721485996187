import NotFound from "../../Core/Page/NotFound/NotFound";
import ErrorPage from "../../Core/Page/ErrorPage";
import AuthenticationSuccessPage from "../../Modules/Auth/Pages/AuthenticationSuccessPage";
import DashboardPage from "../../Project/Pages/DashboardPage";
import {RouteObject} from "react-router-dom";
import PrivateOutlet from "../../Core/Utils/Routing/PrivateOutlet";
import PermissionCheckPage from "../../Project/Pages/PermissionCheckPage";
import FeedsPage from "../../Project/Pages/Feeds/FeedsPage";
import GroupPermissionPages from "../../Modules/AdministrationUsers/Pages/Permissions/GroupPermissionPages";
import BranchPage from "../../Project/Pages/Branches/BranchPage";
import ClientsPage from "../../Project/Pages/CRM/Clients/ClientsPage";
import ClientSourcesPage from "../../Project/Pages/Administration/Directories/ClientSourcesPage";
import CustomerGroupsPage from "../../Project/Pages/CRM/Marketing/CustomerGroupsPage";
import ServiceGroupsPage from "../../Project/Pages/Administration/ServiceManagement/ServiceGroupsPage";
import ClientDetailsPage from "../../Project/Pages/CRM/Clients/ClientDetailsPage";
import ServicesPage from "../../Project/Pages/Administration/ServiceManagement/ServicesPage";
import VisitsPage from "../../Project/Pages/CRM/RecordManagement/VisitsPage";
import JobPositionsPage from "../../Project/Pages/Administration/Directories/JobPositionsPage";
import EmployeesPage from "../../Project/Pages/Administration/PersonnelManagement/EmployeesPage";
import WorkplacesPage from "../../Project/Pages/Administration/PersonnelManagement/WorkplacesPage";
import CalendarPage from "../../Project/Pages/Calendar/CalendarPage";
import EmployeeTimetablePage from "../../Project/Pages/Administration/PersonnelManagement/EmployeeTimetablePage";
import ServicesPerformedCertificatesPage
    from "../../Project/Pages/Documents/ServicesPerformedCertificates/ServicesPerformedCertificatesPage";
import ApplicationSettingsPage from "../../Project/Pages/Administration/ApplicationSettings/ApplicationSettingsPage";
import LoadingProcessPage from "../../Project/Pages/LoadingProcess/LoadingProcessPage";
import NumberOfVisitsPage from "../../Project/Pages/Reports/NumberOfVisits/NumberOfVisitsPage";
import DeletedServicesPage from "../../Project/Pages/Administration/ServiceManagement/DeletedServicesPage";
import ClientPortraitPage from "../../Project/Pages/Reports/ClientPortrait/ClientPortraitPage";
import ServiceAgreementsPage from "../../Project/Pages/Documents/ServiceAgreements/ServiceAgreementsPage";
import ClientPaymentsPage from "../../Project/Pages/Payments/ClientPaymentsPage";
import UserSettingsPage from "../../Project/Pages/UserSettings/UserSettingsPage";

const routes: RouteObject[] = [
    {
        path: "/authentication-success/:code",
        element: <AuthenticationSuccessPage/>,
    },
    {
        path: "/error-page/:message",
        element: <ErrorPage/>,
    },
    {
        path: "/",
        element: <PrivateOutlet/>,
        children: [
            {
                path: "/loading-process",
                element: <LoadingProcessPage/>,
            },
            {
                path: "/",
                element: <DashboardPage/>,
                children: [
                    {
                        index: true,
                        element:
                            <PermissionCheckPage permissionCode="feed">
                                <FeedsPage/>
                            </PermissionCheckPage>
                    },
                    {
                        path: "administration",
                        children: [
                            {
                                path: 'group-permission',
                                element:
                                    <PermissionCheckPage permissionCode="permissionGroup">
                                        <GroupPermissionPages/>
                                    </PermissionCheckPage>
                            },
                            // в базі users залишається, але для користувача фактично users = employees
                            // {
                            //     path: 'users',
                            //     element:
                            //         <PermissionCheckPage permissionCode="user">
                            //             <UsersPage/>
                            //         </PermissionCheckPage>
                            // },
                            {
                                path: 'branches',
                                element:
                                    <PermissionCheckPage permissionCode="branch">
                                        <BranchPage/>
                                    </PermissionCheckPage>
                            },
                            {
                                path: 'service-management',
                                children: [
                                    {
                                        path: 'service-groups',
                                        element:
                                            <PermissionCheckPage permissionCode="serviceGroup">
                                                <ServiceGroupsPage/>
                                            </PermissionCheckPage>,
                                    },
                                    {
                                        path: 'services',
                                        element:
                                            <PermissionCheckPage permissionCode="service">
                                                <ServicesPage/>
                                            </PermissionCheckPage>,
                                    },
                                    {
                                        path: 'deleted-services',
                                        element:
                                            <PermissionCheckPage permissionCode="deletedService">
                                                <DeletedServicesPage/>
                                            </PermissionCheckPage>,
                                    },
                                ]
                            },
                            {
                                path: 'personnel-management',
                                children: [
                                    {
                                        path: 'employees',
                                        element:
                                            <PermissionCheckPage permissionCode="employee">
                                                <EmployeesPage/>
                                            </PermissionCheckPage>,
                                    },
                                    {
                                        path: 'employee-timetable',
                                        element:
                                            <PermissionCheckPage permissionCode="employeeTimetable">
                                                <EmployeeTimetablePage/>
                                            </PermissionCheckPage>,
                                    },
                                    {
                                        path: "job-positions",
                                        element:
                                            <PermissionCheckPage permissionCode="jobPosition">
                                                <JobPositionsPage/>
                                            </PermissionCheckPage>
                                    },
                                    {
                                        path: 'workplaces',
                                        element:
                                            <PermissionCheckPage permissionCode="office">
                                                <WorkplacesPage/>
                                            </PermissionCheckPage>,
                                    },
                                ],
                            },
                            {
                                path: "directories",
                                children: [
                                    {
                                        path: "client-sources",
                                        element:
                                            <PermissionCheckPage permissionCode="clientSource">
                                                <ClientSourcesPage/>
                                            </PermissionCheckPage>
                                    },
                                ],
                            },
                            {
                                path: "application-settings",
                                element:
                                    <PermissionCheckPage permissionCode="applicationSettings">
                                        <ApplicationSettingsPage/>
                                    </PermissionCheckPage>,
                            },
                        ]
                    },
                    {
                        path: "crm",
                        children: [
                            {
                                path: 'clients',
                                element:
                                    <PermissionCheckPage permissionCode="client">
                                        <ClientsPage/>
                                    </PermissionCheckPage>
                            },
                            {

                                path: 'clients/:clientId',
                                element:
                                    <PermissionCheckPage permissionCode="client">
                                        <ClientDetailsPage/>
                                    </PermissionCheckPage>
                            },
                            {
                                path: 'marketing',
                                children: [
                                    {
                                        path: 'customer-groups',
                                        element:
                                            <PermissionCheckPage permissionCode="customerGroup">
                                                <CustomerGroupsPage/>
                                            </PermissionCheckPage>
                                    },
                                ]
                            },
                            {
                                path: "record-management",
                                children: [
                                    {
                                        path: 'visits',
                                        element:
                                            <PermissionCheckPage permissionCode="visits">
                                                <VisitsPage/>
                                            </PermissionCheckPage>
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        path: "documents",
                        children: [
                            {
                                path: "services-performed-certificates",
                                element:
                                    <PermissionCheckPage permissionCode="servicesPerformedCertificate">
                                        <ServicesPerformedCertificatesPage/>
                                    </PermissionCheckPage>,
                            },
                            {
                                path: "service-agreement",
                                element:
                                    <PermissionCheckPage permissionCode="serviceAgreement">
                                        <ServiceAgreementsPage/>
                                    </PermissionCheckPage>,
                            },
                        ],
                    },
                    {
                        path: "calendar",
                        element:
                            <PermissionCheckPage permissionCode="visits">
                                <CalendarPage/>
                            </PermissionCheckPage>,
                    },
                    {
                        path: "reports",
                        children: [
                            {
                                path: "number-of-visits",
                                element:
                                    <PermissionCheckPage permissionCode="visitReport">
                                        <NumberOfVisitsPage/>
                                    </PermissionCheckPage>,
                            },
                            {
                                path: "client-portrait",
                                element:
                                    <PermissionCheckPage permissionCode="clientReport">
                                        <ClientPortraitPage/>
                                    </PermissionCheckPage>,
                            },
                        ],
                    },
                    {
                        path: "payments",
                        children: [
                            {
                                path: "client-payments",
                                element:
                                    <PermissionCheckPage permissionCode="clientPayment">
                                        <ClientPaymentsPage/>
                                    </PermissionCheckPage>,
                            },
                        ],
                    },
                    {
                        path: "user-settings",
                        element: <UserSettingsPage/>,
                    },
                ]
            },
        ]
    },
    {
        path: "*",
        element: <PrivateOutlet/>,
        children: [{
            path: "*",
            element: <NotFound/>,
        }],
    },
];

export default routes;
