import {SaveFiltersFormPropsInterface} from "./SaveFiltersFormInterface";
import Form from "../../Form";
import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import FormStickyContainer from "../../Elements/FormStickyContainer";
import {useEffect, useState} from "react";
import {saveFilters} from "../../../../Api/Filters/Filter/Filter";
import {useDispatch} from "react-redux";
import {getFiltersAction} from "../../../../Store/Filters/Actions";
import {addAlert} from "../../../../Store/Alert/Actions";
import {SaveFilterRequestInterface} from "../../../../Api/Filters/Filter/FilterInterface";

export default function SaveFiltersForm(props: SaveFiltersFormPropsInterface) {

    const {code, filter, onAfterSuccessHandle} = props;

    const [isLoadFilters, setLoadFilters] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        if (isLoadFilters) {
            dispatch(getFiltersAction());
            setLoadFilters(false);
            addAlert({
                type: "success",
                message: "Шаблон успішно створено"
            })
            onAfterSuccessHandle();
        }
    }, [isLoadFilters, dispatch, onAfterSuccessHandle]);

    const onSubmitHandler = (data: { name: string }) => {
        const {name} = data;
        const params: SaveFilterRequestInterface = {
            name,
            code,
            filter
        };

        saveFilters(params).then(async (dataSaveFeeds) => {
            if (dataSaveFeeds?.data) {
                setLoadFilters(true);
            }
        })

    }

    return <>
        <Form onSubmit={onSubmitHandler}>
            <div className="row">
                <div className="col-12">
                    <Input
                        type="text"
                        name="name"
                        title="Назва шаблону"
                        required={true}
                    />
                </div>
            </div>
            <br/>
            <FormStickyContainer>
                <Button
                    type="submit"
                    name="send"
                    title="Створити шаблон"
                    className="lynx-btn-primary"
                />
            </FormStickyContainer>
        </Form>
    </>
}

