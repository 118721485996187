import chroma from "chroma-js";
import {DualListSelectorBlockItemContainerPropsInterface} from "./DualListSelectorBlockItemContainerInterface";

export default function DualListSelectorBlockItemContainer(props: DualListSelectorBlockItemContainerPropsInterface) {
    const {
        color,
        children,
    } = props;

    const backgroundColorItem = chroma(color).alpha(0.25).css();

    return (
        <div
            className="lynx-dual-list-selector-block__item"
            style={{backgroundColor: backgroundColorItem}}
        >
            {children}
        </div>
    );
}