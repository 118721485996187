import React, {useState} from "react";

import {useLynxPermission} from "../../../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../../../Core/Store/Reload/Actions";

import ClientPaymentsGrid from "../../../../../Payments/ClientPayments/ClientPaymentsGrid";
import FieldsetWithElements from "../../../../../../../Core/Components/Forms/Elements/FieldsetWithElements";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormHR from "../../../../../../../Core/Components/Forms/Elements/FormHR";
import ClientPaymentModal from "../../../../../Payments/ClientPayments/ClientPaymentModal";

import {ClientPaymentsBlockPropsInterface} from "./ClientPaymentsBlockInterface";
import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";


const ClientPaymentsBlock = ({clientId}: ClientPaymentsBlockPropsInterface) => {
    const [permission] = useLynxPermission();
    const [isModalOpen, setIsModalOpen] = useState(false);


    const onSuccessHandler = () => {
        setReloadComponent("ClientPaymentsGrid", true);
    };

    const formControl = {
        fieldset: {client: {visible: false}}
    };


    return (
        <>
            <FieldsetWithElements title="Список платежів" elementInTheEnd element={
                permission.isCreate("clientPayment")
                    ? <Button
                        name="createVsist"
                        title="Створити платіж"
                        className="lynx-btn-primary"
                        onClick={() => setIsModalOpen(true)}
                    /> : ""
            }/>

            <FormHR type="dashed" isPaddingOut/>

            <ClientPaymentsGrid
                filters={{clientId}}
                formControl={formControl}
                isHideNavigation
                isHidePadding
                isHideFilters
            />

            <ClientPaymentModal
                isOpen={isModalOpen}
                mode={formMode.create}
                data={{clientId}}
                formControl={formControl}
                onAfterCloseHandle={() => setIsModalOpen(false)}
                onAfterSuccessHandle={onSuccessHandler}
            />
        </>
    );
};

export default ClientPaymentsBlock;