import {Link} from "react-router-dom";
import "./ProfileNavigationBlockItem.css";
import {ProfileNavigationBlockItemPropsInterface} from "./ProfileNavigationBlockItemInterface";


export default function ProfileNavigationBlockItem({item}: ProfileNavigationBlockItemPropsInterface) {
    const titleMarkup = <><span>{item.title}</span>{item.icon && <i className={item.icon}/>}</>;

    return item.link
        ? <Link to={item.link} className="lynx-profile-navigation-item">{titleMarkup}</Link>
        : <div onClick={item.handler} className="lynx-profile-navigation-item">{titleMarkup}</div>;
};