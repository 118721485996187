import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {deleteOffice} from "../../../../Api/Administration/PersonnelManagement/Offices";
import {OfficeInterface} from "../../../../Api/Administration/PersonnelManagement/Offices/OfficesInterface";


export async function viewOfficeAction(data: OfficeInterface, functions: {
    setIsModalViewOpen(isOpen: boolean): void,
    setOfficeId(id: number | string): void,
}) {
    const {setIsModalViewOpen, setOfficeId} = functions;

    setIsModalViewOpen(true);
    setOfficeId(data.id);
}

export async function editOfficeAction(data: OfficeInterface, functions: {
    setIsModalEditOpen(isOpen: boolean): void,
    setOfficeId(id: number | string): void,
}) {
    const {setIsModalEditOpen, setOfficeId} = functions;

    setIsModalEditOpen(true);
    setOfficeId(data.id);
}

export async function deleteOfficeAction(data: OfficeInterface, functions: {
    onSuccessDeleteHandler(): void,
}) {
    const {onSuccessDeleteHandler} = functions;
    if (!onSuccessDeleteHandler) {
        return;
    }

    const response = await deleteOffice(data.id);
    if (response?.status === "success") {
        onSuccessDeleteHandler();
        addAlert({
            type: response.status,
            message: response.message,
        });
    }
}