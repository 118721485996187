import React from 'react';
import './BrandTiny.css';
import {BrandTinyPropsInterface} from "./BrandTinyInterface";

export default function BrandTiny(props: BrandTinyPropsInterface) {
    const {link, logo, title} = props;

    return (
        <div className="lynx-tiny-menu-brand">
            <a href={link} target="_blank" rel="noreferrer noopener">
                <img className="lynx-tiny-menu-brand__images" src={logo} alt={title}/>
            </a>
        </div>
    );
}
