import React from 'react';
import "./FormTabsContainer.css"
import {FormTabsContainerPropsInterface} from "./FormTabsContainerInterface";

export default function FormTabsContainer({children}: FormTabsContainerPropsInterface) {
    return (
        <div className="lynx-form-tabs-container__wrapper">
            <div className="lynx-form-tabs-container">{children}</div>
        </div>
    );
};