import {useEffect} from "react";
import "./ClientPaymentFilters.css"
import {useSelector, useDispatch} from "react-redux";

import {getLiveSearchClient} from "../../../../Api/CRM/Clients";
import {
    getClientPaymentFormsAction
} from "../../../../Store/Directories/Payments/ClientPayments/ClientPaymentForms/Actions";
import {
    getClientPaymentStatesAction
} from "../../../../Store/Directories/Payments/ClientPayments/ClientPaymentStates/Actions";
import {
    getClientPaymentTypesAction
} from "../../../../Store/Directories/Payments/ClientPayments/ClientPaymentTypes/Actions";

import ExpandableLink from "../../../../../Core/Components/ExpandableLink";
import DateTime from '../../../../../Core/Components/Forms/Elements/DateTime';
import FiltersForm from "../../../../../Core/Components/Forms/Filters/FiltersForm";
import DropDown, {prepareDropDownOptions} from "../../../../../Core/Components/Forms/Elements/DropDown";
import LiveSearch, {prepareLiveSearchOptions} from "../../../../../Core/Components/Forms/Elements/LiveSearch";

import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {
    ClientPaymentsFiltersPropsInterface,
} from "./ClientPaymentFiltersInterface";
import {LiveSearchOptionInterface} from "../../../../../Core/Components/Forms/Elements/LiveSearch/LiveSearchInterface";


function ClientPaymentsFilters(props: ClientPaymentsFiltersPropsInterface) {
    const {
        searchParams,
        onFilterHandler,
    } = props;


    const {
        clientPaymentStates,
        clientPaymentForms,
        clientPaymentTypes,
    } = useSelector((store: StoreInterface) => store.shortCache);
    const dispatch = useDispatch();


    useEffect(() => {
        !clientPaymentForms?.length && dispatch(getClientPaymentFormsAction());
        !clientPaymentStates?.length && dispatch(getClientPaymentStatesAction());
        !clientPaymentTypes?.length && dispatch(getClientPaymentTypesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const loadClientOptions = async (inputValue: string): Promise<LiveSearchOptionInterface[] | []> => {
        return getLiveSearchClient(inputValue).then(response => {
            return prepareLiveSearchOptions(response?.data, "id", "clientName");
        });
    };


    return (
        <FiltersForm
            code="clientPaymentFilters"
            searchParams={searchParams ? searchParams : {}}
            filterHandler={onFilterHandler}
        >
            <div className="row">
                <div className="client-payment-filters__inner">
                    <ExpandableLink title="Основні фільтри" isVisibleDefault>
                        <div className="col-12">
                            <LiveSearch
                                name="clientId"
                                loadOptions={loadClientOptions}
                                title="Клієнт"
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="stateId"
                                title="Статус"
                                options={prepareDropDownOptions(clientPaymentStates, "id", "name")}
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="typeId"
                                title="Тип платежу"
                                options={prepareDropDownOptions(clientPaymentTypes, "id", "name")}
                            />
                        </div>
                        <div className="col-12">
                            <DateTime name="dateStart" title="Дата (з)"/>
                        </div>
                        <div className="col-12">
                            <DateTime name="dateEnd" title="Дата (по)"/>
                        </div>
                    </ExpandableLink>

                    <ExpandableLink title="Додаткові фільтри">
                        <div className="col-12">
                            <DropDown
                                name="formId"
                                title="Тип оплати"
                                options={prepareDropDownOptions(clientPaymentForms, "id", "name")}
                            />
                        </div>
                    </ExpandableLink>
                </div>
            </div>
        </FiltersForm>
    );
}

export default ClientPaymentsFilters;
