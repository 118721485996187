import "./TabsLinkNavigationGeneralItem.css"
import {TabsLinkNavigationGeneralItemPropsInterface} from "./TabsLinkNavigationGeneralItemInterface";


export default function TabsLinkNavigationGeneralItem({icon, text}: TabsLinkNavigationGeneralItemPropsInterface) {
    return (
        <div className="lynx-tabs-link-navigation-general-item">
            {icon &&
                <div className="lynx-tabs-link-navigation-general-item__icon">
                    <i className={icon}/>
                </div>
            }
            {text && <div className="lynx-tabs-link-navigation-general-item__text">{text}</div>}
        </div>
    );
};