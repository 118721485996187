import {GlobalStatePropsInterface} from "./GlobalStateInterface";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {useMediaMatch} from "rooks";
import {setConf} from "../../Store/Conf/Actions";
import {screenType} from "../../Store/Conf/Types";

export default function GlobalState({children}: GlobalStatePropsInterface) {


    const dispatch = useDispatch();

    const isMobile = useMediaMatch('(max-width: 768px)');
    const isTablet = useMediaMatch('(min-width: 768px) and (max-width: 1024px)');
    const isDesktop = useMediaMatch('(min-width: 1024px)');



    useEffect(() => {
        let screen = screenType.mobile;

        if (isMobile) {
            screen = screenType.mobile;
        }

        if (isTablet) {
            screen = screenType.tablet;
        }

        if (isDesktop) {
            screen = screenType.desktop;
        }

        dispatch(setConf({screenType: screen}));
    }, [dispatch, isMobile, isTablet, isDesktop]);

    return <>{children}</>;
}