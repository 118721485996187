import {request} from "../../../../Core/Utils/Request/Request";
import {
    EmployeesResponseInterface,
    EmployeesRequestInterface,
    EmployeeResponseInterface,
    EmployeeRequestInterface,
    LiveSearchEmployeeRequestInterface,
    EmployeeWorkingBranchResponseInterface,
    LiveSearchEmployeeResponseInterface,
} from "./EmployeesInterface";


export const getEmployees = async (params?: EmployeesRequestInterface): Promise<EmployeesResponseInterface> => {
    return await request({
        url: '/v1/employees/filters',
        method: 'post',
        data: params,
    });
};

export const findEmployeeById = async (id: number | string): Promise<EmployeeResponseInterface> => {
    return await request({
        url: `/v1/employees/${id}`,
        method: 'get'
    });
};

export const createEmployee = async (params: EmployeeRequestInterface): Promise<EmployeeResponseInterface> => {
    return await request({
        url: '/v1/employees',
        method: 'post',
        data: params
    });
};

export const editEmployee = async (
    id: number | string, params: EmployeeRequestInterface
): Promise<EmployeeResponseInterface> => {
    return await request({
        url: `/v1/employees/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteEmployee = async (id: number | string): Promise<EmployeeResponseInterface> => {
    return await request({
        url: `/v1/employees/${id}`,
        method: 'delete'
    });
};

export const getLiveSearchEmployee = async (
    params: LiveSearchEmployeeRequestInterface
): Promise<LiveSearchEmployeeResponseInterface> => {
    return await request({
        url: "/v1/employee-search/filters",
        method: "post",
        data: params,
        options: {
            isShowSpinner: false,
        },
    });
};

export const getEmployeeWorkingBranches = async (): Promise<EmployeeWorkingBranchResponseInterface> => {
    return await request({
        url: '/v1/employees/working-branch',
        method: 'get',
        options: {
            isShowSpinner: false,
        }
    });
};
