import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import "./CalendarItem.css";

import {getTimesAction} from "../../../../Store/Directories/Times/Actions";
import {isTimeInRange} from "../../CalendarsHelpers";

import Avatar from "../../../../../Core/Components/Avatar";
import CalendarShortCard from "../CalendarCard/CalendarShortCard";
import CalendarEmptyCard from "../CalendarCard/CalendarEmptyCard";

import {CalendarItemInterface} from "./CalendarItemInterface";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";


const CalendarItem = React.memo((props: CalendarItemInterface) => {
    const {
        employee,
        date,
        employeesTimetable,
        branchId,
    } = props;

    const {
        userId,
        employeeName,
        avatar,
        visits,
    } = employee;

    const dispatch = useDispatch();
    const times = useSelector((store: StoreInterface) => store.longTermCache.times);


    useEffect(() => {
        !times.length && dispatch(getTimesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const employeeTimetableForDay = employeesTimetable
        .find(employeeTimetable => employeeTimetable.employeeName === employeeName)
        ?.timetable.find(timetableDay => timetableDay.date === date);


    return (
        <div className="lynx-calendar-item__item">
            <div className="lynx-calendar-item__item-header">
                {avatar
                    ? <img src={avatar} alt="Співробітник JetDents"/>
                    : <Avatar caller="CalendarItem" seed={userId} size={25}/>
                }
                <span>{employeeName}</span>
            </div>

            <div className="lynx-calendar-item__visits">
                {times?.map(({time}) => {
                    const visitForCurrentTime = visits.find(
                        visit => time === visit.timeStart
                    );

                    // картка з візитом
                    if (visitForCurrentTime) {
                        // розраховуємо к-ть частин по 15хв для визначення висоти карточки
                        const duration = moment.duration(visitForCurrentTime.duration)
                        const parts = duration.as("minutes") / 15;

                        return (
                            <div className="lynx-calendar-item__visit"
                                 key={time}
                            >
                                <div
                                    className="lynx-calendar-item__visit-inner"
                                    style={{height: `calc(${parts} * var(--calendarCellHeight))`}}
                                >
                                    <CalendarShortCard visit={visitForCurrentTime}/>
                                </div>
                            </div>
                        );
                    }

                    // визначачємо робочий час
                    const isWorkTime = employeeTimetableForDay && isTimeInRange(
                        employeeTimetableForDay.workDayPeriodStart,
                        employeeTimetableForDay.workDayPeriodEnd,
                        time,
                    );

                    const itemClassName = ["lynx-calendar-item__visit"];
                    !isWorkTime && itemClassName.push("lynx-calendar-item__visit--time-off-clinic");


                    return (
                        <div
                            key={time}
                            className={itemClassName.join(" ")}
                        >
                            <div
                                className="lynx-calendar-item__visit-inner"
                                style={{height: "var(--calendarCellHeight))"}}
                                title={
                                    isWorkTime ? "" : "Щоб додати візит, потрібно змінити графік роботи співробітника."
                                }
                            >
                                {isWorkTime &&
                                    <CalendarEmptyCard
                                        employee={employee}
                                        date={date}
                                        time={time}
                                        branchId={branchId}
                                    />
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default CalendarItem;