import axios from 'axios';
import qs from 'qs';

import {startLoading, stopLoading} from "../../Store/Application/Actions";
import {IRequest} from "./IRequest";
import RequestConfig from "../../../Config/Request/RequestConfig";
import IRequestConfig from "./IRequestConfig";
import {addAlert} from "../../Store/Alert/Actions";
import Cookies from "universal-cookie";
import {signOut} from "../../../Modules/Auth/Utils/Sign";

async function query(url: string, params: any, config: IRequestConfig) {
    let response;

    try {
        switch (config.method?.toLowerCase()) {
            case "get":
                response = await axios.get(
                    url,
                    config
                )
                break;
            case "post":
                response = await axios.post(
                    url,
                    params,
                    config
                )
                break;
            case "patch":
                response = await axios.patch(
                    url,
                    params,
                    config
                )
                break;
            case "delete":
                response = await axios.delete(
                    url,
                    config
                )
                break;
        }
    } catch (error: any) {
        if (error?.response?.status && error.response.status === 401) {
            await signOut();
        }

        if (error?.response?.status && error.response.status === 422) {
            addAlert({
                type: 'warning',
                message: error.response.message,
            });
        }

        if (error?.response?.status && error.response.status === 500) {
            addAlert({
                type: 'default',
                message: 'Виникла помилка на сервері. Ми вже працюємо над її вирішенням.'
            });
        }
    }

    if (response?.data?.status && response.data.status === 'error') {
        addAlert({
            type: 'error',
            message: response.data.message
        });
    }

    stopLoading();

    return response?.data ? response.data : null;
}

export async function request({url, method, data = {}, headers = {}, options = {}}: IRequest) {
    const {
        isShowSpinner = true,
    } = options;

    let headersRequest = {};
    let cookie = new Cookies();
    let token = cookie.get('token')
    if (token) {
        headersRequest = {
            Authorization: `Bearer ${token}`
        };
    }

    let config = Object.assign({}, RequestConfig);
    config.headers = Object.assign(headersRequest, RequestConfig.headers, headers);
    config.method = method;

    const qData = qs.stringify(data);
    isShowSpinner && startLoading();

    return query(url, qData, config);
}


export async function requestFiles({url, method, data = {}, headers = {}, options = {}}: IRequest) {
    const {
        isShowSpinner = true,
    } = options;

    const cookie = new Cookies();
    const token = cookie.get('token');

    const headersRequest = {
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'multipart/form-data',
    };

    let config = Object.assign({}, RequestConfig);
    config.headers = Object.assign(headersRequest, RequestConfig.headers, headers);
    config.method = method;

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item: any, index: number) => {
                formData.append(`${key}[${index}]`, item);
            });
        } else {
            formData.append(key, value);
        }
    });

    isShowSpinner && startLoading();

    return query(url, formData, config);
}