import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getTimesAction} from "../../../Store/Directories/Times/Actions";
import {editTimetable, getTimetables} from "../../../Api/Branches/Timetables";
import {addAlert} from "../../../../Core/Store/Alert/Actions";

import Form from "../../../../Core/Components/Forms/Form";
import FormStickyContainer from "../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Button from "../../../../Core/Components/Forms/Elements/Button";
import Fieldset from "../../../../Core/Components/Forms/Elements/Fieldset";
import FormHR from "../../../../Core/Components/Forms/Elements/FormHR";
import Checkbox from "../../../../Core/Components/Forms/Elements/Checkbox";
import DropDown, {prepareDropDownOptions} from "../../../../Core/Components/Forms/Elements/DropDown";

import {TimetableFormPropsInterface} from "./TimetableFormInterface";
import {formMode} from "../../../../Core/Components/Forms/Form/FormInterface";
import {TimetableInterface} from "../../../Api/Branches/Timetables/TimetablesInterface";
import {StoreInterface} from "../../../../Config/Redux/StoreInterface";


export default function TimetableForm({branchId, mode}: TimetableFormPropsInterface) {
    const dispatch = useDispatch();
    const times = useSelector((store: StoreInterface) => store.longTermCache.times);

    const [timetable, setTimetable] = useState<TimetableInterface[]>();
    const [preparedTimetable, setPreparedTimetable] = useState<any>();
    const [isReload, setIsReload] = useState(true);

    const weekDays = [
        {
            id: 1,
            name: "Понеділок",
        },
        {
            id: 2,
            name: "Вівторок",
        },
        {
            id: 3,
            name: "Середа",
        },
        {
            id: 4,
            name: "Четвер",
        },
        {
            id: 5,
            name: "П'ятниця",
        },
        {
            id: 6,
            name: "Субота",
        },
        {
            id: 7,
            name: "Неділя",
        }
    ];


    useEffect(() => {
        isReload && getTimetables({filters: {branchId}}).then(response => {
            if (response?.data) {
                let timetable: any = {};

                // масив з об'єктами в один об'єкт для Form
                response.data.forEach(day => {
                    timetable = {
                        ...timetable,
                        branchId: day.branchId,
                        [`id${day.weekDayId}`]: day.id,
                        [`workDayStart${day.weekDayId}`]: day.workDayStart,
                        [`workDayEnd${day.weekDayId}`]: day.workDayEnd,
                        [`isWeekend${day.weekDayId}`]: day.isWeekend,
                    };
                });

                setTimetable(response.data);
                setPreparedTimetable(timetable);
            }

            setIsReload(false);
        });
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        !times?.length && dispatch(getTimesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const afterIsWeekendChangeHandler = ({name, value}: any) => {
        const weekDayId = Number(name.charAt(name.length - 1));

        if (value) {
            setPreparedTimetable((prevState: any) => ({
                ...prevState,
                [`workDayStart${weekDayId}`]: null,
                [`workDayEnd${weekDayId}`]: null,
                [`isWeekend${weekDayId}`]: 1,
            }));
        } else {
            setPreparedTimetable((prevState: any) => ({
                ...prevState,
                [`workDayStart${weekDayId}`]: timetable?.[weekDayId]?.workDayStart,
                [`workDayEnd${weekDayId}`]: timetable?.[weekDayId]?.workDayEnd,
                [`isWeekend${weekDayId}`]: 0,
            }));
        }
    };

    const afterWorkDayChangeHandler = ({name, value}: any) => {
        setPreparedTimetable((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onSubmitHandler = (data: any) => {
        const keyFields = Object.keys(data);
        const timetable: any[] = [];

        for (let i = 1; i <= 7; i++) {
            let timetableItem: any = {
                branchId: data.branchId,
                weekDayId: i,
            };

            keyFields.forEach(key => {
                const weekDayId = Number(key.charAt(key.length - 1));
                if (weekDayId === i) {
                    timetableItem = {
                        ...timetableItem,
                        [key.slice(0, -1)]: data[key], //перед додаванням видаляємо символ, в якому записаний weekDayId
                    };
                }
            });

            if (timetableItem.isWeekend === 0 && timetableItem.workDayStart > timetableItem.workDayEnd) {
                addAlert({
                    type: "warning",
                    message: "Кінець робочого дня не може бути меншим, ніж початок",
                })
                return;
            }

            timetable.push(timetableItem);
        }

        editTimetable(timetable)
            .then(response => {
                if (response?.status === 'success') {
                    addAlert({
                        type: "success",
                        message: response.message,
                    });
                    setIsReload(true);
                }
            });
    };


    return (
        <>
            {preparedTimetable &&
                <Form onSubmit={onSubmitHandler} data={preparedTimetable} mode={formMode.edit}>
                    {weekDays.map(({id, name}) => {
                        const isWeekend = preparedTimetable ? preparedTimetable[`isWeekend${id}`] : false;

                        return (
                            <div key={id}>
                                {id > 1 && <FormHR type="dashed" isPaddingOut/>}
                                <Fieldset title={name}/>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <DropDown
                                            name={`workDayStart${id}`}
                                            title="Початок робочого дня"
                                            options={prepareDropDownOptions(times, "time", "time")}
                                            required={!isWeekend}
                                            disabled={isWeekend}
                                            afterChangeHandler={afterWorkDayChangeHandler}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <DropDown
                                            name={`workDayEnd${id}`}
                                            title="Кінець робочого дня"
                                            options={prepareDropDownOptions(times, "time", "time")}
                                            required={!isWeekend}
                                            disabled={isWeekend}
                                            afterChangeHandler={afterWorkDayChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <Checkbox
                                            name={`isWeekend${id}`}
                                            title="Вихідний день"
                                            afterChangeHandler={afterIsWeekendChangeHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {mode !== formMode.view &&
                        <>
                            <br/>
                            <FormStickyContainer>
                                <Button
                                    type="submit"
                                    name="save"
                                    title="Зберегти"
                                    className="lynx-btn-primary"
                                />
                            </FormStickyContainer>
                        </>
                    }

                </Form>
            }
        </>
    );
}