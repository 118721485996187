import {useState} from "react";
import moment from "moment";
import "./CalendarEmptyCard.css";

import {setReloadComponent} from "../../../../../../Core/Store/Reload/Actions";

import VisitModal from "../../../../CRM/RecordManagement/Visits/VisitModal";

import {CalendarEmptyCardPropsInterface} from "./CalendarEmptyCardInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";


export default function CalendarEmptyCard(props: CalendarEmptyCardPropsInterface) {
    const {
        employee,
        date,
        time,
        branchId,
    } = props;


    const [isVisitModalOpen, setIsVisitModalOpen] = useState(false);


    const visitModalSuccessHandler = () => {
        setIsVisitModalOpen(false);
        setReloadComponent("CalendarsContainer", true);
    };


    return (
        <>
            <div className="calendar-empty-card"
                 onClick={() => setIsVisitModalOpen(!isVisitModalOpen)}
            >
                <div className="calendar-empty-card__text">
                    <span>{employee.employeeName}</span>
                    <span>{moment(time, 'HH:mm:ss').format('HH:mm')}</span>
                </div>

                <div className="calendar-empty-card__icon"><i className="fa-duotone fa-plus"/></div>
            </div>

            {isVisitModalOpen &&
                <VisitModal
                    visitData={{
                        branchId,
                        employeeId: employee.employeeId,
                        timeStart: time,
                        visitDate: date,
                    }}
                    formControl={{
                        fields: {
                            employeeId: {disabled: true},
                            timeStart: {disabled: true},
                            visitDate: {disabled: true},
                        }
                    }}
                    mode={formMode.create}
                    isOpen={isVisitModalOpen}
                    onAfterCloseHandle={() => setIsVisitModalOpen(false)}
                    onAfterSuccessHandle={visitModalSuccessHandler}
                />
            }
        </>
    );
}