import React, {useState} from "react";

import {useLynxPermission} from "../../../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../../../Core/Store/Reload/Actions";

import FormHR from "../../../../../../../Core/Components/Forms/Elements/FormHR";
import VisitsGrid from "../../../../RecordManagement/Visits/VisitsGrid";
import FieldsetWithElements from "../../../../../../../Core/Components/Forms/Elements/FieldsetWithElements";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import VisitModal from "../../../../RecordManagement/Visits/VisitModal";

import {ClientLastVisitBlockPropsInterface} from "./ClientVisitsBlockInterface";
import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";


const ClientVisitsBlock = ({clientId}: ClientLastVisitBlockPropsInterface) => {
    const [permission] = useLynxPermission();
    const [isModalOpen, setIsModalOpen] = useState(false);


    const onSuccessHandler = () => {
        setReloadComponent("ClientLastVisitBlock", true);
        setReloadComponent("VisitsGrid", true);
        setReloadComponent("CalendarsContainer", true);
        setIsModalOpen(false);
    };

    const formControl = {
        fieldset: {client: {visible: false}}
    };


    return (
        <>
            <FieldsetWithElements title="Список візитів" elementInTheEnd element={
                permission.isView("visits")
                    ? <Button
                        name="createVsist"
                        title="Створити візит"
                        className="lynx-btn-primary"
                        onClick={() => setIsModalOpen(true)}
                    /> : ""
            }/>
            <FormHR type="dashed" isPaddingOut/>
            <VisitsGrid
                filters={{clientId}}
                isHideNavigation
                isHidePadding
                formControl={formControl}
            />

            <VisitModal
                isOpen={isModalOpen}
                mode={formMode.create}
                clientId={clientId}
                formControl={formControl}
                onAfterCloseHandle={() => setIsModalOpen(false)}
                onAfterSuccessHandle={onSuccessHandler}
            />
        </>
    );
};

export default ClientVisitsBlock;