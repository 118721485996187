import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {deleteEmployee} from "../../../../Api/Administration/Employees";
import {EmployeeInterface} from "../../../../Api/Administration/Employees/EmployeesInterface";


export async function viewEmployeeAction(data: EmployeeInterface, functions: any) {
    const {setIsModalViewOpen, setEmployeeId} = functions;
    if (!setIsModalViewOpen || !setEmployeeId) {
        return;
    }

    setIsModalViewOpen(true);
    setEmployeeId(data.id);
}

export async function editEmployeeAction(data: EmployeeInterface, functions: any) {
    const {setIsModalEditOpen, setEmployeeId} = functions;
    if (!setIsModalEditOpen || !setEmployeeId) {
        return;
    }

    setIsModalEditOpen(true);
    setEmployeeId(data.id);
}

export async function deleteEmployeeAction(data: EmployeeInterface, functions: any) {
    const {onSuccessDeleteHandler} = functions;
    if (!onSuccessDeleteHandler) {
        return;
    }

    const response = await deleteEmployee(data.id);
    if (response?.status === "success") {
        onSuccessDeleteHandler();
        addAlert({
            type: response.status,
            message: response.message,
        });
    }
}