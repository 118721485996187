import ClientPaymentsBlock from "../../ClientBlocks/ClientPaymentsBlock";
import {ClientPaymentsTabPropsInterface} from "./ClientPaymentsTabInterface";


export default function ClientPaymentsTab({clientId}: ClientPaymentsTabPropsInterface) {
    return (
        <div className="row">
            <div className="col-xl-4">
            </div>
            <div className="col-xl-8">
                <div className="lynx-client__block">
                    <ClientPaymentsBlock clientId={clientId}/>
                </div>
            </div>
        </div>
    );
}