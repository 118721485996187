import React, {useState} from "react";
import "./VisitsPage.css";

import {usePageTitle} from "../../../../../Core/hooks";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../Core/Store/Reload/Actions";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import VisitModal from "../../../../Components/CRM/RecordManagement/Visits/VisitModal";
import VisitsGrid from "../../../../Components/CRM/RecordManagement/Visits/VisitsGrid";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";


export default function VisitsPage() {
    usePageTitle("Візити");
    const [permission] = useLynxPermission();
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);


    const onSuccessHandler = () => {
        setReloadComponent("VisitsGrid", true);
        setIsModalCreateOpen(false);
    };


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Візити" tree={["CRM", "Управління записом"]}/>

                {permission.isCreate("visits") &&
                    <div className="lynx-visits-page__button">
                        <Button
                            name="addVisit"
                            title="Створити візит"
                            className="lynx-btn-primary"
                            onClick={() => setIsModalCreateOpen(true)}
                        />
                    </div>
                }

            </BreadCrumbContainer>

            <br/>

            {permission.isView("visits") && <VisitsGrid showFilters/>}

            <VisitModal
                isOpen={isModalCreateOpen}
                mode={formMode.create}
                onAfterCloseHandle={() => setIsModalCreateOpen(false)}
                onAfterSuccessHandle={onSuccessHandler}
            />
        </>
    );
};