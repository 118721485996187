import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changeActiveTab} from "../../../Store/TabsLink/Actions";
import TabsLinkNavigationItemsDefault from "../TabsLinkNavigationItems/TabsLinkNavigationItemsDefault";
import TabsLinkNavigationItemsContent from "../TabsLinkNavigationItems/TabsLinkNavigationItemsContent";
import {
    TabsLinkNavigationContentInterface,
    TabsLinkNavigationDefaultInterface,
    TabsLinkNavigationPropsInterface
} from "./TabsLinkNavigationInterface";
import {filterVisibleTabs} from "./TabsLinkNavigationHelpers";


const TabsLinkNavigation = ({name, tabs, isTabsContent}: TabsLinkNavigationPropsInterface) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(tabs[0]?.id)


    useEffect(() => {
        return () => {
            // скидаємо активний таб в null, щоб при наступному монтуванні зарендерився правильний контент
            dispatch(changeActiveTab(name, null));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onChangeHandler = (id: number | string) => {
        setActiveTab(id)
    }

    const tab = tabs.find(item => item.id === activeTab);

    useEffect(() => {
        dispatch(changeActiveTab(name, tab));
    }, [activeTab, dispatch, name]);  // eslint-disable-line react-hooks/exhaustive-deps

    const visibleTabs = filterVisibleTabs(tabs);

    return (
        <>
            {isTabsContent
                ? <TabsLinkNavigationItemsContent
                    tabs={visibleTabs as TabsLinkNavigationContentInterface[]}
                    activeTab={activeTab}
                    onChangeHandler={onChangeHandler}
                />
                : <TabsLinkNavigationItemsDefault
                    tabs={visibleTabs as TabsLinkNavigationDefaultInterface[]}
                    activeTab={activeTab}
                    onChangeHandler={onChangeHandler}
                />
            }
        </>
    )
}

export default TabsLinkNavigation
