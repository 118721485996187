import {AnyAction} from "redux";
import {ClientPaymentTypesStateType, ClientPaymentTypesType} from "./Types";

const initialStates: ClientPaymentTypesStateType = [];

const clientPaymentTypes = (
    states: ClientPaymentTypesStateType = initialStates, action: AnyAction
): ClientPaymentTypesStateType => {
    const {type, payload} = action;
    switch (type) {
        case ClientPaymentTypesType.GET_CLIENT_PAYMENT_TYPES:
            return [...payload];
        default:
            return states;
    }
};

export default clientPaymentTypes;