import {deleteCustomerGroup, findCustomerGroupById} from "../../../../Api/CRM/Marketing/CustomerGroups";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {
    ShortCardItemDataActionsInterface
} from "../../../../../Core/Components/DataVisualization/ShortCardItem/ShortCardItemInterface";


export async function viewCustomerGroupAction(data: ShortCardItemDataActionsInterface, functions: any) {
    const {setIsModalViewOpen, setCustomerGroupData} = functions;
    if (!setIsModalViewOpen || !setCustomerGroupData) {
        return;
    }

    setIsModalViewOpen(true);

    const response = await findCustomerGroupById(data.id);
    if (response?.status === "success" && response.data) {
        setCustomerGroupData(response.data)
    }
}

export async function editCustomerGroupAction(data: ShortCardItemDataActionsInterface, functions: any) {
    const {setIsModalEditOpen, setCustomerGroupData} = functions;
    if (!setIsModalEditOpen || !setCustomerGroupData) {
        return;
    }

    setIsModalEditOpen(true);

    const response = await findCustomerGroupById(data.id);
    if (response?.status === "success" && response.data) {
        setCustomerGroupData(response.data)
    }
}

export async function deleteCustomerGroupAction(data: ShortCardItemDataActionsInterface, functions: any) {
    const {onSuccessHandler} = functions;
    if (!onSuccessHandler) {
        return;
    }

    const response = await deleteCustomerGroup(data.id);
    if (response?.status === "success") {
        onSuccessHandler();
        addAlert({
            type: response.status,
            message: response.message,
        });
    }
}