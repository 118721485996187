import {ResponseInterface} from "../../../Core/Utils/Request/ResponseInterface";


export enum UserSettingGroupIdsEnum {
    Notifications = 1,
}

export enum UserSettingKeysEnum {
    EmailNotifications ="email_notifications",
}

export interface UserSettingInterface {
    id: number | string,
    title: string,
    keyName: UserSettingKeysEnum,
    value: number,
    groupId: UserSettingGroupIdsEnum,
    groupName: string,
}

export interface EditUserSettingsRequestInterface {
    value?: number,
}

export interface UserSettingsRequestInterface {
    filters?: {
        groupId?: UserSettingGroupIdsEnum,
    }
}

export interface UserSettingsResponseInterface extends ResponseInterface {
    data?: UserSettingInterface[],
}