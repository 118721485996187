import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import lodash from "lodash";

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {getClientSourcesAction} from "../../../../../Store/Directories/ClientSources/Actions";
import {createVisit, editVisit} from "../../../../../Api/CRM/RecordManagement/Visits/Visits";
import {getLiveSearchClient} from "../../../../../Api/CRM/Clients";
import {getVisitStatuses} from "../../../../../Api/CRM/RecordManagement/Visits/VisitStatuses";
import {getEmployees} from "../../../../../Api/Administration/Employees";
import {getTimesAction} from "../../../../../Store/Directories/Times/Actions";
import {useBranch} from "../../../../../hooks";
import {getFilteredWorkplaces} from "../../../../../Api/Directories/Workplaces";

import Form from "../../../../../../Core/Components/Forms/Form";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import DateTime from "../../../../../../Core/Components/Forms/Elements/DateTime";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";
import LiveSearch, {prepareLiveSearchOptions} from "../../../../../../Core/Components/Forms/Elements/LiveSearch";
import Textarea from "../../../../../../Core/Components/Forms/Elements/Textarea";
import FormHR from "../../../../../../Core/Components/Forms/Elements/FormHR";
import Fieldset from "../../../../../../Core/Components/Forms/Elements/Fieldset";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import FormFieldset from "../../../../../../Core/Components/Forms/Elements/FormFieldset/FormFieldset";
import ClientModal from "../../../Clients/ClientModal";
import ColoredDropDown from "../../../../../../Core/Components/Forms/Elements/ColoredDropDown";

import {VisitFormPropsInterface} from "./VisitFormInterface";
import {StoreInterface} from "../../../../../../Config/Redux/StoreInterface";
import {
    formMode,
    FormControlStateInterface
} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {
    VisitStatusInterface
} from "../../../../../Api/CRM/RecordManagement/Visits/VisitStatuses/VisitStatusesInterface";
import {EmployeeInterface} from "../../../../../Api/Administration/Employees/EmployeesInterface";
import {
    LiveSearchOptionInterface
} from "../../../../../../Core/Components/Forms/Elements/LiveSearch/LiveSearchInterface";
import {WorkplaceInterface} from "../../../../../Api/Directories/Workplaces/WorkplacesInterface";
import {OnSuccessHandlerInterface} from "../../../../../Pages/CRM/Clients/ClientsPage/ClientsPageInterface";


export default function VisitForm(props: VisitFormPropsInterface) {
    const {
        onAfterSuccessHandle,
        visitData,
        mode,
        clientId,
        formControlProp,
    } = props;

    const {
        selectedEmployeeWorkingBranch,
        availableBranches,
    } = useBranch();

    const dispatch = useDispatch();

    const prevVisitData = useRef(visitData);

    // lists state
    const [
        visitStatuses, setVisitStatuses
    ] = useState<VisitStatusInterface[]>([]);
    const [workplaces, setWorkplaces] = useState<WorkplaceInterface[]>([]);
    const [branchEmployees, setBranchEmployees] = useState<EmployeeInterface[]>([]);
    const times = useSelector((store: StoreInterface) => store.longTermCache.times);

    const [isClientModalOpen, setIsClientModalOpen] = useState(false);

    // simple form
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        clientId,
        ...visitData,
    });

    const [formControlState, setFormControlState] = useState<FormControlStateInterface>({
        fields: {
            branchId: {
                required: true,
            },
            clientId: {
                required: true,
            },
            employeeId: {
                required: true,
                disabled: mode === formMode.create,
            },
            visitDate: {
                required: true,
            },
            timeStart: {
                required: true,
            },
            duration: {
                required: true,
            },
            workplaceId: {
                required: true,
                disabled: mode === formMode.create,
            },
        },
        fieldset: {
            client: {visible: true},
            visit: {visible: true}
        },
    });


    useEffect(() => {
        dispatch(getClientSourcesAction());
        !times?.length && dispatch(getTimesAction());

        getVisitStatuses().then(response => response?.data && setVisitStatuses(response.data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mode === formMode.edit && visitData?.branchId && !lodash.isEqual(visitData, prevVisitData?.current)) {
            toggleFieldsDisabled(false);
            setFormData(visitData);
            prevVisitData.current = visitData;
            fetchBranchDataById(visitData.branchId);
        }

        if (mode === formMode.create) {
            toggleFieldsDisabled(true);
            if (selectedEmployeeWorkingBranch) {
                setFormData({
                    branchId: selectedEmployeeWorkingBranch.id,
                });
                fetchBranchDataById(selectedEmployeeWorkingBranch.id);
            }
        }
    }, [mode, visitData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        formControlProp && updateFormControlState(formControlProp);
    }, [formControlProp]);

    useEffect(() => {
        toggleFieldsDisabled(!formData.branchId);
        formData.branchId && fetchBranchDataById(formData.branchId);
    }, [formData.branchId]); // eslint-disable-line react-hooks/exhaustive-deps


    const loadClientOptions = async (inputValue: string): Promise<LiveSearchOptionInterface[] | []> => {
        return getLiveSearchClient(inputValue).then(response => {
            return prepareLiveSearchOptions(response?.data, "id", "clientName");
        });
    };

    const onAfterBranchIdHandler = ({value}: { value: number | string }) => {
        setFormData(prevState => ({
            ...prevState,
            employeeId: null,
            workplaceId: null,
            branchId: value ? value : null,
        }));

        toggleFieldsDisabled(!value);
    };

    function fetchBranchDataById(branchId: number | string) {
        getFilteredWorkplaces({filters: {branchId}})
            .then(response => {
                response?.data && setWorkplaces(response?.data);
            });
        getEmployees({filters: {branchId}})
            .then(response => {
                response?.data && setBranchEmployees(response?.data);
            });
    }

    function updateFormControlState(updatedState: FormControlStateInterface) {
        setFormControlState(prevState => {
            return lodash.merge({}, prevState, updatedState);
        });
    }

    // змінює disabled полів, які залежать від branchId
    function toggleFieldsDisabled(disabled: boolean) {
        updateFormControlState({
            fields: {
                employeeId: {disabled},
                workplaceId: {disabled},
            },
        });
    }

    const onSuccessClientModalHandler = (params: OnSuccessHandlerInterface = {}) => {
        const clientId = params.data?.clientId;
        const clientName = params.data?.clientName;

        if (clientId && clientName) {
            setFormData(prevState => ({
                ...prevState,
                clientId,
                clientName,
            }));
            setIsClientModalOpen(false);
        }
    };

    const onSubmitHandler = async (data: any, name: string) => {
        if (name === "create") {
            createVisit(data)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === "success") {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }

        if (name === "save") {
            editVisit(data.id, data).then((response) => {
                if (onAfterSuccessHandle && response.status === "success") {
                    onAfterSuccessHandle();
                    addAlert({
                        type: "success",
                        message: response.message,
                    });
                }
            });
        }
    };


    return (
        <>
            <Form
                onSubmit={onSubmitHandler}
                data={formData}
                mode={mode}
                controlState={formControlState}
                mapStateForm={params => params.form.data && setFormData(params.form.data)}
            >

                <FormFieldset name="client">
                    <Fieldset title="Інформація про клієнта"/>

                    <div className="lynx-create-client-button__with-field-wrapper">
                        <LiveSearch
                            name="clientId"
                            loadOptions={loadClientOptions}
                            visibleLabel={formData?.clientName && formData?.clientName}
                            title="Клієнт"
                        />
                        {mode === formMode.create &&
                            <button
                                className="lynx-create-client-button"
                                title="Додати нового клієнта"
                                onClick={() => setIsClientModalOpen(true)}
                            >
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        }
                    </div>

                    <FormHR type="dashed"/>
                </FormFieldset>

                <FormFieldset name="visit">
                    <Fieldset title="Інформація про візит"/>

                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <DropDown
                                name="branchId"
                                title="Філія"
                                options={availableBranches}
                                afterChangeHandler={onAfterBranchIdHandler}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <ColoredDropDown
                                name="statusId"
                                options={prepareDropDownOptions(visitStatuses, "id", "name", "color")}
                                title="Стан візиту"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <DropDown
                                name="employeeId"
                                title="Співробітник, який буде вести прийом клієнта"
                                options={prepareDropDownOptions(branchEmployees, "id", "userName")}
                            />

                        </div>
                        <div className="col-12 col-sm-6">
                            <DateTime
                                name="visitDate"
                                title="Дата візиту"
                            />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <DropDown
                                name="workplaceId"
                                title="Місце обслуговування"
                                options={prepareDropDownOptions(workplaces, "id", "unitedName")}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <DropDown
                                name="timeStart"
                                options={prepareDropDownOptions(times, "time", "time")}
                                title="Час початку візиту"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <DropDown
                                name="duration"
                                title="Тривалість"
                                options={prepareDropDownOptions(times, "time", "time")}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Textarea
                                name="note"
                                title="Анамнез"
                            />
                        </div>
                    </div>
                </FormFieldset>

                {mode !== formMode.view &&
                    <>
                        <br/>
                        <FormStickyContainer>
                            {mode === formMode.create ? (
                                <Button
                                    type="submit"
                                    name="create"
                                    title="Створити"
                                    className="lynx-btn-primary"
                                />
                            ) : (
                                <div className="button-container">
                                    <Button
                                        type="submit"
                                        name="save"
                                        title="Зберегти"
                                        className="lynx-btn-primary"
                                    />
                                </div>
                            )}
                        </FormStickyContainer>
                    </>
                }

            </Form>

            {isClientModalOpen &&
                <ClientModal
                    isOpen={isClientModalOpen}
                    mode={formMode.create}
                    onAfterCloseHandle={() => setIsClientModalOpen(false)}
                    onAfterSuccessHandle={onSuccessClientModalHandler}
                />
            }
        </>
    );
};
