import moment from "moment/moment";
import {
    EmployeeTimetableInterface,
    TimetableInterface
} from "../../../../../Api/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableInterface";
import {GenerateDateArrayInterface} from "./EmployeeTimetableGridInterface";
import {TimetableTypesEnum} from "../../../../../Api/Directories/TimetableTypes/TimetableTypesInterface";


export const getWorkingHoursForDay = (start: string, end: string) => {
    const workDayPeriodStart = moment(start, "HH:mm:ss");
    const workDayPeriodEnd = moment(end, "HH:mm:ss");

    const durationMoment = moment.duration(workDayPeriodEnd.diff(workDayPeriodStart));

    const hours = Math.floor(durationMoment.asHours());
    const minutes = durationMoment.minutes();

    return {
        workingHoursColon: minutes ? (`${hours}:${minutes}`) : hours,
        workingHoursDecimal: minutes ? (hours + minutes / 60) : hours,
    };
};

export const getAllWorkingHours = (employeeTimetable: EmployeeTimetableInterface) => {
    let totalWorkingHours = 0;

    if (employeeTimetable.timetable.length) {
        const validTimetable = employeeTimetable.timetable as TimetableInterface[];

        totalWorkingHours = validTimetable.reduce((totalWorkingHours, dayTimetable) => {
            if (dayTimetable.dayTypeId !== TimetableTypesEnum.Workday) {
                return totalWorkingHours;
            }

            const {workingHoursDecimal} = getWorkingHoursForDay(dayTimetable.workDayPeriodStart, dayTimetable.workDayPeriodEnd);

            return totalWorkingHours + workingHoursDecimal;
        }, 0);
    }

    const hours = Math.floor(totalWorkingHours);
    const minutes = (totalWorkingHours % 1) * 60;

    return {hours, minutes};
};

export const generateDateArray = (params?: (GenerateDateArrayInterface)): string[] => {
    const dates: string[] = [];
    let startDate: moment.Moment;
    let endDate: moment.Moment;

    const period = params?.period;

    const number = Number(params?.value); // перший тиждень = 1, перший місяць = 0
    const year = params?.year;
    const isParams = !!period && number !== undefined && !!year; // для number інша перевірка, так як number може дорівнювати 0

    if (isParams && period === "month" && number >= 0 && number <= 11) {
        startDate = moment().year(year).month(number).startOf("month");
        endDate = moment().year(year).month(number).endOf("month");
    } else if (isParams && period === "week" && number >= 1 && number <= 53) {
        startDate = moment().year(year).week(number).startOf("isoWeek");
        endDate = moment().year(year).week(number).endOf("isoWeek");
    } else {
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
    }

    while (startDate.isSameOrBefore(endDate, "day")) {
        dates.push(startDate.format("YYYY-MM-DD"));
        startDate.add(1, "day");
    }

    return dates;
};

export const isBranchWeekend = (date: string, branchWeekendId: number[]) => {
    const dayOfWeek = new Date(date).getDay(); // 0 - неділя
    const preparedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek; // 7 - неділя

    return branchWeekendId?.some(id => id === preparedDayOfWeek);
};