import React, {useEffect, useRef, useState} from "react";
import AsyncSelect from "react-select/async";
import {useDebounce} from "rooks";
import "./LiveSearch.css";
import HelperInfo from "../../../HelperInfo";
import {LiveSearchOptionInterface, LiveSearchPropsInterface} from "./LiveSearchInterface";


export default function LiveSearch(props: LiveSearchPropsInterface) {
    const {
        visibleLabel,
        disabled,
        required,
        name,
        title,
        value,
        minLengthForCall = 3,
        loadOptions,
        initComponent,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler,
        className,
        tooltip,
    } = props;


    const [isMenuOpen, setIsMenuOpen] = useState(false);


    useEffect(() => {
        const handleScroll = (e: Event) => {
            const target = e.target as Element;

            if (!target?.classList?.contains("lynx-live-search__menu-list")) {
                setIsMenuOpen(false);
                document.removeEventListener("scroll", handleScroll, {capture: true});
            }
        };

        if (isMenuOpen) {
            document.addEventListener("scroll", handleScroll, {capture: true});
        }

        return () => {
            document.removeEventListener("scroll", handleScroll, {capture: true});
        };
    }, [isMenuOpen]);

    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler,
            });
        }


    }, [initComponent]); // eslint-disable-line react-hooks/exhaustive-deps


    const [selectOption, setSelectOption] = useState<LiveSearchOptionInterface | null>(null);
    const prevSelectOption = useRef<LiveSearchOptionInterface>();


    useEffect(() => {
        const isOption = !!value && !!visibleLabel;

        // коли поле треба заповнити дефолтним значенням(наприклад при edit Form)
        const isDefaultRecorded = isOption && !selectOption;

        // коли змінився visibleLabel і selectOption треба оновити
        const isUpdateSelectOption = isOption && visibleLabel !== prevSelectOption.current?.label;

        if (isDefaultRecorded || isUpdateSelectOption) {
            setSelectOption({
                value: value,
                label: visibleLabel,
            });
            prevSelectOption.current = {
                value: value,
                label: visibleLabel,
            };
        }

        if (!value) {
            setSelectOption(null);
        }
    }, [value, visibleLabel]); // eslint-disable-line react-hooks/exhaustive-deps


    // допоміжна ф-я для коректної роботи useDebounce
    const _loadOptions = (inputValue: string, callback: any) => {
        loadOptions(inputValue).then((resp: any) => callback(resp));
    };

    const debounceLoadOptions = useDebounce(_loadOptions, 500);

    const onChangeHandler = (option: any) => {
        setSelectOption(option);

        if (changeHandler) {
            changeHandler({
                name,
                value: option?.value ? option.value : null,
            });
        }
    };

    const liveSearchClass = ["lynx-live-search"];

    if (disabled) {
        liveSearchClass.push("disabled");
    }

    if (required) {
        liveSearchClass.push("required");
    }

    if (className) {
        liveSearchClass.push(className);
    }


    return (
        <div className={liveSearchClass.join(" ")}>
            <div className="lynx-live-search--place-title">
                <label htmlFor={name} className="lynx-live-search__title">
                    {title}
                </label>
                {tooltip && <HelperInfo {...tooltip}/>}
            </div>

            <AsyncSelect
                data-testid={"lynx-live-search-" + name}
                name={name}
                inputId={name}
                defaultOptions={selectOption ? [selectOption] : undefined}
                loadOptions={(inputValue: string, callback: (options: any) => void) => {
                    if (inputValue.length < minLengthForCall) {
                        return Promise.resolve([]);
                    }
                    return debounceLoadOptions(inputValue, callback);
                }}
                onChange={onChangeHandler}
                value={selectOption}
                isClearable={true}
                isDisabled={disabled}
                classNamePrefix={"lynx-live-search"}
                placeholder={`Введіть щонайменше ${minLengthForCall} символи`}
                styles={{
                    menuPortal: base => ({
                        ...base,
                        zIndex: 9999, // щоб menu не ховалось під модальне вікно
                    }),
                }}
                menuIsOpen={isMenuOpen}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                menuPortalTarget={document.body} // щоб menu не ховалось під footer
                menuPosition="fixed" // щоб виходило за межі компонентів, у яких overflow не дефолтний
                menuPlacement="auto" // визначає в top/bottom для відкриття menu
            />
        </div>
    );
};