import {request} from "../../../../../Core/Utils/Request/Request";
import {
    JobPositionsRequestInterface,
    JobPositionsResponseInterface,
    JobPositionResponseInterface,
    JobPositionRequestInterface,
    JobPositionsTreeResponseInterface,
} from "./JobPositionsInterface";


export const getJobPositions = async (
    params?: JobPositionsRequestInterface
): Promise<JobPositionsResponseInterface> => {
    return await request({
        url: '/v1/directory/job-positions/filters',
        method: 'post',
        data: params,
    });
};

export const getJobPositionsTree = async (
    params?: JobPositionsRequestInterface
): Promise<JobPositionsTreeResponseInterface> => {
    return await request({
        url: '/v1/directory/job-positions/tree/filters',
        method: 'post',
        data: params,
    });
};

export const findJobPositionById = async (id: number | string): Promise<JobPositionResponseInterface> => {
    return await request({
        url: `/v1/directory/job-positions/${id}`,
        method: 'get'
    });
};

export const createJobPosition = async (params: JobPositionRequestInterface): Promise<JobPositionResponseInterface> => {
    return await request({
        url: '/v1/directory/job-positions',
        method: 'post',
        data: params
    });
};

export const editJobPosition = async (id: number | string, params: JobPositionRequestInterface): Promise<JobPositionResponseInterface> => {
    return await request({
        url: `/v1/directory/job-positions/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteJobPosition = async (id: number | string): Promise<JobPositionResponseInterface> => {
    return await request({
        url: `/v1/directory/job-positions/${id}`,
        method: 'delete'
    });
};
