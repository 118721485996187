import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {
    deleteEmployeesJobPosition,
} from "../../../../../../Api/Administration/PersonnelManagement/EmployeeJobPositions";
import {
    EmployeesJobPositionInterface
} from "../../../../../../Api/Administration/PersonnelManagement/EmployeeJobPositions/EmployeeJobPositionsInterface";


export function createEmployeesJobPositionAction(data: EmployeesJobPositionInterface, functions: any) {
    const {setIsModalCreateOpen} = functions;
    if (!setIsModalCreateOpen) {
        return;
    }

    setIsModalCreateOpen(true);
}

export async function viewEmployeesJobPositionAction(data: EmployeesJobPositionInterface, functions: any) {
    const {setIsModalViewOpen, setEmployeesJobPositionId} = functions;
    if (!setIsModalViewOpen || !setEmployeesJobPositionId) {
        return;
    }

    setIsModalViewOpen(true);
    setEmployeesJobPositionId(data.id);
}

export async function editEmployeesJobPositionAction(data: EmployeesJobPositionInterface, functions: any) {
    const {setIsModalEditOpen, setEmployeesJobPositionId} = functions;
    if (!setIsModalEditOpen || !setEmployeesJobPositionId) {
        return;
    }

    setIsModalEditOpen(true);
    setEmployeesJobPositionId(data.id);
}

export async function deleteEmployeesJobPositionAction(data: EmployeesJobPositionInterface, functions: any) {
    const {onSuccessHandler} = functions;
    if (!onSuccessHandler) {
        return;
    }

    const response = await deleteEmployeesJobPosition(data.id);
    if (response?.status === "success") {
        onSuccessHandler();
        addAlert({
            type: response.status,
            message: response.message,
        });
    }
}