import React from 'react';
import "./ClientCard.css";

import {useLynxPermission} from "../../../../../../Modules/AdministrationUsers/hooks";
import {useLynxTariffPlan} from "../../../../../hooks";

import TabsLinkNavigation from "../../../../../../Core/Components/TabsLink/TabsLinkNavigation";
import TabsLinkContent from "../../../../../../Core/Components/TabsLink/TabsLinkContent";
import ClientHeader from "../ClientHeader";
import ClientGeneralTab from "../ClientTabs/ClientGeneralTab";
import ClientVisitsTab from "../ClientTabs/ClientVisitsTab";
import ClientCommunicationLogTab from "../ClientTabs/ClientCommunicationLogTab";
import ClientDocumentsTab from "../ClientTabs/ClientDocumentsTab";
import ClientPaymentsTab from "../ClientTabs/ClientPaymentsTab";

import {ClientCardPropsInterface} from "./ClientCardPropsInterface";

export default function ClientCard({client}: ClientCardPropsInterface) {
    const [permission] = useLynxPermission();
    const {isAvailable} = useLynxTariffPlan();

    let tabs = [
        {
            id: "clientGeneralTab",
            name: "Загальне",
        },
        {
            id: "clientVisitsTab",
            name: "Візити",
            isShow: isAvailable("visits") && permission.isView("visits"),
        },
        {
            id: "clientDocumentsTab",
            name: "Документи",
            isShow: (isAvailable("servicesPerformedCertificate") && permission.isView("servicesPerformedCertificate"))
                || (isAvailable("serviceAgreement") && permission.isView("serviceAgreement")),
        },
        {
            id: "clientPaymentsTab",
            name: "Платежі",
            isShow: isAvailable("clientPayment") && permission.isView("clientPayment"),
        },
        {
            id: "clientCommunicationLogTab",
            name: "Комунікації",
            isShow: isAvailable("communicationHistory"),
        },
    ];


    return (
        <>
            <div className="lynx-client__card lynx-client__block">
                <ClientHeader client={client}/>
                <TabsLinkNavigation
                    tabs={tabs}
                    name="client-card"
                />
            </div>

            <TabsLinkContent tabFor="client-card" tabs={[
                {
                    id: "clientGeneralTab",
                    component: <ClientGeneralTab client={client}/>,
                },
                {
                    id: "clientVisitsTab",
                    component: <ClientVisitsTab clientId={client.id}/>
                },
                {
                    id: "clientDocumentsTab",
                    component: <ClientDocumentsTab clientId={client.id}/>
                },
                {
                    id: "clientPaymentsTab",
                    component: <ClientPaymentsTab clientId={client.id}/>
                },
                {
                    id: "clientCommunicationLogTab",
                    component: <ClientCommunicationLogTab clientId={client.id}/>
                },
            ]}/>
        </>
    );
};
