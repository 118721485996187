import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import lodash from "lodash";

import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {
    createEmployeesJobPosition,
    editEmployeesJobPosition,
    findEmployeesJobPositionById,
} from "../../../../../../Api/Administration/PersonnelManagement/EmployeeJobPositions";
import {getJobPositions} from "../../../../../../Api/Administration/Directories/JobPositions";
import {getBranchesAction} from "../../../../../../Store/Branches/Actions";

import Form from "../../../../../../../Core/Components/Forms/Form";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import DropDown, {prepareDropDownOptions} from "../../../../../../../Core/Components/Forms/Elements/DropDown";

import {EmployeeJobPositionFormInterface} from "./EmployeeJobPositionFormInterface";
import {
    formMode,
    FormControlStateInterface,
} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {
    JobPositionInterface
} from "../../../../../../Api/Administration/Directories/JobPositions/JobPositionsInterface";
import {StoreInterface} from "../../../../../../../Config/Redux/StoreInterface";


export default function EmployeeJobPositionForm(props: EmployeeJobPositionFormInterface) {
    const {
        onAfterSuccessHandle,
        employeesJobPositionId,
        employeeId,
        mode,
    } = props;

    const dispatch = useDispatch();

    const branches = useSelector((store: StoreInterface) => store.shortCache.branches);
    const [jobPositions, setJobPositions] = useState<JobPositionInterface[]>([]);

    // simple form
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        branchId: null,
        jobPositionId: null,
        employeeId: null, // не відображається, порібно передавати в параметрах на бек
    });
    const [formControlState, setFormControlState] = useState<FormControlStateInterface>({
        fields: {
            branchId: {
                disabled: false,
                required: true,
            },
            jobPositionId: {
                disabled: true,
                required: true,
            },
        },
    });

    const getJobPositionsData = (branchId: number) => {
        getJobPositions({filters: {branchId, freeJobPositions: 1}})
            .then((response) => {
                if (response?.data) {
                    setJobPositions(response.data);

                    setFormControlState(prevState => {
                        const updJobPositionId = {fields: {jobPositionId: {disabled: false}}};
                        return lodash.merge({}, prevState, updJobPositionId)
                    });
                }
            });
    };

    useEffect(() => {
        if (employeesJobPositionId) {
            findEmployeesJobPositionById(employeesJobPositionId).then(response => {
                if (response?.data) {
                    setFormData({
                        branchId: response.data.branchId,
                        jobPositionId: response.data.jobPositionId,
                        employeeId: response.data.employeeId,
                    });
                    getJobPositionsData(response.data.branchId);
                }
            });
        }
    }, [employeesJobPositionId]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        dispatch(getBranchesAction());
    }, [dispatch]);


    const afterChangeBranchIdHandler = ({value}: any) => {
        setFormControlState(prevState => {
            const updJobPositionId = {fields: {jobPositionId: {disabled: !value}}};
            return lodash.merge({}, prevState, updJobPositionId)
        });

        setFormData(prevState => ({
            ...prevState,
            branchId: !!value ? value : null,
            jobPositionId: null,
        }));

        !!value && getJobPositionsData(value);
    };

    const onSubmitHandler = (data: any, name: string) => {
        const params = {
            employeeId: data.employeeId || employeeId,
            jobPositionId: data.jobPositionId,
        }

        if (name === "create") {
            createEmployeesJobPosition(params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === "success") {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }

        if (name === "save" && employeesJobPositionId) {
            editEmployeesJobPosition(employeesJobPositionId, params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === "success") {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }
    };


    return <>
        <Form
            onSubmit={onSubmitHandler}
            data={formData}
            controlState={formControlState}
            mode={mode}
        >
            <div className="row">
                <div className="col-12 col-sm-6">
                    <DropDown
                        options={prepareDropDownOptions(branches, "id", "name")}
                        name="branchId"
                        title="Філія"
                        afterChangeHandler={afterChangeBranchIdHandler}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DropDown
                        options={prepareDropDownOptions(jobPositions, "id", "name")}
                        name="jobPositionId"
                        title="Посада"
                    />
                </div>
            </div>

            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create ? (
                            <Button
                                type="submit"
                                name="create"
                                title="Додати"
                                className="lynx-btn-primary"
                            />
                        ) : (
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                        )}
                    </FormStickyContainer>
                </>
            }
        </Form>
    </>;
};
