import {useEffect, useState} from "react";
import ExpandableLink from "../../../../../../Core/Components/ExpandableLink";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import FiltersForm from "../../../../../../Core/Components/Forms/Filters/FiltersForm";
import DropDown from "../../../../../../Core/Components/Forms/Elements/DropDown";
import {ServiceGroupsFiltersPropsInterface} from "./ServiceGroupsFiltersInterface";
import {
    ServiceGroupInterface
} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";
import {getServiceGroups} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups";


export default function ServiceGroupsFilters(props: ServiceGroupsFiltersPropsInterface) {
    const {searchParams, onFilterHandler} = props;

    const [
        parentGroups, setParentGroups
    ] = useState<ServiceGroupInterface[]>();

    useEffect(() => {
        getServiceGroups().then(response => {
            if (response?.status === "success") {
                setParentGroups(response.data);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <FiltersForm code="service-groups" searchParams={searchParams} filterHandler={onFilterHandler}>
            <div className="row">
                <ExpandableLink title="Основні фільтри" isVisibleDefault>
                    <div className="col-12">
                        <Input
                            type="text"
                            name="name"
                            title="Назва"
                        />
                    </div>
                    <div className="col-12">
                        <DropDown
                            options={parentGroups || []}
                            name="parentGroupId"
                            title="Батьківська група"
                        />
                    </div>
                    <div className="col-12">
                        <Input
                            type="text"
                            name="code"
                            title="Код групи послуги"
                        />
                    </div>
                </ExpandableLink>
            </div>
        </FiltersForm>
    );
};
