import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./BuildEmployeeTimetableForm.css";

import {getEmployees} from "../../../../../../Api/Administration/Employees";
import {getBranchesAction} from "../../../../../../Store/Branches/Actions";
import {getWeekDays} from "../../../../../../Api/Directories/WeekDays";
import {buildEmployeeTimetable} from "../../../../../../Api/Administration/PersonnelManagement/EmployeeTimetable";
import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {getTimetableTypesAction} from "../../../../../../Store/Directories/TimetableTypes/Actions";
import {isSuccessfulFormValidated} from "./BuildEmployeeTimetableFormHelpers";
import {getTimesAction} from "../../../../../../Store/Directories/Times/Actions";

import Form from "../../../../../../../Core/Components/Forms/Form";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import DropDown, {prepareDropDownOptions} from "../../../../../../../Core/Components/Forms/Elements/DropDown";
import Checkbox from "../../../../../../../Core/Components/Forms/Elements/Checkbox";
import Fieldset from "../../../../../../../Core/Components/Forms/Elements/Fieldset";
import FormHR from "../../../../../../../Core/Components/Forms/Elements/FormHR";
import DateTime from "../../../../../../../Core/Components/Forms/Elements/DateTime";

import {StoreInterface} from "../../../../../../../Config/Redux/StoreInterface";
import {TimetableTypesEnum,} from "../../../../../../Api/Directories/TimetableTypes/TimetableTypesInterface";
import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {BuildEmployeeTimetableFormPropsInterface,} from "./BuildEmployeeTimetableFormInterface";
import {WeekDayInterface} from "../../../../../../Api/Directories/WeekDays/WeekDaysInterface";
import {EmployeeInterface} from "../../../../../../Api/Administration/Employees/EmployeesInterface";


export default function BuildEmployeeTimetableForm(props: BuildEmployeeTimetableFormPropsInterface) {
    const {
        mode,
        branchId,
        onAfterSuccessHandler,
    } = props;


    const initialStateForm = {
        workDayPeriodStart: {
            disabled: true,
            required: false,
        },
        workDayPeriodEnd: {
            disabled: true,
            required: false,
        },
    };


    const branches = useSelector((store: StoreInterface) => store.shortCache.branches);
    const timetableTypes = useSelector((store: StoreInterface) => store.shortCache.timetableTypes);
    const times = useSelector((store: StoreInterface) => store.longTermCache.times);
    const dispatch = useDispatch();

    const [weekdays, setWeekdays] = useState<WeekDayInterface[]>([]);
    const [formData, setFormData] = useState<{[key: string]: any}>({});
    const [form, setForm] = useState<any>(initialStateForm);
    const [branchEmployees, setBranchEmployees] = useState<EmployeeInterface[]>([]);


    useEffect(() => {
        getWeekDays().then(response => response?.data && setWeekdays(response.data));
        dispatch(getBranchesAction());
        !timetableTypes?.length && dispatch(getTimetableTypesAction());
        !times?.length && dispatch(getTimesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        branchId && getEmployees({filters: {branchId}})
            .then(response => {
                response?.data && setBranchEmployees(response?.data);
            });
    }, [branchId]);


    const afterChangeDayTypeIdHandler = ({value}: { value: number }) => {
        if (value === TimetableTypesEnum.Workday) {
            setForm((prevState: any) => ({
                ...prevState,
                workDayPeriodStart: {
                    disabled: false,
                    required: true
                },
                workDayPeriodEnd: {
                    disabled: false,
                    required: true
                },
            }));

            setFormData((prevState: any) => ({
                    ...prevState,
                    dayTypeId: TimetableTypesEnum.Workday,
                }
            ));
        } else {
            setForm((prevState: any) => ({
                ...prevState,
                workDayPeriodStart: {
                    disabled: true,
                    required: false,
                },
                workDayPeriodEnd: {
                    disabled: true,
                    required: false,
                },
            }));

            setFormData((prevState: any) => ({
                    ...prevState,
                    dayTypeId: value,
                    workDayPeriodStart: null,
                    workDayPeriodEnd: null,
                }
            ));
        }
    };

    const onSubmitHandler = (data: any, name: string) => {
        const weekdays = [];

        for (const key in data) {
            // властивості з weekday в data будуть в форматі { "1"(id weekday): 1 або 0 }
            // перевіряємо чи можна привести key до числа
            if (key && !isNaN(Number(key)) && data[key] === 1) {
                weekdays.push(Number(key));
                delete data[key]; // видаляємо цю властивість з data, так як вона не потрібна в params
            }
        }

        const params = {
            ...data,
            weekdays,
        };

        if (!isSuccessfulFormValidated(params)) {
            return;
        }

        if (name === "create") {
            buildEmployeeTimetable(params).then(async (response) => {
                if (response?.status === "success" && response.data) {
                    addAlert({
                        type: "success",
                        message: response.message,
                    });

                    onAfterSuccessHandler && onAfterSuccessHandler({
                        operation: "build",
                        messages: response.data,
                    });
                }
            });
        }
    };


    return (
        <Form
            onSubmit={onSubmitHandler}
            data={{
                branchId,
                ...formData,
            }}
            mode={mode}>

            <Fieldset title="Загальна інформація"/>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <DropDown
                        options={prepareDropDownOptions(branches, "id", "name")}
                        name="branchId"
                        title="Філія"
                        required
                        disabled
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="employeeId"
                        options={prepareDropDownOptions(branchEmployees, "id", "userName")}
                        title="Співробітник"
                        required
                        disabled={!!formData?.employeeId}
                    />
                </div>
            </div>

            <FormHR type="dashed" isPaddingOut/>
            <Fieldset title="Інформація для створення графіку"/>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <DropDown
                        // timetableType не реалізовано на беку
                        options={[{id: 1, name: "Тип графіку #1"}]}
                        name="timetableType"
                        title="Тип графіку"
                        required
                        value={1}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DropDown
                        options={prepareDropDownOptions(timetableTypes, "id", "name")}
                        name="dayTypeId"
                        title="Тип дня"
                        required
                        afterChangeHandler={afterChangeDayTypeIdHandler}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <DateTime
                        name="dateStart"
                        title="Дата з якої формуємо"
                        required
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DateTime
                        name="dateEnd"
                        title="Дата по яку формуємо"
                        required
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="workDayPeriodStart"
                        title="Початок періоду робочих годин"
                        options={prepareDropDownOptions(times, "time", "time")}
                        required={form.workDayPeriodStart.required}
                        disabled={form.workDayPeriodStart.disabled}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="workDayPeriodEnd"
                        title="Кінець періоду робочих годин"
                        options={prepareDropDownOptions(times, "time", "time")}
                        required={form.workDayPeriodStart.required}
                        disabled={form.workDayPeriodStart.disabled}
                    />
                </div>
            </div>

            <div className="row row-cols-2 row-cols-sm-4">
                {weekdays.map(weekday => {
                    return (
                        <div className="col" key={weekday.id}>
                            <Checkbox
                                name={`${weekday.id}`}
                                title={weekday.name}
                            />
                        </div>
                    )
                })}
            </div>

            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create ? (
                            <Button
                                type="submit"
                                name="create"
                                title="Створити"
                                className="lynx-btn-primary"
                            />
                        ) : (
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                        )}
                    </FormStickyContainer>
                </>
            }
        </Form>
    )
}
