import {
    ClientSourcePostInterface,
    ClientSourceResponseInterface,
    ClientSourcesResponseInterface
} from "./ClientSourcesInterface";
import {request} from "../../../../../Core/Utils/Request/Request";


export const getClientSources = async (): Promise<ClientSourcesResponseInterface> => {
    return await request({
        url: '/v1/directory/client-sources',
        method: 'get',
    });
};

export const findClientSourceById = async (id: number)
    : Promise<ClientSourceResponseInterface> => {
    return await request({
        url: `/v1/directory/client-sources/${id}`,
        method: 'get'
    });
};

export const createClientSource = async (params: ClientSourcePostInterface)
    : Promise<ClientSourcesResponseInterface> => {
    return await request({
        url: '/v1/directory/client-sources',
        method: 'post',
        data: params
    });
};

export const editClientSource = async (id: number, params: ClientSourcePostInterface)
    : Promise<ClientSourcesResponseInterface> => {
    return await request({
        url: `/v1/directory/client-sources/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteClientSource = async (id: number): Promise<ClientSourcesResponseInterface> => {
    return await request({
        url: `/v1/directory/client-sources/${id}`,
        method: 'delete'
    });
};