import {useState} from 'react';

import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {useLynxPermission} from "../../../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../../../Core/Store/Reload/Actions";

import EmployeeTimetableModalForDay from "../../EmployeeTimetableModals/EmployeeTimetableModalForDay";

import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {EmployeeTimetableGeneralTypePropsInterface} from "./EmployeeTimetableGeneralTypeInterface";


export default function EmployeeTimetableGeneralType(props: EmployeeTimetableGeneralTypePropsInterface) {
    const {
        branchId,
        employeeId,
        employeeName,
        dayTimetable,
        iconClassName,
        className,
    } = props;

    const [permission] = useLynxPermission();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mode, setMode] = useState<formMode | null>(null);


    const onCellClickHandler = () => {
        if (permission.isEdit("employeeTimetable")) {
            setMode(formMode.edit);
            setIsModalOpen(true);
            return;
        }

        if (permission.isView("employeeTimetable")) {
            setMode(formMode.view);
            setIsModalOpen(true);
            return;
        }

        addAlert({
            type: "warning",
            message: "Відсутні права на перегляд",
        });
    };

    const onSuccessHandler = () => {
        setIsModalOpen(false);
        setReloadComponent("employeeTimetableGrid", true);
    };

    // дані підготовлені для SimpleFrom
    const employeeDayTimetableData = {
        employeeId,
        employeeName,
        ...dayTimetable,
    };


    return (
        <>
            <div className={className} onClick={onCellClickHandler}>
                <i className={iconClassName}/>
            </div>

            <EmployeeTimetableModalForDay
                mode={mode}
                branchId={branchId}
                employeeDayTimetableData={employeeDayTimetableData}
                isOpen={isModalOpen}
                onAfterSuccessHandler={onSuccessHandler}
                onAfterCloseHandler={() => setIsModalOpen(false)}
            />
        </>
    );
}