import {Moment} from "moment";

export enum DateTypeEnum {
    Day = "day",
    Week = "week",
    Month = "month",
}

export interface OnChangeDatePickerSwitcherParamsInterface {
    period: DateTypeEnum,
    value: number | string, // номер місяця, тижня або день в форматі YYYY-MM-DD
    year: number,
}

export interface DatePickerSwitcherPropsInterface {
    timeframeButtons: DateTypeEnum[],
    defaultSelectedDataType?: DateTypeEnum,
    defaultDate?: Moment,
    className?: string,

    onChange(params: OnChangeDatePickerSwitcherParamsInterface): void,
}