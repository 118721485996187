import moment from "moment";
import {AnimateValueParamsInterface, ValueTypeEnum} from "./ShortInfoItemInterface";


export const animateValue = (params: AnimateValueParamsInterface) => {
    const {
        targetValue,
        valueType,
        setValue,
        startValue,
        animationDuration = 1000,
        partsCount = 20,
    } = params;

    // ==== ValueTypeEnum.Number ====
    if (
        valueType === ValueTypeEnum.Number
        && typeof targetValue === "number"
        && (!startValue || typeof startValue === "number")
    ) {
        let value = startValue as number ?? 0;

        // розбиваємо число на partsCount частинок, щоб кожного інтервалу додавати цю частинку
        const increment = (targetValue - value) / partsCount;
        // з загального часу анімації вираховуємо інтервал між однією зміною числа
        const durationInterval = animationDuration / partsCount;

        const interval = setInterval(() => {
            value = Number((value + increment).toFixed(2));

            if (value >= targetValue) {
                clearInterval(interval);
                value = targetValue;
            }

            setValue && setValue(value);
        }, durationInterval);

        return;
    }

    // ==== ValueTypeEnum.Time ====
    if (
        valueType === ValueTypeEnum.Time
        && typeof targetValue === "string"
        && (!startValue || typeof startValue === "string")
    ) {
        const format = "HH:mm:ss";
        const defaultStartTime = moment("00:00:00", format);
        const targetMoment = moment(targetValue, format);

        const startSeconds = startValue ? moment(startValue, format).diff(defaultStartTime, "seconds") : 0;
        const targetSeconds = targetMoment.diff(defaultStartTime, "seconds");

        let seconds = startSeconds ?? 0;

        // розбиваємо число на partsCount частинок, щоб кожного інтервалу додавати цю частинку
        const increment = (targetSeconds - startSeconds) / partsCount;
        const durationInterval = animationDuration / partsCount;

        const interval = setInterval(() => {
            seconds += increment;

            if (seconds >= targetSeconds) {
                clearInterval(interval);
                seconds = targetSeconds;
            }

            const currentTime = defaultStartTime.clone().add(seconds, "seconds").format(format);
            setValue && setValue(currentTime);
        }, durationInterval);
    }
}

export const getTypeValue = (value: any) => {
    let type;

    if (typeof value === "number") type = ValueTypeEnum.Number;
    if (moment(value, "HH:mm:ss", true).isValid()) type = ValueTypeEnum.Time;

    return type;
};