import {AnyAction} from "redux";
import {WorkplacesType, WorkplacesStateType} from "./Types";

const initialState: WorkplacesStateType = [];

/**
 *
 * @param state
 * @param action
 */

const workplaces = (state: WorkplacesStateType = initialState, action: AnyAction): WorkplacesStateType => {
    const {type, payload} = action;
    switch (type) {
        case WorkplacesType.GET_WORKPLACES:
            return [...payload];
        default:
            return state;
    }
};

export default workplaces;