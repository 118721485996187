import ClientCommunicationLogGridBlock from "../../ClientBlocks/ClientCommunicationLogGridBlock";
import {ClientVisitsTabPropsInterface} from "./ClientCommunicationLogTabInterface";


export default function ClientCommunicationLogTab({clientId}: ClientVisitsTabPropsInterface) {
    return (
        <div className="row">
            <div className="col-xl-6">
                <div className="lynx-client__block">
                    <ClientCommunicationLogGridBlock clientId={clientId}/>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="lynx-client__block">
                </div>
            </div>
        </div>
    );
}