import {useEffect, useState} from "react";
import moment from "moment/moment";

import {useReloadComponent} from "../../../../../Core/hooks";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../Core/Store/Reload/Actions";
import {
    deleteServicesPerformedCertificateAction,
    editServicesPerformedCertificateAction,
    viewServicesPerformedCertificateAction
} from "./ServicesPerformedCertificatesGridHelpers";
import {
    deleteServicesPerformedCertificate,
    getServicesPerformedCertificates
} from "../../../../Api/Documents/ServicesPerformedCertificates";

import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import ServicesPerformedCertificateModal from "../ServicesPerformedCertificateModal";
import ServicesPerformedCertificatesFilters from "../ServicesPerformedCertificatesFilters";

import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {ServicesPerformedCertificatesGridPropsInterface} from "./ServicesPerformedCertificatesGridInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {
    ServicesPerformedCertificateInterface
} from "../../../../Api/Documents/ServicesPerformedCertificates/ServicesPerformedCertificatesInterface";
import PreparedPrompt from "../../../../../Core/Components/Modal/PreparedPrompt";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";


export default function ServicesPerformedCertificatesGrid(props: ServicesPerformedCertificatesGridPropsInterface) {
    const {
        filters = {},
        formControl,
        isHideFilters,
        isHideNavigation,
        isHidePadding,
        searchParams,
        setSearchParams,
    } = props;


    const isReload = useReloadComponent("ServicesPerformedCertificatesGrid");
    const [permission] = useLynxPermission();

    const [servicesPerformedCertificates, setServicesPerformedCertificates] = useState<any>({});
    const [servicesPerformedCertificateId, setServicesPerformedCertificateId] = useState<number | string>();
    const [pagination, setPagination] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState<formMode>(formMode.view);
    const [isPromptOpen, setIsPromptOpen] = useState(false);


    const gridTitle: GridTitleInterface[] = [
        {
            title: "Id",
            code: "id",
            width: "60px"
        },
        {
            title: "Номер акту",
            code: "code",
            width: "140px",
        },
        {
            title: "Дата акту",
            code: "date",
            width: "120px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY"),
        },
        {
            title: "Стан",
            code: "stateName",
            width: "200px",
        },
        {
            title: "Клієнт",
            code: "clientName",
            width: "270px",
        },
        {
            title: "Співробіник",
            code: "employeeName"
        },
        {
            title: "Філія",
            code: "branchName",
            width: "250px",
        },
    ];


    useEffect(() => {
        if (isReload) {
            getServicesPerformedCertificates({filters: {...filters, ...searchParams}, pagination})
                .then(response => {
                    if (response?.status === "success") {
                        setServicesPerformedCertificates(response);
                    }

                    setReloadComponent("ServicesPerformedCertificatesGrid", false);
                });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    // гарантуємо, що при наступному монтуванні isReload = true
    useEffect(() => () => setReloadComponent("ServicesPerformedCertificatesGrid", true), []);


    const onFilterHandler = (data: ServicesPerformedCertificateInterface) => {
        setSearchParams && setSearchParams(data);
        setReloadComponent("ServicesPerformedCertificatesGrid", true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setReloadComponent("ServicesPerformedCertificatesGrid", true);
        setPagination(pagination);
    };

    const onSuccessHandler = () => setReloadComponent("ServicesPerformedCertificatesGrid", true);

    const onApproveDelete = async (id: number | string) => {
        const response = await deleteServicesPerformedCertificate(id);

        if (response?.status === "success") {
            addAlert({
                type: response.status,
                message: response.message,
            });
            onSuccessHandler();
            setIsPromptOpen(false);
        }
    };

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "viewServicesPerformedCertificate",
            isShow: permission.isView("servicesPerformedCertificate"),
            functions: {setModalMode, setIsModalOpen, setServicesPerformedCertificateId},
            action: viewServicesPerformedCertificateAction,
        },
        {
            title: "Редагувати",
            name: "editServicesPerformedCertificate",
            classNameItem: "lynx-button-action-warning",
            isShow: permission.isEdit("servicesPerformedCertificate"),
            functions: {setModalMode, setIsModalOpen, setServicesPerformedCertificateId},
            action: editServicesPerformedCertificateAction,
        },
        {
            title: "Видалити",
            name: "deleteServicesPerformedCertificate",
            classNameItem: "lynx-button-action-danger",
            isShow: permission.isDelete("servicesPerformedCertificate"),
            functions: {setIsPromptOpen, setServicesPerformedCertificateId},
            action: deleteServicesPerformedCertificateAction,
        },
    ];

    const gridMarkup = (
        <Grid
            data={
                (permission.isView("servicesPerformedCertificate") && servicesPerformedCertificates?.data)
                    ? servicesPerformedCertificates?.data
                    : []
            }
            title={gridTitle}
            pagination={servicesPerformedCertificates?.pagination || {}}
            onLoadDataHandler={onLoadDataHandler}
            actions={actions}
            isHideNavigation={isHideNavigation}
            isHidePadding={isHidePadding}
        />
    );


    return (
        <>
            {permission.isView("servicesPerformedCertificate") &&
                <>
                    {!isHideFilters &&
                        <div className="row">
                            <div className="col-12 order-2 col-xl-9 order-xl-1">
                                {gridMarkup}
                            </div>
                            <div className="col-12 order-1 col-xl-3 order-xl-2">
                                <ServicesPerformedCertificatesFilters
                                    searchParams={searchParams}
                                    onFilterHandler={onFilterHandler}
                                />
                            </div>
                        </div>
                    }

                    {isHideFilters && gridMarkup}

                    {servicesPerformedCertificateId &&
                        <ServicesPerformedCertificateModal
                            isOpen={isModalOpen}
                            mode={modalMode}
                            data={{
                                id: servicesPerformedCertificateId,
                            }}
                            formControl={formControl}
                            onAfterCloseHandle={() => setIsModalOpen(false)}
                            onAfterSuccessHandle={onSuccessHandler}
                        />
                    }

                    {servicesPerformedCertificateId &&
                        <PreparedPrompt
                            isOpen={isPromptOpen}
                            code="delete"
                            onCancel={() => setIsPromptOpen(false)}
                            onApprove={() => onApproveDelete(servicesPerformedCertificateId)}
                        />
                    }
                </>
            }
        </>
    );
}