export function addUserToPermissionGroupAction(_: never, functions: {
    setIsModalAddUserOpen(isOpen: boolean): void,
}) {
    functions.setIsModalAddUserOpen(true);
}

export function deleteUserToPermissionGroupAction(data: any, functions: {
    setIsPromptOpen(isOpen: boolean): void,
    setUserId(id: number | string): void,
}) {
    functions.setIsPromptOpen(true);
    functions.setUserId(data.id);
}