import {request} from "../../../../Core/Utils/Request/Request";
import {
    TariffPlanPermissionsRequestInterface,
    TariffPlanPermissionsResponseInterface
} from "./TariffPlanPermissionsInterface";


export const getTariffPlanPermissions = async (
    params?: TariffPlanPermissionsRequestInterface
): Promise<TariffPlanPermissionsResponseInterface> => {
    return await request({
        url: "/v1/tariff-plan/info",
        method: "get",
        options: params?.options,
    });
}
