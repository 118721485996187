import React, {useEffect, useState} from "react";
import "./ClientPortraitPage.css";
import moment from "moment";

import {
    useLynxSearchParams,
    usePageTitle,
    useReloadPage,
} from "../../../../../Core/hooks";
import {setReloadPage} from "../../../../../Core/Store/Reload/Actions";
import {getClientPortrait} from "../../../../Api/Reports/ClientPortrait";
import {
    preparedDoughnutChartData
} from "../../../../../Core/Components/Charts/ECharts/EChartsReactTypes/DoughnutChart/DoughnutChartHelpers";
import {prepareAgeChartData, prepareSourceByDateChartData} from "./ClientPortraitPageHelpers";
import {useDispatch, useSelector} from "react-redux";
import {getClientSourcesAction} from "../../../../Store/Directories/ClientSources/Actions";
import {dateRangeValidation} from "../../NumberOfVisits/NumberOfVisitsPage/NumberOfVisitsPageHelpers";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import ShortInfoContainer from "../../../../../Core/Components/DataVisualization/ShortInfo/ShortInfoContainer";
import DoughnutChart from "../../../../../Core/Components/Charts/ECharts/EChartsReactTypes/DoughnutChart";
import StackedLineChart from "../../../../../Core/Components/Charts/ECharts/EChartsReactTypes/StackedLineChart";
import ClientPortraitFilters from "../../../../Components/Reports/ClientPortrait/ClientPortraitFilters";

import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {
    ClientPortraitFiltersInterface,
    ClientPortraitInterface,
} from "../../../../Api/Reports/ClientPortrait/ClientPortraitInterface";


export default function ClientPortraitPage() {
    usePageTitle("Портрет клієнта");
    const isReload = useReloadPage("ClientPortraitsPage");
    const [searchParams, setSearchParams] = useLynxSearchParams();

    const dispatch = useDispatch();
    const clientSources = useSelector((store: StoreInterface) => store.shortCache.clientSources);


    const initClientPortrait = {
        age: {
            above45: 0,
            averageAge: 0,
            between18And30: 0,
            between30And45: 0,
            under18: 0,
        },
        frequencyOfVisits: 0,
        gender: {
            men: 0,
            women: 0,
        },
        multiVisitClientsPercent: 0,
        onlyOneVisitClientsPercent: 0,
        source: [],
        sourceByDate: [],
    }

    const [clientPortrait, setClientPortrait] = useState<ClientPortraitInterface>(initClientPortrait);

    const initFilterData = {
        dateCreateStart: moment().subtract(3, "months").format("YYYY-MM-DD"),
        dateCreateEnd: moment().format("YYYY-MM-DD"),
    };


    useEffect(() => {
        dispatch(getClientSourcesAction());
        return () => setReloadPage("ClientPortraitsPage", true)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isReload) return;

        let filters;

        if (!Object.keys(searchParams).length) {
            filters = initFilterData;
            setSearchParams(initFilterData);
        } else filters = searchParams;

        getClientPortrait({filters}).then(response => {
            setClientPortrait(response?.data ? response.data : initClientPortrait);
            setReloadPage("ClientPortraitsPage", false);
        });
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps


    const onFilterHandler = (data: ClientPortraitFiltersInterface) => {
        if (!dateRangeValidation(data.dateCreateStart, data.dateCreateEnd)) return;

        setSearchParams(data);
        setReloadPage("ClientPortraitsPage", true);
    };


    const preparedAgeChartData = clientPortrait?.age && prepareAgeChartData(clientPortrait?.age);
    const preparedSourceByDateChartData = clientPortrait?.sourceByDate && prepareSourceByDateChartData(clientPortrait?.sourceByDate, clientSources);


    return (
        <div className="lynx-client-portrait-page">
            <BreadCrumbContainer>
                <BreadCrumb title="Портрет клієнта" tree={["Звіти"]}/>
            </BreadCrumbContainer>

            <br/>

            <div className="row">
                <div className="col-12 order-2 col-xl-9 order-xl-1 lynx-client-portrait-page__charts">
                    {!!clientPortrait.sourceByDate.length &&
                        <>
                            <ShortInfoContainer itemsData={[
                                {
                                    icon: "fa-duotone fa-users",
                                    targetValue: clientPortrait.age.averageAge,
                                    description: "Середній вік пацієнтів",
                                },
                                {
                                    icon: "fa-duotone fa-venus",
                                    targetValue: clientPortrait.gender.women,
                                    description: "Відсоток жінок",
                                },
                                {
                                    icon: "fa-duotone fa-mars",
                                    targetValue: clientPortrait.gender.men,
                                    description: "Відсоток чоловіків",
                                },
                            ]}/>

                            <DoughnutChart
                                title="Вік пацієнтів, %"
                                data={preparedAgeChartData}
                            />

                            <ShortInfoContainer itemsData={[
                                {
                                    icon: "fa-duotone fa-arrows-maximize",
                                    targetValue: clientPortrait.frequencyOfVisits,
                                    description: "Середня частота відвідувань",
                                },
                                {
                                    icon: "fa-duotone fa-user-check",
                                    targetValue: clientPortrait.onlyOneVisitClientsPercent,
                                    description: "Відсоток пацієнтів, які відвідують клініку один раз",
                                },
                                {
                                    icon: "fa-duotone  fa-user-friends",
                                    targetValue: clientPortrait.multiVisitClientsPercent,
                                    description: "Відсоток пацієнтів, які відвідують клініку два і більше разів",
                                },
                            ]}/>

                            {preparedSourceByDateChartData &&
                                <StackedLineChart
                                    title="Кількість пацієнтів по кожному джерелу в розрізі днів"
                                    data={preparedSourceByDateChartData}
                                />
                            }

                            <DoughnutChart
                                title="Кількість пацієнтів по кожному джерелу залучення"
                                data={preparedDoughnutChartData(clientPortrait.source, "clientCount", "source")}
                            />
                        </>
                    }

                    {!clientPortrait.sourceByDate.length &&
                        <div className="lynx-client-portrait-page__not-found-data">
                            <i className="fa-duotone fa-memo-circle-info lynx-client-portrait-page__not-found-data-icon"/>
                            <span className="lynx-client-portrait-page__not-found-data-text">
                                На жаль, за обраними фільтрами немає даних
                            </span>
                        </div>
                    }
                </div>

                <div className="col-12 order-1 col-xl-3 order-xl-2">
                    <ClientPortraitFilters
                        onFilterHandler={onFilterHandler}
                        searchParams={searchParams}
                    />
                </div>
            </div>
        </div>
    );
}