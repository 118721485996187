import React from 'react';
import './assets/vendor/bootstrap5/bootstrap-reboot.min.css';
import './assets/vendor/bootstrap5/bootstrap-grid.min.css';
import './assets/vendor/icofont/icofont.min.css';
import './assets/vendor/fontawesome/css/all.css';
import './App.css';
import './Core/Config/Utils/Style/Helpers/Helpers.css';

import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import routes from './Config/Routing/Routes';
import Spinner from './Core/Components/Spinner/Spinner'
import AlertContainer from "./Core/Components/Alert/AlertContainer/AlertContainer";
import {store, persistor} from "./Config/Redux/Store";
import {PersistGate} from "redux-persist/integration/react";
import AppRouter from "./Core/Utils/Routing/AppRouter";
import GlobalState from "./Core/Utils/GlobalState";
import { CookiesProvider } from 'react-cookie';

function App() {

    return (
        <CookiesProvider>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <GlobalState>
                    <BrowserRouter>
                        <AppRouter routes={routes}/>
                    </BrowserRouter>
                </GlobalState>
            </PersistGate>
            <AlertContainer/>

            <Spinner/>

        </Provider>

        </CookiesProvider>
    );
}

export default App;
