import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getUsers} from "../../../../../../Modules/AdministrationUsers/Api/Users/Users/Users";
import {getBranchesAction} from "../../../../../Store/Branches/Actions";

import ExpandableLink from "../../../../../../Core/Components/ExpandableLink";
import FiltersForm from "../../../../../../Core/Components/Forms/Filters/FiltersForm";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";
import Checkbox from "../../../../../../Core/Components/Forms/Elements/Checkbox";

import {EmployeeFiltersPropsInterface} from "./EmployeeFiltersInterface";
import {UserInterface} from "../../../../../../Modules/AdministrationUsers/Api/Users/Users/UserInterface";
import {StoreInterface} from "../../../../../../Config/Redux/StoreInterface";


export default function EmployeeFilters(props: EmployeeFiltersPropsInterface) {
    const {searchParams, onFilterHandler} = props;

    const dispatch = useDispatch();
    const branches = useSelector((store: StoreInterface) => store.shortCache.branches);
    const [users, setUsers] = useState<UserInterface[]>([]);

    
    useEffect(() => {
        getUsers({filters: {}, pagination: {}}).then((response) => {
            if (response?.status === "success") {
                setUsers(response.data);
            }
        });

        dispatch(getBranchesAction());
    }, [dispatch]);


    return (
        <FiltersForm code="employees" searchParams={searchParams} filterHandler={onFilterHandler}>
            <div className="row">
                <ExpandableLink title="Основні фільтри" isVisibleDefault>
                    <div className="col-12">
                        <DropDown
                            options={prepareDropDownOptions(users, "id", "name")}
                            name="userId"
                            title="Користувач"
                        />
                    </div>
                    <div className="col-12">
                        <DropDown
                            options={prepareDropDownOptions(branches, "id", "name")}
                            name="branchId"
                            title="Філія"
                        />
                    </div>
                    <div className="col-12">
                        <Checkbox
                            name="isPublishedOnSite"
                            title="Співробітник опублікований на сайті"
                        />
                    </div>
                </ExpandableLink>
            </div>
        </FiltersForm>
    );
};
