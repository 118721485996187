import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {NestedLevelPropsInterface} from "./NestedLevelInterface";

export default function NestedLevel(props: NestedLevelPropsInterface) {


    const {icon, title, items, level} = props;
    const [isOpen, setStateOpen] = useState(false);

    const onClickHandler = () => {
        setStateOpen(!isOpen);
    }

    let row;
    const padding = 20 * level;

    if (items) {
        row = items.map((item) => {
            if (item.items) {
                return <NestedLevel
                    key={item.title}
                    link={item.link}
                    title={item.title}
                    icon={item.icon}
                    items={item.items}
                    level={level + 1}
                />
            } else {
                return <NavLink to={item.link} key={item.link} style={{paddingLeft: 20 + padding}}>
                    <span>{item.title}</span>
                </NavLink>
            }

        })
    }

    let subMenuCss = 'lynx-submenu ' + (isOpen ? ' lynx-submenu-active ' : ' ') + (level === 1 ? 'lynx-submenu__first-level' : ' ');

    return (
        <li>
            <div onClick={onClickHandler} className={subMenuCss} style={{paddingLeft: padding}}>
                {icon && <i className={icon}/>}<span>{title}</span>
            </div>
            {isOpen && <ul className="lynx-submenu-items">{row}</ul>}
        </li>
    )
}