import React from 'react';
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {ClientModalPropsInterface} from "./ClientModalInterface";
import ClientForm from "../СlientForm";


export default function ClientModal(props: ClientModalPropsInterface) {
    const {
        isOpen,
        mode,
        clientData,
        onAfterSuccessHandle,
        onAfterCloseHandle,
    } = props;


    return (
        <>
            {mode === "create" &&
                <ModalSimple
                    isOpen={isOpen}
                    body={<ClientForm onAfterSuccessHandle={onAfterSuccessHandle} mode={formMode.create}/>}
                    title="Створення нового клієнта"
                    onAfterCloseHandle={onAfterCloseHandle}
                />
            }

            {mode === "edit" &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <ClientForm
                            onAfterSuccessHandle={onAfterSuccessHandle}
                            clientData={clientData}
                            mode={formMode.edit}/>}
                    title="Редагування клієнта"
                    onAfterCloseHandle={onAfterCloseHandle}
                />
            }
        </>
    );
};