import {PromptConfigInterface} from "../../Core/Components/Modal/PreparedPrompt/PreparedPromptInterface";


export const promptConfig: PromptConfigInterface[] = [
    {
        code: "delete",
        title: "Ви впевнені, що хочете видалити?",
        description: "Після видалення дані буде не можливо відновити",
    },
    {
        code: "save",
        title: "Ви впевнені, що хочете зберегти?",
        description: "Після збереження дані буде змінено",
    },
];