import {request} from "../../../../Core/Utils/Request/Request";
import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";
import {
    CertificatesServicesRequestInterface,
    CertificatesServicesResponseInterface,
    EditCertificatesServicesRequestInterface, EditCertificatesServicesResponseInterface
} from "./CertificatesServicesInterface";


export const getCertificatesServices = async (
    params?: CertificatesServicesRequestInterface
): Promise<CertificatesServicesResponseInterface> => {
    return await request({
        url: "/v1/documents/certificates-services/filters",
        method: "post",
        data: params,
    });
};

export const editCertificatesServices = async (
    params: EditCertificatesServicesRequestInterface)
    : Promise<EditCertificatesServicesResponseInterface> => {
    return await request({
        url: "/v1/documents/certificates-services/update",
        method: "post",
        data: params,
    });
};

export const deleteCertificatesService = async (id: number | string): Promise<ResponseInterface> => {
    return await request({
        url: `/v1/documents/certificates-services/${id}`,
        method: "delete"
    });
};