import {
    UserPermissionInterface,
} from "../Api/Permissions/UserPermission/UserPermissionInterface";
import {useLynxPermissionInterface} from "./hookInterface";
import {addAlert} from "../../../Core/Store/Alert/Actions";

export default function useLynxPermission(): [useLynxPermissionInterface] {

    let userPermissionStorage = localStorage.getItem('userPermission');

    let userPermission: UserPermissionInterface;
    if (userPermissionStorage) {
        userPermission = JSON.parse(userPermissionStorage) as UserPermissionInterface;
    } else {
        addAlert({
            type: 'error',
            message: 'Відсутні права у користувача'
        })
    }

    let permission: useLynxPermissionInterface = {
        isView: (page) => {
            return userPermission.pagePermission[page]?.isView ?? false;
        },
        isCreate: (page) => {
            return userPermission.pagePermission[page]?.isCreate ?? false;
        },
        isEdit: (page) => {
            return userPermission.pagePermission[page]?.isEdit ?? false;
        },
        isDelete: (page) => {
            return userPermission.pagePermission[page]?.isDelete ?? false;
        },
        isExecute: (page) => {
            return userPermission.pagePermission[page]?.isExecute ?? false;
        },
        isFeatureFlag: (page, flag) => {
            if (!userPermission.featureFlags[page]) {
                return false;
            }

            return userPermission.featureFlags[page].includes(flag)
        }
    }

    return [permission];
}
