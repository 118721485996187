import React, {useEffect, useState} from "react";

import ServiceGroupForm from "../ServiceGroupForm";
import DeleteServiceGroupForm from "../DeleteServiceGroupForm";
import ModalSimple from "../../../../../../Core/Components/Modal/ModalSimple";

import {ServiceGroupsModalPropsInterface} from "./ServiceGroupsModalInterface";
import {formMode as formModeEnum} from "../../../../../../Core/Components/Forms/Form/FormInterface";


export default function ServiceGroupsModal(props: ServiceGroupsModalPropsInterface) {
    const {
        isOpen,
        mode,
        data,
        onAfterSuccessHandle,
        onAfterCloseHandle,
    } = props;


    const [formMode, setFormMode] = useState<formModeEnum>(mode);

    useEffect(() => setFormMode(mode), [mode]);


    let modeTitle = "";
    switch (mode) {
        case formModeEnum.view:
            modeTitle = "Перегляд групи";
            break;
        case formModeEnum.create:
            modeTitle = "Додавання нової групи";
            break;
        case formModeEnum.edit:
            modeTitle = "Редагування групи";
            break;
        case formModeEnum.delete:
            modeTitle = "Видалити групу";
            break;
        default:
            return null;
    }


    return (
        <>
            {mode !== formModeEnum.delete &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <ServiceGroupForm
                            onAfterSuccessHandle={onAfterSuccessHandle}
                            data={data}
                            mode={formMode}
                            setFormMode={setFormMode}
                        />}
                    title={modeTitle}
                    onAfterCloseHandle={onAfterCloseHandle}
                />
            }

            {mode === formModeEnum.delete && data?.serviceGroupId &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <DeleteServiceGroupForm
                            onAfterSuccessHandle={() => {
                                onAfterSuccessHandle && onAfterSuccessHandle();
                                onAfterCloseHandle && onAfterCloseHandle();
                            }}
                            data={{
                                serviceGroupId: data.serviceGroupId,
                            }}
                            onAfterRejectHandle={onAfterCloseHandle}
                        />
                    }
                    title={modeTitle}
                    widthClass="sm"
                    onAfterCloseHandle={onAfterCloseHandle}
                />
            }
        </>
    );
};