import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./LoadingProcessPage.css";
import {useDispatch, useSelector} from "react-redux";

import {getEmployeeWorkingBranches} from "../../../Api/Administration/Employees";
import {setEmployeeWorkingBranches} from "../../../Store/EmployeeWorkingBranches/Actions";
import {setSelectedEmployeeWorkingBranch} from "../../../Store/SelectedEmployeeWorkingBranch/Actions";
import {setProfile} from "../../../../Modules/AdministrationUsers/Store/Profile/Actions";
import {getProfile} from "../../../../Modules/Auth/Api/Users/Profile";
import {getTariffPlanPermissions} from "../../../Api/Permissions/TariffPlanPermissions";

import {StoreInterface} from "../../../../Config/Redux/StoreInterface";
import {
    LoadingProcessesStateInterface,
    LoadingProcessInterface
} from "./LoadingProcessPageInterfcae";


function LoadingProcessPage() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const selectedEmployeeWorkingBranch = useSelector(
        (store: StoreInterface) => store.longTermCache.users.selectedEmployeeWorkingBranch
    );

    const [loadingProcesses, setLoadingProcesses] = useState<LoadingProcessesStateInterface>({
        auth: {
            name: "Авторизовано",
            isLoaded: true
        },
        permissions: {
            name: "Отримано права користувача",
            isLoaded: false
        },
        profile: {
            name: "Завантажено профіль користувача",
            isLoaded: false
        },
        employeeWorkingBranch: {
            name: "Визначено робочі філії",
            isLoaded: false,
        }
    });

    // обробка робочих філій користувача
    const processingGetEmployeeWorkingBranches = async () => {
        const result = await getEmployeeWorkingBranches();

        if (!result?.data) {
            navigate("/error-page/Помилка при завантаженні робочих філій");
            throw new Error(); // перериваємо ланцюжок промісів в useEffect
        }

        if (result.data.length === 0) {
            dispatch(setSelectedEmployeeWorkingBranch(null));
            dispatch(setEmployeeWorkingBranches([]));
        }

        if (result.data.length > 0) {
            dispatch(setEmployeeWorkingBranches(result.data));

            // закешована вибрана філія в longTermCache
            if (selectedEmployeeWorkingBranch) {
                // чи є філія, яка в longTermCache, в списку робочих філій
                const branchIsWorkingBranch = result.data.some(({id}) => id === selectedEmployeeWorkingBranch.id);
                // якщо немає - записуємо першу, яка прийшла з беку
                !branchIsWorkingBranch && dispatch(setSelectedEmployeeWorkingBranch(result.data[0]));
            } else {
                dispatch(setSelectedEmployeeWorkingBranch(result.data[0]));
            }
        }

        setLoadingProcesses(prevState => ({
            ...prevState,
            employeeWorkingBranch: {
                ...prevState.employeeWorkingBranch,
                isLoaded: true,
            },
        }));
    };

    // перевірка чи є права користувача
    const checkUserPermissions = async () => {
        const userPermissionStorage = localStorage.getItem("userPermission");

        // викидаємо помилку, якщо права відсуті в LS або ж присутні, але немає принаймні одного дозволу
        if (!userPermissionStorage
            || (Object.keys(JSON.parse(userPermissionStorage).pagePermission).length === 0)) {
            navigate("/error-page/Помилка при завантаженні відсутні права доступу");
            throw new Error(); // перериваємо ланцюжок промісів в useEffect
        }

        setLoadingProcesses(prevState => ({
            ...prevState,
            permissions: {
                ...prevState.permissions,
                isLoaded: true,
            },
        }));
    };

    // записуємо права тарифного плану
    const setTariffPlanPermissions = async () => {
        const response = await getTariffPlanPermissions({
            options: {
                isShowSpinner: false,
            },
        });

        if (response?.data) {
            localStorage.setItem("tariffPlanPermission", JSON.stringify(response.data));
        }
    };

    // завантаження профілю
    const loadingProfile = async () => {
        const result = await getProfile({
            options: {
                isShowSpinner: false,
            },
        });

        if (result?.status !== "success" || !result?.data) {
            navigate("/error-page/Помилка при завантаженні профілю користувача");
            throw new Error(); // перериваємо ланцюжок промісів в useEffect
        } else {
            dispatch(setProfile(result.data));
            setLoadingProcesses(prevState => ({
                ...prevState,
                profile: {
                    ...prevState.profile,
                    isLoaded: true,
                },
            }));
        }
    };

    const executeWithDelay = async (func: any, delay: number) => {
        await new Promise((resolve) => setTimeout(resolve, delay));
        await func();
    };


    useEffect(() => {
        (async () => {
            try {
                await setTariffPlanPermissions();
                await checkUserPermissions();
                await loadingProfile();
                await processingGetEmployeeWorkingBranches();

                // якщо всі проміси fulfilled то переходимо в додаток
                await executeWithDelay(navigate("/"), 700);
            } catch (error) {
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const loadingProcessesToArray = (obj: LoadingProcessesStateInterface) => {
        const loadingProcessesArray: LoadingProcessInterface[] = [];

        for (const key in obj) {
            const {name, isLoaded} = obj[key];
            loadingProcessesArray.push({name, isLoaded});
        }

        return loadingProcessesArray;
    };

    const getLoadingIconMarkup = (isLoaded: boolean) => {
        const loadedIconClass = isLoaded ? "check" : "xmark";

        return (
            <span className={
                `lynx-loading-process-page-item__icon lynx-loading-process-page-item__icon--${loadedIconClass}`
            }>
                <i className={`fa-duotone fa-${loadedIconClass} fa-2xs`}/>
            </span>
        );
    };


    return (
        <div className="lynx-loading-process-page">
            <div className="lynx-loading-process-page__inner">
                <div className="lynx-loading-process-page__title">
                    Завантаження додатку . . .
                </div>
                <div className="lynx-loading-process-page__subtitle">
                    Будь ласка, дочекайтесь завантаження додатку
                </div>
                <ul className="lynx-loading-process-page__list">
                    {loadingProcessesToArray(loadingProcesses).map(item => {
                        return (
                            <li className="lynx-loading-process-page-item" key={item.name}>
                                <span>{item.name}</span>
                                <span className="lynx-loading-process-page-item__line"/>
                                {getLoadingIconMarkup(item.isLoaded)}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default LoadingProcessPage;