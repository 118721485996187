import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./NumberOfVisitsFilters.css";

import {useBranch} from "../../../../hooks";
// import {getVisitStatuses} from "../../../../Api/CRM/RecordManagement/Visits/VisitStatuses";
import {getTimesAction} from "../../../../Store/Directories/Times/Actions";
import {getEmployees} from "../../../../Api/Administration/Employees";
import {getFilteredWorkplaces} from "../../../../Api/Directories/Workplaces";

import DropDown, {prepareDropDownOptions} from "../../../../../Core/Components/Forms/Elements/DropDown";
import DateTime from "../../../../../Core/Components/Forms/Elements/DateTime";
import FiltersForm from "../../../../../Core/Components/Forms/Filters/FiltersForm";
import ExpandableLink from "../../../../../Core/Components/ExpandableLink";

import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
// import {VisitStatusInterface} from "../../../../Api/CRM/RecordManagement/Visits/VisitStatuses/VisitStatusesInterface";
import {NumberOfVisitsFiltersPropsInterface} from "./NumberOfVisitsIFiltersnterface";
import {FormControlStateInterface} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {SubmitNameEnum} from "../../../../../Core/Components/Forms/Filters/FiltersForm/FiltersFormInterface";
import {EmployeeInterface} from "../../../../Api/Administration/Employees/EmployeesInterface";
import {WorkplaceInterface} from "../../../../Api/Directories/Workplaces/WorkplacesInterface";


export default function NumberOfVisitsFilters(props: NumberOfVisitsFiltersPropsInterface) {
    const {
        searchParams,
        onFilterHandler,
    } = props;

    const {availableBranches} = useBranch();

    const dispatch = useDispatch();
    const times = useSelector((store: StoreInterface) => store.longTermCache.times);

    const [workplaces, setWorkplaces] = useState<WorkplaceInterface[]>([]);
    const [branchEmployees, setBranchEmployees] = useState<EmployeeInterface[]>([]);
    // const [visitStatuses, setVisitStatuses] = useState<VisitStatusInterface[]>([]);

    const [isFirstRender, setIsFirstRender] = useState(true);

    // simple form
    const [filterData, setFilterData] = useState<{ [key: string]: any }>({});
    const [controlState, setControlState] = useState<FormControlStateInterface>({
        fields: {
            employees: {
                disabled: true,
            },
            workplaceId: {
                disabled: true,
            },
        }
    });


    useEffect(() => {
        !times?.length && dispatch(getTimesAction());

        // getVisitStatuses().then(response => response?.data && setVisitStatuses(response.data));

        // на 1му рендері branchId беремо з searchParams, на всіх інших з filterData
        if (searchParams.branchId) {
            toggleFieldsDisabled(false);
            fetchBranchDataById(searchParams.branchId);
        }

        setIsFirstRender(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // на 1му рендері branchId беремо з searchParams, на всіх інших з filterData
        if (isFirstRender) return;

        toggleFieldsDisabled(!filterData.branchId);
        filterData.branchId && fetchBranchDataById(filterData.branchId);
    }, [filterData.branchId]); // eslint-disable-line react-hooks/exhaustive-deps


    const afterBranchIdChangeHandler = ({value}: { value: number }) => {
        toggleFieldsDisabled(!value);

        if (value) {
            setFilterData({
                branchId: value,
                employees: null,
                workplaceId: null,
            });
        } else {
            setFilterData({
                branchId: null,
                employees: null,
                workplaceId: null,
            });
        }
    };

    const onFilterSubmitHandler = (_: any, name: string) => {
        name === SubmitNameEnum.ClearFilter && toggleFieldsDisabled(true);
    };

    function fetchBranchDataById(branchId: number | string) {
        getFilteredWorkplaces({filters: {branchId}})
            .then(response => {
                response?.data && setWorkplaces(response?.data);
            });
        getEmployees({filters: {branchId}})
            .then(response => {
                response?.data && setBranchEmployees(response?.data);
            });
    }

    // змінює disabled полів, які залежать від branchId
    function toggleFieldsDisabled(disabled: boolean) {
        setControlState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                employees: {disabled},
                workplaceId: {disabled},
            },
        }));
    }


    return (
        <FiltersForm
            code="numberOfVisits"
            searchParams={searchParams}
            filterHandler={onFilterHandler}
            onSubmitHandler={onFilterSubmitHandler}
            formPropsFilters={{
                data: filterData,
                controlState,
            }}
        >
            <div className="row">
                <div className="lynx-number-of-visits-filters__inner">
                    <ExpandableLink title="Основні фільтри" isVisibleDefault>
                        <div className="col-12">
                            <DropDown
                                name="branchId"
                                title="Філія"
                                options={availableBranches}
                                afterChangeHandler={afterBranchIdChangeHandler}
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="employees"
                                options={prepareDropDownOptions(branchEmployees, "id", "userName")}
                                title="Співробітники"
                                isMulti
                            />
                        </div>
                        <div className="col-12">
                            <DateTime
                                name="dateStart"
                                title="Дата з"
                            />
                        </div>
                        <div className="col-12">
                            <DateTime
                                name="dateEnd"
                                title="Дата по"
                            />
                        </div>
                    </ExpandableLink>

                    <ExpandableLink title="Додаткові фільтри" isVisibleDefault>
                        <div className="col-12">
                            <DropDown
                                name="workplaceId"
                                title="Місце обслуговування"
                                options={prepareDropDownOptions(workplaces, "id", "unitedName")}
                            />
                        </div>
                        {/*
                            закоментовано, так як зараз в звіт по кількості візитів додаються тільки завершені.
                            пізніше функціонал буде розширюватись
                        */}
                        {/*<div className="col-12">*/}
                        {/*    <DropDown*/}
                        {/*        name="statusId"*/}
                        {/*        options={visitStatuses}*/}
                        {/*        title="Стан візиту"*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </ExpandableLink>
                </div>
            </div>
        </FiltersForm>
    );
}