import {useEffect, useState} from "react";
import {Tree, TreeNode} from "react-organizational-chart";
import {Scrollbars} from "react-custom-scrollbars-2";
import "./JobPositionsPage.css"

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useBodyScroll, usePageTitle} from "../../../../../Core/hooks";
import {getBranches} from "../../../../Api/Branches/Branch";
import {findJobPositionById, getJobPositionsTree} from "../../../../Api/Administration/Directories/JobPositions";

import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import JobPositionForm from "../../../../Components/Administration/Directories/JobPositions/JobPositionForm";
import DropDown, {prepareDropDownOptions} from "../../../../../Core/Components/Forms/Elements/DropDown";
import TextBox from "../../../../../Core/Components/DataVisualization/TextBox";
import ComponentErrorMessage from "../../../../../Core/Components/ErrorVisualization/ComponentErrorMessage";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {BranchInterface} from "../../../../Api/Branches/Branch/BranchInterface";
import {
    JobPositionInterface,
    JobPositionResponseInterface, JobPositionTreeInterface
} from "../../../../Api/Administration/Directories/JobPositions/JobPositionsInterface";


export default function JobPositionsPage() {
    usePageTitle("Посади");
    useBodyScroll(false);
    const [permission] = useLynxPermission();

    const [jobPositionsTree, setJobPositionsTree] = useState<JobPositionTreeInterface[]>([]);
    const [jobPositionData, setJobPositionData] = useState<JobPositionInterface>();
    const [branches, setBranchesList] = useState<BranchInterface[]>([]);
    const [branchData, setBranchData] = useState<{ id: number, name: string }>();

    const [isReload, setIsReload] = useState(false);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);


    useEffect(() => {
        getBranches().then((response) => {
            if (response?.data) {
                setBranchesList(response.data);
                setBranchData({
                    id: response.data[0].id,
                    name: response.data[0].name
                });
                setIsReload(true);
            }
        });
    }, []);

    useEffect(() => {
        if (isReload) {
            branchData?.id && getJobPositionsTree({filters: {branchId: branchData.id}}).then((response) => {
                if (response?.data) {
                    setJobPositionsTree(response.data);
                }

                setIsReload(false);
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps


    const renderTree = (jobPositionsTree: any) => {
        const renderLabel = (id: number, name: string, isMultiple: boolean, isManageBranch?: boolean) => {
            let icon = "fa-duotone fa-person";
            isMultiple && (icon = "fa-duotone fa-people-group");
            isManageBranch && (icon = "fa-duotone fa-crown");

            return (
                <div onClick={() => onToggleEditHandler(id)}>
                    <TextBox
                        title={name}
                        icon={icon}
                        iconClassColor={isManageBranch ? "gold" : ""}
                    />
                </div>
            )
        };

        return jobPositionsTree.map((jobPosition: any) => {
            const {
                id, name, children, parentId, isMultiple, isManageBranch
            } = jobPosition;

            if (parentId === 0) {
                return (
                    <Tree
                        key={id}
                        lineWidth={"2px"}
                        lineColor={"#3699ff"}
                        lineBorderRadius={"5px"}
                        label={renderLabel(id, name, isMultiple, isManageBranch)}
                    >
                        {children?.length !== 0 && renderTree(children)}
                    </Tree>
                );
            }

            if (children?.length !== 0) {
                return (
                    <TreeNode key={id} label={renderLabel(id, name, isMultiple)}>
                        {children?.length !== 0 && renderTree(children)}
                    </TreeNode>
                );
            }

            return <TreeNode key={id} label={renderLabel(id, name, isMultiple)}/>;
        });
    };

    const onToggleCreateHandler = () => {
        setIsModalCreateOpen(!isModalCreateOpen);
    };

    const onToggleEditHandler = (id: number) => {
        if (permission.isEdit("jobPosition")) {
            !isModalEditOpen && findJobPositionById(id).then(async (branch: JobPositionResponseInterface) => {
                if (branch?.data) {
                    setJobPositionData(branch.data);
                    setIsModalEditOpen(!isModalEditOpen);
                }
            });
        }
    };

    const onSuccessHandler = () => {
        setIsReload(true);
        setIsModalCreateOpen(false);
        setIsModalEditOpen(false);
    };

    const onDropDownHandler = (data: { value: number, name: string }) => {
        setBranchData({
            id: data.value,
            name: data.name,
        });
        data.value && setIsReload(true);
    };


    return <>
        <div className="lynx-job-positions-page__header">
            <div className="lynx-job-positions-page__header-left">
                <BreadCrumbContainer>
                    <BreadCrumb title="Посади" tree={["Адміністрування", "Довідники"]}/>
                </BreadCrumbContainer>
            </div>
            <div className="lynx-job-positions-page__header-right">
                {permission.isView("jobPosition") &&
                    <div>
                        <DropDown
                            name="branchId"
                            options={prepareDropDownOptions(branches, "id", "name")}
                            required={true}
                            placeholder="Філія"
                            value={branchData?.id}
                            changeHandler={onDropDownHandler}
                            className="lynx-job-positions-page__dropdown"
                        />
                    </div>
                }
                {permission.isCreate("jobPosition") &&
                    <div>
                        <Button
                            name="createJobPosition"
                            title="Створити посаду"
                            className="lynx-btn-primary"
                            onClick={onToggleCreateHandler}
                        />
                    </div>
                }
            </div>
        </div>

        <br/>


        {branchData?.id
            ? <div className="lynx-job-positions-page__scrollbars-wrapper">
                <Scrollbars autoHeight autoHeightMax="100%">
                    <div className="lynx-job-positions-page__scrollbars-inner">{renderTree(jobPositionsTree)}</div>
                </Scrollbars>
            </div>
            : <ComponentErrorMessage title="Для відображення посад потрібна бути вибрана філія"/>
        }

        <ModalSimple
            isOpen={isModalCreateOpen}
            body={
                <JobPositionForm
                    onAfterSuccessHandle={onSuccessHandler}
                    mode={formMode.create}
                    branches={branches}
                    branchId={branchData?.id}
                />}
            title="Створення нової посади"
            onAfterCloseHandle={onToggleCreateHandler}
        />

        <ModalSimple
            isOpen={isModalEditOpen}
            body={
                <JobPositionForm
                    onAfterSuccessHandle={onSuccessHandler}
                    jobPositionData={jobPositionData}
                    mode={formMode.edit}
                    branches={branches}
                    branchId={branchData?.id}
                />}
            title="Редагування посади"
            onAfterCloseHandle={() => setIsModalEditOpen(!isModalEditOpen)}
        />
    </>
};
