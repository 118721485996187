import {request} from "../../../../../../Core/Utils/Request/Request";
import {
    VisitsRequestInterface,
    VisitsResponseInterface,
    VisitResponseInterface,
    VisitRequestInterface, UpdateVisitStateRequestInterface, UpdateVisitStateResponseInterface,
} from "./VisitsInterface";


export const getVisits = async (params: VisitsRequestInterface): Promise<VisitsResponseInterface> => {
    return await request({
        url: '/v1/visits/filters',
        method: 'post',
        data: params,
    })
};

export const getLastVisit = async (clientId: number | string): Promise<VisitResponseInterface> => {
    return await request({
        url: `/v1/visits/last/${clientId}`,
        method: "get",
    })
};

export const findVisitById = async (id: number | string): Promise<VisitResponseInterface> => {
    return await request({
        url: `/v1/visits/${id}`,
        method: 'get'
    });
};

export const createVisit = async (params: VisitRequestInterface): Promise<VisitResponseInterface> => {
    return await request({
        url: '/v1/visits',
        method: 'post',
        data: params
    });
};

export const editVisit = async (id: number | string, params: VisitRequestInterface): Promise<VisitResponseInterface> => {
    return await request({
        url: `/v1/visits/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteVisit = async (id: number | string): Promise<VisitResponseInterface> => {
    return await request({
        url: `/v1/visits/${id}`,
        method: 'delete'
    });
};

export const updateVisitState = async (
   visitId: number | string, params: UpdateVisitStateRequestInterface,
): Promise<UpdateVisitStateResponseInterface> => {
    return await request({
        url: `/v1/visits/state-update/${visitId}`,
        method: "patch",
        data: params,
    });
};
