import {useLynxTariffPlan} from "../../../Project/hooks";
import AccessDenied from "../../../Core/Components/AccessDenied";
import {PermissionPageOutletPropsInterface} from "./PermissionCheckPageInterface";
import PermissionCheckPageCore from "../../../Core/Page/PermissionCheckPage";



function PermissionCheckPage(props: PermissionPageOutletPropsInterface) {
    const {
        permissionCode,
        children,
    } = props;

    const {isAvailable} = useLynxTariffPlan();

    if (!isAvailable(permissionCode)) {
        return <AccessDenied text="Цей розділ недоступний для вашого тарифного плану"/>;
    }

    return <PermissionCheckPageCore permissionCode={permissionCode}>{children}</PermissionCheckPageCore>;
}

export default PermissionCheckPage;