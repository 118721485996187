import {useEffect, useState} from "react";
import moment from "moment/moment";

import {useReloadComponent} from "../../../../../Core/hooks";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../Core/Store/Reload/Actions";
import {
    deleteServiceAgreementAction,
    editServiceAgreementAction,
    viewServiceAgreementAction
} from "./ServiceAgreementsGridHelpers";
import {
    deleteServiceAgreement,
    getServiceAgreements
} from "../../../../Api/Documents/ServiceAgreements";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";

import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import ServiceAgreementModal from "../ServiceAgreementModal";
import ServiceAgreementFilters from "../ServiceAgreementFilters";
import PreparedPrompt from "../../../../../Core/Components/Modal/PreparedPrompt";

import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {ServiceAgreementsGridPropsInterface} from "./ServiceAgreementsGridInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {
    ServiceAgreementInterface
} from "../../../../Api/Documents/ServiceAgreements/ServiceAgreementsInterface";


export default function ServiceAgreementsGrid(props: ServiceAgreementsGridPropsInterface) {
    const {
        filters = {},
        formControl,
        isHideFilters,
        isHideNavigation,
        isHidePadding,
        searchParams,
        setSearchParams,
    } = props;


    const isReload = useReloadComponent("ServiceAgreementsGrid");
    const [permission] = useLynxPermission();

    const [serviceAgreements, setServiceAgreements] = useState<any>({});
    const [serviceAgreementId, setServiceAgreementId] = useState<number | string>();
    const [pagination, setPagination] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState<formMode>(formMode.view);
    const [isPromptOpen, setIsPromptOpen] = useState(false);


    const gridTitle: GridTitleInterface[] = [
        {
            title: "Id",
            code: "id",
            width: "60px"
        },
        {
            title: "Номер договору",
            code: "code",
            width: "140px",
        },
        {
            title: "Дата договору",
            code: "date",
            width: "120px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY"),
        },
        {
            title: "Стан",
            code: "stateName",
            width: "200px",
        },
        {
            title: "Клієнт",
            code: "clientName",
            width: "270px",
        },
        {
            title: "Співробіник",
            code: "employeeName"
        },
        {
            title: "Філія",
            code: "branchName",
            width: "250px",
        },
    ];


    useEffect(() => {
        if (isReload) {
            getServiceAgreements({filters: {...filters, ...searchParams}, pagination})
                .then(response => {
                    response?.status === "success" && setServiceAgreements(response);

                    setReloadComponent("ServiceAgreementsGrid", false);
                });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    // гарантуємо, що при наступному монтуванні isReload = true
    useEffect(() => () => setReloadComponent("ServiceAgreementsGrid", true), []);


    const onFilterHandler = (data: ServiceAgreementInterface) => {
        setSearchParams && setSearchParams(data);
        setReloadComponent("ServiceAgreementsGrid", true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setReloadComponent("ServiceAgreementsGrid", true);
        setPagination(pagination);
    };

    const onSuccessHandler = () => setReloadComponent("ServiceAgreementsGrid", true);

    const onApproveDelete = async (id: number | string) => {
        const response = await deleteServiceAgreement(id);

        if (response?.status === "success") {
            addAlert({
                type: response.status,
                message: response.message,
            });
            onSuccessHandler();
            setIsPromptOpen(false);
        }
    };

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "viewServiceAgreement",
            isShow: permission.isView("serviceAgreement"),
            functions: {setModalMode, setIsModalOpen, setServiceAgreementId},
            action: viewServiceAgreementAction,
        },
        {
            title: "Редагувати",
            name: "editServiceAgreement",
            classNameItem: "lynx-button-action-warning",
            isShow: permission.isEdit("serviceAgreement"),
            functions: {setModalMode, setIsModalOpen, setServiceAgreementId},
            action: editServiceAgreementAction,
        },
        {
            title: "Видалити",
            name: "deleteServiceAgreement",
            classNameItem: "lynx-button-action-danger",
            isShow: permission.isDelete("serviceAgreement"),
            functions: {setIsPromptOpen, setServiceAgreementId},
            action: deleteServiceAgreementAction,
        },
    ];

    const gridMarkup = (
        <Grid
            data={
                (permission.isView("serviceAgreement") && serviceAgreements?.data)
                    ? serviceAgreements?.data
                    : []
            }
            title={gridTitle}
            pagination={serviceAgreements?.pagination || {}}
            onLoadDataHandler={onLoadDataHandler}
            actions={actions}
            isHideNavigation={isHideNavigation}
            isHidePadding={isHidePadding}
        />
    );


    return (
        <>
            {permission.isView("serviceAgreement") &&
                <>
                    {!isHideFilters &&
                        <div className="row">
                            <div className="col-12 order-2 col-xl-9 order-xl-1">
                                {gridMarkup}
                            </div>
                            <div className="col-12 order-1 col-xl-3 order-xl-2">
                                <ServiceAgreementFilters
                                    searchParams={searchParams}
                                    onFilterHandler={onFilterHandler}
                                />
                            </div>
                        </div>
                    }

                    {isHideFilters && gridMarkup}

                    {serviceAgreementId &&
                        <ServiceAgreementModal
                            isOpen={isModalOpen}
                            mode={modalMode}
                            serviceAgreementId={serviceAgreementId}
                            formControl={formControl}
                            onAfterCloseHandle={() => setIsModalOpen(false)}
                            onAfterSuccessHandle={onSuccessHandler}
                        />
                    }

                    {serviceAgreementId &&
                        <PreparedPrompt
                            isOpen={isPromptOpen}
                            code="delete"
                            onCancel={() => setIsPromptOpen(false)}
                            onApprove={() => onApproveDelete(serviceAgreementId)}
                        />
                    }
                </>
            }
        </>
    );
}