// в яку сторону має відкриватись будь-який елемент відносно іншого елемента
import {RefObject} from "react";

export enum PositionEnum {
    Top = "top",
    Right = "right",
    Bottom = "bottom",
    Left = "left",
}

export interface ElementDirectionReturnInterface {
    directionX: PositionEnum.Right | PositionEnum.Left,
    directionY: PositionEnum.Top | PositionEnum.Bottom,
}

export interface PositionDataInterface {
    coordinates: {
        top: number
        left: number,
    },
    openingDirections: ElementDirectionReturnInterface,
    relativeElementData: {
        width: number,
        height: number,
    },
    absoluteElementData: {
        ref: RefObject<HTMLElement>,
    },
}

// відстані до меж viewport
export interface BoundariesInterface {
    top: number,
    right: number,
    bottom: number,
    left: number,
}