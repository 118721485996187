import React, {useEffect} from 'react';
import {TextareaPropsInterface} from "./TextareaInterface";
import './Textarea.css'
import HelperInfo from "../../../HelperInfo";

export default function Textarea(props: TextareaPropsInterface) {

    const {
        disabled,
        required,
        name,
        title,
        value,
        initComponent,
        tooltip,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler
    } = props;

    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            })
        }
    }, [initComponent, name, value, required, beforeChangeHandler, afterChangeHandler]);


    let textareaClasses = ['lynx-textarea'];

    if (disabled) {
        textareaClasses.push('disabled')
    }

    if (required) {
        textareaClasses.push('required')
    }

    const onChangeHandler = (event: React.FormEvent<HTMLTextAreaElement>) => {
        if (changeHandler) {
            const value = event.currentTarget.value
            changeHandler({
                name,
                value
            })
        }
    }


    return (
        <div className={textareaClasses.join(' ')}>
            <div className="lynx-textarea-place-title">
                <div className="lynx-textarea__title">{title}</div>
                {tooltip && <HelperInfo {...tooltip}/>}
            </div>
            <textarea
                data-testid={'textarea-' + name}
                className="lynx-textarea__text"
                data-name={name}
                disabled={!!disabled}
                onChange={onChangeHandler}
                value={value ? value : ''}
            />
        </div>
    )
}