import {useEffect, useRef, useState} from "react";
import {useBoundaryCheck} from "../../hooks";
import {useOutsideClick} from "rooks";
import "./HelperInfo.css";

import FormHR from "../Forms/Elements/FormHR";

import {HelperInfoPropsInterface} from "./HelperInfoInterface";
import {PositionDataInterface} from "../../hooks/hooksInterface";


export default function HelperInfo(props: HelperInfoPropsInterface) {
    const {
        title = "Підказка",
        content,
        className,
        isTitleHide,
    } = props;

    const helperInfoRef = useRef(null);
    const helperInfoButtonRef = useRef(null);
    const helperInfoTooltipRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    // -- start -- все для визначення координат dropdown
    const [helperInfoTooltipRefState, setHelperInfoTooltipRefState] = useState<any>(null);
    const [positionData, setPositionData] = useState<PositionDataInterface | null>(null);
    useBoundaryCheck(helperInfoButtonRef, helperInfoTooltipRefState, setPositionData);

    useEffect(() => {
        if (isOpen && helperInfoTooltipRef?.current) {
            setHelperInfoTooltipRefState(helperInfoTooltipRef);
        }

        return () => {
            setHelperInfoTooltipRefState(null);
            setPositionData(null);
        }
    }, [isOpen]);
    // -- end -- все для визначення координат dropdown


    useOutsideClick(helperInfoTooltipRef, (e: any) => {
        setIsOpen(false);
    });

    const tooltipStyle = {
        opacity: positionData ? 1 : 0,
        top: positionData?.coordinates.top,
        left: positionData?.coordinates.left,
    };

    const tooltipClassName = ["lynx-helper-info__tooltip"];
    className && tooltipClassName.push(className);


    return (
        <div className="lynx-helper-info" ref={helperInfoRef}>
            <div
                className="lynx-helper-info__button"
                onClick={() => setIsOpen(true)}
                title="Натисніть, щоб відкрити підказку"
                ref={helperInfoButtonRef}>
                <i className="fa-duotone fa-lightbulb fa-sm lynx-helper-info__button-icon"></i>
            </div>

            {isOpen && (
                <div className={tooltipClassName.join(" ")} ref={helperInfoTooltipRef} style={tooltipStyle}>
                    {!isTitleHide &&
                        <>
                            <div className="lynx-helper-info__tooltip-title">
                                <i className="fa-duotone fa-square-info"></i>

                                <span>{title}</span>
                            </div>

                            <FormHR type="dashed"/>
                        </>
                    }

                    <div>{content}</div>
                </div>
            )}
        </div>
    );
}