import configLoader from './ConfigLoader';

export {
    configLoader
};

const conf = {
    __esModule: true,
    configLoader
}

export default conf;