import React from 'react';
import ShortCardItem from "../ShortCardItem";
import "./ShortCardContainer.css"
import {ShortCardContainerPropsInterface} from "./ShortCardContainerInterface"

export default function ShortCardContainer(props: ShortCardContainerPropsInterface) {
    return (
        <ul className="lynx-short-card-container">
            {props.itemsData.map((itemData: any) =>
                <li key={itemData.id} className="lynx-short-card-container__item">
                    <ShortCardItem itemData={itemData}/>
                </li>
            )}
        </ul>
    );
};