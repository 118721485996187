import {DateTimePropsInterface} from "./DateTimeInterface";
import React, {useEffect, useState} from "react";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './DateTime.css';
import HelperInfo from "../../../HelperInfo";

export default function DateTime(props: DateTimePropsInterface) {

    const {
        disabled,
        required,
        name,
        title,
        value,
        dateFormat,
        initComponent,
        className,
        tooltip,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler
    } = props;


    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            })
        }
    }, [initComponent, name, value, required, beforeChangeHandler, afterChangeHandler]);

    const [selectedValue, setSelectedValue] = useState<string | null>();

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);



    const onChangeHandler = (date: Date | null) => {
        !date && setSelectedValue(null);

        if (!disabled && changeHandler) {
            // Для moment DD - день місяця, dd - день тижня
            const dateToString = date ? moment(date).format("yyyy-MM-DD") : "";

            changeHandler({
                name,
                value: dateToString,
            })
        }
    };

    let dateTimeClasses = ['lynx-date-time'];

    if (disabled) {
        dateTimeClasses.push('disabled')
    }

    if (required) {
        dateTimeClasses.push('required')
    }

    if (className) {
        dateTimeClasses.push(className);
    }

    return <div className={dateTimeClasses.join(' ')}>
        <div className="lynx-date-time-place-title">
            <div className="lynx-date-time__title">
                {title}
            </div>
            {tooltip && <HelperInfo {...tooltip}/>}
        </div>
        <DatePicker
            // "dd.MM.yyyy" - формат для візуального відображення,
            // де DD - день року, dd - день місяця
            dateFormat={dateFormat ? dateFormat : "dd.MM.yyyy"}
            // "yyyy-MM-DD" - формат в якому приходить value через проп data компонента Form,
            // де DD - день місяця, dd - день тижня(Пн)
            selected={selectedValue ? (moment(selectedValue, "yyyy-MM-DD").toDate()) : null}
            disabled={disabled}
            onChange={onChangeHandler}
        />
    </div>
}