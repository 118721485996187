import {ResponseInterface} from "../../../../../Core/Utils/Request/ResponseInterface";


export enum PermissionsEnum {
    isView = "isView",
    isCreate = "isCreate",
    isEdit = "isEdit",
    isDelete = "isDelete",
}

interface PermissionGroupItemInterface {
    id: number | string,
    name: string,
    code: string,
    isCreate: number | boolean,
    isDelete: number | boolean,
    isEdit: number | boolean,
    isView: number | boolean,
    featureFlags: unknown[], // тип призначається нижче
}

// ========== СПИСОК ГРУП ПРАВ ==========
// група прав в списку всіх груп
export interface ShortPermissionGroupInterface {
    id: number | string,
    name: string,
    counterUser: number,
    items: string[]
}

export interface PermissionGroupsResponseInterface extends ResponseInterface {
    data?: ShortPermissionGroupInterface[]
}

// ========== end СПИСОК ГРУП ПРАВ ==========

// ========== СТРУКТУРА ==========
// featureFlag в структурі
export interface FeatureFlagStructureItemInterface {
    id: number | string,
    name: string,
    code: string,
}

export interface PermissionGroupStructureItemInterface extends PermissionGroupItemInterface {
    featureFlags: FeatureFlagStructureItemInterface[] | [],
}

export interface PermissionGroupStructureResponseInterface extends ResponseInterface {
    data: {
        structure: PermissionGroupStructureItemInterface[],
    }
}

// ========== end СТРУКТУРА ==========

// ========== PERMISSION GROUP ==========
export interface FeatureFlagPermissionItemInterface {
    id: number | string,
    name: string,
    codeFlags: string,
    codePage: string,
}

export interface PermissionGroupPermissionItemInterface extends PermissionGroupItemInterface {
    featureFlags: FeatureFlagPermissionItemInterface[] | [],
}

export interface PermissionGroupInterface {
    id: number | string,
    name: string,
    permission: PermissionGroupPermissionItemInterface[],
    structure: PermissionGroupStructureItemInterface[],
}

export interface CreatePermissionGroupRequestInterface {
    name: string,
}

export interface EditPermissionGroupItemRequestInterface extends Omit<PermissionGroupItemInterface, "code" | "name" | "featureFlags"> {
    featureFlags: (number | string)[],
}

export interface EditPermissionGroupRequestInterface {
    id: number | string,
    name: string,
    permission: EditPermissionGroupItemRequestInterface[],
}

export interface PermissionGroupResponseInterface extends ResponseInterface {
    data?: PermissionGroupInterface,
}

// ========== end PERMISSION GROUP ==========