import "./BrandMobile.css";
import {BrandMobilePropsInterface} from "./BrandMobileInterface";


export default function BrandMobile(props: BrandMobilePropsInterface) {
    const {link, logo, title} = props;

    return (
        <div className="lynx-mobile-brand">
            <a href={link} target="_blank" rel="noreferrer noopener">
                <img className="lynx-mobile-brand__images" src={logo} alt={title}/>
                <span>{title}</span>
            </a>
        </div>
    );
}
