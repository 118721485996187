import {NamedInfoPropsInterface} from "./NamedContentInterface";
import "./NamedContent.css"


export default function NamedContent({items, firstColumnWidth = "30%"}: NamedInfoPropsInterface) {
    return (
        <div className="named-info">
            {items.map((item, index) => {
                return (
                    <div className="named-info__item" key={item.name + index}>
                        <div className="named-info__name" style={{width: `${firstColumnWidth}`}}>
                            {item.name}
                        </div>
                        <div className="named-info__content" style={{width: `calc(100% - ${firstColumnWidth})`}}>{item.content}</div>
                    </div>
                )
            })}
        </div>
    );
};