import './Prompt.css'
import {PromptPropsInterface} from './PromptInterface';
import Button from "../../Forms/Elements/Button";

export default function Prompt(props: PromptPropsInterface) {
    const {
        title,
        description,
        isOpen,
        approveTextButton = 'Підтвердити',
        onApprove,
        cancelTextButton = 'Скасувати',
        onCancel,
        className,
    } = props;

    const approveHandle = (): void => {
        if (onApprove) {
            onApprove()
        }
    }

    const cancelHandle = (): void => {
        if (onCancel) {
            onCancel()
        }
    }

    const promptClass = ['lynx-prompt'];

    if (className) {
        promptClass.push(className)
    }

    return (
        isOpen
            ? <div className="lynx-prompt-overlay">
                <div className={promptClass.join(' ')}>
                    <div className="lynx-prompt__title">{title}</div>
                    <div className="lynx-prompt__description">{description}</div>
                    <div className="lynx-prompt__controls">
                        <Button className="lynx-btn-primary approve" onClick={approveHandle} name="approve"
                                title={approveTextButton}/>
                        <Button className="lynx-btn-secondary cancel" onClick={cancelHandle} name="cancel"
                                title={cancelTextButton}/>
                    </div>
                </div>
            </div>
            : <></>
    );
}
