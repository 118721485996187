import {useState} from "react";
import "./ClientPaymentsPage.css";

import {useLynxPermission} from "../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle} from "../../../../Core/hooks";
import {setReloadComponent} from "../../../../Core/Store/Reload/Actions";

import BreadCrumbContainer from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../Core/Components/Forms/Elements/Button";
import ClientPaymentsGrid from "../../../Components/Payments/ClientPayments/ClientPaymentsGrid";
import ClientPaymentModal from "../../../Components/Payments/ClientPayments/ClientPaymentModal";

import {formMode as formModeEnum} from "../../../../Core/Components/Forms/Form/FormInterface";


export default function ClientPaymentsPage() {
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    usePageTitle("Платежі клієнта");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formMode, setFormMode] = useState<formModeEnum>();


    const onSuccessHandler = () => {
        setReloadComponent("ClientPaymentsGrid", true);

        if (permission.isEdit("clientPayment")) {
            setFormMode(formModeEnum.edit);
        } else if (permission.isView("clientPayment")) {
            setFormMode(formModeEnum.view);
        } else {
            setIsModalOpen(false);
        }
    };


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Платежі клієнта" tree={["Платежі"]}/>
                <div className="lynx-service-agreements-page__buttons">
                    {permission.isCreate("clientPayment") &&
                        <>
                            <Button
                                name="addClientPayment"
                                title="Створити платіж"
                                className="lynx-btn-primary"
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setFormMode(formModeEnum.create)
                                }}
                            />
                        </>
                    }
                </div>
            </BreadCrumbContainer>

            <br/>

            <ClientPaymentsGrid
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />

            {formMode &&
                <ClientPaymentModal
                    isOpen={isModalOpen}
                    mode={formMode}
                    onAfterCloseHandle={() => setIsModalOpen(false)}
                    onAfterSuccessHandle={onSuccessHandler}
                />
            }
        </>
    );
}