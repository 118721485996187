import React, {useEffect, useState} from "react";
import moment from "moment";
import "./ClientLastVisitBlock.css";

import {setReloadComponent} from "../../../../../../../Core/Store/Reload/Actions";
import {getLastVisit} from "../../../../../../Api/CRM/RecordManagement/Visits/Visits";
import {useReloadComponent} from "../../../../../../../Core/hooks";

import FieldsetWithElements from "../../../../../../../Core/Components/Forms/Elements/FieldsetWithElements";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormHR from "../../../../../../../Core/Components/Forms/Elements/FormHR";
import NamedContent from "../../../../../../../Core/Components/DataVisualization/NamedContent";
import VisitModal from "../../../../RecordManagement/Visits/VisitModal";

import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {ClientLastVisitBlockPropsInterface} from "./ClientLastVisitBlockInterface";
import {VisitInterface} from "../../../../../../Api/CRM/RecordManagement/Visits/Visits/VisitsInterface";


const ClientLastVisitBlock = ({clientId}: ClientLastVisitBlockPropsInterface) => {
    const isReload = useReloadComponent("ClientLastVisitBlock");
    const [lastVisit, setLastVisit] = useState<VisitInterface>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (isReload && clientId) {
            getLastVisit(clientId).then(response => {
                response?.data && setLastVisit(response.data);
            });
            setReloadComponent("ClientLastVisitBlock", false);
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    // гарантуємо, що при наступному монтуванні isReload = true
    useEffect(() => () => setReloadComponent("ClientLastVisitBlock", true), []);


    const onEditButtonClick = () => {
        lastVisit && setIsModalOpen(true);
    };

    const onAfterSuccessHandle = () => {
        setIsModalOpen(false);
        setReloadComponent("ClientLastVisitBlock", true);
        setReloadComponent("VisitsGrid", true);
        setReloadComponent("CalendarsContainer", true);
    };


    return (
        <>
            <FieldsetWithElements title="Останній візит" elementInTheEnd element={
                <Button
                    name="editLastVsist"
                    icon="fa-duotone fa-user-pen"
                    className="lynx-button lynx-btn__button-xs"
                    onClick={onEditButtonClick}
                />
            }/>

            <FormHR type="dashed" isPaddingOut/>

            {lastVisit &&
                <div className="lynx-client__block-content">
                    <NamedContent items={[
                        {
                            name: "ПІБ",
                            content: <>{lastVisit.clientName}</>,
                        },
                        {
                            name: "Дата візиту",
                            content: <>{moment(lastVisit.visitDate).format("DD.MM.YYYY")}</>,
                        },
                        {
                            name: "Тривалість візиту",
                            content: <>{lastVisit.duration}</>,
                        },
                        {
                            name: "Стан візиту",
                            content: <>{lastVisit.statusName}</>,
                        },
                        {
                            name: "Робоче місце",
                            content: <>{lastVisit.workplaceName}</>,
                        },
                        {
                            name: "Анамнез",
                            content: <>{lastVisit.note}</>,
                        },
                        {
                            name: "Співробітник",
                            content: <>{lastVisit.employeeName}</>,
                        },
                        {
                            name: "Ким створено",
                            content: <>{lastVisit.userName}</>,
                        },
                    ]}/>
                </div>
            }

            {!lastVisit &&
                <div className="lynx-client-last-visit-block-not-found-data">
                    <i className="fa-duotone fa-memo-circle-info lynx-client-last-visit-block-not-found-data__icon"/>
                    <span className="lynx-client-last-visit-block-not-found-data__text">Дані відсутні</span>
                </div>}

            <VisitModal
                isOpen={isModalOpen}
                mode={formMode.edit}
                visitData={lastVisit}
                clientId={clientId}
                formControl={{
                    fieldset: {client: {visible: false}}
                }}
                onAfterCloseHandle={() => setIsModalOpen(false)}
                onAfterSuccessHandle={onAfterSuccessHandle}
            />
        </>
    );
};

export default ClientLastVisitBlock;