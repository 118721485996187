import moment from "moment";
import {
    AgeEnum,
    AgeInterface,
    SourceByDateInterface,
} from "../../../../Api/Reports/ClientPortrait/ClientPortraitInterface";
import {
    DoughnutChartSeriesInterface
} from "../../../../../Core/Components/Charts/ECharts/EChartsReactTypes/DoughnutChart/DoughnutChartInterface";
import {
    StackedLineChartData,
} from "../../../../../Core/Components/Charts/ECharts/EChartsReactTypes/StackedLineChart/StackedLineChartInterface";
import {
    ClientSourceInterface,
} from "../../../../Api/Administration/Directories/ClientSources/ClientSourcesInterface";


export const prepareAgeChartData = (ages: AgeInterface): DoughnutChartSeriesInterface[] => {
    const dataArr: DoughnutChartSeriesInterface[] = [];

    if (ages) {
        const ageNames: Partial<{ [key in AgeEnum]: string }> = {
            [AgeEnum.Above45]: "старше 45 років",
            [AgeEnum.between30And45]: "від 30 до 45 років",
            [AgeEnum.Between18And30]: "від 18 до 30 років",
            [AgeEnum.Under18]: "до 18 років",
        };

        for (const agesKey in ages) {
            if (ageNames.hasOwnProperty(agesKey)) {
                dataArr.push({
                    name: ageNames[agesKey as AgeEnum]!,
                    value: ages[agesKey as AgeEnum],
                });
            }
        }
    }

    return dataArr;
};

export const prepareSourceByDateChartData = (
    sourcesOnSelectedDates: SourceByDateInterface[], clientSources: ClientSourceInterface[]
): StackedLineChartData | null => {
    if (!sourcesOnSelectedDates?.length || !clientSources?.length) {
        return null;
    }

    const series= clientSources.map(clientSource => {
        const data: number[] = [];

        sourcesOnSelectedDates.forEach(({sourceByDate}) => {
            for (const sourceObj of sourceByDate) {
                if (sourceObj.source === clientSource.name) {
                    data.push(sourceObj.clientCount);
                    break;
                }
            }
        });

        return {
            name: clientSource.name,
            type: "line",
            smooth: true,
            data,
        };
    });

    return {
        xAxis: sourcesOnSelectedDates.map(({date}) => moment(date).format("DD.MM.YYYY")),
        series: series,
        legend: clientSources.map(({name}) => name), // всі series.name це і буде масив legend
    };
};