import React, {useEffect, useState} from "react";
import "./EmployeeTimetablePage.css";
import 'moment/locale/uk';

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../Core/Store/Reload/Actions";
import {
    buildEmployeeTimetableAction,
    createEmployeeTimetableAction,
    destroyEmployeeTimetableAction
} from "./EmployeeTimetablePageHelpers";
import {usePageTitle} from "../../../../../Core/hooks";
import {useBranch} from "../../../../hooks";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import ButtonActions from "../../../../../Core/Components/ButtonActions";
import EmployeeTimetableGrid
    from "../../../../Components/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableGrid";
import EmployeeTimetableFormForDay
    from "../../../../Components/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableForms/EmployeeTimetableFormForDay";
import DropDown, {prepareDropDownOptions} from "../../../../../Core/Components/Forms/Elements/DropDown";
import BuildEmployeeTimetableForm
    from "../../../../Components/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableForms/BuildEmployeeTimetableForm";
import TimetableIntervalMessageForm
    from "../../../../Components/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableForms/TimeyableIntervalMessageForm";
import DestroyEmployeeTimetableForm
    from "../../../../Components/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableForms/DestroyEmployeeTimetableForm";

import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {
    ClarifyingMessagesAfterBuildInterface
} from "../../../../Api/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableInterface";
import {
    OnSuccessHandlerParamsInterface
} from "../../../../Components/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableGrid/EmployeeTimetableGridInterface";
import {EmployeeWorkingBranch} from "../../../../Api/Administration/Employees/EmployeesInterface";
import ComponentErrorMessage from "../../../../../Core/Components/ErrorVisualization/ComponentErrorMessage";


export default function EmployeeTimetablePage() {
    const [permission] = useLynxPermission();
    usePageTitle("Графік роботи");

    const {
        selectedEmployeeWorkingBranch,
        availableBranches,
    } = useBranch();

    const [branchData, setBranchData] = useState<EmployeeWorkingBranch | null>(null);

    const [isModalCreateTimetableOpen, setIsModalCreateTimetableOpen] = useState(false);
    const [isModalBuildTimetableOpen, setIsModalBuildTimetableOpen] = useState(false);
    const [isModalDestroyTimetableOpen, setIsModalDestroyTimetableOpen] = useState(false);

    // для TimetableIntervalMessageForm
    const [isRejectedMessagesFormOpen, setIsRejectedMessagesFormOpen] = useState(false);
    const [rejectedMessages, setRejectedMessages] = useState<ClarifyingMessagesAfterBuildInterface>({});


    useEffect(() => {
        setBranchData(selectedEmployeeWorkingBranch)
    }, [selectedEmployeeWorkingBranch?.id]); // eslint-disable-line react-hooks/exhaustive-deps


    const actions: Actions[] = [
        {
            title: "Створити на день",
            name: "createEmployeeTimetable",
            isShow: permission.isEdit("employeeTimetable"),
            functions: {setIsModalCreateTimetableOpen},
            action: createEmployeeTimetableAction,
        },
        {
            title: "Створити по шаблону",
            name: "buildEmployeeTimetable",
            isShow: permission.isEdit("employeeTimetable"),
            classNameItem: "lynx-button-action-info",
            functions: {setIsModalBuildTimetableOpen},
            action: buildEmployeeTimetableAction,
        },
        {
            title: "Видалити інтервал",
            name: "destroyEmployeeTimetable",
            isShow: permission.isDelete("employeeTimetable"),
            classNameItem: "lynx-button-action-danger",
            functions: {setIsModalDestroyTimetableOpen},
            action: destroyEmployeeTimetableAction,
        },
    ];

    const onDropDownHandler = (data: { value: number, name: string }) => {
        setBranchData({
            id: data.value,
            name: data.name,
        });
        setReloadComponent("employeeTimetableGrid", true);
    };

    const onSuccessHandler = (params?: OnSuccessHandlerParamsInterface) => {
        const operation = params?.operation;
        const rejectedMessagesAfterBuild = params?.messages;

        setIsModalCreateTimetableOpen(false);
        setIsModalBuildTimetableOpen(false);
        setIsModalDestroyTimetableOpen(false);
        setReloadComponent("employeeTimetableGrid", true);

        if (operation === "build" && rejectedMessagesAfterBuild && Object.keys(rejectedMessagesAfterBuild).length) {
            setRejectedMessages(rejectedMessagesAfterBuild);
            setIsRejectedMessagesFormOpen(true);
        }
    };


    return (
        <>
            <div className="lynx-employee-timetable-page__header">
                <div className="lynx-employee-timetable-page__header-left">
                    <BreadCrumbContainer>
                        <BreadCrumb title="Графік роботи" tree={["Адміністрування", "Управління персоналом"]}/>
                    </BreadCrumbContainer>
                </div>
                <div className="lynx-employee-timetable-page__header-right">
                    {permission.isView("employeeTimetable") &&
                        <DropDown
                            name="branchId"
                            options={prepareDropDownOptions(availableBranches, "id", "name")}
                            placeholder="Філія"
                            value={branchData?.id}
                            changeHandler={onDropDownHandler}
                        />
                    }
                    {permission.isCreate("employeeTimetable") && branchData?.id &&
                        <div>
                            <ButtonActions
                                title="Опції"
                                actions={actions}
                            />
                        </div>
                    }
                </div>
            </div>

            <br/>

            {branchData?.id
                ? <EmployeeTimetableGrid branchId={branchData.id}/>
                : <ComponentErrorMessage title="Для відображення графіку потрібна бути вибрана філія"/>
            }

            <ModalSimple
                isOpen={isModalCreateTimetableOpen}
                body={
                    <EmployeeTimetableFormForDay
                        mode={formMode.create}
                        branchId={branchData?.id}
                        onAfterSuccessHandler={onSuccessHandler}
                    />
                }
                title="Створення графіку на день"
                onAfterCloseHandle={() => setIsModalCreateTimetableOpen(false)}
            />

            <ModalSimple
                isOpen={isModalBuildTimetableOpen}
                body={
                    <BuildEmployeeTimetableForm
                        mode={formMode.create}
                        branchId={branchData?.id}
                        onAfterSuccessHandler={onSuccessHandler}
                    />
                }
                title="Створення графіку по шаблону"
                onAfterCloseHandle={() => setIsModalBuildTimetableOpen(false)}
            />

            <ModalSimple
                isOpen={isRejectedMessagesFormOpen}
                title="Уточнююча інформація"
                body={
                    <TimetableIntervalMessageForm
                        messages={rejectedMessages}
                        onAfterCloseHandler={() => setIsRejectedMessagesFormOpen(false)}
                    />
                }
                widthClass="sm"
                onAfterCloseHandle={() => setIsRejectedMessagesFormOpen(false)}
            />

            {branchData?.id &&
                <ModalSimple
                    isOpen={isModalDestroyTimetableOpen}
                    title="Видалення інтервалу"
                    body={
                        <DestroyEmployeeTimetableForm
                            branchId={branchData?.id}
                            onAfterSuccessHandler={onSuccessHandler}
                        />
                    }
                    onAfterCloseHandle={() => setIsModalDestroyTimetableOpen(false)}
                />
            }
        </>
    );
};