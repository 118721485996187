import './FormHR.css';
import {FormHRPropsInterface} from "./FormHRInterface";

const FormHR = ({type, isPaddingOut}: FormHRPropsInterface) => {
    const formHRClass = ["lynx-form-hr lynx-form-hr__" + type];
    isPaddingOut && formHRClass.push("lynx-form-hr--padding-out");

    return <div className={formHRClass.join(" ")}/>
}

export default FormHR;