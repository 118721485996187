export const createEmployeeTimetableAction = (_: never, functions: {
    setIsModalCreateTimetableOpen(isOpen: boolean): void,
}) => {
    functions.setIsModalCreateTimetableOpen(true);
};

export const buildEmployeeTimetableAction = (_: never, functions: {
    setIsModalBuildTimetableOpen(isOpen: boolean): void,
}) => {
    functions.setIsModalBuildTimetableOpen(true);
};

export const destroyEmployeeTimetableAction = (_: never, functions: {
    setIsModalDestroyTimetableOpen(isOpen: boolean): void,
}) => {
    functions.setIsModalDestroyTimetableOpen(true);
};