export const Core = {
    SET_FILTERS: 'SET_FILTERS'
};

export interface FilterInterface {
    id: number,
    name: string,
    code: string,
    filter: {},
    created: string
}

export interface FiltersReducersInterface {
    filters: FilterInterface[]
}