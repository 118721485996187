import {useEffect, useState} from "react";
import moment from "moment";

import {getClientCommunicationLog} from "../../../../../../Api/CRM/Clients";

import FormHR from "../../../../../../../Core/Components/Forms/Elements/FormHR";
import Fieldset from "../../../../../../../Core/Components/Forms/Elements/Fieldset";
import Grid from "../../../../../../../Core/Components/Tables/Grids/Grid";

import {ClientCommunicationLogGridBlockPropsInterface} from "./ClientCommunicationLogGridBlockInterface";
import {GridTitleInterface} from "../../../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {ClientCommunicationLogInterface} from "../../../../../../Api/CRM/Clients/ClientsInterface";


const ClientCommunicationLogGridBlock = ({clientId}: ClientCommunicationLogGridBlockPropsInterface) => {
    const [communicationLogs, setCommunicationLogs] = useState<ClientCommunicationLogInterface[] | []>([]);

    const gridTitle: GridTitleInterface[] = [
        {
            title: "Id",
            code: "id",
            width: "60px"
        },
        {
            title: "Подія",
            code: "eventName",
        },
        {
            title: "Дата та час виникнення",
            code: "dateTime",
            width: "190px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY HH:mm:ss"),
        },
        {
            title: "Канал комунікацій",
            code: "channelName",
        },
        {
            title: "Результат",
            code: "result",
        },
    ];


    useEffect(() => {
        getClientCommunicationLog({filters: {clientId}}).then(response => {
            response?.data && setCommunicationLogs(response.data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Fieldset title="Список комунікацій"/>
            <FormHR type="dashed" isPaddingOut/>

            <Grid
                data={communicationLogs}
                title={gridTitle}
                isHideNavigation
                isHidePadding
            />
        </>
    );
};

export default ClientCommunicationLogGridBlock;