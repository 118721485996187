import {AnyAction} from "redux";
import {CustomerGroupsStateType, CustomerGroupsType} from "./Types";

const initialState: CustomerGroupsStateType = [];

/**
 *
 * @param state
 * @param action
 */

const customerGroups = (state: CustomerGroupsStateType = initialState, action: AnyAction): CustomerGroupsStateType => {
    const {type, payload} = action;
    switch (type) {
        case CustomerGroupsType.GET_CUSTOMER_GROUP:
            return [...payload];
        default:
            return state;
    }
};

export default customerGroups;