import {AnyAction} from "redux";
import {TimesType, TimesStateType} from "./Types";

const initialState: TimesStateType = [];


const times = (state: TimesStateType = initialState, action: AnyAction): TimesStateType => {
    const {type, payload} = action;
    switch (type) {
        case TimesType.GET_TIMES:
            return [...payload];
        default:
            return state;
    }
};

export default times;