import {Dispatch, SetStateAction} from "react";


export enum ValueTypeEnum {
    Number = "number",
    Time = "time",
}

export interface AnimateValueParamsInterface {
    targetValue: number | string, // value до якого потрібно збільшити
    valueType?: ValueTypeEnum,
    setValue?: Dispatch<SetStateAction<number | string>>, // setState value, щоб було видно анімацію в інтерфейсі
    startValue?: number | string, // з якого значення починати
    animationDuration?: number, // тривалість збільшення value
    partsCount?: number, // на скільки частинок робивати targetValue
}

export interface ItemDataInterface extends AnimateValueParamsInterface {
    icon?: string,
    description?: string | number,
    className?: string,
    classNameIcon?: string,
    isShow?: boolean | ((data: any) => boolean)
}

export interface ShortInfoItemPropsInterface {
    itemData: ItemDataInterface,
}