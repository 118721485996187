import {
    ClientsRequestInterface,
    ClientsResponseInterface,
    ClientRequestInterface,
    ClientResponseInterface,
    ClientCommunicationLogsResponseInterface,
    ClientCommunicationLogsRequestInterface,
} from "./ClientsInterface";
import {request} from "../../../../Core/Utils/Request/Request";

export const getClients = async (params: ClientsRequestInterface): Promise<ClientsResponseInterface> => {
    return await request({
        url: '/v1/clients/filters',
        method: 'post',
        data: params,
    });
};

export const findClientById = async (id: number | string): Promise<ClientResponseInterface> => {
    return await request({
        url: `/v1/clients/${id}`,
        method: 'get'
    });
};

export const createClient = async (params: ClientRequestInterface): Promise<ClientResponseInterface> => {
    return await request({
        url: '/v1/clients',
        method: 'post',
        data: params
    });
};

export const editClient = async (id: number | string, params: ClientRequestInterface): Promise<ClientResponseInterface> => {
    return await request({
        url: `/v1/clients/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteClient = async (id: number | string): Promise<ClientResponseInterface> => {
    return await request({
        url: `/v1/clients/${id}`,
        method: 'delete'
    });
};

export const getLiveSearchClient = async (searchRequest: string) => {
    return await request({
        url: "/v1/client-search",
        method: "post",
        data: {
            searchRequest,
        },
        options: {
            isShowSpinner: false,
        },
    });
};

export const getClientCommunicationLog = async (
    params?: ClientCommunicationLogsRequestInterface
): Promise<ClientCommunicationLogsResponseInterface> => {
    return await request({
        url: "/v1/communication/logs/filters",
        method: "post",
        data: params,
    });
};