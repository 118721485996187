import Cookies from "universal-cookie";
import {configLoader} from "../../../../Core/Utils/ConfigLoader";

export async function signOut() {
    const cookies = new Cookies();
    let token = cookies.get('token');
    if (token) {
        cookies.remove('token', {path: '/'})
    }

    const {redirectNoAuthUser} = configLoader().authConfig;

    window.location.href = redirectNoAuthUser.link;
}

export async function setAuthCookie(token: string, time: number) {
    const cookies = new Cookies();

    cookies.set('token', token, {
        path: '/',
        maxAge: time
    });
    return await cookies.get('token');
}

export async function removeAuthCookie() {
    const cookiesRemove = new Cookies();

    let tokenRemove = cookiesRemove.get('token');
    if (tokenRemove) {
        await cookiesRemove.remove('token', {path: '/'})
    }
}



export async function redirectSuccessAuth() {
    const {successAuth} = configLoader().authConfig;
    window.location.href = successAuth.link;
}


