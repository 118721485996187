import {useEffect} from "react";
import "./ServicesPerformedCertificatesFilters.css"
import {useSelector, useDispatch} from "react-redux";

import {useBranch} from "../../../../hooks";
import {getLiveSearchClient} from "../../../../Api/CRM/Clients";
import {getLiveSearchEmployee} from "../../../../Api/Administration/Employees";
import {
    getServicesPerformedCertificatesStatesAction
} from "../../../../Store/Documents/ServicesPerformedCertificates/Actions";

import ExpandableLink from "../../../../../Core/Components/ExpandableLink";
import DateTime from '../../../../../Core/Components/Forms/Elements/DateTime';
import FiltersForm from "../../../../../Core/Components/Forms/Filters/FiltersForm";
import DropDown, {prepareDropDownOptions} from "../../../../../Core/Components/Forms/Elements/DropDown";
import LiveSearch, {prepareLiveSearchOptions} from "../../../../../Core/Components/Forms/Elements/LiveSearch";

import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {
    ServicesPerformedCertificatesFiltersPropsInterface
} from "./ServicesPerformedCertificatesFiltersInterface";
import {LiveSearchOptionInterface} from "../../../../../Core/Components/Forms/Elements/LiveSearch/LiveSearchInterface";


function ServicesPerformedCertificatesFilters(props: ServicesPerformedCertificatesFiltersPropsInterface) {
    const {
        searchParams,
        onFilterHandler,
    } = props;

    const {availableBranches} = useBranch();

    const servicesPerformedCertificatesStates = useSelector(
        (store: StoreInterface) => store.shortCache.servicesPerformedCertificatesStates
    ); // статуси актів
    const dispatch = useDispatch();


    useEffect(() => {
        !servicesPerformedCertificatesStates?.length && dispatch(getServicesPerformedCertificatesStatesAction());
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps


    const loadClientOptions = async (inputValue: string): Promise<LiveSearchOptionInterface[] | []> => {
        return getLiveSearchClient(inputValue).then(response => {
            return prepareLiveSearchOptions(response?.data, "id", "clientName");
        });
    };

    const loadEmployeeOptions = async (inputValue: string): Promise<LiveSearchOptionInterface[] | []> => {
        return getLiveSearchEmployee({
            filters: {
                searchRequest: inputValue,
            }
        }).then(response => {
            return response?.data ? prepareLiveSearchOptions(response.data, "id", "employeeName") : []
        });
    };


    return (
        <FiltersForm
            code="servicesPerformedCertificate"
            searchParams={searchParams ? searchParams : {}}
            filterHandler={onFilterHandler}
        >
            <div className="row">
                <div className="services-performed-certificates-filters__inner">
                    <ExpandableLink title="Основні фільтри" isVisibleDefault>
                        <div className="col-12">
                            <LiveSearch
                                name="clientId"
                                loadOptions={loadClientOptions}
                                title="Клієнт"
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="stateId"
                                title="Статус"
                                options={prepareDropDownOptions(servicesPerformedCertificatesStates, "id", "name")}
                            />
                        </div>
                        <div className="col-12">
                            <DateTime name="dateStart" title="Дата (з)"/>
                        </div>
                        <div className="col-12">
                            <DateTime name="dateEnd" title="Дата (по)"/>
                        </div>
                    </ExpandableLink>

                    <ExpandableLink title="Додаткові фільтри">
                        <div className="col-12">
                            <DropDown
                                name="branchId"
                                title="Філія"
                                options={availableBranches}
                            />
                        </div>
                        <div className="col-12">
                            <LiveSearch
                                name="employeeId"
                                loadOptions={loadEmployeeOptions}
                                title="Співробітник"
                            />
                        </div>
                    </ExpandableLink>
                </div>
            </div>
        </FiltersForm>
    );
}

export default ServicesPerformedCertificatesFilters;
