import React from "react";
import {TabsLinkNavigationItemsContentPropsInterface} from "./TabsLinkNavigationItemsContentInterface";

export default function TabsLinkNavigationItemsContent(props: TabsLinkNavigationItemsContentPropsInterface) {
    const {
        tabs,
        onChangeHandler,
        activeTab,
    } = props;

    return (
        <div>
            {tabs.map((tab) => (
                <div
                    key={tab.id}
                    className={tab.id === activeTab ? "active" : ""}
                    onClick={() => onChangeHandler(tab.id)}
                >
                    {tab.content}
                </div>
            ))}
        </div>
    );
};