import {addAlert} from "../../../../Core/Store/Alert/Actions";
import {deleteServiceAgreement} from "../../../Api/Documents/ServiceAgreements";
import {deleteServicesPerformedCertificate} from "../../../Api/Documents/ServicesPerformedCertificates";

import {formMode} from "../../../../Core/Components/Forms/Form/FormInterface";
import {
    ClientDocumentType,
    ClientDocumentTypeIdEnum
} from "../../../Api/Documents/ClientDocuments/ClientDocumentsInterface";
import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export const createServicesPerformedCertificateAction = (_: never, functions: {
    setIsServicesPerformedCertificateModalOpen(isOpen: boolean): void,
    setModalMode(mode: formMode): void,
}) => {
    functions.setIsServicesPerformedCertificateModalOpen(true);
    functions.setModalMode(formMode.create);
};

export const createServiceAgreementAction = (_: never, functions: {
    setIsServiceAgreementModalOpen(isOpen: boolean): void,
    setModalMode(mode: formMode): void,
}) => {
    functions.setIsServiceAgreementModalOpen(true);
    functions.setModalMode(formMode.create);
};

export const viewDocumentAction = (data: ClientDocumentType, functions: {
    setModalMode(mode: formMode): void,
    setIsServiceAgreementModalOpen(isOpen: boolean): void,
    setIsServicesPerformedCertificateModalOpen(isOpen: boolean): void,
    setServicesPerformedCertificateId(id: number | string): void,
    setServiceAgreementId(id: number | string): void,
}) => {
    functions.setModalMode(formMode.view);

    switch (data.documentTypeId) {
        case ClientDocumentTypeIdEnum.ServiceAgreement:
            functions.setIsServiceAgreementModalOpen(true);
            functions.setServiceAgreementId(data.id);
            break;
        case ClientDocumentTypeIdEnum.ServicePerformedCertificate:
            functions.setIsServicesPerformedCertificateModalOpen(true);
            functions.setServicesPerformedCertificateId(data.id);
            break;
    }
};

export const editDocumentAction = (data: ClientDocumentType, functions: {
    setModalMode(mode: formMode): void,
    setIsServiceAgreementModalOpen(isOpen: boolean): void,
    setIsServicesPerformedCertificateModalOpen(isOpen: boolean): void,
    setServicesPerformedCertificateId(id: number | string): void,
    setServiceAgreementId(id: number | string): void,
}) => {
    functions.setModalMode(formMode.edit);

    switch (data.documentTypeId) {
        case ClientDocumentTypeIdEnum.ServiceAgreement:
            functions.setIsServiceAgreementModalOpen(true);
            functions.setServiceAgreementId(data.id);
            break;
        case ClientDocumentTypeIdEnum.ServicePerformedCertificate:
            functions.setIsServicesPerformedCertificateModalOpen(true);
            functions.setServicesPerformedCertificateId(data.id);
            break;
    }
};

export const deleteDocumentAction = async (data: ClientDocumentType, functions: {
    onSuccessHandler(): void,
}) => {
    let response: ResponseInterface | null = null;

    switch (data.documentTypeId) {
        case ClientDocumentTypeIdEnum.ServiceAgreement:
            response = await deleteServiceAgreement(data.id);
            break;
        case ClientDocumentTypeIdEnum.ServicePerformedCertificate:
            response = await deleteServicesPerformedCertificate(data.id);
            break;
    }

    if (response && response.status === "success") {
        addAlert({
            type: response.status,
            message: response.message,
        });
        functions.onSuccessHandler();
    }
};