import {ApplicationActions, ApplicationState} from './Types'
import {AnyAction} from "redux";

const initialState: ApplicationState = {
    loader: false
};


const application = (state: ApplicationState = initialState, action: AnyAction) => {
    const {type} = action;

    switch (type) {
        case ApplicationActions.START_LOADING :
            return {...state, loader: true};
        case ApplicationActions.STOP_LOADING :
            return {...state, loader: false}
    }

    return state;
}

export default application;