import {useEffect} from "react";

import {setReloadPage} from "../../../../Core/Store/Reload/Actions";
import {usePageTitle} from "../../../../Core/hooks";

import UserSettingNotifications from "../../../Components/UserSettings/UserSettingsGroups/UserSettingNotifications";
import TabsLinkGeneralContainer from "../../../../Core/Components/TabsLink/TabsLinkContainers/TabsLinkGeneralContainer";
import TabsLinkContent from "../../../../Core/Components/TabsLink/TabsLinkContent";
import BreadCrumbContainer from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import TabsLinkNavigation from "../../../../Core/Components/TabsLink/TabsLinkNavigation";
import TabsLinkNavigationGeneralItem
    from "../../../../Core/Components/TabsLink/TabsLinkNavigationItem/TabsLinkNavigationGeneralItem";

import {UserSettingGroupIdsEnum} from "../../../Api/UserSettings/UserSettingsInterface";


export default function UserSettingsPage() {
    usePageTitle("Налаштування користувача");

    useEffect(() => {
      return  () => setReloadPage("UserSettingsPage", true);
    }, []);


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Налаштування користувача"/>
            </BreadCrumbContainer>

            <br/>

            <TabsLinkGeneralContainer>
                <TabsLinkNavigation
                    isTabsContent
                    tabs={[
                        {
                            id: UserSettingGroupIdsEnum.Notifications,
                            content:
                                <TabsLinkNavigationGeneralItem
                                    icon="fa-duotone fa-bell-on"
                                    text="Нотифікації"
                                />
                        },
                    ]}
                    name="userSettings"
                />

                <TabsLinkContent tabFor="userSettings" tabs={[
                    {
                        id: UserSettingGroupIdsEnum.Notifications,
                        component: <UserSettingNotifications/>
                    },
                ]}/>
            </TabsLinkGeneralContainer>
        </>
    );
}