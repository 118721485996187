import {useState} from 'react';

import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {useLynxPermission} from "../../../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../../../Core/Store/Reload/Actions";

import EmployeeTimetableModalForDay from "../../EmployeeTimetableModals/EmployeeTimetableModalForDay";

import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {
    EmployeeTimetableNotTypePropsInterface
} from "./EmployeeTimetableNotTypeInterface";


export default function EmployeeTimetableNotType(props: EmployeeTimetableNotTypePropsInterface) {
    const {
        branchId,
        employeeName,
        employeeId,
        date,
        className,
    } = props;

    const [permission] = useLynxPermission();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mode, setMode] = useState<formMode | null>(null);


    const onCellClickHandler = () => {
        if (permission.isCreate("employeeTimetable")) {
            setMode(formMode.create);
            setIsModalOpen(true);
            return;
        }

        addAlert({
            type: "warning",
            message: "Відсутні права на створення",
        });
    };

    const onSuccessHandler = () => {
        setIsModalOpen(false);
        setReloadComponent("employeeTimetableGrid", true);
    };

    // дані підготовлені для SimpleFrom
    const employeeDayTimetableData = {
        employeeId,
        employeeName,
        date,
    };


    return (
        <>
            <div className={className} onClick={onCellClickHandler}/>

            <EmployeeTimetableModalForDay
                mode={mode}
                branchId={branchId}
                employeeDayTimetableData={employeeDayTimetableData}
                isOpen={isModalOpen}
                onAfterSuccessHandler={onSuccessHandler}
                onAfterCloseHandler={() => setIsModalOpen(false)}
            />
        </>
    );
}