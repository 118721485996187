import {ClientSourceInterface} from "../../../Api/Administration/Directories/ClientSources/ClientSourcesInterface";
import {ClientSourcesType} from "./Types";
import {getClientSources} from "../../../Api/Administration/Directories/ClientSources";


export const setClientSources = (clientSources: ClientSourceInterface[] | undefined) => {
    return {
        type: ClientSourcesType.GET_CLIENT_SOURCES,
        payload: clientSources,
    };
};

export const getClientSourcesAction = () => async (dispatch: Function) => {
    const clientSources = await getClientSources();
    if (clientSources.status === 'success') {
        dispatch(setClientSources(clientSources?.data));
    }
};