import {useEffect, useState} from "react";
import "./CalendarsContainer.css";

import {useBranch} from "../../../hooks";
import {setReloadComponent} from "../../../../Core/Store/Reload/Actions";
import {getEmployeeTimetables} from "../../../Api/Administration/PersonnelManagement/EmployeeTimetable";
import {useReloadComponent} from "../../../../Core/hooks";
import {getCalendarForDay, getCalendarForWeek} from "../../../Api/Calendars";
import {getCurrentTime, getWeekNumberByDate} from "../CalendarsHelpers";

import CalendarFilters from "../CalendarFilters";
import CalendarForDayGrid from "../CalendarForDay/CalendarForDayGrid";
import CalendarForWeekGrid from "../CalendarForWeek/CalendarForWeekGrid";

import {CalendarForWeekInterface, EmployeeWithVisitsInterface} from "../../../Api/Calendars/CalendarsInterface";
import {DateTypeEnum} from "../../../../Core/Components/DatePickerSwitcher/DatePickerSwitcherInterface";
import {
    EmployeeTimetableFiltersInterface,
    EmployeeTimetableInterface,
} from "../../../Api/Administration/PersonnelManagement/EmployeeTimetable/EmployeeTimetableInterface";
import {
    CalendarFiltersInterface,
    CalendarsContainerPropsInterface,
} from "./CalendarsContainerInterface";
import ComponentErrorMessage from "../../../../Core/Components/ErrorVisualization/ComponentErrorMessage";


export default function CalendarsContainer(props: CalendarsContainerPropsInterface) {
    const {
        searchParams,
        onFilterHandler,
    } = props;

    const {selectedEmployeeWorkingBranch} = useBranch();

    // після монтування DatePickerSwitcher буде виклик onChange, який змінить фільтри і спрацює useEffect
    const isReload = useReloadComponent("CalendarsContainer", false);

    const {currentDate, currentYear} = getCurrentTime();

    const initFilters: CalendarFiltersInterface = {
        branchId: searchParams?.branchId ?? selectedEmployeeWorkingBranch?.id ?? null,
        date: {
            period: searchParams?.period as DateTypeEnum ?? DateTypeEnum.Day,
            value: searchParams?.value ?? currentDate,
            year: searchParams?.year ? Number(searchParams?.year) : currentYear,
        },
        employees: searchParams?.employees ?? [],
    };

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [filters, setFilters] = useState<CalendarFiltersInterface>(initFilters);
    const [calendarForDay, setCalendarForDay] = useState<EmployeeWithVisitsInterface[]>([]);
    const [calendarForWeek, setCalendarForWeek] = useState<CalendarForWeekInterface[]>([]);

    const [weeklyTimetableEmployees, setWeeklyTimetableEmployees] = useState<EmployeeTimetableInterface[] | []>([]);

    // перший рендер
    useEffect(() => {
        setIsFirstRender(false);

        return () => setReloadComponent("CalendarsContainer", false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        // при зміні філії очищуємо співробітників
        setFilters(prevState => ({
            ...prevState,
            employees: [],
        }));

        // приховуємо графік, якщо не вибрана філія
        if (!filters.branchId) {
            setCalendarForDay([]);
            setCalendarForWeek([]);
        }
    }, [filters.branchId]);

    // при зміні filters.branchId та filters.date.value
    useEffect(() => {
        if (filters.branchId && filters.date.value) {
           let weekNumber: number = 0;

            if (filters.date.period === DateTypeEnum.Day && typeof filters.date.value === "string") {
                weekNumber = getWeekNumberByDate(filters.date.value);
            }

            if (filters.date.period === DateTypeEnum.Week && typeof filters.date.value === "number") {
                weekNumber = filters.date.value;
            }

            const timetableFilters: EmployeeTimetableFiltersInterface = {
                branchId: filters.branchId,
                week: {
                    year: filters.date.year,
                    week_number: weekNumber,
                }
            }

            getEmployeeTimetables({filters: timetableFilters}).then(response => {
                response?.data && setWeeklyTimetableEmployees(response.data);
            });
        }
    }, [filters.branchId, filters.date.value]); // eslint-disable-line react-hooks/exhaustive-deps

    // при зміні фільтрів перезавантажуємо сторінку
    useEffect(() => {
        if (filters.branchId === null) {
            setReloadComponent("CalendarsContainer", false);
            return;
        }

        if (!isFirstRender && onFilterHandler) {
            onFilterHandler(filters);
        }

        !isReload && setReloadComponent("CalendarsContainer", true);
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    // дані для календаря
    useEffect(() => {
        if (isReload && filters.branchId) {
            if (filters.date.period === DateTypeEnum.Day) {
                getCalendarForDay({
                    filters: {
                        branchId: filters.branchId,
                        date: filters.date.value.toString(),
                        employees: filters.employees,
                    }
                }).then(response => {
                    response?.data && setCalendarForDay(response.data);
                    setReloadComponent("CalendarsContainer", false);
                });
            }

            if (filters.date.period === DateTypeEnum.Week) {
                getCalendarForWeek({
                    filters: {
                        branchId: filters.branchId,
                        week: {
                            year: filters.date.year,
                            weekNumber: Number(filters.date.value),
                        },
                        employees: filters.employees,
                    }
                }).then(response => {
                    response?.data && setCalendarForWeek(response.data);
                    setReloadComponent("CalendarsContainer", false);
                });
            }
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps


    const shouldRenderDayCalendar = () => (
        !!calendarForDay?.length
        && filters.date.period === DateTypeEnum.Day
        && filters.branchId
    );

    const shouldRenderWeekCalendar = () => (
        !!calendarForWeek?.length
        && filters.date.period === DateTypeEnum.Week
        && filters.branchId
    );


    return (
        <>
            <div className="lynx-calendar-container__switchers">
                <CalendarFilters filters={filters} setFilters={setFilters}/>
            </div>

            {shouldRenderDayCalendar() &&
                <CalendarForDayGrid
                    calendarForDay={calendarForDay}
                    branchId={filters.branchId ? filters.branchId : ""}
                    date={{
                        ...filters.date,
                        value: filters.date.value.toString(),
                    }}
                    employeesTimetable={weeklyTimetableEmployees}
                />
            }

            {shouldRenderWeekCalendar() &&
                <CalendarForWeekGrid
                    calendarForWeek={calendarForWeek}
                    branchId={filters.branchId ? filters.branchId : ""}
                    date={filters.date}
                    employeesTimetable={weeklyTimetableEmployees}
                />
            }

            {!filters.branchId &&
                <ComponentErrorMessage title="Для відображення календаря потрібна бути вибрана філія"/>
            }

            {filters.branchId && !shouldRenderDayCalendar() && !shouldRenderWeekCalendar() &&
                <ComponentErrorMessage title="За вибраними фільтрами дані відсутні"/>
            }
        </>
    );
}