import {request} from "../../../Core/Utils/Request/Request";
import {
    EditUserSettingsRequestInterface,
    UserSettingsRequestInterface,
    UserSettingsResponseInterface
} from "./UserSettingsInterface";
import {ResponseInterface} from "../../../Core/Utils/Request/ResponseInterface";


export const getUserSettings = async (
    params?: UserSettingsRequestInterface
): Promise<UserSettingsResponseInterface> => {
    return await request({
        url: "v1/users/settings/filters",
        method: "post",
        data: params,
    });
};

export const editUserSettings = async (
    id: number | string, params?: EditUserSettingsRequestInterface
): Promise<ResponseInterface> => {
    return await request({
        url: `v1/users/settings/${id}`,
        method: "patch",
        data: params,
    });
};
