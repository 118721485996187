import {request} from "../../../../../Core/Utils/Request/Request";
import {
    ServicesResponseInterface,
    ServicesRequestInterface,
    ServiceResponseInterface,
    ServiceRequestInterface,
} from "./ServicesInterface";


export const getServices = async (
    params?: ServicesRequestInterface
): Promise<ServicesResponseInterface> => {
    return await request({
        url: '/v1/services/filters',
        method: 'post',
        data: params,
    });
};

export const findServiceById = async (id: number | string): Promise<ServiceResponseInterface> => {
    return await request({
        url: `/v1/services/${id}`,
        method: 'get'
    });
};

export const createService = async (params: ServiceRequestInterface): Promise<ServiceResponseInterface> => {
    return await request({
        url: '/v1/services',
        method: 'post',
        data: params
    });
};

export const editService = async (id: number | string, params: ServiceRequestInterface): Promise<ServiceResponseInterface> => {
    return await request({
        url: `/v1/services/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteService = async (id: number | string): Promise<ServiceResponseInterface> => {
    return await request({
        url: `/v1/services/${id}`,
        method: 'delete'
    });
};
