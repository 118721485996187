import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {deleteWorkplace} from "../../../../../../Api/Directories/Workplaces";
import {WorkplaceInterface} from "../../../../../../Api/Directories/Workplaces/WorkplacesInterface";


export function createWorkplaceAction(data: WorkplaceInterface, functions: {
    setIsModalCreateOpen(isOpen: boolean): void,
}) {
    functions.setIsModalCreateOpen(true);
}

export async function viewWorkplaceAction(data: WorkplaceInterface, functions: {
    setIsModalViewOpen(isOpen: boolean): void,
    setWorkplaceId(id: number | string): void,
}) {
    functions.setIsModalViewOpen(true);
    functions.setWorkplaceId(data.id);
}

export async function editWorkplaceAction(data: WorkplaceInterface, functions: {
    setIsModalEditOpen(isOpen: boolean): void,
    setWorkplaceId(id: number | string): void,
}) {
    functions.setIsModalEditOpen(true);
    functions.setWorkplaceId(data.id);
}

export async function deleteWorkplaceAction(data: WorkplaceInterface, functions: {
    onSuccessHandler(): void,
}) {
    const response = await deleteWorkplace(data.id);
    if (response?.status === "success") {
        functions.onSuccessHandler();
        addAlert({
            type: response.status,
            message: response.message,
        });
    }
}