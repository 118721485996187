import React from 'react';
import "./FormFlexible.css"
import {FormFlexiblePropsInterface} from "./FormFlexibleInterface";

export default function FormFlexible({children, isFirstElementHidden}: FormFlexiblePropsInterface) {
    return (
        <>
            {isFirstElementHidden
                ? <>
                    <div style={{display: "none"}}>{children[0]}</div>
                    {children[1]}
                </>
                : <div className="lynx-form-flexible">
                    {children[0]}
                    <div className="lynx-form-flexible__right">{children[1]}</div>
                </div>
            }
        </>
    );
};