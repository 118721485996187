import React, {useState} from 'react';
import './MainMenuTiny.scss';
import {MainMenuTinyPropsInterface} from "./MainMenuTinyInterface";
import Scrollbars from 'react-custom-scrollbars-2';
import {MainMenuTinyContext} from "./MainMenuTinyContext";
import FirstLevelTiny from "../FirstLevelTiny";

export default function MainMenuTiny(props: MainMenuTinyPropsInterface) {

    const {items} = props;

    const [scrollScrollbars, setScrollScrollbars] = useState<React.UIEvent<any, UIEvent>>()

    return (
        <MainMenuTinyContext.Provider value={{
            scrollScrollbars,
        }}>
            <Scrollbars className="lynx-tiny-menu__scrollbars" onScroll={(e) => setScrollScrollbars(e)}>
                <div className="lynx-tiny-menu-nav">
                    <ul className="lynx-tiny-menu__list">
                        {items?.map((item, index) => (
                            <FirstLevelTiny
                                key={index}
                                title={item.title}
                                icon={item.icon}
                                link={item.link}
                                items={item.items}
                            />
                        ))}
                    </ul>
                </div>
            </Scrollbars>
        </MainMenuTinyContext.Provider>
    );
}
