import {
    ServiceGroupInterface
} from "../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";


export async function viewServiceGroupAction(data: ServiceGroupInterface, functions: {
    setIsModalOpen(isOpen: boolean): void,
    setMode(mode: formMode): void,
    setServiceGroupId(id: number | string): void,
}) {
    functions.setMode(formMode.view);
    functions.setServiceGroupId(data.id);
    functions.setIsModalOpen(true);
}

export async function editServiceGroupAction(data: ServiceGroupInterface, functions: {
    setIsModalOpen(isOpen: boolean): void,
    setMode(mode: formMode): void,
    setServiceGroupId(id: number | string): void,
}) {
    functions.setMode(formMode.edit);
    functions.setServiceGroupId(data.id);
    functions.setIsModalOpen(true);
}

export async function deleteServiceGroupAction(data: ServiceGroupInterface, functions: {
    setIsModalOpen(isOpen: boolean): void,
    setMode(mode: formMode): void,
    setServiceGroupId(id: number | string): void,
}) {
    functions.setMode(formMode.delete);
    functions.setServiceGroupId(data.id);
    functions.setIsModalOpen(true);
}