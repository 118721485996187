import {useState} from "react";
import "./ServiceAgreementsPage.css";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle} from "../../../../../Core/hooks";
import {setReloadComponent} from "../../../../../Core/Store/Reload/Actions";

import ServiceAgreementsGrid
    from "../../../../Components/Documents/ServiceAgreements/ServiceAgreementsGrid";
import BreadCrumbContainer from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import ServiceAgreementModal
    from "../../../../Components/Documents/ServiceAgreements/ServiceAgreementModal";

import {formMode as formModeEnum} from "../../../../../Core/Components/Forms/Form/FormInterface";


export default function ServiceAgreementsPage() {
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    usePageTitle("Договір про надання послуг");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formMode, setFormMode] = useState<formModeEnum>();


    const onSuccessHandler = () => {
        setReloadComponent("ServiceAgreementsGrid", true);

         if (permission.isEdit("serviceAgreement")) {
             setFormMode(formModeEnum.edit);
         } else if (permission.isView("serviceAgreement")) {
             setFormMode(formModeEnum.view);
         } else {
             setIsModalOpen(false);
         }
    };


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Договір про надання послуг" tree={["Документи"]}/>
                <div className="lynx-service-agreements-page__buttons">
                    {permission.isCreate("serviceAgreement") &&
                        <>
                            <Button
                                name="addServiceAgreement"
                                title="Створити договір"
                                className="lynx-btn-primary"
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setFormMode(formModeEnum.create)
                                }}
                            />
                        </>
                    }
                </div>
            </BreadCrumbContainer>

            <br/>

            <ServiceAgreementsGrid
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />

            {formMode &&
                <ServiceAgreementModal
                    isOpen={isModalOpen}
                    mode={formMode}
                    onAfterCloseHandle={() => setIsModalOpen(false)}
                    onAfterSuccessHandle={onSuccessHandler}
                />
            }
        </>
    );
}