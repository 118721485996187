import {request} from "../../../../../Core/Utils/Request/Request";
import {ResponseInterface} from "../../../../../Core/Utils/Request/ResponseInterface";
import {
    UsersPermissionGroupResponseInterface,
    UsersPermissionGroupRequestInterface,
    AddUserToPermissionGroupRequestInterface,
    AddUserToPermissionGroupResponseInterface,
} from "./UsersPermissionGroupInterface";


export const getUsersPermissionGroup = async (
    params?: UsersPermissionGroupRequestInterface
): Promise<UsersPermissionGroupResponseInterface> => {
    return await request({
        url: "/v1/administration/permissions/group-user/filters",
        method: "post",
        data: params,
    });
};

export const addUserToPermissionGroup = async (
    params: AddUserToPermissionGroupRequestInterface
): Promise<AddUserToPermissionGroupResponseInterface> => {
    return await request({
        url: "/v1/administration/permissions/group-user",
        method: "post",
        data: params
    });
};

export const deleteUserPermissionGroup = async (id: number | string): Promise<ResponseInterface> => {
    return await request({
        url: `/v1/administration/permissions/group-user/${id}`,
        method: "delete",
    });
};
