import React from "react";
import {useRoutes} from "react-router-dom";
import AppRouterInterface from "./AppRouterInterface";

/**
 * Маршрутизация по проекту
 *
 * @constructor
 * @param props
 */
export default function AppRouter(props: AppRouterInterface) {
    return <>{useRoutes(props.routes)}</>;
}
