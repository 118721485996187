import {useEffect, useState} from "react";
import "./CertificateServicesForm.css";

import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {getServices} from "../../../../Api/Administration/ServiceManagement/Services";
import {getServiceGroups} from "../../../../Api/Administration/ServiceManagement/ServiceGroups";
import {editCertificatesServices, getCertificatesServices} from "../../../../Api/Documents/CertificatesServices";
import {changeSelectedServicesForSubmit} from "../CertificatesServicesBlock/CertificatesServicesBlockHelpers";
import {
    editServicesPerformedCertificateDiscount,
    findServicesPerformedCertificateById,
} from "../../../../Api/Documents/ServicesPerformedCertificates/ServicesPerformedCertificates";

import FormStickyContainer from "../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import CertificatesServicesBlock from "../CertificatesServicesBlock";

import {
    CertificateServicesFormPropsInterface,
} from "./CertificateServicesFormInterface";
import {
    formMode,
} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {ServiceInterface} from "../../../../Api/Administration/ServiceManagement/Services/ServicesInterface";
import {
    ServiceGroupInterface
} from "../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";
import {
    CertificatesServiceInterface,
} from "../../../../Api/Documents/CertificatesServices/CertificatesServicesInterface";
import {PrepareSelectedDataReturnInterface} from "../CertificatesServicesBlock/CertificatesServicesBlockInterface";


export default function CertificateServicesForm(props: CertificateServicesFormPropsInterface) {
    const {
        servicesPerformedCertificateId,
        mode,
        onAfterSuccessHandle,
    } = props;


    const [services, setServices] = useState<ServiceInterface[]>([]);
    const [serviceGroups, setServiceGroups] = useState<ServiceGroupInterface[]>([])
    const [certificatesServices, setCertificatesServices] = useState<CertificatesServiceInterface[] | []>([]);
    const [selectedCertificatesServices, setSelectedCertificatesServices] = useState<PrepareSelectedDataReturnInterface[]>([]);
    const [discount, setDiscount] = useState<number>(0);


    useEffect(() => {
        getServices({pagination: {perPage: 99999}}).then(response => response?.data && setServices(response.data));
        getServiceGroups({pagination: {perPage: 99999}}).then(response => response?.data && setServiceGroups(response.data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        findServicesPerformedCertificateById(servicesPerformedCertificateId)
            .then(response => {
                response?.data && setDiscount(response.data.discount);
            });
        getCertificatesServices({filters: {certificateId: servicesPerformedCertificateId}})
            .then(response => {
                response?.data && setCertificatesServices(response.data);
            });
    }, [servicesPerformedCertificateId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmitHandler = () => {
        const selectedServices = changeSelectedServicesForSubmit(selectedCertificatesServices);

        if (!selectedServices.length) {
            addAlert({
                type: "warning",
                message: "Виберіть хоча б одну послугу",
            });
            return;
        }

        editCertificatesServices({
            certificateId: servicesPerformedCertificateId,
            services: selectedServices,
        }).then(servicesResponse => {
            if (servicesResponse?.status === "success") {
                addAlert({
                    type: "success",
                    message: servicesResponse.message,
                });

            }

            editServicesPerformedCertificateDiscount(servicesPerformedCertificateId, discount)
                .then(discountResponse => {
                    if (discountResponse?.status === "success") {
                        addAlert({
                            type: "success",
                            message: discountResponse.message,
                        });

                    }

                    onAfterSuccessHandle && onAfterSuccessHandle();
                });
        });
    };


    return (
        <>
            <CertificatesServicesBlock
                services={services}
                serviceGroups={serviceGroups}
                certificatesServices={certificatesServices}
                setSelectedServices={setSelectedCertificatesServices}
                discount={discount}
                setDiscount={setDiscount}
            />

            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        <Button
                            name="save"
                            title="Зберегти"
                            className="lynx-btn-primary"
                            onClick={onSubmitHandler}
                        />
                    </FormStickyContainer>
                </>
            }
        </>
    );
};
