import FormTabsContainer from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsContainer";
import TabsLinkNavigation from "../../../../../../Core/Components/TabsLink/TabsLinkNavigation";
import FormTabsItem from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsItem";
import TabsLinkContent from "../../../../../../Core/Components/TabsLink/TabsLinkContent";
import FormFlexible from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormFlexible";
import GroupPermissionForm from "../GroupPermissionForm";
import UsersGroupPermissionGrid from "../UsersGroupPermissionGrid";

import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {GroupPermissionFormPropsInterface} from "./GroupPermissionFormContainerInterface";


export default function GroupPermissionFormContainer(props: GroupPermissionFormPropsInterface) {
    const {
        permissionGroupId,
        setPermissionGroupId,
        mode,
        setMode,
        onSuccessHandler,
    } = props;


    return (
        <FormFlexible isFirstElementHidden={mode === formMode.create}>
            <FormTabsContainer>
                <TabsLinkNavigation
                    isTabsContent
                    tabs={[
                        {
                            id: "groupPermissionForm",
                            content:
                                <FormTabsItem>
                                    <i title="Група прав" className="fa-duotone fa-house"/>
                                </FormTabsItem>
                        },
                        {
                            id: "usersGroupPermission",
                            content: mode === formMode.edit && permissionGroupId &&
                                <FormTabsItem>
                                    <i title="Користувачі" className="fa-duotone fa-users"/>
                                </FormTabsItem>
                        }
                    ]}
                    name="groupPermissionFormContainer"
                />
            </FormTabsContainer>


            <TabsLinkContent tabFor="groupPermissionFormContainer" tabs={[
                {
                    id: "groupPermissionForm",
                    component:
                        <GroupPermissionForm
                            mode={mode}
                            setMode={setMode}
                            permissionGroupId={permissionGroupId}
                            setPermissionGroupId={setPermissionGroupId}
                            onSuccessHandler={onSuccessHandler}
                        />
                },
                {
                    id: "usersGroupPermission",
                    component: permissionGroupId &&
                        <UsersGroupPermissionGrid
                            data={{permissionGroupId}}
                            mode={mode}
                        />
                },
            ]}/>
        </FormFlexible>
    );
}