import lodash from "lodash";
import {styleVars} from "../../../../../Config/Utils/Style/Vars";
import EChartsReact from "../../EChartsReact/EChartsReact";
import {
    DoughnutChartOptionInterface,
    DoughnutChartPropsInterface
} from "./DoughnutChartInterface";


export default function DoughnutChart(props: DoughnutChartPropsInterface) {
    const {
        title,
        data,
        option,
        opts,
        className,
        style,
        isPaddingOut,
    } = props;


    let defaultOption: DoughnutChartOptionInterface = {
        tooltip: {
            trigger: "item"
        },
        legend: {
            top: "5%",
            left: 0,
            orient: "vertical",
        },
        series: [
            {
                type: "pie",
                radius: ["40%", "70%"],
                itemStyle: {
                    borderRadius: 5,
                    borderColor: styleVars.colorWhite,
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: "center",
                    color: styleVars.colorDark65,
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: styleVars.fontSizeH5,
                        fontWeight: 700,
                    }
                },
                labelLine: {
                    show: false
                },
                data,
            }
        ],
    };


    return <>
        {!!data?.length &&
            <EChartsReact
                option={lodash.merge(defaultOption, option)} // оновлюємо дефолтні опції глибоким злиттям об"єктів, заливши в них опції з пропа
                opts={opts}
                className={className}
                title={title}
                style={style}
                isPaddingOut={isPaddingOut}
                quantityColor={data.length}
            />
        }
    </>;
}