import {getClientPaymentForms} from "../../../../../Api/Directories/Payments/ClientPayments/ClientPaymentForms";
import {ClientPaymentFormsType} from "./Types";
import {
    ClientPaymentFormInterface
} from "../../../../../Api/Directories/Payments/ClientPayments/ClientPaymentForms/ClientPaymentFormsInterface";


export const setClientPaymentForms = (clientPaymentForms: ClientPaymentFormInterface[]) => {
    return {
        type: ClientPaymentFormsType.GET_CLIENT_PAYMENT_FORMS,
        payload: clientPaymentForms,
    };
};

export const getClientPaymentFormsAction = () => async (dispatch: Function) => {
    const clientPaymentForms = await getClientPaymentForms();
    if (clientPaymentForms?.data) {
        dispatch(setClientPaymentForms(clientPaymentForms?.data));
    }
};