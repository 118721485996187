import {request} from "../../../Utils/Request/Request";
import {FilterResponseInterface, SaveFilterRequestInterface, SaveFilterResponseInterface} from "./FilterInterface";

export const getFilters = async (): Promise<FilterResponseInterface> => {
    return await request({
        url: '/v1/filters',
        method: 'get',
    });
}

export const saveFilters = async (params:SaveFilterRequestInterface): Promise<SaveFilterResponseInterface> => {
    return await request({
        url: '/v1/filters',
        method: 'post',
        data: params
    });
}