import './CardVisualisation.css';
import ButtonActions from "../../ButtonActions";
import {CardVisualisationPropsInterface} from "./CardVisualisationInterface";


export default function CardVisualisation(props: CardVisualisationPropsInterface) {
    const {
        title,
        description,
        items,
        itemsCount,
        children,
        className,

        // actions
        actions,
        titleActions,
        iconActions,
        classNameActions = "lynx-button-action__button-sm",
    } = props;


    const cardClassName = ["lynx-card-visualisation"];
    className && cardClassName.push(className);


    return (
        <div className={cardClassName.join(" ")}>
            <div className="lynx-card-visualisation__top-block">
                {(title || !!actions?.length) &&
                    <div className="lynx-card-visualisation__header">
                        {title && <h3 className="lynx-card-visualisation__title">{title}</h3>}
                        {!!actions?.length &&
                            <div className="lynx-card-visualisation__button-action">
                                <ButtonActions
                                    data={props}
                                    actions={actions}
                                    title={titleActions}
                                    icon={iconActions}
                                    className={classNameActions}
                                />
                            </div>
                        }
                    </div>
                }

                {description && <h3 className="lynx-card-visualisation__description">{description}</h3>}

                {items &&
                    <div className="lynx-card-visualisation__items">
                        {items.map((item, index) => {
                            if (itemsCount && (index + 1 > itemsCount)) {
                                return null;
                            }

                            return (
                                <div key={item} className="lynx-card-visualisation__item">
                                    <span className="lynx-card-visualisation__marker">-</span>
                                    <span>{item}</span>
                                </div>
                            );
                        })}
                    </div>
                }
            </div>

            {children && <div className="lynx-card-visualisation__bottom-block">{children}</div>}
        </div>
    );
}