import {useContext, useEffect, useRef, useState} from "react";
import './ButtonActions.css';
import {useOutsideClick} from "rooks";
import {useBoundaryCheck, useScrollListener} from "../../hooks";
import {ModalSimpleContext} from "../Modal/ModalSimple/ModalSimpleContext";
import {ButtonActionsPropsInterface} from "./ButtonActionsInterface";
import {PositionEnum, PositionDataInterface} from "../../hooks/hooksInterface";


export default function ButtonActions(props: ButtonActionsPropsInterface) {
    const {title, actions, icon, data, className} = props;
    let [isOpen, setOpen] = useState(false);
    const refButtonAction = useRef(null);

    // -- start -- все для визначення координат dropdown
    const refDropDown = useRef<HTMLDivElement | null>(null);
    const [dropDownRefState, setDropDownRefState] = useState<any>(null);
    const [positionData, setPositionData] = useState<PositionDataInterface | null>(null);
    const {modalBodyRefContext} = useContext(ModalSimpleContext);
    useScrollListener(modalBodyRefContext, () => setOpen(false));
    useBoundaryCheck(refButtonAction, dropDownRefState, setPositionData);

    useEffect(() => {
        if (isOpen && refDropDown?.current) {
            setDropDownRefState(refDropDown);
        }

        return () => {
            setDropDownRefState(null);
            setPositionData(null);
        }
    }, [isOpen]);
    // -- end -- все для визначення координат dropdown


    const clickHandler = () => {
        setOpen(!isOpen);
    }

    const closeHandler = () => {
        setOpen(false);
    }

    useOutsideClick(refButtonAction, closeHandler);

    let btnClassName = ['lynx-button-action-block'];

    className && btnClassName.push(className)

    // стилі для відкритого dropDown
    let dropDownPositionLeft = 0;
    if (positionData) {
        const sign = positionData.openingDirections.directionX === PositionEnum.Right ? -1 : +1;
        dropDownPositionLeft = positionData.coordinates.left + sign * positionData.relativeElementData.width;
    }

    const dropDownStyle = {
        opacity: positionData ? 1 : 0,
        top: positionData?.coordinates.top,
        left: dropDownPositionLeft,
    };


    return <div className="lynx-button-action" ref={refButtonAction}>
        <div className={btnClassName.join(' ')} onClick={clickHandler}>
            {icon && <span className="lynx-button-action__icon"><i className={icon}></i></span>}
            {title && <span className="lynx-button-action__button">{title}</span>}
            {(!title && !icon) && <span className="lynx-button-action__arrow-down">
                <i className="fa-duotone fa-angle-down"/>
            </span>}
        </div>
        {actions.length > 0
            && isOpen
            && <div className="lynx-button-action-dropdown" ref={refDropDown} style={dropDownStyle}>
                {actions.map((item) => {
                    let classNameItem = ['lynx-button-action-dropdown__item']
                    item.classNameItem && classNameItem.push(item.classNameItem);

                    if (typeof item?.isShow === "boolean" && !item.isShow) {
                        return null;
                    }

                    if (typeof item?.isShow === "function" && !item.isShow(data)) {
                        return null;
                    }

                    return <div key={item.name} className={classNameItem.join(' ')} onClick={() => {
                        closeHandler();
                        item.action(data, item.functions)
                    }}>{item.title}</div>

                })}
            </div>}
    </div>;
}