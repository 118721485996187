import {AlertActions} from "./Types";
import {IAlert} from "../../Components/Alert/Alert/IAlert";
import {generateToken} from "../../Config/Utils/Generators";
import {dispatch} from "../../../Config/Redux/Store";

export const setAlert = (alert: IAlert) => {
    return {
        type: AlertActions.ADD_ALERT,
        payload: alert
    }
}

/**
 * Добавление сообщения в store
 * @param alert
 */
export const addAlert = (alert: IAlert) =>  {
    alert.token = generateToken();
    dispatch(setAlert(alert));

    /**
     * После успешного добавления в store сообщения запускается его удаление
     */
    setTimeout(() => {
        alert.token && removeAlertAction(alert.token);
    }, 9000)
};
/**
 * Удаление alert
 *
 * @param token
 */
export const removeAlert = (token: string) => {
    return {
        type: AlertActions.REMOVE_ALERT,
        payload: {
            token
        }
    };
}

/**
 * Удаление определенного сообщения в store
 * @param token
 */
export const removeAlertAction = (token: string) => {
    dispatch(removeAlert(token))
};

export const clearAlert = () => {
    return {
        type: AlertActions.CLEAR_ALERT
    }
}

/**
 * Удаление всех сообщений которые есть в store
 */
export const clearAlertAction = () => {
    dispatch(clearAlert())
};