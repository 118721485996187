import {useState} from "react";
import "./ServicesPerformedCertificatesPage.css";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle} from "../../../../../Core/hooks";
import {setReloadComponent} from "../../../../../Core/Store/Reload/Actions";

import ServicesPerformedCertificatesGrid
    from "../../../../Components/Documents/ServicesPerformedCertificates/ServicesPerformedCertificatesGrid";
import BreadCrumbContainer from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import ServicesPerformedCertificateModal
    from "../../../../Components/Documents/ServicesPerformedCertificates/ServicesPerformedCertificateModal";

import {formMode as formModeEnum} from "../../../../../Core/Components/Forms/Form/FormInterface";


export default function ServicesPerformedCertificatesPage() {
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    usePageTitle("Акти наданих послуг");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formMode, setFormMode] = useState<formModeEnum>();


    const onSuccessHandler = () => {
        setReloadComponent("ServicesPerformedCertificatesGrid", true);

         if (permission.isEdit("servicesPerformedCertificate")) {
             setFormMode(formModeEnum.edit);
         } else if (permission.isView("servicesPerformedCertificate")) {
             setFormMode(formModeEnum.view);
         } else {
             setIsModalOpen(false);
         }
    };


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Акти наданих послуг" tree={["Документи"]}/>
                <div className="lynx-services-performed-certificates-page__buttons">
                    {permission.isCreate("servicesPerformedCertificate") &&
                        <>
                            <Button
                                name="addServicesPerformedCertificate"
                                title="Створити акт"
                                className="lynx-btn-primary"
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setFormMode(formModeEnum.create)
                                }}
                            />
                        </>
                    }
                </div>
            </BreadCrumbContainer>

            <br/>

            <ServicesPerformedCertificatesGrid
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />

            {formMode &&
                <ServicesPerformedCertificateModal
                    isOpen={isModalOpen}
                    mode={formMode}
                    onAfterCloseHandle={() => setIsModalOpen(false)}
                    onAfterSuccessHandle={onSuccessHandler}
                />
            }
        </>
    );
}