import env from "../../../Config/Global/Env";
import requests from "../../../Config/Request/RequestConfig";
import authConfig from "../../../Config/Modules/AuthConfig";
import supportConfig from "../../../Config/ControlPanel/Suport/SupportConfig";
import {filtersConfig} from "../../../Config/Core/FiltersConfig";


export default function configLoader() {
    return {
        env,
        authConfig,
        requests,
        supportConfig,
        filtersConfig,
    }
}
