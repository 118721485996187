import React, {useEffect, useState} from "react";
import moment from "moment";
import "./ServicesPage.css";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle} from "../../../../../Core/hooks";
import {
    getServices
} from "../../../../Api/Administration/ServiceManagement/Services";
import {
    deleteServiceAction,
    editServiceAction,
    viewServiceAction,
} from "./ServicesPageHelpers";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import ServiceForm from "../../../../Components/Administration/ServiceManagement/Services/ServiceForm";
import ServiceFilters from "../../../../Components/Administration/ServiceManagement/Services/ServiceFilters";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {
    ServiceInterface,
    ServicesResponseInterface
} from "../../../../Api/Administration/ServiceManagement/Services/ServicesInterface";


export default function ServicesPage() {
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    usePageTitle("Послуги");
    const [
        services,
        setServices
    ] = useState<ServicesResponseInterface | null>(null);
    const [
        serviceData,
        setServiceData
    ] = useState<ServiceInterface>();
    const [isReload, setReload] = useState(true);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [pagination, setPagination] = useState({});


    const gridTitles: GridTitleInterface[] = [
        {
            title: "ID",
            code: "id",
            width: "50px",
        },
        {
            title: "Назва послуги",
            code: "name",
            width: "270px",
        },
        {
            title: "Код послуги",
            code: "code",
            width: "150px",
        },
        {
            title: "Опис послуги",
            code: "description",
            width: "270px",
        },
        {
            title: "Назва групи",
            code: "groupName",
            width: "270px",
        },
        {
            title: "Основна вартість послуги, грн",
            code: "price",
            width: "220px",
        },
        {
            title: "Мінімальна ціна, грн",
            code: "minimalPrice",
            width: "160px",
        },
        {
            title: "Максимальна ціна, грн",
            code: "maximalPrice",
            width: "170px",
        },
        {
            title: "Створено",
            code: "createdAt",
            width: "130px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
        {
            title: "Ким створено",
            code: "userName",
            width: "200px",
        },
    ];

    useEffect(() => {
        if (isReload) {
            getServices({filters: searchParams, pagination}).then(response => {
                if (response?.status === "success") {
                    setServices(response);
                }

                setReload(false);
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFilterHandler = (data: any) => {
        setSearchParams(data);
        setReload(true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setReload(true);
        setPagination(pagination);
    };

    const onSuccessHandler = () => {
        setReload(true);
        setIsModalCreateOpen(false);
        setIsModalEditOpen(false);
    };

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "view",
            functions: {setIsModalViewOpen, setServiceData},
            isShow: permission.isView("service"),
            action: viewServiceAction,
        },
        {
            title: "Редагувати",
            name: "edit",
            classNameItem: "lynx-button-action-warning",
            functions: {setIsModalEditOpen, setServiceData},
            isShow: permission.isEdit("service"),
            action: editServiceAction,
        },
        {
            title: "Видалити",
            name: "delete",
            classNameItem: "lynx-button-action-danger",
            functions: {onSuccessHandler},
            isShow: permission.isDelete("service"),
            action: deleteServiceAction,
        },
    ];


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Послуги" tree={["Адміністрування", "Управління послугами"]}/>
                <div className="lynx-services-page__buttons">
                    {permission.isCreate("service") &&
                        <>
                            <Button
                                name="addService"
                                title="Додати послугу"
                                className="lynx-btn-primary"
                                onClick={() => setIsModalCreateOpen(true)}
                            />
                        </>
                    }
                </div>
            </BreadCrumbContainer>

            <br/>

            {permission.isView("service") &&
                <>
                    <div className="row">
                        <div className="col-12 order-2 col-xl-9 order-xl-1">
                            <Grid
                                data={services?.data || []}
                                title={gridTitles}
                                pagination={services?.pagination}
                                onLoadDataHandler={onLoadDataHandler}
                                actions={actions}
                            />
                        </div>
                        <div className="col-12 order-1 col-xl-3 order-xl-2">
                            <ServiceFilters onFilterHandler={onFilterHandler} searchParams={searchParams}/>
                        </div>
                    </div>
                </>
            }

            <ModalSimple
                isOpen={isModalViewOpen}
                body={
                    <ServiceForm
                        serviceData={serviceData}
                        mode={formMode.view}
                    />}
                title="Перегляд послуги"
                onAfterCloseHandle={() => setIsModalViewOpen(false)}
            />

            <ModalSimple
                isOpen={isModalCreateOpen}
                body={
                    <ServiceForm
                        onAfterSuccessHandle={onSuccessHandler}
                        mode={formMode.create}
                    />}
                title="Додавання нової послуги"
                onAfterCloseHandle={() => setIsModalCreateOpen(false)}
            />

            <ModalSimple
                isOpen={isModalEditOpen}
                body={
                    <ServiceForm
                        onAfterSuccessHandle={onSuccessHandler}
                        serviceData={serviceData}
                        mode={formMode.edit}
                    />}
                title="Редагування послуги"
                onAfterCloseHandle={() => setIsModalEditOpen(false)}
            />
        </>
    );
};