import {AnyAction} from "redux";
import {TimetableTypes, TimetableTypesStateType} from "./Types";


const initialState: TimetableTypesStateType = [];

const timetableTypes = (state: TimetableTypesStateType = initialState, action: AnyAction): TimetableTypesStateType => {
    const {type, payload} = action;
    switch (type) {
        case TimetableTypes.GET_TIMETABLE_TYPES:
            return [...payload];
        default:
            return state;
    }
};

export default timetableTypes;