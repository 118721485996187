import React, {useEffect, useRef, useState} from 'react';
import {useOutsideClick} from "rooks";
import './HeaderProfileUser.css';
import {useDispatch, useSelector} from "react-redux";
import {getProfileAction} from "../../../../../../../Modules/AdministrationUsers/Store/Profile/Actions";
import ProfileNavigationContainer from "../ProfileNavigation/ProfileNavigationContainer";
import Avatar from "../../../../../Avatar";
import {ProfileInterface} from "../../../../../../../Modules/Auth/Api/Users/Profile/ProfileInterface";
import {StoreInterface} from "../../../../../../../Config/Redux/StoreInterface";
import {screenType as screenTypeEnum} from "../../../../../../Store/Conf/Types";


export default function HeaderProfileUser() {

    const refProfileNavigation = useRef(null);

    const screenType = useSelector((store: StoreInterface) => store.system.conf.screenType);
    const profile = useSelector((store: { users: { profile: ProfileInterface } }) => store.users.profile);
    const [isNavigationOpen, setIsNavigationOpen] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!Object.keys(profile).length) {
            dispatch(getProfileAction());
        }
    }, [dispatch, profile]);


    useOutsideClick(refProfileNavigation, (e: any) => {
        const isProfileWrapperEl = e.target.offsetParent?.classList.contains("lynx-header-right-user__profile-wrapper");
        !isProfileWrapperEl && setIsNavigationOpen(false);
    });

    const onProfileNavigationClick = (e: any) => {
        const isNavigationItemEl = e.target.parentElement.classList.contains("lynx-profile-navigation-item");
        isNavigationItemEl && setIsNavigationOpen(false);
    };


    const profileClass = ["lynx-header-right-user__profile"];
    isNavigationOpen && profileClass.push("active");

    const isMobileScreen = screenType === screenTypeEnum.mobile;
    const profileNavigationMobileClass = ["lynx-header-right-user__profile-navigation-mobile"];
    isNavigationOpen && profileNavigationMobileClass.push("active");

    return (
        <div className="lynx-header-item lynx-header-right-user__profile-wrapper">
            <div
                className={profileClass.join(" ")}
                title="Профіль користувача"
                onClick={() => setIsNavigationOpen(!isNavigationOpen)}
            >
                <div className="lynx-header-right-user__profile-text">
                    {Object.keys(profile).length ? `${profile.lastName} ${profile.firstName}` : ''}
                </div>
                {profile?.avatar
                    ? <img
                        className="lynx-header-right-user__profile-images"
                        src={profile.avatar}
                        alt="Аватар користувача"/>
                    : <Avatar caller="HeaderProfileUser" seed={profile.id} size={40}/>
                }
            </div>

            {!isMobileScreen && isNavigationOpen &&
                <div
                    className="lynx-header-right-user__profile-navigation"
                    ref={refProfileNavigation}
                    onClick={onProfileNavigationClick}
                >
                    <ProfileNavigationContainer profile={profile}/>
                </div>
            }

            {isMobileScreen &&
                <div
                    className={profileNavigationMobileClass.join(" ")}
                    onClick={onProfileNavigationClick}
                >
                    <ProfileNavigationContainer profile={profile}/>
                </div>
            }
        </div>
    );
}