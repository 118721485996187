import {getBranches} from "../../Api/Branches/Branch";
import {BranchInterface} from "../../Api/Branches/Branch/BranchInterface";
import {BranchesTypes} from "./Types";

export const setBranches = (branches: BranchInterface[] | undefined) => {
    return {
        type: BranchesTypes.GET_BRANCHES,
        payload: branches,
    };
};

export const getBranchesAction = () => async (dispatch: Function) => {
    const branches = await getBranches();
    if (branches.status === 'success') {
        dispatch(setBranches(branches?.data));
    }
};