import {FeedTags} from "../Feed/FeedInterface";

export const sortFeedTags = (feedTags: FeedTags[], availableTags: number[]) => {
    const sortedArray: FeedTags[] = [];

    availableTags.forEach((id) => {
        const found = feedTags.find(item => item.id === id);
        found && sortedArray.push(found);
    });

    return sortedArray;
};
