import lodash from "lodash";
import {styleVars} from "../../../../../Config/Utils/Style/Vars";
import {getColorArray} from "../../../../../Utils/Colors/Colors";
import EChartsReact from "../../EChartsReact/EChartsReact";
import {StackedLineChartOptionInterface, StackedLineChartPropsInterface} from "./StackedLineChartInterface";


export default function StackedLineChart(props: StackedLineChartPropsInterface) {
    const {
        title,
        data,
        option,
        opts,
        className,
        style,
        isPaddingOut,
    } = props;


    const textStyle = {
        fontFamily: "inherit",
        fontSize: styleVars.fontSizeText,
        fontWeight: 500,
        color: styleVars.colorDark65,
    };

    const defaultOption: StackedLineChartOptionInterface = {
        color: getColorArray(25),
        tooltip: {
            trigger: "axis", // коли наводиш на лінію - відображається інформація
        },
        legend: {
            data: data.legend,
            textStyle,
        },
        xAxis: {
            type: "category",
            data: data.xAxis,
            axisLabel: {
                textStyle,
                rotate: 7,
            },
        },
        yAxis: {
            type: "value",
            axisLabel: {
                textStyle: {
                    ...textStyle,
                    color: styleVars.colorDark50,
                }
            },
        },
        series: data.series,
    };


    return <>
        {!!data.series.length &&
            <EChartsReact
                option={lodash.merge(defaultOption, option)} // оновлюємо дефолтні опції глибоким злиттям об"єктів, заливши в них опції з пропа
                opts={opts}
                className={className}
                title={title}
                style={style}
                isPaddingOut={isPaddingOut}
                quantityColor={data.xAxis.length}
            />
        }
    </>;
}