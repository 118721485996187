import React from 'react';
import './Dashboard.css';
import {DashboardPropsInterface} from "./DashboardInterface";
import {useSelector} from "react-redux";
import Container from "../Containers/Container";
import Menu from "../Menu/Menu";
import TinyMenu from "../TinyMenu/TinyMenu";
import MobileMenu from "../MobileMenu/MobileMenu";
import {StoreInterface} from "../../../../Config/Redux/StoreInterface";
import {screenType} from "../../../Store/Conf/Types";

export default function Dashboard(props: DashboardPropsInterface) {

    let screen = useSelector((store: StoreInterface) => store.system.conf.screenType);
    let isTinyMenu = useSelector((store: StoreInterface) => store.controlPanelMenu.tinyMenu);
    const {brand, menu, headerChildren} = props;

    return (
        <div className="lynx-page">
            <Container headerChildren={headerChildren}/>
            {!isTinyMenu && screen !== screenType.mobile && <Menu menu={menu} brand={brand}/>}
            {isTinyMenu && screen !== screenType.mobile && <TinyMenu menu={menu} brand={brand}/>}
            {screen === screenType.mobile && <MobileMenu menu={menu} brand={brand}/>}
        </div>
    );
}
