import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {deleteVisit, findVisitById} from "../../../../../Api/CRM/RecordManagement/Visits/Visits";

import {
    ShortCardItemDataActionsInterface
} from "../../../../../../Core/Components/DataVisualization/ShortCardItem/ShortCardItemInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";


export async function viewVisitAction(data: ShortCardItemDataActionsInterface, functions: any) {
    functions.setModalMode(formMode.view);
    functions.setIsModalOpen(true);

    const response = await findVisitById(data.id);
    response?.data && functions.setVisitData(response.data);
}

export async function editVisitAction(data: ShortCardItemDataActionsInterface, functions: any) {
    functions.setModalMode(formMode.edit);
    functions.setIsModalOpen(true);

    const response = await findVisitById(data.id);
    response?.data && functions.setVisitData(response.data);
}

export async function deleteVisitAction(data: ShortCardItemDataActionsInterface, functions: any) {
    const response = await deleteVisit(data.id);

    if (response?.status === "success") {
        addAlert({
            type: response.status,
            message: response.message,
        });
        functions.onSuccessHandler();
    }
}