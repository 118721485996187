import React, {useState} from "react";
import moment from "moment";
import "./FeedTypeVisit.css";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {setReloadPage} from "../../../../../Core/Store/Reload/Actions";

import ClientModalView from "../../../CRM/Clients/ClientModalView";
import VisitModal from "../../../CRM/RecordManagement/Visits/VisitModal";

import {FeedTextPropsInterface} from "./FeedTypeVisitInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";


export default function FeedTypeVisit({visit}: FeedTextPropsInterface) {
    const [permission] = useLynxPermission();
    const [isModalClientViewOpen, setIsModalClientViewOpen] = useState(false);
    const [isModalVisitOpen, setIsModalVisitOpen] = useState(false);
    const [mode, setMode] = useState<formMode>();


    const onClientClickHandler = () => {
        if (!permission.isView("client")) {
            addAlert({
                type: "warning",
                message: "Відсутні права для перегляду клієнта"
            })
            return;
        }

        setIsModalClientViewOpen(true);
    };

    const onVisitClickHandler = () => {
        if (permission.isEdit("visits")) {
            setMode(formMode.edit);
            setIsModalVisitOpen(true);
            return;
        }

        if (permission.isView("visits")) {
            setMode(formMode.view);
            setIsModalVisitOpen(true);
            return;
        }

        addAlert({
            type: "warning",
            message: "Відсутні права для перегляду візиту клієнта"
        });
    };

    const onSuccessHandler = () => {
        setReloadPage("FeedsPage", true);
        setIsModalVisitOpen(false);
    };


    return (
        <>
            <div className="lynx-feed-type-visit">
                <div className="lynx-feed-type-visit-data__list">
                    <div className="lynx-feed-type-visit-data__item lynx-feed-type-visit-data__item--width-parent">
                        <div className="lynx-feed-type-visit-data__title">Клієнт</div>
                        <div className="lynx-feed-type-visit-data__link" onClick={onClientClickHandler}>
                            <i className="fa-duotone fa-link"></i> {visit.clientName}
                        </div>
                    </div>
                    <div className="lynx-feed-type-visit-data__item">
                        <div className="lynx-feed-type-visit-data__title">Дата візиту</div>
                        <div
                            className="lynx-feed-type-visit-data__desc">{moment(visit.visitDate).format("DD.MM.YYYY")}</div>
                    </div>
                    <div className="lynx-feed-type-visit-data__item">
                        <div className="lynx-feed-type-visit-data__title">Час початку візиту</div>
                        <div className="lynx-feed-type-visit-data__desc">{visit.timeStart}</div>
                    </div>
                    <div className="lynx-feed-type-visit-data__item">
                        <div className="lynx-feed-type-visit-data__title">Тривалість</div>
                        <div className="lynx-feed-type-visit-data__desc">{visit.duration}</div>
                    </div>
                    <div className="lynx-feed-type-visit-data__item lynx-feed-type-visit-data__item--status">
                        <div className="lynx-feed-type-visit-data__title">Статус візиту</div>
                        <div
                            className="lynx-feed-type-visit-data__desc lynx-feed-type-visit-data__desc--status">
                            {visit.statusName}
                        </div>
                    </div>
                    <div className="lynx-feed-type-visit-data__item">
                        <div className="lynx-feed-type-visit-data__title">Робоче місце</div>
                        <div className="lynx-feed-type-visit-data__desc">{visit.workplaceName}</div>
                    </div>
                    <div className="lynx-feed-type-visit-data__item">
                        <div className="lynx-feed-type-visit-data__title">Співробітник</div>
                        <div className="lynx-feed-type-visit-data__desc">
                            {visit.employeeName}
                        </div>
                    </div>
                </div>
                <div className="lynx-feed-type-visit-data__item lynx-feed-type-visit-data__item--width-parent">
                    <div className="lynx-feed-type-visit-data__link" onClick={onVisitClickHandler}>
                        <i className="fa-duotone fa-link"></i> Переглянути візит клієнта
                    </div>
                </div>

                {visit.note && <div className="lynx-feed-type-visit__note">{visit.note}</div>}
            </div>

            {isModalClientViewOpen &&
                <ClientModalView
                    isOpen={isModalClientViewOpen}
                    clientId={visit.clientId}
                    onAfterCloseHandle={() => setIsModalClientViewOpen(false)}
                />
            }

            <VisitModal
                isOpen={isModalVisitOpen}
                mode={mode}
                visitData={visit}
                onAfterCloseHandle={() => setIsModalVisitOpen(false)}
                onAfterSuccessHandle={onSuccessHandler}
            />
        </>
    );
}