export const TabsLinkActions = {
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    CLEAR_ACTIVE_TAB: 'CLEAR_ACTIVE_TAB'
};

export interface TabsLinkInterface {
    id: number | string,
    name: string,
    icon?: string
}
