import {AnyAction} from "redux";
import {FullScreenModalTypes, FullScreenModalStateInterface} from "./Types"


const fullScreenModal = (state: FullScreenModalStateInterface = {}, action: AnyAction) => {
    const {type, payload} = action;

    switch (type) {
        case FullScreenModalTypes.TOGGLE_FULL_SCREEN :
            return {
                ...state,
                [payload.key]: !state[payload.key],
            };
    }

    return state;
}
export default fullScreenModal;