import React, {useEffect} from "react";
import {FormFieldsetPropsInterface} from "./FormFieldsetInterface";


export default function FormFieldset(props: FormFieldsetPropsInterface) {
    const {
        name,
        visible = true,
        initFieldset,
        children,
        className,
    } = props;


    useEffect(() => {
        if (initFieldset) {
            initFieldset({
                name,
                visible,
            });
        }
    }, [initFieldset]); // eslint-disable-line react-hooks/exhaustive-deps


    const formFieldsetClass = ["lynx-form-fieldset"];
    className && formFieldsetClass.push(className);


    if (!visible) {
        return null;
    }

    return <div className={formFieldsetClass.join(" ")}>{children}</div>;
}

