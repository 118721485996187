import {TabsLinkActions} from "./Types";

export const changeActiveTab = (name: string, tab: any) => {
    return {
        type: TabsLinkActions.SET_ACTIVE_TAB,
        payload: {
            name,
            tab
        }
    }
}

export const clearActiveTab = () => {
    return {
        type: TabsLinkActions.CLEAR_ACTIVE_TAB
    }
}
