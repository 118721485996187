import React from 'react';
import './Brand.css';
import {BrandPropsInterface} from "./BrandInterface";

export default function Brand(props: BrandPropsInterface) {
    const {link, logo, title} = props;

    return (
        <div className="lynx-menu-brand">
            <a href={link} target="_blank" rel="noreferrer noopener">
                <img className="lynx-menu-brand__images" src={logo} alt={title}/>
                <span>{title}</span>
            </a>
        </div>
    );
}
