import {request} from "../../../../Core/Utils/Request/Request";
import {
    FeedRequestInterface,
    FeedResponseInterface,
    CreateFeedResponseInterface,
    CreateFeedRequestInterface,
} from "./FeedInterface";

export const getFeeds = async (params: FeedRequestInterface): Promise<FeedResponseInterface> => {
    return await request({
        url: '/v1/feeds/filters',
        method: 'post',
        data: params
    });
};

export const createFeed = async (params: CreateFeedRequestInterface): Promise<CreateFeedResponseInterface> => {
    return await request({
        url: '/v1/feeds',
        method: 'post',
        data: params,
    });
};
