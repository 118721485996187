import React, {useRef} from 'react';
import './ModalSimple.css'
import {ModalSimplePropsInterface} from "./ModalSimpleInterface";
import {useMediaMatch, useOutsideClick} from "rooks";
import {useBodyScroll, useFullScreen} from "../../../hooks";
import {toggleFullScreen} from "../../../Store/Modal/FullScreen/Actions";
import {ModalSimpleContext} from "./ModalSimpleContext";


export default function ModalSimple(props: ModalSimplePropsInterface) {
    const {
        isOpen,
        body,
        title,
        widthClass,
        bgColorClass,
        fullScreenModal,
        onBeforeCloseHandle,
        onAfterCloseHandle,
    } = props;

    useBodyScroll(!isOpen);
    const isMobile = useMediaMatch("(max-width: 767px)");
    const isFullScreenStore = useFullScreen(fullScreenModal?.key);

    const contentRef = useRef(null);
    const bodyRef = useRef(null);


    const hideModal = () => {
        if (onBeforeCloseHandle) {
            onBeforeCloseHandle();
        }

        if (onAfterCloseHandle) {
            onAfterCloseHandle();
        }
    };

    useOutsideClick(contentRef, (e: any) => {
        if (e.target.classList.contains("lynx-modal__content-wrapper")) {
            hideModal();
        }
    });

    const isFullScreen = !!(!isMobile && fullScreenModal?.key && isFullScreenStore);

    const buildModalClass = () => {
        const modalClasses = ["lynx-modal"];
        isFullScreen && modalClasses.push("lynx-modal--full-screen");

        return modalClasses.join(' ');
    };

    const buildModalContentWrapperClass = () => {
        const contentWrapperClasses = ["lynx-modal__content-wrapper"];

        isFullScreen && contentWrapperClasses.push("lynx-modal__content-wrapper--full-screen");

        return contentWrapperClasses.join(' ');
    };

    const buildModalContentClass = () => {
        const contentClasses = ["lynx-modal__content"];

        widthClass && contentClasses.push(`lynx-modal__content--${widthClass}`);
        isFullScreen && contentClasses.push("lynx-modal__content--full-screen");

        return contentClasses.join(' ');
    };

    const buildModalBodyClass = () => {
        const modalClasses = ["lynx-modal__body"];

        bgColorClass && modalClasses.push(bgColorClass);
        isFullScreen && modalClasses.push("lynx-modal__body--full-screen");

        return modalClasses.join(' ');
    };


    return (
        <ModalSimpleContext.Provider value={{
            modalBodyRefContext: bodyRef,
        }}>
            {isOpen &&
                <div className={buildModalClass()}>
                    <div className="lynx-modal__overlay"/>
                    <div className={buildModalContentWrapperClass()}>
                        <div className={buildModalContentClass()} ref={contentRef}>
                            <div className="lynx-modal__buttons">
                                {!isMobile && fullScreenModal?.key &&
                                    <div
                                        className="lynx-modal__full-screen-btn"
                                        data-testid="button-modal-full-screen"
                                        onClick={() => toggleFullScreen(fullScreenModal?.key)}
                                    >
                                        {isFullScreenStore
                                            ? <i className="fa-solid fa-down-left-and-up-right-to-center"></i>
                                            :
                                            <i className="fa-solid fa-arrow-up-right-and-arrow-down-left-from-center"></i>
                                        }
                                    </div>
                                }
                                <div className="lynx-modal__close" data-testid="button-modal-close" onClick={hideModal}>
                                    <i className="icofont-close"/>
                                </div>
                            </div>
                            {title &&
                                <div className="lynx-modal__header">
                                    <div className="title">{title}</div>
                                </div>
                            }
                            <div className={buildModalBodyClass()} ref={bodyRef}>
                                {body}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </ModalSimpleContext.Provider>
    );
};