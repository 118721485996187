import {CSSProperties, useContext, useEffect, useRef, useState} from "react";
import {useOutsideClick} from "rooks";
import moment from "moment";
import "./CalendarShortCard.css";

import {CalendarGridContext} from "../../CalendarContainer/CalendarContainerContext";
import {getVisitStatuses} from "../../../../../Api/CRM/RecordManagement/Visits/VisitStatuses";
import {useBoundaryCheck} from "../../../../../../Core/hooks";

import CalendarUnfoldedCard from "../CalendarUnfoldedCard";

import {CalendarShortCardPropsInterface} from "./CalendarShortCardInterface";
import {
    VisitStatusInterface
} from "../../../../../Api/CRM/RecordManagement/Visits/VisitStatuses/VisitStatusesInterface";
import {PositionDataInterface, PositionEnum} from "../../../../../../Core/hooks/hooksInterface";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../Core/Store/Application/Types";

// видалити, коли буде додано color до visitStatuses(бек буде віддавати visitStatuses з color)
export const testSources =
    [
        {id: 1, name: "Чернетка", color: "#8736ff",},
        {id: 2, name: "Новий", color: "#2ecfff",},
        {id: 3, name: "Підтверджений", color: "#f8b148",},
        {id: 4, name: "Помилковий", color: "#50ff54",},
        {id: 5, name: "Успішно закритий", color: "#9accff",},
        {id: 6, name: "Скасований клієнтом", color: "#ff844b",},
        {id: 7, name: "Скасований клінікою", color: "#4c93ff",},
        {id: 8, name: "Клієнт перебуває у лікаря", color: "#ff509b",},
        {id: 9, name: "Клієнт запізнюється", color: "#ffce39",},
        {id: 10, name: "Клієнт не з`явився", color: "#145eff",},
    ];


export default function CalendarShortCard({visit}: CalendarShortCardPropsInterface) {
    const {
        duration,
        employeeName,
        statusName,
        statusId,
        timeStart,
        client,
    } = visit;

    const isShowSpinner = useSelector((store: { application: ApplicationState }) => store.application.loader);

    const [visitStatuses, setVisitStatuses] = useState<VisitStatusInterface[]>();
    const [isUnfoldedCardOpen, setIsUnfoldedCardOpen] = useState(false);

    // -- start -- все для визначення координат CalendarUnfoldedCard
    // координати будуть визачатись відносно елемента в якого pos sticky
    const shortCardInnerRef = useRef<HTMLDivElement | null>(null);
    const refUnfoldedCard = useRef<HTMLDivElement | null>(null);
    const [unfoldedCardRefState, setUnfoldedCardRefState] = useState<any>(null);
    const [positionData, setPositionData] = useState<PositionDataInterface | null>(null);
    const {scrollScrollbars} = useContext(CalendarGridContext);
    useBoundaryCheck(shortCardInnerRef, unfoldedCardRefState, setPositionData);

    useEffect(() => {
        setIsUnfoldedCardOpen(false);
    }, [scrollScrollbars]);

    useEffect(() => {
        if (isUnfoldedCardOpen && refUnfoldedCard?.current) {
            setUnfoldedCardRefState(refUnfoldedCard);
        }

        return () => {
            setUnfoldedCardRefState(null);
            setPositionData(null);
        }
    }, [isUnfoldedCardOpen]);
    // -- end -- все для визначення координат CalendarUnfoldedCard


    useEffect(() => {
        getVisitStatuses().then(response => {
            response?.data &&
            setVisitStatuses(testSources)
            // setVisitStatuses(response.data)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useOutsideClick(refUnfoldedCard, (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const isDropDownOptionElement = target?.classList?.contains("lynx-dropdown__option");
        const isLiveSearchOptionElement = target?.classList?.contains("lynx-live-search__option");

        // випадаючий список dropDown та liveSearch працюють через портали, на них буде спрацьовувати outsideClick
        if (!isDropDownOptionElement && !isLiveSearchOptionElement && !isShowSpinner) {
            toggleUnfoldedCard();
        }
    });


    const toggleUnfoldedCard = () => {
        setIsUnfoldedCardOpen(!isUnfoldedCardOpen);
    };

    // прибрати @ts-ignore, коли буде додано color до visitStatuses(після того, як бек буде віддавати visitStatuses з color)
    // @ts-ignore
    const statusColor = visitStatuses?.find(status => status.id === statusId)?.color;

    const startTime = moment(timeStart, "HH:mm:ss").format("HH:mm")
    const sumTime = moment.duration(timeStart).add(moment.duration(duration));
    const endTime = moment.utc(sumTime.asMilliseconds()).format('HH:mm');

    // стилі для відкритого CalendarUnfoldedCard
    let dropDownPositionLeft = 0;
    let dropDownPositionTop = 0;
    if (positionData) {
        const padding = {
            right: 5,
            left: 10,
        };

        // auxiliary - допоміжний
        const auxiliaryValueOfLeft =
            positionData.openingDirections.directionX === PositionEnum.Right
                ? padding.right
                : -1 * padding.left;
        const auxiliarySignOfTop = positionData.openingDirections.directionY === PositionEnum.Bottom ? +1 : -1;

        dropDownPositionLeft = positionData.coordinates.left + auxiliaryValueOfLeft;
        dropDownPositionTop = positionData.coordinates.top - auxiliarySignOfTop * positionData.relativeElementData.height;
    }

    const unfoldedCardStyle: CSSProperties = {
        opacity: positionData ? 1 : 0,
        top: dropDownPositionTop,
        left: dropDownPositionLeft,
    };


    return visitStatuses ? (
            <>
                <div
                    className="lynx-calendar-short-card__wrapper"
                    onClick={toggleUnfoldedCard}
                >
                    <div
                        className="lynx-calendar-short-card"
                        style={{
                            borderLeft: `5px solid ${statusColor}`,
                            backgroundColor: `${statusColor}45`,
                        }}
                    >
                        <div className="lynx-calendar-short-card__inner" ref={shortCardInnerRef}>
                            <div className="lynx-calendar-short-card__header">
                            <span className="lynx-calendar-short-card__header-left">
                                <i className="fa-duotone fa-clock fa-lg"/>
                                <span className="lynx-calendar-short-card__header-left-text">
                                    {startTime} - {endTime}
                                </span>
                            </span>
                                <span className="lynx-calendar-short-card__header-right-text">
                                {statusName}
                            </span>
                            </div>
                            <div className="lynx-calendar-short-card__item--bold">
                                {client.lastName} {client.firstName} {client.middleName}
                            </div>
                            <div>Співробітник: {employeeName}</div>
                        </div>
                    </div>
                </div>

                {isUnfoldedCardOpen &&
                    <div
                        className="lynx-calendar-short-card__unfolded-card"
                        ref={refUnfoldedCard}
                        style={unfoldedCardStyle}
                    >
                        <CalendarUnfoldedCard
                            closeCardHandler={toggleUnfoldedCard}
                            visit={{
                                ...visit,
                                startTime,
                                endTime,
                                statusColor,
                            }}
                        />
                    </div>
                }
            </>
        )
        :
        <></>
}