import React from 'react';
import './AlertContainer.css';
import Alert from "../Alert/Alert";
import {connect} from "react-redux";
import {IAlertContainerProps, IAlertContainerStore} from "./IAlertContainer";
import {IAlert} from "../Alert/IAlert";

class AlertContainer extends React.Component<IAlertContainerProps> {

    render() {

        return (
            <>
                {this.props.alerts && this.props.alerts.length > 0 &&
                    <div
                        className="lynx-alert-stack"
                        style={{
                            // 70px - height одного alert, 20px - margin-top + margin-bottom одного alert
                            height: this.props.alerts.length * (70 + 20),
                        }}
                    >
                        <div className="lynx-alert-wrapper">
                            <div className="lynx-alert-scroll">
                                {this.props.alerts.map((alert: IAlert) => {
                                    if (alert.token) {
                                        return <Alert
                                            key={alert.token}
                                            type={alert.type}
                                            icon={alert.icon ?? alert.icon}
                                            message={alert.message ?? alert.message}
                                            title={alert.title ?? alert.title}
                                            id={alert.id ?? alert.id}
                                            time={alert.time ?? alert.time}
                                            token={alert.token}
                                        />
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        </div>
                    </div>}
            </>

        )
    }
}

// const mapStateToProps = (store: IAlertContainerStore): { alerts: IAlert[] } => ({
//     alerts: store.system.alerts
// });

const mapStateToProps = (store: IAlertContainerStore): { alerts: IAlert[] } => {
    return {alerts: store.system.alerts}
};

export default connect(mapStateToProps)(AlertContainer);