import {BreadCrumbPropsInterface} from "./BreadCrumbInterface";
import './BreadCrumb.css';

export default function BreadCrumb(props: BreadCrumbPropsInterface) {

    const {title, tree} = props;

    return <div className="lynx-bread-crumb">
        <div className="lynx-bread-crumb__title">{title}</div>
        {<div className="lynx-bread-crumb__tree">

            {tree && tree.map((item) => {
                return <span key={item}>{item} - </span>
            })}
            <span key={title}>{title}</span>
        </div>}
    </div>
}