import {getStates} from "../../../Api/Directories/ServiceAgreements/States";
import {ServiceAgreementStatesType} from "./Types";
import {StateInterface} from "../../../Api/Directories/ServiceAgreements/States/StatesInterface";


export const setServiceAgreementStates = (
    serviceAgreementStates: StateInterface[]
) => {
    return {
        type: ServiceAgreementStatesType.GET_SERVICE_AGREEMENT_STATES,
        payload: serviceAgreementStates,
    };
};

export const getServiceAgreementStatesAction = () => async (dispatch: Function) => {
    const serviceAgreementStates = await getStates();
    if (serviceAgreementStates?.data) {
        dispatch(setServiceAgreementStates(serviceAgreementStates?.data));
    }
};