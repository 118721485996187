import {request} from "../../../../../Core/Utils/Request/Request";
import {UserRequestInterface, UsersResponseInterface} from "./UserInterface";

export const getUsers = async (params: UserRequestInterface): Promise<UsersResponseInterface> => {
    return await request({
        url: '/v1/administration/users',
        method: 'post',
        data: params
    });
}

export const blockUser = async (id: number): Promise<UsersResponseInterface> => {
    return await request({
        url: `/v1/administration/users/${id}/block`,
        method: 'patch'
    });
}

export const unblockUser = async (id: number): Promise<UsersResponseInterface> => {
    return await request({
        url: `/v1/administration/users/${id}/unblock`,
        method: 'patch'
    });
}
