import {FiltersFormPropsInterface, SubmitNameEnum} from "./FiltersFormInterface";
import './FiltersForm.css';
import configLoader from "../../../../Utils/ConfigLoader/ConfigLoader";
import Form from "../../Form";
import Fieldset from "../../Elements/Fieldset";
import FormHR from "../../Elements/FormHR";
import DropDown from "../../Elements/DropDown";
import Button from "../../Elements/Button";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import lodash from "lodash";
import {useForm} from "../../../../hooks";
import {FilterInterface} from "../../../../Store/Filters/Types";
import {getFiltersAction} from "../../../../Store/Filters/Actions";
import {DropDownOption} from "../../Elements/DropDown/DropDownInterface";
import {FormFunctionInterface} from "../../Form/FormInterface";
import ModalSimple from "../../../Modal/ModalSimple";
import SaveFiltersForm from "../SaveFiltersForm";
import {useMediaMatch} from "rooks";

export default function FiltersForm(props: FiltersFormPropsInterface) {

    const {
        code,
        searchParams,
        filterHandler,
        onSubmitHandler,
        formPropsFilters,
    } = props;

    const {mapStateForm, setMapStateForm} = useForm();
    const prevSearchParams = useRef({}); // для глибокого порівняння об'єктів в useEffect

    const saveFilters = useSelector((store: { users: { filters: FilterInterface[] } }) => store.users.filters);

    const [filterData, setFilterData] = useState({});

    const isTablet = useMediaMatch('(max-width: 1200px)');
    const isApplyIcon = useMediaMatch('(max-width: 1375px)');

    const [isOpen, setOpen] = useState(!isTablet);

    const [isModalOpen, setModalOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!saveFilters?.length) {
            dispatch(getFiltersAction());
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setOpen(!isTablet)
    }, [isTablet]);

    useEffect(() => {
        if (!lodash.isEqual(prevSearchParams.current, searchParams)) {
            // після зміни searchParams очищуємо стейт в Form і прокидаємо через дату новий
            mapStateForm?.actions.clear();
            setFilterData(searchParams);

            // оновлюємо prevSearchParams
            prevSearchParams.current = searchParams;
        }
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    let filtersOption: DropDownOption[] | [] = [];

    if (saveFilters?.length > 0) {
        filtersOption = saveFilters
            .filter((item: FilterInterface) => (item.code === code))
            .map((item: FilterInterface) => ({id: item.id, name: item.name}));
    }

    const _onSubmitHandler = (data: any, name: string, object: FormFunctionInterface) => {
        if (name === SubmitNameEnum.ApplyFilter) {
            filterHandler(data);
        }

        if (name === SubmitNameEnum.SaveFilter) {
            setModalOpen(!isModalOpen);
            setFilterData(data);
        }

        if (name === SubmitNameEnum.ClearFilter) {
            object.clear();
            filterHandler({}); // прокинувши {} в useSearchParams()[2] - видаляться всі параметри пошуку з маршруту
        }

        onSubmitHandler && onSubmitHandler(data, name, object);
    }
    const onSelectSaveFilterHandler = (data: any) => {

        if (data && data.value) {
            const selectFilter = saveFilters?.find((item: FilterInterface) => (item.id === data.value))
            filterHandler(selectFilter?.filter);
        } else {
            filterHandler(null);
        }
    }
    const onCloseHandler = () => {
        setModalOpen(!isModalOpen)
    }
    const onToggleHandler = () => {
        setOpen(!isOpen);
    }

    const isShowSaveFilters = configLoader()?.filtersConfig?.showSaveFilters;

    return <>
        <div className="lynx-feed-filters-containers">
            {isTablet &&
                <div className="lynx-feed-filters-container lynx-feed-filters-toggle" onClick={onToggleHandler}>
                    <i className="fa-duotone fa-filter-list"/> Фільтри
                </div>}
            {isShowSaveFilters && isOpen && <div className="lynx-feed-filters-container">
                <Form>
                    <Fieldset title="Збережені фільтри"/>
                    <FormHR type="dashed"/>
                    <div className="row">
                        <div className="col-12">
                            <DropDown
                                name="saveFilterID"
                                options={filtersOption}
                                title="Список фільтрів"
                                afterChangeHandler={onSelectSaveFilterHandler}
                            />
                        </div>
                    </div>
                </Form>
            </div>}

            {isOpen && <div className="lynx-feed-filters-container">
                <Form
                    onSubmit={_onSubmitHandler}
                    data={{...filterData, ...formPropsFilters?.data}}
                    controlState={formPropsFilters?.controlState}
                    mapStateForm={(mapState) => {
                        // для використаня mapState в батьківському компоненті через проп
                        formPropsFilters?.mapStateForm && formPropsFilters.mapStateForm(mapState);
                        // для внутрішнього використання
                        setMapStateForm(mapState);
                    }}
                >
                    <Fieldset title="Фільтри"/>
                    <FormHR type="dashed"/>
                    {props.children}
                    <br/>
                    <div className="row">
                        <div className="col-12">
                            <Button
                                type="submit"
                                name={SubmitNameEnum.ApplyFilter}
                                title={isApplyIcon ? "" : "Застосувати"}
                                icon={isApplyIcon ? "fa-duotone fa-filters" : ""}
                                className="lynx-btn-primary"
                            /> &nbsp;
                            {isShowSaveFilters &&
                                <>
                                    <Button
                                        type="submit"
                                        name={SubmitNameEnum.SaveFilter}
                                        icon="fa-duotone fa-floppy-disk"
                                    /> &nbsp;
                                </>
                            }
                            <Button
                                type="submit"
                                name={SubmitNameEnum.ClearFilter}
                                icon="fa-duotone fa-trash-can"
                            />
                        </div>
                    </div>
                </Form>
            </div>}
        </div>
        <ModalSimple
            isOpen={isModalOpen}
            body={<SaveFiltersForm code={code} filter={filterData} onAfterSuccessHandle={onCloseHandler}/>}
            title="Створити шаблон фільтрів"
            widthClass="xs"
            onAfterCloseHandle={onCloseHandler}
        />
    </>
}
