import React from 'react';
import './TinyMenu.css';
import BrandTiny from "../BrandTiny";
import MainMenuTiny from "../MenuSupport/MainMenuTiny";
import BottomMenuTiny from "../BottomMenuTiny";
import {TinyMenuPropsInterface} from "./TinyMenuInterface";


export default function TinyMenu(props: TinyMenuPropsInterface) {

    const {logo, link, title} = props.brand;
    const {mainMenu, bottomMenu} = props.menu;

    return (
        <div className="lynx-tiny-menu">
            <BrandTiny
                link={link}
                logo={logo}
                title={title}
            />
            <MainMenuTiny items={mainMenu}/>
            <BottomMenuTiny items={bottomMenu}/>
        </div>
    );
}
