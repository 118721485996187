import React from 'react';
import "./ShortCardItem.css"
import ButtonActions from "../../ButtonActions";
import {ShortCardItemPropsInterface} from "./ShortCardItemInterface"


export default function ShortCardItem({itemData}: ShortCardItemPropsInterface) {
    const {id, icon, title, description, actions} = itemData;

    return (
        <div className="lynx-short-card-item">
            <div className="lynx-short-card-item__content">
                <div className="lynx-short-card-item__image">
                    {icon ? <i className={icon}></i> : <i className="fa-sharp fa-solid fa-building"></i>}
                </div>
                <div className="lynx-short-card-item__info">
                    <h6 className="lynx-short-card-item__title">{title}</h6>
                    <div className="lynx-short-card-item__description">{description}</div>
                </div>
            </div>
            <div className="lynx-short-card-item__btn-actions-wrap">
                <ButtonActions className="lynx-button-action__button-sm" data={{id, title, description}}
                               actions={actions}/>
            </div>
        </div>
    );
};