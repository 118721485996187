import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {usePageTitle} from "../../../../../Core/hooks";
import {
    deleteClientSource, findClientSourceById,
} from "../../../../Api/Administration/Directories/ClientSources/ClientSources";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {getClientSourcesAction} from "../../../../Store/Directories/ClientSources/Actions";

import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import BreadCrumbContainer from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import ShortCardContainer from "../../../../../Core/Components/DataVisualization/ShortCardContainer";
import ClientSourceForm from "../../../../Components/Administration/Directories/ClientSources/ClientSourceForm";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {
    ClientSourceInterface
} from "../../../../Api/Administration/Directories/ClientSources/ClientSourcesInterface";


export default function ClientSourcesPage() {
    usePageTitle("Джерела клієнтів");
    const clientSources = useSelector((store: StoreInterface) => store.shortCache.clientSources);
    const dispatch = useDispatch();
    const [permission] = useLynxPermission();
    const [isReload, setIsReload] = useState(true);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);
    const [
        clientSourceData,
        setClientSourceData
    ] = useState<ClientSourceInterface>();


    useEffect(() => {
        if (isReload) {
            setIsReload(false);
            dispatch(getClientSourcesAction());
        }
    }, [isReload, dispatch]);

    const onToggleCreateHandler = () => {
        setIsModalCreateOpen(!isModalCreateOpen);
    };

    const onSuccessHandler = () => {
        setIsReload(true);
        setIsModalCreateOpen(false);
        setIsModalEditOpen(false);
        setIsModalViewOpen(false);
    };

    const viewClientSourceAction = {
        title: "Переглянути",
        name: "Переглянути",
        action: async ({id}: any) => {
            setIsModalViewOpen(true);
            const response = await findClientSourceById(id);
            if (response?.status === "success" && response.data) {
                setClientSourceData(response.data)
            }
        },
    };

    const editClientSourceAction = {
        title: "Редагувати",
        name: "Редагувати",
        classNameItem: "lynx-button-action-warning",
        action: async ({id}: any) => {
            setIsModalEditOpen(true);
            const response = await findClientSourceById(id);
            if (response?.status === "success" && response.data) {
                setClientSourceData(response.data)
            }
        },
    };

    const deleteClientSourceAction = {
        title: "Видалити",
        name: "Видалити",
        classNameItem: "lynx-button-action-danger",
        action: async (data: any) => {
            const response = await deleteClientSource(data.id);
            if (response?.status === "success") {
                onSuccessHandler();
                addAlert({
                    type: response.status,
                    message: response.message,
                });
            }
        },
    };

    const addActions = (data: any) => data.map((item: any) => {
        const actions = [];
        const {id, name, code, isSystem} = item;

        actions.push(viewClientSourceAction);

        if (permission.isEdit('clientSource') && !isSystem) {
            actions.push(editClientSourceAction);
        }

        if (permission.isDelete('clientSource') && !isSystem) {
            actions.push(deleteClientSourceAction);
        }

        return {
            id,
            icon: "fa solid fa-down-left-and-up-right-to-center",
            title: name,
            description: code,
            actions,
        };
    });


    return <>
        <BreadCrumbContainer>
            <BreadCrumb title="Джерела клієнтів" tree={['Довідники']}/>
            {permission.isCreate('clientSource') &&
                <div className="lynx-feeds-page-buttons">
                    <Button
                        name="createClientSource"
                        title="Створити джерело"
                        className="lynx-btn-primary"
                        onClick={onToggleCreateHandler}
                    />
                </div>
            }
        </BreadCrumbContainer>

        <br/>

        <ShortCardContainer itemsData={addActions(clientSources)}/>

        <ModalSimple
            isOpen={isModalCreateOpen}
            body={<ClientSourceForm onAfterSuccessHandle={onSuccessHandler} mode={formMode.create}/>}
            title="Створення нового джерела клієнта"
            onAfterCloseHandle={onToggleCreateHandler}
        />

        <ModalSimple
            isOpen={isModalEditOpen}
            body={
                <ClientSourceForm
                    onAfterSuccessHandle={onSuccessHandler}
                    clientSourceData={clientSourceData}
                    mode={formMode.edit}/>}
            title="Редагування джерела клієнта"
            onAfterCloseHandle={() => setIsModalEditOpen(!isModalEditOpen)}
        />

        <ModalSimple
            isOpen={isModalViewOpen}
            body={
                <ClientSourceForm
                    onAfterSuccessHandle={onSuccessHandler}
                    clientSourceData={clientSourceData}
                    mode={formMode.view}/>}
            title="Перегляд джерела клієнта"
            onAfterCloseHandle={() => setIsModalViewOpen(!isModalViewOpen)}
        />
    </>
};
