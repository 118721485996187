import "./ClientPortraitFilters.css";

import {useBranch} from "../../../../hooks";

import DropDown from "../../../../../Core/Components/Forms/Elements/DropDown";
import DateTime from "../../../../../Core/Components/Forms/Elements/DateTime";
import FiltersForm from "../../../../../Core/Components/Forms/Filters/FiltersForm";
import ExpandableLink from "../../../../../Core/Components/ExpandableLink";

import {ClientPortraitPropsInterface} from "./ClientPortraitFiltersnterface";


export default function ClientPortraitFilters(props: ClientPortraitPropsInterface) {
    const {
        searchParams,
        onFilterHandler,
    } = props;


    const {availableBranches} = useBranch();


    return (
        <FiltersForm
            code="clientPortrait"
            searchParams={searchParams}
            filterHandler={onFilterHandler}
        >
            <div className="row">
                <div className="lynx-client-portrait-filters__inner">
                    <ExpandableLink title="Основні фільтри" isVisibleDefault>
                        <div className="col-12">
                            <DropDown
                                name="branchId"
                                title="Філія"
                                options={availableBranches}
                            />
                        </div>
                        <div className="col-12">
                            <DateTime
                                name="dateCreateStart"
                                title="Дата з"
                            />
                        </div>
                        <div className="col-12">
                            <DateTime
                                name="dateCreateEnd"
                                title="Дата по"
                            />
                        </div>
                    </ExpandableLink>
                </div>
            </div>
        </FiltersForm>
    );
}