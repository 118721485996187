import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";


export async function viewServiceAgreementAction(data: any, functions: {
    setModalMode(mode: formMode): void,
    setIsModalOpen(isOpen: boolean): void,
    setServiceAgreementId(id: number | string): void,
}) {
    functions.setModalMode(formMode.view);
    functions.setIsModalOpen(true);
    functions.setServiceAgreementId(data.id);
}

export async function editServiceAgreementAction(data: any, functions: {
    setModalMode(mode: formMode): void,
    setIsModalOpen(isOpen: boolean): void,
    setServiceAgreementId(id: number | string): void,
}) {
    functions.setModalMode(formMode.edit);
    functions.setIsModalOpen(true);
    functions.setServiceAgreementId(data.id);
}

export async function deleteServiceAgreementAction(data: any, functions: {
    setIsPromptOpen(isOpen: boolean): void,
    setServiceAgreementId(id: number | string): void,
}) {
    functions.setIsPromptOpen(true);
    functions.setServiceAgreementId(data.id);
}