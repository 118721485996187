import React, {useEffect, useState} from "react";
import "./NumberOfVisitsPage.css";
import moment from "moment";

import {
    useLynxSearchParams,
    usePageTitle,
    useReloadPage,
} from "../../../../../Core/hooks";
import {getNumberOfVisits} from "../../../../Api/Reports/NumberOfVisits";
import {setReloadPage} from "../../../../../Core/Store/Reload/Actions";
import {
    preparedDoughnutChartData
} from "../../../../../Core/Components/Charts/ECharts/EChartsReactTypes/DoughnutChart/DoughnutChartHelpers";
import {dateRangeValidation} from "./NumberOfVisitsPageHelpers";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import NumberOfVisitsFilters from "../../../../Components/Reports/NumberOfVisits/NumberOfVisitsFilters";
import BasicLineChart from "../../../../../Core/Components/Charts/ECharts/EChartsReactTypes/BasicLineChart";
import DoughnutChart from "../../../../../Core/Components/Charts/ECharts/EChartsReactTypes/DoughnutChart";
import ShortInfoContainer from "../../../../../Core/Components/DataVisualization/ShortInfo/ShortInfoContainer";

import {
    AverageVisitDurationInterface,
    NumberOfVisitsFiltersInterface,
    NumberOfVisitsInterface,
} from "../../../../Api/Reports/NumberOfVisits/NumberOfVisitsInterface";


export default function NumberOfVisitsPage() {
    usePageTitle("Кількість відвідувань");
    const isReload = useReloadPage("NumberOfVisitsPage");
    const [searchParams, setSearchParams] = useLynxSearchParams();

    const initVisitsState = {
        averageCustomers: 0,
        averageVisitDuration: "00:00:00",
        customersCountByDay: [],
        maxCustomersCountByDay: 0,
        visitsCountByEmployee: [],
    }
    const [visits, setVisits] = useState<NumberOfVisitsInterface>(initVisitsState);

    const initFilterData = {
        dateStart: moment().subtract(3, "months").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
    };


    useEffect(() => () => setReloadPage("NumberOfVisitsPage", true), []);

    useEffect(() => {
        if (!isReload) return;

        let filters;

        if (!Object.keys(searchParams).length) {
            filters = initFilterData;
            setSearchParams(initFilterData);
        } else filters = searchParams;

        getNumberOfVisits({filters}).then(response => {
            setVisits(response?.data ? response.data : initVisitsState);
            setReloadPage("NumberOfVisitsPage", false);
        });
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps


    const onFilterHandler = (data: NumberOfVisitsFiltersInterface) => {
        if (!dateRangeValidation(data.dateStart, data.dateEnd)) return;

        setSearchParams(data);
        setReloadPage("NumberOfVisitsPage", true);
    };

    const preparedBasicLineChartData = (customersCountByDay?: AverageVisitDurationInterface[] | []) => {
        const xAxis: (string | number)[] = [];
        const series: number[] = [];

        if (!!customersCountByDay?.length) {
            const timeValue = (time: string) => moment(time).valueOf();

            customersCountByDay
                .sort((a, b) => timeValue(a.day) - timeValue(b.day))
                .forEach(item => {
                    xAxis.push(moment(item.day, "YYYY-MM-DD").format("DD.MM.YYYY"));
                    series.push(item.count);
                });
        }

        return {
            xAxis,
            series,
        };
    };


    return (
        <div className="lynx-number-of-visits-page">
            <BreadCrumbContainer>
                <BreadCrumb title="Кількість відвідувань" tree={["Звіти"]}/>
            </BreadCrumbContainer>

            <div className="row">
                <div className="col-12 order-2 col-xl-9 order-xl-1">
                    {!!visits.customersCountByDay.length &&
                        <>
                            <ShortInfoContainer itemsData={[
                                {
                                    icon: "fa-duotone fa-users-medical",
                                    targetValue: visits.averageCustomers,
                                    description: "Середня кількість відвідувачів",
                                },
                                {
                                    icon: "fa-duotone fa-stopwatch",
                                    targetValue: visits.averageVisitDuration,
                                    description: "Середня тривалість 1 візиту",
                                },
                                {
                                    icon: "fa-duotone fa-chart-line",
                                    targetValue: visits.maxCustomersCountByDay,
                                    description: "Максимальна кількість відвідувачів за день",
                                },
                            ]}/>

                            <br/>

                            <BasicLineChart
                                title="Кількість відвідувачів по дням"
                                data={preparedBasicLineChartData(visits?.customersCountByDay)}
                                option={{
                                    xAxis: {
                                        axisLabel: {
                                            rotate: 0,
                                        },
                                    },
                                }}
                            />

                            <br/>

                            <DoughnutChart
                                title="Кількість візитів у кожного лікаря"
                                data={preparedDoughnutChartData(
                                    visits?.visitsCountByEmployee, "visitsCount", "employeeName"
                                )}
                            />

                        </>
                    }

                    {!visits.customersCountByDay.length &&
                        <div className="number-of-visits-page__not-found-data">
                            <i className="fa-duotone fa-memo-circle-info number-of-visits-page__not-found-data-icon"/>
                            <span className="number-of-visits-page__not-found-data-text">
                                На жаль, за обраними фільтрами немає даних
                            </span>
                        </div>
                    }
                </div>

                <div className="col-12 order-1 col-xl-3 order-xl-2">
                    <NumberOfVisitsFilters
                        onFilterHandler={onFilterHandler}
                        searchParams={searchParams}
                    />
                </div>
            </div>
        </div>
    );
}