import {getFilters} from "../../Api/Filters/Filter";
import {Core, FilterInterface} from "./Types";

export const setFilters = (filters: FilterInterface[]) => {
    return {
        type: Core.SET_FILTERS,
        payload: filters
    }
}


export const getFiltersAction = () => async (dispatch: Function) => {
    let filters = await getFilters();
    if (filters.status === 'success') {
        dispatch(setFilters(filters.data));
    }
};
