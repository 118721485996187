import React, {useEffect, useState} from "react";
import "./DeleteServiceGroupForm.css";

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {deleteServiceGroup, getServiceGroups,} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups";

import Form from "../../../../../../Core/Components/Forms/Form";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import DropDown from "../../../../../../Core/Components/Forms/Elements/DropDown";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Fieldset from "../../../../../../Core/Components/Forms/Elements/Fieldset";
import ServiceGroupsModal from "../ServiceGroupsModal";

import {ServiceGroupFormPropsInterface} from "./DeleteServiceGroupFormInterface";
import {
    ServiceGroupInterface
} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {AfterSuccessHandleParamsInterface} from "../ServiceGroupForm/ServiceGroupFormInterface";


export default function DeleteServiceGroupForm(props: ServiceGroupFormPropsInterface) {
    const {
        data: dataProp,
        onAfterSuccessHandle,
        onAfterRejectHandle,
    } = props;

    const [serviceGroupId, setServiceGroupId] = useState<number | string | null>(null);
    const [serviceGroups, setServiceGroups] = useState<ServiceGroupInterface[] | []>([]);
    const [isServiceGroupModalOpen, setIsServiceGroupModalOpen] = useState(false);


    useEffect(() => {
        getServiceGroups({pagination: {perPage: 99999}}).then(response => {
            if (response?.data?.length) {
                const filteredServiceGroups = response.data.filter(item => {
                    return item.id !== dataProp?.serviceGroupId;
                });

                setServiceGroups(filteredServiceGroups);
            }
        });
    }, [serviceGroupId]); // eslint-disable-line react-hooks/exhaustive-deps


    const onSubmitHandler = (submitData: any) => {
        deleteServiceGroup({
            serviceGroupId: dataProp.serviceGroupId,
            newServiceGroupId: submitData.newServiceGroupId,
        }).then(response => {
            if (response.status === "success") {
                onAfterSuccessHandle && onAfterSuccessHandle();
                addAlert({
                    type: "success",
                    message: response.message,
                });
            }
        });
    };


    return (
        <>
            <Form onSubmit={onSubmitHandler} data={{newServiceGroupId: serviceGroupId}}>
                <Fieldset
                    title={
                        `Увага! Ви збираєтесь видалити групу послуг. 
                        Перед видаленням, ми рекомендуємо вам перенести всі послуги цієї групи в іншу групу. 
                        В інакшому випадку, при видаленні групи, всі її послуги будуть видалені.`
                    }
                />

                <div className="lynx-delete-service-group-form__row-wrapper">
                    <DropDown
                        options={serviceGroups}
                        name="newServiceGroupId"
                    />
                    <button
                        className="lynx-delete-service-group-form__create-button"
                        title="Додати нового клієнта"
                        onClick={() => setIsServiceGroupModalOpen(true)}
                    >
                        <i className="fa-solid fa-plus"></i>
                    </button>
                </div>

                <br/>

                <FormStickyContainer>
                    <Button
                        type="submit"
                        name="approve"
                        title="Підтвердити"
                        className="lynx-btn-primary"
                    /> &nbsp;
                    <Button
                        name="reject"
                        title="Скасувати"
                        className="lynx-button"
                        onClick={onAfterRejectHandle}
                    />
                </FormStickyContainer>
            </Form>

            {isServiceGroupModalOpen &&
                <ServiceGroupsModal
                    isOpen={isServiceGroupModalOpen}
                    mode={formMode.create}
                    onAfterCloseHandle={() => setIsServiceGroupModalOpen(false)}
                    onAfterSuccessHandle={(params: AfterSuccessHandleParamsInterface) => {
                        setServiceGroupId(params?.data?.id);
                        setIsServiceGroupModalOpen(false);
                    }}
                />
            }
        </>
    )
};
