import {combineReducers} from "redux";
import application from "./../../Core/Store/Application/Reducers";
import controlPanelMenu from "./../../Core/Store/ControlPanelMenu/Reducers";
import profile from './../../Modules/AdministrationUsers/Store/Profile/Reducers'
import alerts from "./../../Core/Store/Alert/Reducers";
import filters from "./../../Core/Store/Filters/Reducers";
import conf from "./../../Core/Store/Conf/Reducers";
import branches from "../../Project/Store/Branches/Reducers";
import clientSources from "../../Project/Store/Directories/ClientSources/Reducers";
import genders from "../../Project/Store/Directories/Genders/Reducers";
import tabsLink from "../../Core/Store/TabsLink/Reducers";
import feedTags from "../../Project/Store/Directories/FeedTags/Reducers";
import customerGroups from "../../Project/Store/CRM/Marketing/CustomerGroups/Reducers";
import workplaces from "../../Project/Store/Directories/Workplaces/Reducers";
import reloads from "../../Core/Store/Reload/Reducers";
import timetableTypes from "../../Project/Store/Directories/TimetableTypes/Reducers";
import employeeWorkingBranches from "../../Project/Store/EmployeeWorkingBranches/Reducers";
import times from "../../Project/Store/Directories/Times/Reducers";
import selectedEmployeeWorkingBranch from "../../Project/Store/SelectedEmployeeWorkingBranch/Reducers";
import servicesPerformedCertificatesStates from "../../Project/Store/Documents/ServicesPerformedCertificates/Reducers";
import serviceAgreementStates from "../../Project/Store/Documents/ServiceAgreements/Reducers";
import fullScreenModal from "../../Core/Store/Modal/FullScreen/Reducers";
import clientPaymentForms from "../../Project/Store/Directories/Payments/ClientPayments/ClientPaymentForms/Reducers";
import clientPaymentStates from "../../Project/Store/Directories/Payments/ClientPayments/ClientPaymentStates/Reducers";
import clientPaymentTypes from "../../Project/Store/Directories/Payments/ClientPayments/ClientPaymentTypes/Reducers";

const rootReducer = combineReducers({
    longTermCache: combineReducers({
        genders,
        times,
        fullScreenModal,
        users: combineReducers({selectedEmployeeWorkingBranch})
    }),
    shortCache: combineReducers({ // очищається при кожній авторизації
        clientSources,
        feedTags,
        workplaces,
        branches,
        customerGroups,
        timetableTypes,
        servicesPerformedCertificatesStates,
        serviceAgreementStates,
        clientPaymentForms,
        clientPaymentStates,
        clientPaymentTypes,
    }),
    application,
    system: combineReducers({alerts, conf, tabsLink, reloads}),
    controlPanelMenu,
    users: combineReducers({profile, filters, employeeWorkingBranches}),
    // directories: combineReducers({}),
});

export default rootReducer;
