import {getStates} from "../../../Api/Directories/ServicesPerformedCertificates/States";
import {ServicesPerformedCertificatesStatesType} from "./Types";
import {StateInterface} from "../../../Api/Directories/ServicesPerformedCertificates/States/StatesInterface";


export const setServicesPerformedCertificateStates = (
    servicesPerformedCertificateStates: StateInterface[]
) => {
    return {
        type: ServicesPerformedCertificatesStatesType.GET_SERVICES_PERFORMED_CERTIFICATES_STATES,
        payload: servicesPerformedCertificateStates,
    };
};

export const getServicesPerformedCertificatesStatesAction = () => async (dispatch: Function) => {
    const servicesPerformedCertificateStates = await getStates();
    if (servicesPerformedCertificateStates?.data) {
        dispatch(setServicesPerformedCertificateStates(servicesPerformedCertificateStates?.data));
    }
};