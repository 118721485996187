import {request} from "../../../../../Core/Utils/Request/Request";
import {
    ServiceGroupsResponseInterface,
    ServiceGroupResponseInterface,
    ServiceGroupRequestInterface,
    ServiceGroupsRequestInterface,
    DeleteServiceGroupRequestInterface,
} from "./ServiceGroupsInterface";


export const getServiceGroups = async (
    params?: ServiceGroupsRequestInterface
): Promise<ServiceGroupsResponseInterface> => {
    return await request({
        url: '/v1/service-groups/filters',
        method: 'post',
        data: params,
    });
};

export const findServiceGroupById = async (id: number | string): Promise<ServiceGroupResponseInterface> => {
    return await request({
        url: `/v1/service-groups/${id}`,
        method: 'get'
    });
};

export const createServiceGroup = async (params: ServiceGroupRequestInterface): Promise<ServiceGroupResponseInterface> => {
    return await request({
        url: '/v1/service-groups',
        method: 'post',
        data: params
    });
};

export const editServiceGroup = async (id: number | string, params: ServiceGroupRequestInterface): Promise<ServiceGroupResponseInterface> => {
    return await request({
        url: `/v1/service-groups/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteServiceGroup = async (
    params: DeleteServiceGroupRequestInterface
): Promise<ServiceGroupResponseInterface> => {
    return await request({
        url: `/v1/service-groups/delete`,
        method: 'post',
        data: params,
    });
};
