import {FullScreenModalTypes} from "./Types";
import {dispatch} from "../../../../Config/Redux/Store";

export const toggleFullScreenAction = (key: string) => {
    return {
        type: FullScreenModalTypes.TOGGLE_FULL_SCREEN,
        payload: {
            key,
        },
    };
};

export const toggleFullScreen = (key: string) => {
    dispatch(toggleFullScreenAction(key));
};