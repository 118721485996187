import {ResponseInterface} from "../../../../../../Core/Utils/Request/ResponseInterface";


export enum ClientPaymentTypeIdEnum {
    Advance = 1,
    Partial = 2,
    AccordingToTheCertificate = 3,
}

export interface ClientPaymentTypeInterface {
    id: number,
    name: string,
}

export interface ClientPaymentTypesResponseInterface extends ResponseInterface {
    data?: ClientPaymentTypeInterface[],
}
