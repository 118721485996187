import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";


export async function viewClientPaymentAction(data: any, functions: {
    setModalMode(mode: formMode): void,
    setIsModalOpen(isOpen: boolean): void,
    setClientPaymentId(id: number | string): void,
}) {
    functions.setModalMode(formMode.view);
    functions.setIsModalOpen(true);
    functions.setClientPaymentId(data.id);
}

export async function editClientPaymentAction(data: any, functions: {
    setModalMode(mode: formMode): void,
    setIsModalOpen(isOpen: boolean): void,
    setClientPaymentId(id: number | string): void,
}) {
    functions.setModalMode(formMode.edit);
    functions.setIsModalOpen(true);
    functions.setClientPaymentId(data.id);
}

export async function createClientPaymentAction(_: never, functions: {
    setModalMode(mode: formMode): void,
    setIsModalOpen(isOpen: boolean): void,
}) {
    functions.setModalMode(formMode.create);
    functions.setIsModalOpen(true);
}

export async function deleteClientPaymentAction(data: any, functions: {
    setIsPromptOpen(isOpen: boolean): void,
    setClientPaymentId(id: number | string): void,
}) {
    functions.setIsPromptOpen(true);
    functions.setClientPaymentId(data.id);
}