import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import {DropDownOption, DropDownPropsInterface, ReactSelectOptions} from "./DropDownInterface";
import './DropDown.css';
import HelperInfo from "../../../HelperInfo";

export default function DropDown(props: DropDownPropsInterface) {

    const {
        disabled,
        required,
        name,
        title,
        value,
        placeholder = "",
        initComponent,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler,
        options,
        className,
        isMulti,
        styles,
        isClearable = true,
        tooltip,
    } = props;


    const [isMenuOpen, setIsMenuOpen] = useState(false);


    useEffect(() => {
        const handleScroll = (e: Event) => {
            const target = e.target as Element;

            if (!target?.classList?.contains("lynx-dropdown__menu-list")) {
                setIsMenuOpen(false);
                document.removeEventListener("scroll", handleScroll, {capture: true});
            }
        };

        if (isMenuOpen) {
            document.addEventListener("scroll", handleScroll, {capture: true});
        }

        return () => {
            document.removeEventListener("scroll", handleScroll, {capture: true});
        };
    }, [isMenuOpen]);


    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            })
        }
    }, [initComponent, required, afterChangeHandler, beforeChangeHandler]); // eslint-disable-line react-hooks/exhaustive-deps

    const optionFormat: any = options?.map((item: DropDownOption) => ({
        value: item.id,
        label: item.name,
        color: item.color,
    }));
    const [selectOption, setSelectOption] = useState<any>(null);


    useEffect(() => {
        if (!value || optionFormat?.length === 0) {
            setSelectOption(null);
            return;
        }

        const option = optionFormat?.filter((item: ReactSelectOptions) => {
            // value: [{id: string | number, name: string | number}]
            if (Array.isArray(value) && value.length && value[0].id) {
                return value.some(({id}) => id === item.value);
            }

            // value: [number | string, number | string]
            if (Array.isArray(value)) {
                return value.some((opt) => opt == item.value); // eslint-disable-line eqeqeq
            }

            // value: number | string
            return item.value == value; // eslint-disable-line eqeqeq
        })

        setSelectOption(option);
    }, [value, options]); // eslint-disable-line react-hooks/exhaustive-deps


    const onChangeHandler = (values: any) => {
        setSelectOption(values);
        if (changeHandler) {
            if (isMulti) {
                changeHandler({
                    name,
                    value: values.map((item: any) => item.value)
                })
            } else {
                changeHandler({
                    name,
                    value: values?.value ? values.value : null
                })
            }
        }
    }

    let dropDownClass = ['lynx-dropdown'];

    if (disabled) {
        dropDownClass.push('disabled')
    }

    if (required) {
        dropDownClass.push('required')
    }

    if (className) {
        dropDownClass.push(className)
    }


    return (
        <div className={dropDownClass.join(' ')}>
            {title &&
                <div className="lynx-dropdown--place-title">
                    <label htmlFor={name} className="lynx-dropdown__title">
                        {title}
                    </label>
                    {tooltip && <HelperInfo {...tooltip}/>}
                </div>
            }
            <Select
                data-testid={'dropdown-' + name}
                name={name}
                inputId={name}
                options={optionFormat}
                onChange={onChangeHandler}
                value={selectOption}
                isClearable={isClearable}
                isMulti={isMulti}
                isDisabled={disabled}
                classNamePrefix={"lynx-dropdown"}
                placeholder={placeholder}
                styles={{
                    menuPortal: base => ({
                        ...base,
                        zIndex: 9999, // щоб menu не ховалось під модальне вікно
                    }),
                    ...styles
                }}
                menuIsOpen={isMenuOpen}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                menuPortalTarget={document.body} // щоб menu не ховалось під footer
                menuPosition="fixed" // щоб виходило за межі компонентів, у яких overflow не дефолтний
                menuPlacement="auto" // визначає в top/bottom для відкриття menu
            />
        </div>
    );
}
