import {AnyAction} from "redux";
import {EmployeeWorkingBranchesStateType, EmployeeWorkingBranchesTypes} from "./Types";


const initialState: EmployeeWorkingBranchesStateType = [];

const employeeWorkingBranches = (
    state: EmployeeWorkingBranchesStateType = initialState, action: AnyAction
): EmployeeWorkingBranchesStateType => {
    const {type, payload} = action;
    switch (type) {
        case  EmployeeWorkingBranchesTypes.GET_EMPLOYEE_WORKING_BRANCHES:
            return [...payload];
        default:
            return state;
    }
};

export default employeeWorkingBranches;