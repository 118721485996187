import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./MobileMenu.css";

import {toggleMobileMenu} from "../../../../Store/ControlPanelMenu/Actions";

import MainMobileMenu from "../MobileMenuSupport/MainMenu";
import BottomMenu from "../../Menu/BottomMenu";
import BrandMobile from "../BrandMobile";

import {MobileMenuPropsInterface} from "./MobileMenuInterface";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";


export default function MobileMenu(props: MobileMenuPropsInterface) {
    const {link, title, logo} = props.brand;

    const dispatch = useDispatch();
    const isMobileMenuOpen = useSelector((store: StoreInterface) => store.controlPanelMenu.isMobileMenuOpen);

    const onToggleButton = () => dispatch(toggleMobileMenu());


    useEffect(() => {
        isMobileMenuOpen && onToggleButton();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const wrapperClassName = ["lynx-mobile-menu__wrapper"];
    isMobileMenuOpen && wrapperClassName.push("active");


    return (
        <>
            <div className={wrapperClassName.join(" ")}>
                <div className="lynx-mobile-menu">
                    <BrandMobile
                        link={link}
                        logo={logo}
                        title={title}
                    />
                    <MainMobileMenu items={props.menu.mainMenu}/>
                    <BottomMenu items={props.menu.bottomMenu}/>
                </div>
                <div
                    className="lynx-mobile-menu__right"
                    onClick={onToggleButton}>
                </div>
            </div>
        </>
    );
}

