import './Radio.scss'
import {RadioPropsInterface} from "./RadioInterface";
import {useEffect, useState} from "react";

export default function Radio(props: RadioPropsInterface) {
    const {
        name,
        label,
        colorClass,
        disabled,
        changeHandler,
        value,
        isChecked
    } = props;

    const [isCheck, setIsCheck] = useState(isChecked)

    useEffect(() => {
        setIsCheck(isChecked)
    }, [isChecked])

    const handleRadioChange = (e: any) => {
        if (changeHandler) {
            const {value} = e.target;
            setIsCheck(true)
            changeHandler(Number(value));
        }
    };

    let radio = ['lynx-radio']
    disabled && radio.push('disabled')
    isCheck && radio.push('checked')

    let checkmark = ['lynx-radio__checkmark']
    colorClass && checkmark.push(colorClass)

    return (
        <label className={radio.join(' ')}>{label}
            <input className="lynx-radio__check"
                   type="radio"
                   name={name}
                   disabled={disabled}
                   onChange={handleRadioChange}
                   data-testid={'radio-' + name}
                   checked={isCheck || false}
                   value={value}/>
            <span className={checkmark.join(' ')}/>
        </label>
    )
}
