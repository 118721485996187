export const styleVars = {
//Налаштування кольору
    colorPrimary: "#3699ff",
    colorSecondary: "#e4e6ef",
    colorSuccess: "#1bc5bd",
    colorInfo: "#3699ff",
    colorWarning: "#ffa800",
    colorDanger: "#f64e60",
    colorLight: "#f3f6f9",
    colorDark: "#181c32",

//Colors Gray BackGround
    colorGray100: "#f3f6f9",
    colorGray200: "#ebedf3",
    colorGray300: "#e4e6ef",
    colorGray400: "#d1d3e0",
    colorGray500: "#b5b5c3",
    colorGray600: "#7e8299",
    colorGray700: "#5e6278",
    colorGray800: "#3f4254",
    colorGray900: "#181c32",

//Colors Other Text
    colorDark75: "#3f4254",
    colorDark65: "#5e6278",
    colorDark50: "#7e8299",
    colorDark40: "#b5b5c3",
    colorDark25: "#d1d3e0",

    colorTextBody: "#3f4254",
    colorMuted: "#b5b5c3",
    colorWhite: "#ffffff",

//Font size
    fontSizeText: "14px",
    fontSizeXs: "10px",
    fontSizeSm: "12px",
    fontSizeLg: "14px",
    fontSizeH6: "15px",
    fontSizeH5: "16px",
    fontSizeH4: "18px",
    fontSizeH3: "20px",
    fontSizeH2: "23px",
    fontSizeH1: "26px",


//Vars z-index

//Component: historyPanel
    zIndexHistoryPanelOverlay: 5000,
    zIndexHistoryPanelBars: 5001,

//Component: Alert
    zIndexAlertStack: 10000,

//Component: Prompt
    zIndexPromptStack: 9050,


//Component: ControlPanel
//Component: ControlPanel->Container->Content
    componentContent: "#e9f0f6",
    zIndexContent: 100,
};