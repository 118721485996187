import React from "react";
import "./TabsLinkNavigationItemsDefault.css"
import {TabsLinkNavigationItemsDefaultPropsInterface} from "./TabsLinkNavigationItemsDefaultInterface";

export default function TabsLinkNavigationItemsDefault(props: TabsLinkNavigationItemsDefaultPropsInterface) {
    const {
        tabs,
        onChangeHandler,
        activeTab,
    } = props;

    return (
        <div className="lynx-tab-link-navigation-items-default">
            {tabs.map((tab) => {
                const tabClass = ["lynx-tab-link-navigation-items-default__tab"];

                if (tab.id === activeTab) {
                    tabClass.push("active")
                }

                return (
                    <div key={tab.id} className={tabClass.join(" ")}
                         onClick={() => onChangeHandler(tab.id)}>
                        {tab.icon && <i className={`fa-solid ${tab.icon}`}/>}
                        {tab.name}
                    </div>
                )
            })}
        </div>
    );
};