import {useEffect, useState} from "react";

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {addUserToPermissionGroup} from "../../../../Api/Permissions/UsersPermissionGroup";
import {getUsers} from "../../../../Api/Users/Users/Users";

import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import Form from "../../../../../../Core/Components/Forms/Form";

import {UserGroupPermissionFormPropsInterface} from "./UserGroupPermissionFormInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {UserInterface} from "../../../../Api/Users/Users/UserInterface";


export default function UserGroupPermissionForm(props: UserGroupPermissionFormPropsInterface) {
    const {
        data,
        mode,
        onAfterSuccessHandle,
    } = props;

    const [users, setUsers] = useState<UserInterface[] | []>([]);


    useEffect(() => {
        getUsers({pagination: {perPage: 99999}}).then(response => {
            response?.data && setUsers(response.data);
        });
    }, [data.permissionGroupId]);


    const onSubmit = (data: any) => {
        addUserToPermissionGroup(data).then(response => {
            if (response?.data) {
                addAlert({
                    type: "success",
                    message: response.message,
                });
                onAfterSuccessHandle();
            }
        });
    };


    return (
        <Form data={data} mode={mode} onSubmit={onSubmit}>
            <DropDown
                name="userId"
                options={prepareDropDownOptions(users, "id", "name")}
                title="Користувач (співробітник)"
                required
            />

            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        <Button
                            type="submit"
                            name="add"
                            title="Додати"
                            className="lynx-btn-primary"
                        />
                    </FormStickyContainer>
                </>
            }
        </Form>
    );
}