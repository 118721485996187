import {FeedInterface} from "../../../Api/Feeds/Feed/FeedInterface";


export enum FeedTypeIdEnum {
    Text = 1,
    HTML = 2,
    Visit = 3,
    ServicesPerformedCertificate = 4,
}

export interface FeedTags {
    id: number,
    name: string
}

export interface FeedPropsInterface extends FeedInterface{
}
