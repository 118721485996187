import {request} from "../../../Core/Utils/Request/Request";
import {
    CalendarForDayRequestInterface,
    CalendarForDayResponseInterface,
    CalendarForWeekRequestInterface,
    CalendarForWeekResponseInterface,
} from "./CalendarsInterface";


export const getCalendarForDay = async (
    params: CalendarForDayRequestInterface
): Promise<CalendarForDayResponseInterface> => {
    return await request({
        url: "/v1/calendars/one-day/filters",
        method: "post",
        data: params,
    });
};

export const getCalendarForWeek = async (
    params: CalendarForWeekRequestInterface
): Promise<CalendarForWeekResponseInterface> => {
    return await request({
        url: "/v1/calendars/one-week/filters",
        method: "post",
        data: params,
    });
};