import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {usePageTitle} from "../../../../../Core/hooks";
import {getCustomerGroupsAction} from "../../../../Store/CRM/Marketing/CustomerGroups/Actions";
import {deleteCustomerGroupAction, editCustomerGroupAction, viewCustomerGroupAction} from "./CustomerGroupsPageHelpers";

import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import
    BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import ShortCardContainer from "../../../../../Core/Components/DataVisualization/ShortCardContainer";
import CustomerGroupForm from "../../../../Components/CRM/Marketing/CustomerGroups/CustomerGroupForm";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {CustomerGroupInterface} from "../../../../Api/CRM/Marketing/CustomerGroups/CustomerGroupsInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";


export default function CustomerGroupsPage() {
    const [permission] = useLynxPermission();
    usePageTitle("Групи клієнтів");
    const customerGroups = useSelector((store: StoreInterface) => store.shortCache.customerGroups);
    const dispatch = useDispatch();
    const [isReload, setIsReload] = useState(true);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);
    const [customerGroupData, setCustomerGroupData] = useState<CustomerGroupInterface>();
    const formWidth = "sm";

    useEffect(() => {
        if (isReload) {
            dispatch(getCustomerGroupsAction());
            setIsReload(false);
        }
    }, [isReload, dispatch]);

    const onSuccessHandler = () => {
        setIsReload(true);
        setIsModalCreateOpen(false);
        setIsModalEditOpen(false);
        setIsModalViewOpen(false);
    };

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "Переглянути",
            isShow: permission.isView('customerGroup'),
            functions: {setIsModalViewOpen, setCustomerGroupData},
            action: viewCustomerGroupAction,
        },
        {
            title: "Редагувати",
            name: "Редагувати",
            isShow: permission.isView('customerGroup'),
            classNameItem: "lynx-button-action-warning",
            functions: {setIsModalEditOpen, setCustomerGroupData},
            action: editCustomerGroupAction,
        },
        {
            title: "Видалити",
            name: "Видалити",
            isShow: permission.isDelete('customerGroup'),
            classNameItem: "lynx-button-action-danger",
            functions: {onSuccessHandler},
            action: deleteCustomerGroupAction,
        },
    ];

    const itemsData = (customerGroups: CustomerGroupInterface[]) => customerGroups.map((action: any) => {
        const {id, name, description} = action;

        return {
            id,
            icon: "fa-duotone fa-people-group",
            title: name,
            description,
            actions,
        };
    });


    return <>
        <BreadCrumbContainer>
            <BreadCrumb title="Групи клієнтів" tree={['CRM', 'Маркетинг']}/>
            {permission.isCreate('customerGroup') &&
                <div className="lynx-feeds-page-buttons">
                    <Button
                        name="createCustomerGroup"
                        title="Створити групу"
                        className="lynx-btn-primary"
                        onClick={() => setIsModalCreateOpen(!isModalCreateOpen)}
                    />
                </div>
            }
        </BreadCrumbContainer>

        <br/>

        {permission.isView('customerGroup') && <ShortCardContainer itemsData={itemsData(customerGroups)}/>}


        <ModalSimple
            isOpen={isModalCreateOpen}
            body={<CustomerGroupForm onAfterSuccessHandle={onSuccessHandler} mode={formMode.create}/>}
            title="Створення нової групи"
            onAfterCloseHandle={() => setIsModalCreateOpen(!isModalCreateOpen)}
            widthClass={formWidth}
        />

        <ModalSimple
            isOpen={isModalEditOpen}
            body={
                <CustomerGroupForm
                    onAfterSuccessHandle={onSuccessHandler}
                    clientSourceData={customerGroupData}
                    mode={formMode.edit}/>}
            title="Редагування групи клієнта"
            widthClass={formWidth}
            onAfterCloseHandle={() => setIsModalEditOpen(!isModalEditOpen)}
        />

        <ModalSimple
            isOpen={isModalViewOpen}
            body={
                <CustomerGroupForm
                    onAfterSuccessHandle={onSuccessHandler}
                    clientSourceData={customerGroupData}
                    mode={formMode.view}/>}
            title="Перегляд групи клієнта"
            widthClass={formWidth}
            onAfterCloseHandle={() => setIsModalViewOpen(!isModalViewOpen)}
        />
    </>
};
