import React, {useEffect, useState} from "react";

import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {createWorkplace, editWorkplace, findWorkplaceById} from "../../../../../../Api/Directories/Workplaces";

import Form from "../../../../../../../Core/Components/Forms/Form";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Input from "../../../../../../../Core/Components/Forms/Elements/Input";

import {WorkplaceFormInterface} from "./WorkplaceFormInterface";
import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {WorkplaceInterface} from "../../../../../../Api/Directories/Workplaces/WorkplacesInterface";


export default function WorkplaceForm(props: WorkplaceFormInterface) {
    const {
        onAfterSuccessHandle,
        workplaceId,
        officeId,
        mode,
    } = props;


    const [workplace, setWorkplace] = useState<WorkplaceInterface>();

    useEffect(() => {
        if (workplaceId) {
            findWorkplaceById(workplaceId).then(response => {
                if (response?.data) {
                    setWorkplace(response.data);
                }
            });
        }
    }, [officeId]); // eslint-disable-line react-hooks/exhaustive-deps


    const onSubmitHandler = (data: any, name: string) => {
        if (name === "create") {
            const params = {
                ...data,
                officeId,
            };

            createWorkplace(params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === "success") {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }

        if (name === "save") {
            const params = {
                ...data,
                officeId: workplace?.officeId,
            };

            editWorkplace(params.id, params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === "success") {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }
    };


    return <>
        <Form
            onSubmit={onSubmitHandler}
            data={workplace}
            mode={mode}
        >
            <div className="row">
                <Input
                    type="text"
                    name="name"
                    title="Назва робочого місця"
                    required
                />
            </div>

            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create ? (
                            <Button
                                type="submit"
                                name="create"
                                title="Додати"
                                className="lynx-btn-primary"
                            />
                        ) : (
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                        )}
                    </FormStickyContainer>
                </>
            }
        </Form>
    </>;
};
