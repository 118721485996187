import {MouseEvent, useEffect, useState} from "react";
import "moment/locale/uk";
import moment from "moment";
import "./DatePickerSwitcher.css";

import DateTime from "../Forms/Elements/DateTime";

import {DateTypeEnum, DatePickerSwitcherPropsInterface} from "./DatePickerSwitcherInterface";


export default function DatePickerSwitcher(props: DatePickerSwitcherPropsInterface) {
    const {
        timeframeButtons,
        defaultSelectedDataType = DateTypeEnum.Month,
        defaultDate = moment(),
        className,
        onChange,
    } = props;


    const [dataType, setDataType] = useState(defaultSelectedDataType);
    const [currentDate, setCurrentDate] = useState(defaultDate);

    const currentDay = currentDate.format("YYYY-MM-DD");
    const currentWeek = currentDate.week(); // перший тиждень = 1
    const currentMonth = currentDate.month(); // перший місяць = 0
    const currentYear = currentDate.year();


    useEffect(() => {
        onChange && onChange({
            period: dataType,
            value: getDateValue(dataType),
            year: currentYear,
        });
    }, [currentDate, dataType]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleSwitchPeriodType = (e: MouseEvent<HTMLButtonElement>) => {
        setDataType(e.currentTarget.name as DateTypeEnum);
    };

    const handleSwitchTimeframe = (e: MouseEvent<HTMLButtonElement>) => {
        const buttonName = e.currentTarget.name;

        // плюсуємо/віднімаємо день/тиждень/місяць в задлежності від dataType
        buttonName === "previous" && setCurrentDate(currentDate.clone().subtract(1, dataType));
        buttonName === "next" && setCurrentDate(currentDate.clone().add(1, dataType));
        buttonName === "current" && setCurrentDate(moment());
    };

    function getDateValue(dataType: DateTypeEnum): number | string {
        let value: string | number = "";

        switch (dataType) {
            case DateTypeEnum.Day:
                value = currentDay;
                break;
            case DateTypeEnum.Week:
                value = currentWeek;
                break;
            case DateTypeEnum.Month:
                value = currentMonth;
                break;
        }

        return value;
    }

    const onDateTimeHandler = ({value}: { value: string }) => {
        setCurrentDate(moment(value));
    };

    const classNameButton = "lynx-button";
    const activeClassNameSwitcher = "lynx-button active";

    const switcherClassName = ["lynx-date-picker-switcher"];
    className && switcherClassName.push(className);


    return (
        <div className={switcherClassName.join(" ")}>

            <div className="lynx-date-picker-switcher__left-buttons">
                {timeframeButtons.includes(DateTypeEnum.Day) &&
                    <button
                        name={DateTypeEnum.Day}
                        data-testid="day"
                        className={dataType === DateTypeEnum.Day ? activeClassNameSwitcher : classNameButton}
                        onClick={handleSwitchPeriodType}>
                        День
                    </button>
                }
                {timeframeButtons.includes(DateTypeEnum.Week) &&
                    <button
                        name={DateTypeEnum.Week}
                        data-testid="week"
                        className={dataType === DateTypeEnum.Week ? activeClassNameSwitcher : classNameButton}
                        onClick={handleSwitchPeriodType}>
                        Тиждень
                    </button>
                }
                {timeframeButtons.includes(DateTypeEnum.Month) &&
                    <button
                        name={DateTypeEnum.Month}
                        data-testid="month"
                        className={dataType === DateTypeEnum.Month ? activeClassNameSwitcher : classNameButton}
                        onClick={handleSwitchPeriodType}>
                        Місяць
                    </button>
                }
            </div>

            <div className="lynx-date-picker-switcher__field">
                <i className="fa-duotone fa-calendar-days"/>
                <div className="lynx-date-picker-switcher__field-inner">
                    {dataType === DateTypeEnum.Day &&
                        <DateTime
                            className="lynx-date-picker-switcher__datepicker"
                            name="date"
                            value={currentDay}
                            title="Дата"
                            changeHandler={onDateTimeHandler}
                        />
                    }
                    {dataType === DateTypeEnum.Week &&
                        // .padStart(2, '0') додає "0" перед числом, якщо воно складається лише з одного символу
                        <span>{`${currentWeek.toString().padStart(2, '0')} тиждень`}</span>
                    }
                    {dataType === DateTypeEnum.Month &&
                        <span className="lynx-date-picker-switcher__field--capitalize">
                            {moment().month(currentMonth).format("MMMM")}
                        </span>
                    }

                    {/* рік для DateTypeEnum.Day буде відображатись з компоненту DateTime */}
                    {dataType !== DateTypeEnum.Day && <span>{currentYear}</span>}
                </div>
            </div>

            <div className="lynx-date-picker-switcher__right-buttons">
                <button
                    name="previous"
                    data-testid="previous"
                    className={classNameButton}
                    onClick={handleSwitchTimeframe}>
                    <i className="fa-solid fa-angle-left"/>
                </button>
                <button
                    name="current"
                    data-testid="current"
                    className={classNameButton}
                    onClick={handleSwitchTimeframe}>
                    <i className="fa-regular fa-arrow-rotate-left"></i>
                </button>
                <button
                    name="next"
                    data-testid="next"
                    className={classNameButton}
                    onClick={handleSwitchTimeframe}>
                    <i className="fa-solid fa-angle-right"/>
                </button>
            </div>
        </div>
    );
}