import DropDown from "../DropDown";
import {ColoredDropDownPropsInterface} from "./ColoredDropDownInterface";
import {colourStylesMulti, colourStylesSingle} from "./ColoredDropDownHelpers";


function ColoredDropDown(props: ColoredDropDownPropsInterface) {
    return (
        <DropDown
            {...props}
            styles={props.isMulti ? colourStylesMulti : colourStylesSingle}
        />
    );
}

export default ColoredDropDown;