import React from 'react';
import './Alert.css';
import {IAlertProps} from './IAlert';
import {removeAlertAction} from "../../../Store/Alert/Actions";

export default function Alert(props: IAlertProps) {
    const {
        type,
        title,
        message,
        token,
    } = props;

    const getIcon = () => {
        switch (type) {
            case 'success':
                return 'icofont-check';
            case 'warning':
                return 'icofont-warning-alt';
            case 'error':
                return 'icofont-close-circled';
            case 'info':
                return 'icofont-info-circle';
            default:
                return 'icofont-cubes';
        }
    };

    const removeClickHandler = () => {
        token && removeAlertAction(token);
    };

    const alertClasses = ['lynx-alert', type];

    return (
        <div className={alertClasses.join(' ')}>
            <div className="icon"><i className={getIcon()}/></div>
            <div className="content">
                <div className="title">{title ? title : 'Нове повідомлення'}</div>
                <div className="message">{message}</div>
            </div>
            <div className="close"><i className="icofont-close" onClick={removeClickHandler}/></div>
        </div>
    );
};
