import React, {useEffect, useState} from 'react';
import {findClientById} from "../../../../Api/CRM/Clients";
import {useReloadComponent} from "../../../../../Core/hooks";
import {setReloadComponent} from "../../../../../Core/Store/Reload/Actions";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import ClientCard from "../ClientCard/ClientCard";
import {ClientModalViewPropsInterface} from "./ClientModalViewInterface";
import {ClientInterface} from "../../../../Api/CRM/Clients/ClientsInterface";


export default function ClientModalView({isOpen, clientId, onAfterCloseHandle}: ClientModalViewPropsInterface) {
    const [client, setClient] = useState<ClientInterface | null>(null);
    const isReload = useReloadComponent("clientModalView");


    useEffect(() => {
        isReload && clientId && findClientById(clientId).then(response => {
            response?.data && setClient(response.data);
            setReloadComponent("clientModalView", false);
        });
    }, [isReload, clientId]);

    useEffect(() => {
       clientId && isOpen && setReloadComponent("clientModalView", true);
    }, [clientId, isOpen]);



    const clientIsNotEmptyObject = client !== null && typeof client === 'object' && Object.keys(client).length !== 0;


    return (
        <>
            {clientIsNotEmptyObject && clientId === client?.id &&
                <ModalSimple
                    isOpen={isOpen}
                    body={<ClientCard client={client}/>}
                    title="Клієнт"
                    widthClass="xl"
                    bgColorClass="lynx-modal__body--bg-content"
                    fullScreenModal={{key: "ClientModalView"}}
                    onAfterCloseHandle={onAfterCloseHandle}
                />
            }
        </>
    );
};