import {DualListSelectorDataItem} from "./DualListSelectorInterface";

export const filterData = (
    data: DualListSelectorDataItem[],
    input: string,
    additionalSearchFields?: (string | number)[],
): DualListSelectorDataItem[] | [] => {
    return data.map(dataItem => {
        const filteredGroupList = dataItem.groupList.filter((item) => {
            const preparedInput = input.toLowerCase().trim();
            const fieldIncludesInput = (field: string | number) => field.toString().toLowerCase().includes(preparedInput);

            return (
                fieldIncludesInput(item.name)
                || additionalSearchFields?.some(field => fieldIncludesInput(item.fields[field]))
            );
        });

        return {
            ...dataItem,
            groupList: filteredGroupList,
        };
    })
};