import React, {useEffect, useState} from 'react';
import './RadioGroup.scss'
import {RadioGroupPropsInterface} from "./RadioGroupInterface";
import Radio from "../Radio";
import HelperInfo from "../../../HelperInfo";

export default function RadioGroup(props: RadioGroupPropsInterface) {
    const {
        disabled,
        required,
        name,
        title,
        value,
        colorClass,
        radioList,
        direction,
        radioDirection,
        initComponent,
        tooltip,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler
    } = props;

    const [selectedRadio, setSelectedRadio] = useState<number | undefined>(Number(value));

    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            })
        }

        setSelectedRadio(value);
    }, [initComponent, name, value, required, beforeChangeHandler, afterChangeHandler]);

    const clickHandler = (val: number) => {
        setSelectedRadio(val);
        if (changeHandler) {
            changeHandler({
                name,
                value: val
            });
        }
    };

    let radioGroupClasses = ['lynx-radio-group-container'];
    let radioListClasses = ['lynx-radio-list-container'];

    if (required) {
        radioGroupClasses.push('required')
    }

    if (direction && direction === 'column') {
        radioGroupClasses.push('lynx-radio-group-column')
    }

    if (radioDirection && radioDirection === 'column') {
        radioListClasses.push('lynx-radio-list-column')
    }

    return (
        <div className={radioGroupClasses.join(' ')} data-name={name}>
            <p className="lynx-radio-group-title">
                <span>{title}</span>
                {tooltip && <HelperInfo {...tooltip}/>}
            </p>
            <div className={radioListClasses.join(' ')}>
                {radioList.map(item => (
                    <Radio key={item.id}
                           value={item.id}
                           label={item.label}
                           name={`radio-${name}-${item.id}`}
                           disabled={disabled}
                           colorClass={colorClass}
                           changeHandler={clickHandler}
                           isChecked={selectedRadio === item.id}
                    />
                ))}
            </div>
        </div>
    )
}
