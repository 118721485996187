import {
    SelectedEmployeeWorkingBranchTypes,
    SelectedEmployeeWorkingBranchStateType,
} from "./Types";


export const setSelectedEmployeeWorkingBranch = (
    selectedEmployeeWorkingBranch: SelectedEmployeeWorkingBranchStateType
) => {
    return {
        type: SelectedEmployeeWorkingBranchTypes.SET_SELECTED_EMPLOYEE_WORKING_BRANCH,
        payload: selectedEmployeeWorkingBranch,
    };
};