import {getClientPaymentStates} from "../../../../../Api/Directories/Payments/ClientPayments/ClientPaymentStates";
import {ClientPaymentStatesType} from "./Types";
import {
    ClientPaymentStateInterface
} from "../../../../../Api/Directories/Payments/ClientPayments/ClientPaymentStates/ClientPaymentStatesInterface";


export const setClientPaymentStates = (clientPaymentStates: ClientPaymentStateInterface[]) => {
    return {
        type: ClientPaymentStatesType.GET_CLIENT_PAYMENT_STATES,
        payload: clientPaymentStates,
    };
};

export const getClientPaymentStatesAction = () => async (dispatch: Function) => {
    const clientPaymentStates = await getClientPaymentStates();
    if (clientPaymentStates?.data) {
        dispatch(setClientPaymentStates(clientPaymentStates?.data));
    }
};