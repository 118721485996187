import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import lodash from "lodash";
import "./ServiceAgreementForm.css";

import {
    createServiceAgreement,
    editServiceAgreement,
    findServiceAgreementById,
} from "../../../../Api/Documents/ServiceAgreements";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {getLiveSearchClient} from "../../../../Api/CRM/Clients";
import {getEmployees} from "../../../../Api/Administration/Employees";
import {useForm} from "../../../../../Core/hooks";
import {useBranch} from "../../../../hooks";
import {getServiceAgreementStatesAction} from "../../../../Store/Documents/ServiceAgreements/Actions";

import Form from "../../../../../Core/Components/Forms/Form";
import Input from "../../../../../Core/Components/Forms/Elements/Input";
import FormStickyContainer from "../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import DropDown, {prepareDropDownOptions} from "../../../../../Core/Components/Forms/Elements/DropDown";
import LiveSearch, {prepareLiveSearchOptions} from "../../../../../Core/Components/Forms/Elements/LiveSearch";
import FormFieldset from "../../../../../Core/Components/Forms/Elements/FormFieldset/FormFieldset";
import Fieldset from "../../../../../Core/Components/Forms/Elements/Fieldset";
import FormHR from "../../../../../Core/Components/Forms/Elements/FormHR";
import Textarea from "../../../../../Core/Components/Forms/Elements/Textarea";

import {ServiceAgreementFormPropsInterface} from "./ServiceAgreementFormInterface";
import {LiveSearchOptionInterface} from "../../../../../Core/Components/Forms/Elements/LiveSearch/LiveSearchInterface";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {
    formMode,
    FormControlStateInterface
} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {StateIdEnum} from "../../../../Api/Directories/ServiceAgreements/States/StatesInterface";
import {EmployeeInterface} from "../../../../Api/Administration/Employees/EmployeesInterface";


const ServiceAgreementForm = (props: ServiceAgreementFormPropsInterface) => {
    const {
        mode,
        setFormMode,
        serviceAgreementId,
        onAfterSuccessHandle,
        formControlProp,
        clientId,
    } = props;

    const {
        selectedEmployeeWorkingBranch,
        availableBranches,
    } = useBranch();

    const serviceAgreementStates = useSelector((store: StoreInterface) => store.shortCache.serviceAgreementStates);
    const dispatch = useDispatch();

    // simple form
    const {mapStateForm, setMapStateForm} = useForm();
    const prevMapStateFormData = useRef(mapStateForm?.form.data); // для глибокого порівняння об'єктів в useEffect
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        clientId,
    });
    const [formControlState, setFormControlState] = useState<FormControlStateInterface>({
        fields: {
            code: {
                required: mode !== formMode.create,
                disabled: true,
            },
            stateId: {
                required: true,
                disabled: mode === formMode.create,
            },
            clientId: {
                required: true,
                disabled: mode === formMode.edit,
            },
            branchId: {
                required: true,
            },
            employeeId: {
                required: true,
            },
        },
    });

    const [branchEmployees, setBranchEmployees] = useState<EmployeeInterface[]>([]);


    useEffect(() => {
        !serviceAgreementStates?.length && dispatch(getServiceAgreementStatesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mode && updateFormControlState({
            fields: {
                code: {required: mode !== formMode.create},
                stateId: {disabled: mode === formMode.create},
            },
        });

        mode === formMode.create && setFormData({
            branchId: selectedEmployeeWorkingBranch?.id,
            stateId: StateIdEnum.Created,
        });

        updateFormControlState({
            fields: {
                clientId: {disabled: mode === formMode.edit},
            },
        });
    }, [mode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (serviceAgreementId && mode !== formMode.create) {
            findServiceAgreementById(serviceAgreementId).then(response => {
                if (response?.data) {
                    setFormData(response.data);
                }
            });
        }
    }, [serviceAgreementId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!lodash.isEqual(prevMapStateFormData.current, mapStateForm?.form.data)) {
            mapStateForm?.form.data && setFormData(prevState => {
                prevMapStateFormData.current = mapStateForm?.form.data;

                return {
                    ...prevState,
                    ...mapStateForm?.form.data,
                };
            });
        }
    }, [mapStateForm?.form.data]);


    // для зміни formControl ззовні через проп
    useEffect(() => {
        formControlProp && updateFormControlState(formControlProp);
    }, [formControlProp]);

    useEffect(() => {
        toggleFieldsDisabled(!formData.branchId);
        formData.branchId && fetchBranchDataById(formData.branchId);
    }, [formData.branchId]); // eslint-disable-line react-hooks/exhaustive-deps


    function fetchBranchDataById(branchId: number | string) {
        getEmployees({filters: {branchId}})
            .then(response => {
                response?.data && setBranchEmployees(response?.data);
            });
    }

    // змінює disabled полів, які залежать від branchId
    function toggleFieldsDisabled(disabled: boolean) {
        updateFormControlState({
            fields: {
                employeeId: {disabled},
                visitId: {disabled},
            },
        });
    }

    function updateFormControlState(updatedState: FormControlStateInterface) {
        setFormControlState(prevState => {
            return lodash.merge({}, prevState, updatedState);
        });
    }

    const loadClientOptions = async (inputValue: string): Promise<LiveSearchOptionInterface[] | []> => {
        return getLiveSearchClient(inputValue).then(response => {
            return prepareLiveSearchOptions(response?.data, "id", "clientName");
        });
    };

    const onAfterBranchIdHandler = ({value}: { value: number | string }) => {
        setFormData(prevState => ({
            ...prevState,
            employeeId: null,
            branchId: value ? value : null,
        }));

        toggleFieldsDisabled(!value);

        updateFormControlState({
            fields: {
                employeeId: {disabled: !value},
            },
        });
    };

    const onSubmitHandler = (data: any, name: string) => {
        const params = {
            stateId: data.stateId,
            branchId: data.branchId,
            employeeId: data.employeeId,
            note: data.note,
            clientId: data.clientId,
        };

        if (name === "create") {
            createServiceAgreement(params).then(async (response) => {
                if (response?.data) {
                    setFormData(response.data);
                    addAlert({
                        type: "success",
                        message: response.message
                    });
                    setFormMode(formMode.edit);
                    onAfterSuccessHandle && onAfterSuccessHandle();
                }
            });
        }

        if (name === "save") {
            editServiceAgreement(data.id, params).then(async (response) => {
                if (response?.status === "success") {
                    onAfterSuccessHandle && onAfterSuccessHandle();
                    addAlert({
                        type: "success",
                        message: response.message
                    });
                }
            });
        }
    };


    return <>
        <Form
            onSubmit={onSubmitHandler}
            data={formData}
            mode={mode}
            controlState={formControlState}
            mapStateForm={setMapStateForm}
        >


            <FormFieldset name="client">
                <Fieldset title="Інформація про клієнта"/>

                <LiveSearch
                    name="clientId"
                    loadOptions={loadClientOptions}
                    visibleLabel={"clientName" in formData ? formData.clientName : ""}
                    title="Клієнт"
                />

                <FormHR type="dashed"/>
            </FormFieldset>

            <FormFieldset name="agreement">
                <Fieldset title="Інформація про договір"/>

                <div className="row">
                    <div className="col-12 col-sm-6">
                        <Input
                            type="text"
                            name="code"
                            title="Номер договору"
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <DropDown
                            options={prepareDropDownOptions(serviceAgreementStates, "id", "name")}
                            name="stateId"
                            title="Стан"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6">
                        <DropDown
                            options={prepareDropDownOptions(availableBranches, "id", "name")}
                            name="branchId"
                            title="Філія"
                            afterChangeHandler={onAfterBranchIdHandler}
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <DropDown
                            name="employeeId"
                            options={prepareDropDownOptions(branchEmployees, "id", "userName")}
                            title="Співробітник"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Textarea
                            name="note"
                            title="Коментар"
                        />
                    </div>
                </div>
            </FormFieldset>


            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create ? (
                            <Button
                                type="submit"
                                name="create"
                                title="Створити"
                                className="lynx-btn-primary"
                            />
                        ) : (
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                        )}
                    </FormStickyContainer>
                </>
            }
        </Form>
    </>
}

export default ServiceAgreementForm;
