import {AnyAction} from "redux";
import {ReloadTypes, ReloadsStateInterface, ReloadInterface} from "./Types";


const initialState: ReloadsStateInterface = {
    reloadPage: {},
    reloadComponent: {},
};


const reloads = (state: ReloadsStateInterface = initialState, action: AnyAction): ReloadsStateInterface => {
    const {type, payload} = action;
    const {RELOAD_PAGE, RELOAD_COMPONENT} = ReloadTypes;

    const obj: ReloadInterface = {};

    if ((type === RELOAD_PAGE || type === RELOAD_COMPONENT) && payload?.key) {
        obj[payload.key] = payload.value;
    }

    switch (type) {
        case RELOAD_PAGE:
            return {
                ...state,
                reloadPage: {
                    ...state.reloadPage,
                    ...obj,
                },
            };
        case RELOAD_COMPONENT:
            return {
                ...state,
                reloadComponent: {
                    ...state.reloadComponent,
                    ...obj,
                },
            };
        default:
            return state;
    }
};

export default reloads;