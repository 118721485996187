import React from "react";

export interface FormPropsInterface {
    onSubmit?(event: any, name?: string, obj?: any): void
    mapStateForm?(params: MapStateFormInterface): void,

    hiddenValues?: object,
    validate?: any,
    data?: any,
    mode?: formMode
    controlState?: FormControlStateInterface,
    isSubmitEmptyFields?: boolean,
}

export interface MapStateFormInterface {
    mode?: formMode,
    form: FormDataInterface,
    handlers: HandlersInterface,
    actions: FormActionsInterface,
}

export interface FormDataInterface {
    data?: { [key: string]: any },
    controlState?: FormControlStateInterface,
}

export interface HandlersInterface {
    onSubmitHandler(event: React.FormEvent, name: string): void,
}

export interface FormActionsInterface {
    clear(): void,
    validated(): Promise<{}>,
}

export interface FormStateInterface {
    values: { [key: string | number]: any } | {} | any,
    fields: { [key: string | number]: any } | {} | any,
    controlState: FormControlStateInterface,
}

export interface FormFunctionInterface {
    clear(): void
}

export interface InitComponentInterface {
    beforeChangeHandler?(data: any): void,

    afterChangeHandler?(data: any): void,

    name: string,
    value: any,
    required: undefined | boolean,
}

export interface InitFieldsetInterface {
    name: string,
    visible: boolean | number,
}

export enum formMode {
    create = 'create',
    edit = 'edit',
    view = 'view',
    delete = 'delete',
}

export interface FieldInterface {
    disabled: boolean | number,
    required: boolean | number,
    visible: boolean | number,
}

export interface FieldsetInterface {
    visible: boolean | number,
}

export interface FormControlStateInterface {
    fields?: { [key: string | number]: FieldInterface } | {} | any,
    fieldset?: { [key: string | number]: FieldsetInterface } | {} | any,
}

export interface FormContextInterface {
    renderChildren(markdown: React.ReactElement | null): any | void,

    setMapStateForm(params?: any): MapStateFormInterface | void,
}