import {useEffect, useState} from "react";
import './BranchForm.css'

import {createBranch, deleteBranch, editBranch, findBranchById} from "../../../Api/Branches/Branch";
import {addAlert} from "../../../../Core/Store/Alert/Actions";
import {useLynxPermission} from "../../../../Modules/AdministrationUsers/hooks";

import Form from "../../../../Core/Components/Forms/Form";
import Input from "../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Textarea from "../../../../Core/Components/Forms/Elements/Textarea";

import {formMode} from "../../../../Core/Components/Forms/Form/FormInterface";
import {BranchFormPropsInterface} from "./BranchFormInterface";
import {BranchInterface} from "../../../Api/Branches/Branch/BranchInterface";


const BranchForm = (props: BranchFormPropsInterface) => {
    const {
        mode,
        branchId,
        onAfterSuccessHandle,
    } = props;


    const [permission] = useLynxPermission();
    const [branch, setBranch] = useState<BranchInterface>();


    useEffect(() => {
        if (branchId) {
            findBranchById(branchId).then(response => {
                response?.data && setBranch(response.data);
            });
        }
    }, [branchId]);


    const onDeleteHandler = () => {
        if (branchId) {
            deleteBranch(branchId)
                .then(response => {
                    if (onAfterSuccessHandle && response?.status === 'success') {
                        onAfterSuccessHandle({
                            operation: "delete",
                        });
                        addAlert({
                            type: "success",
                            message: response.message
                        });
                    }
                });
        }
    };

    const onSubmitHandler = (data: any, name: string) => {
        if (name === 'create') {
            createBranch(data)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response?.data) {
                        onAfterSuccessHandle({
                            branchId: response.data.id,
                            operation: "create",
                        });
                        addAlert({
                            type: "success",
                            message: response.message
                        });
                    }
                });
        }

        if (name === 'save') {
            const params = {
                name: data.name,
                address: data.address,
                description: data.description
            };

            editBranch(data.id, params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response?.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message
                        });
                    }
                });
        }
    };

    return <>
        <Form onSubmit={onSubmitHandler} data={branch} mode={mode}>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="name"
                        title="Назва"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="address"
                        title="Адреса"
                        required={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Textarea
                        name="description"
                        title="Опис"
                        required={true}
                    />
                </div>
            </div>
            <br/>
            <FormStickyContainer>
                {mode === formMode.create ? (
                    <Button
                        type="submit"
                        name="create"
                        title="Створити"
                        className="lynx-btn-primary"
                    />
                ) : (
                    <div className="button-container">
                        <Button
                            type="submit"
                            name="save"
                            title="Зберегти"
                            className="lynx-btn-primary"
                        />
                        {permission.isDelete('branch') && <Button
                            onClick={onDeleteHandler}
                            name="delete"
                            icon="fa-duotone fa-trash-can"
                        />
                        }
                    </div>
                )}
            </FormStickyContainer>
        </Form>
    </>
}

export default BranchForm
