import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {createCustomerGroup, editCustomerGroup} from "../../../../../Api/CRM/Marketing/CustomerGroups";
import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Textarea from "../../../../../../Core/Components/Forms/Elements/Textarea";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {CustomerGroupFormInterface} from "./CustomerGroupFormInterface";

export default function CustomerGroupForm({onAfterSuccessHandle, clientSourceData, mode}: CustomerGroupFormInterface) {
    const onSubmitHandler = (data: any, name: string) => {
        if (name === 'create') {
            createCustomerGroup(data)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        })
                    }
                });
        }

        if (name === 'save' && clientSourceData) {
            const params = {
                name: data.name,
                description: data.description,
            }

            editCustomerGroup(data.id, params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        })
                    }
                });
        }
    };

    return (
        <Form onSubmit={onSubmitHandler} data={clientSourceData} mode={mode}>
            <div className="row">
                <Input
                    type="text"
                    name="name"
                    title="Назва"
                    required={true}
                />
            </div>
            <div className="row">
                <Textarea
                    name="description"
                    title="Опис"
                />
            </div>


            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create ? (
                            <Button
                                type="submit"
                                name="create"
                                title="Створити"
                                className="lynx-btn-primary"
                            />
                        ) : (
                            <div className="button-container">
                                <Button
                                    type="submit"
                                    name="save"
                                    title="Зберегти"
                                    className="lynx-btn-primary"
                                />
                            </div>
                        )}
                    </FormStickyContainer>
                </>
            }
        </Form>
    );
};
