import {AnyAction} from "redux";
import {SelectedEmployeeWorkingBranchStateType, SelectedEmployeeWorkingBranchTypes} from "./Types";


const selectedEmployeeWorkingBranch = (
    state: SelectedEmployeeWorkingBranchStateType = null, action: AnyAction
): SelectedEmployeeWorkingBranchStateType => {
    const {type, payload} = action;
    switch (type) {
        case SelectedEmployeeWorkingBranchTypes.SET_SELECTED_EMPLOYEE_WORKING_BRANCH:
            return payload;
        default:
            return state;
    }
};

export default selectedEmployeeWorkingBranch;