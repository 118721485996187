import * as jdenticon from "jdenticon";
import "./Avatar.css";
import {AvatarPropsInterface} from "./AvatarInterface";


export default function Avatar(props: AvatarPropsInterface) {
    const {
        seed,
        caller,
        size = 40,
        className,
        title = "",
        config = {},
    } = props;

    let hash: string;

    const hashFromSessionStorage = sessionStorage.getItem(`default_avatar_${caller}_${seed}`);

    if (hashFromSessionStorage) {
        hash = hashFromSessionStorage;
    } else {
        const newHash = jdenticon.toSvg(
            seed,
            size,
            {
                backColor: "transparent",
                padding: 0,
                ...config,
            }
        );

        hash = newHash;
        sessionStorage.setItem(`default_avatar_${caller}_${seed}`, newHash);
    }


    const avatarClassName = ["lynx-avatar"];
    className && avatarClassName.push(className);


    return (
        <div
            dangerouslySetInnerHTML={{__html: hash}}
            className={avatarClassName.join(" ")}
            title={title}
        />
    );
}