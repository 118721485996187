import React, {useEffect, useState} from 'react';
import './Grid.css'
import {GridPropsInterface} from "./GridInterface";
import SearchGrid from "../SearchGrid";
import GridCheckbox from "../GridCheckbox";
import ButtonActions from "../../../ButtonActions";
import {Scrollbars} from "react-custom-scrollbars-2";
import {Actions} from "../../../ButtonActions/ButtonActionsInterface";
import {addAlert} from "../../../../Store/Alert/Actions";
import GridPagination from "../GridPagination";
import {GridPaginationInterface} from "../GridPagination/GridPaginationInterface";

export default function Grid(props: GridPropsInterface) {

    const {
        title,
        data,
        actions = [],
        navActions = [],
        onLoadDataHandler,
        onCheckedItemsHandlers,
        pagination,
        isHideNavigation = false,
        isHidePadding = false,
    } = props;

    const [countPage, setCountPage] = useState('10');
    const [checkedItems, setCheckedItems] = useState<any[]>([]);


    useEffect(() => {
        onCheckedItemsHandlers && onCheckedItemsHandlers(checkedItems);
    }, [checkedItems]); // eslint-disable-line react-hooks/exhaustive-deps


    const defaultWidthColumn = '200px';

    let scrollWidth = title.map((item) => {
        return item.width ?? defaultWidthColumn;
    }).join(' + ');


    const navActionsDefault: Actions[] = [
        {
            title: 'Імпорт з Excel',
            name: 'importFromExcel',
            action: () => {
                addAlert({
                    type: 'warning',
                    message: 'Функція в розробці'
                });
            }
        },
        {
            title: 'Імпорт з CSV',
            name: 'importFromCSV',
            action: () => {
                addAlert({
                    type: 'warning',
                    message: 'Функція в розробці'
                });
            }
        }
    ];

    const perPageHandler = (count: number) => {
        setCountPage(String(count));
        onLoadDataHandler && onLoadDataHandler({
            ...pagination,
            perPage: count,
            currentPage: 1
        } as GridPaginationInterface);
        setCheckedItems([]);
    }

    const navCountPage: Actions[] = [
        {
            title: '10 рядків',
            name: 'count10',
            action: () => {
                perPageHandler(10)
            }
        },
        {
            title: '50 рядків',
            name: 'count50',
            action: () => {
                perPageHandler(50)
            }
        },
        {
            title: '100 рядків',
            name: 'count100',
            action: () => {
                perPageHandler(100)
            }
        }
    ];


    let searchHandler = () => {
        addAlert({
            type: 'warning',
            message: 'Функція в розробці'
        });
    }

    const onItemCheckboxClick = (item: { [key: string]: any }) => {
        const isCheckedItem = !!checkedItems.length && checkedItems.some(checkedItem => checkedItem.id === item.id);

        setCheckedItems(prevState => {
            if (isCheckedItem) {
                return prevState.filter(checkedItem => checkedItem.id !== item.id);
            } else {
                return [...prevState, item];
            }
        });
    };

    const onAllItemCheckboxClick = () => {
        setCheckedItems(data.length === checkedItems.length ? [] : data);
    };

    const _onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setCheckedItems([]);
        onLoadDataHandler && onLoadDataHandler(pagination);
    };

    const gridClassName = ["lynx-grid"];
    isHidePadding && gridClassName.push("lynx-grid--hide-padding");

    const tableCenterChildren = (
        <>
            <div className="lynx-grid-table-head" style={{width: `calc(${scrollWidth})`}}>
                {title.map((item) => {
                    return <span
                        key={item.code}
                        className="lynx-grid-table-head__title"
                        style={{width: item.width ?? defaultWidthColumn}}>
                                    {item.title}
                                </span>

                })}
            </div>
            <div className="lynx-grid-table-body">
                {data && data.map((row) => {
                    return <div key={row.id} className="lynx-grid-table-body-row"
                                style={{width: `calc(${scrollWidth})`}}>
                        {title.map((item) => (
                            <span
                                key={item.code}
                                className="lynx-grid-table-body__text"
                                style={{width: item.width ?? defaultWidthColumn}}
                            >
                                {item.handler ? item.handler(row[item.code], row) : row[item.code]}
                            </span>
                        ))}
                    </div>
                })}
            </div>
        </>
    );


    return <div className={gridClassName.join(" ")}>
        {!isHideNavigation &&
            <div className="lynx-grid-top-navigation">
                <SearchGrid searchHandler={searchHandler}/>
                <ButtonActions
                    actions={[...navActions, ...navActionsDefault]}
                    className="lynx-button-action__button-md"
                    icon="fa-duotone fa-lightbulb-exclamation-on"
                />
            </div>
        }

        <div className="lynx-grid-table">
            <div className="lynx-grid-table-left">
                <div className="lynx-grid-table-head">
                    <span className="lynx-grid-table-head__checkbox" onClick={onAllItemCheckboxClick}>
                        <GridCheckbox checked={!!data.length && data.length === checkedItems.length}/>
                    </span>
                </div>
                <div className="lynx-grid-table-body">
                    {data && data.map((item) => {
                        return <div key={item.id} className="lynx-grid-table-body-row">
                            <span
                                className="lynx-grid-table-body__checkbox"
                                onClick={() => onItemCheckboxClick(item)}
                            >
                                <GridCheckbox checked={checkedItems.some(checkedItem => checkedItem.id === item.id)}/>
                            </span>
                        </div>
                    })}
                </div>
            </div>


            <div className="lynx-grid-table-center" style={{overflowX: 'auto'}}>
                {data.length
                    ? <Scrollbars>{tableCenterChildren}</Scrollbars>
                    : <div style={{overflowX: "hidden"}}>{tableCenterChildren}</div>
                }
            </div>

            <div className="lynx-grid-table-right">
                <div className="lynx-grid-table-head">
                    {actions && <span className="lynx-grid-table-head__action"></span>}
                </div>
                <div className="lynx-grid-table-body">
                    {data && data.map((item) => {
                        return <div key={item.id} className="lynx-grid-table-body-row">
                            <div className="lynx-grid-table-body__action">
                                <ButtonActions
                                    data={item}
                                    actions={actions}
                                    className="lynx-button-action__button-sm"
                                    icon="fa-solid fa-ellipsis"
                                />
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>

        {!data.length && <div className="lynx-grid-not-found-data">
            <i className="fa-duotone fa-memo-circle-info lynx-grid-not-found-data__icon"/>
            <span className="lynx-grid-not-found-data__text">Дані відсутні</span>
        </div>}
        {data.length > 0 && pagination && onLoadDataHandler && <div className="lynx-grid-bottom">
            <div className="lynx-grid-bottom-count-page">
                <ButtonActions
                    actions={navCountPage}
                    className="lynx-button-action__button-sm"
                    title={countPage}
                />
            </div>
            <div className="lynx-grid-bottom-navigation">
                <GridPagination pagination={pagination} onLoadDataHandler={_onLoadDataHandler}/>
            </div>
        </div>}
    </div>
}