import React, {useRef, useState, useContext, useEffect} from "react";
import {NavLink} from "react-router-dom";
import {useBoundaryCheck} from "../../../../../hooks";
import {NestedLevelTinyPropsInterface} from "./NestedLevelTinyInterface";
import './NestedLevelTiny.css'
import {useOutsideClick} from "rooks";
import {MainMenuTinyContext} from "../MainMenuTiny/MainMenuTinyContext";
import {PositionEnum, PositionDataInterface} from "../../../../../hooks/hooksInterface";

export default function NestedLevelTiny(props: NestedLevelTinyPropsInterface) {
    const {icon, title, items, level, closePreviousLevel} = props;

    const {scrollScrollbars} = useContext(MainMenuTinyContext);

    const [isOpen, setStateOpen] = useState(false);

    // -- start -- визначення координат submenu
    const submenuItemRef = useRef<HTMLLIElement>(null);
    const submenuListRef = useRef<HTMLUListElement | null>(null);
    const [submenuListRefState, setSubmenuListRefState] = useState<any>(null);
    const [positionData, setPositionData] = useState<PositionDataInterface | null>(null);
    useBoundaryCheck(submenuItemRef, submenuListRefState, setPositionData, [scrollScrollbars]);

    useEffect(() => {
        // для useBoundaryCheck ref треба брати з submenuListRefState,
        // так як при 1му рендері isOpen = false і ref в useBoundaryCheck буде передаватись null
        if (isOpen && submenuListRef?.current) {
            setSubmenuListRefState(submenuListRef);

            return () => {
                setSubmenuListRefState(null);
            }
        }
    }, [isOpen]);
    // -- end -- визначення координат submenu

    useOutsideClick(submenuItemRef, () => {
        setStateOpen(false);
    });

    const onClickHandler = () => {
        setStateOpen(!isOpen);
    };

    const getStyleBySubmenu = (positionData: PositionDataInterface | null) => {
        if (!positionData) {
            return {
                opacity: 0,
            }
        }

        const signTop = positionData.openingDirections.directionY === PositionEnum.Top ? 1 : -1;

        return {
            opacity: 1,
            top: positionData.coordinates.top + signTop * 40,
            left: positionData.coordinates.left + 40,
        };
    };

    const submenuItemInnerClass = ["lynx-tiny-submenu"];
    level === 1 && isOpen && submenuItemInnerClass.push("lynx-tiny-submenu--active-level-1");
    level !== 1 && isOpen && submenuItemInnerClass.push("lynx-tiny-submenu--active");

    const submenuItemClass = ["lynx-tiny-submenu__item"]
    level === 1 && submenuItemClass.push("lynx-tiny-submenu__item--border-transparent");

    const submenuLinkClass = "lynx-tiny-submenu__link lynx-tiny-submenu__link--hover-nested-level";

    let row;

    if (items) {
        row = items.map((item, i) => {
            if (item.items) {
                return (
                    <NestedLevelTiny
                        key={`${item.link}-${item.title}-${i}`}
                        link={item.link}
                        title={item.title}
                        icon={item.icon}
                        items={item.items}
                        level={level + 1}
                        closePreviousLevel={() => setStateOpen(false)}
                    />
                )
            } else {
                return <li className="lynx-tiny-submenu__item" key={`${item.link}-${item.title}-${i}`}>
                    <NavLink
                        className={submenuLinkClass}
                        to={item.link}
                        key={item.link}
                        onClick={() => {
                            setStateOpen(false); // якщо клік відбувся на 1му левелі
                            closePreviousLevel && closePreviousLevel(); // якщо клік відбувся на левелі > 1
                        }}
                    >
                        <span>{item.title}</span>
                    </NavLink>
                </li>
            }
        })
    }


    return (
        <li ref={submenuItemRef} className={submenuItemClass.join(" ")}>
            <div className={submenuItemInnerClass.join(" ")}>
                {
                    level === 1
                        ? (icon && <button onClick={onClickHandler} className="lynx-tiny-submenu__icon" title={title}>
                            <i className={icon}/>
                        </button>)
                        : (
                            <button onClick={onClickHandler} className={submenuLinkClass}>
                                <span className="lynx-tiny-submenu__link-title">{title}</span>
                                {!isOpen
                                    ?
                                    (<i className="fa-regular fa-chevron-down lynx-tiny-submenu__icon--nested-level"/>)
                                    :
                                    (<i className="fa-regular fa-chevron-right lynx-tiny-submenu__icon--nested-level"/>)
                                }
                            </button>
                        )
                }
                {
                    isOpen && <ul
                        ref={submenuListRef}
                        className="lynx-tiny-submenu__list"
                        style={getStyleBySubmenu(positionData)}
                    >
                        {level === 1 && <span className="lynx-tiny-submenu__title">{title}</span>}
                        {row}
                    </ul>
                }
            </div>
        </li>
    )
}