import Big from "big.js"

import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {validateDecimalValue} from "../../../../../Core/Utils/Validations/NumericalValidations/NumericalValidations";

import {ChangeEvent, Dispatch, SetStateAction} from "react";
import {ServiceInterface} from "../../../../Api/Administration/ServiceManagement/Services/ServicesInterface";
import {
    ServiceGroupInterface,
} from "../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";
import {
    DualListSelectorDataItem,
    HandleSelectionChangeDataInterface,
} from "../../../../../Core/Components/DualListSelector/DualListSelector/DualListSelectorInterface";
import {
    CertificateServiceInterface,
    CertificatesServiceInterface,
} from "../../../../Api/Documents/CertificatesServices/CertificatesServicesInterface";
import {
    PrepareSelectedDataGroupItemInterface,
    PrepareSelectedDataReturnInterface
} from "./CertificatesServicesBlockInterface";


// зміна структури даних для послуг, які доступні для вибору
export const prepareDualListSelectorData = (
    services: ServiceInterface[],
    serviceGroups: ServiceGroupInterface[],
): DualListSelectorDataItem[] => {
    return serviceGroups.map(group => {
        const groupWithServices: DualListSelectorDataItem = {
            groupId: group.id,
            name: group.name,
            color: group.color,
            groupList: [],
        };

        services.forEach(service => {
            group.id === service.groupId && groupWithServices.groupList.push({
                id: service.id,
                name: service.name,
                fields: {
                    code: service.code,
                    price: service.price,
                },
            });
        });

        return groupWithServices;
    });
};

// зміна структури даних для послуг, які вибрані
export const prepareSelectedData = (
    certificatesServices: CertificatesServiceInterface[],
    serviceGroups: ServiceGroupInterface[],
): PrepareSelectedDataReturnInterface[] => {
    return serviceGroups.map(group => {
        const groupWithServices: PrepareSelectedDataReturnInterface = {
            groupId: group.id,
            name: group.name,
            color: group.color,
            groupList: [],
        };

        certificatesServices.forEach(service => {
            group.id === service.serviceGroupId && groupWithServices.groupList.push({
                id: service.id,
                serviceId: service.serviceId,
                name: service.serviceName,
                code: service.serviceCode,
                price: service.price,
                quantity: service.quantity,

            });
        });

        return groupWithServices;
    });
};

// додавання послуг з доступних
export const addService = (
    selectedServices: PrepareSelectedDataReturnInterface[],
    service: HandleSelectionChangeDataInterface,
): PrepareSelectedDataReturnInterface[] => {
    const serviceToAdd = {
        name: service.item.name,
        id: null,
        code: service.item.fields.code as string,
        serviceId: service.item.id,
        price: service.item.fields.price as number,
        quantity: 1,
    };

    for (let i = 0; i < selectedServices.length; i++) {
        const serviceGroup = selectedServices[i];
        const groupList = serviceGroup.groupList;

        if (serviceGroup.groupId === service.group.groupId) {
            // якщо послуга вже є в списку, то виводимо повідомлення
            if (groupList.some(item => item.name === service.item.name)) {
                addAlert({
                    type: "info",
                    message: "Послугу вже додано",
                });
                break;
            }

            groupList.push(serviceToAdd);
            break;
        }
    }

    // віддаємо новий масив, щоб посилання оновилось і можна було реагувати на зміну selectedServices
    return [...selectedServices];
}


// видалення послуги з вибраних
export const removeService = (
    selectedServices: PrepareSelectedDataReturnInterface[],
    service: {
        item: PrepareSelectedDataGroupItemInterface,
        group: Omit<PrepareSelectedDataReturnInterface, "groupList">,
    },
) => {
    for (let i = 0; i < selectedServices.length; i++) {
        const serviceGroup = selectedServices[i];
        const groupList = serviceGroup.groupList;

        if (serviceGroup.groupId === service.group.groupId) {
            const serviceIndex = groupList.findIndex(groupItem => groupItem.id === service.item.id);
            groupList.splice(serviceIndex, 1);
            break;
        }
    }

    // віддаємо новий масив, щоб посилання оновилось і можна було реагувати на зміну selectedServices
    return [...selectedServices];
};

export const calculationTotal = (
    selectedCertificatesServices: PrepareSelectedDataReturnInterface[],
    discount: number,
) => {
    let total = Big(0);

    selectedCertificatesServices.forEach(serviceGroup => {
        if (!serviceGroup.groupList.length) return;

        serviceGroup.groupList.forEach(item => {
            const sum = Big(item.quantity).times(Big(item.price));
            total = total.plus(sum);
        });
    });

    // враховуємо знижку
    const totalWithDiscount = total.times(Big(1).minus(Big(discount).div(100)));

    // округлюємо до 2х знаків після крапки
    return totalWithDiscount.toFixed(2);
};

// обробка change інпутів з quantity та price
export const onInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    selectedCertificatesServices: PrepareSelectedDataReturnInterface[],
    setSelectedCertificatesServices: Dispatch<SetStateAction<PrepareSelectedDataReturnInterface[]>>,
    groupId: number | string,
    serviceId: number | string
) => {
    // створюємо новий масив, щоб не мутувати старий
    const prevCertificatesServices = [...selectedCertificatesServices];
    const name = e.target.name;
    const value = e.target.value;
    const inputValueToNumber = Number(value);


    for (let i = 0; i < prevCertificatesServices.length; i++) {
        const serviceGroup = prevCertificatesServices[i];
        const groupList = serviceGroup.groupList;

        if (serviceGroup.groupId === groupId) {
            const serviceIndex = groupList.findIndex(groupItem => groupItem.serviceId === serviceId);
            // кількість
            if (name === "quantity" && Number.isInteger(inputValueToNumber) && inputValueToNumber >= 0) {
                groupList[serviceIndex].quantity = inputValueToNumber;
            }
            // ціна
            else if (name === "price") {
                const valueWithDot = value.replace(",", ".");

                 const validatedPrice = validateDecimalValue(valueWithDot);

                if (validatedPrice) {
                    // записуємо value в форматі string, щоб можна було поставити крапку в десятковому дробу
                    // при події інпута blur змінюємо тип на потрібний - number
                    groupList[serviceIndex].price = valueWithDot as unknown as number;
                } else if (value === "") {
                    groupList[serviceIndex].price = 0 as unknown as number;
                }
            }
        }
    }

    setSelectedCertificatesServices(prevCertificatesServices);
};

// обробка blur price(використовується виключно для зміни типу price в списку вибраних послуг для submit)
// при change тип буде string, щоб можна було вводити крапку в полі price
export const onInputPriceBlur = (
    e: ChangeEvent<HTMLInputElement>,
    selectedCertificatesServices: PrepareSelectedDataReturnInterface[],
    setSelectedCertificatesServices: Dispatch<SetStateAction<PrepareSelectedDataReturnInterface[]>>,
    groupId: number | string,
    serviceId: number | string
) => {
    // створюємо новий масив, щоб не мутувати старий
    const prevCertificatesServices = [...selectedCertificatesServices];

    for (let i = 0; i < prevCertificatesServices.length; i++) {
        const serviceGroup = prevCertificatesServices[i];
        const groupList = serviceGroup.groupList;

        if (serviceGroup.groupId === groupId) {
            const serviceIndex = groupList.findIndex(groupItem => groupItem.serviceId === serviceId);
            groupList[serviceIndex].price = Number(e.target.value);
        }
    }

    setSelectedCertificatesServices(prevCertificatesServices);
};

// зміна структури даних для вибраних послуг, які потрібно відправити на бек
export const changeSelectedServicesForSubmit = (
    selectedCertificatesServices: PrepareSelectedDataReturnInterface[]
): CertificateServiceInterface[] | [] => {
    const services: CertificateServiceInterface[] = [];

    selectedCertificatesServices.forEach(serviceGroup => {
        if (!serviceGroup.groupList.length) return;

        serviceGroup.groupList.forEach(groupItem => {
            services.push({
                id: groupItem.id,
                serviceId: groupItem.serviceId,
                price: groupItem.price,
                quantity: groupItem.quantity,
            });
        });
    });

    return services;
};