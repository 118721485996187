import moment from "moment";
import {CalendarForWeekInterface} from "../../../../Api/Calendars/CalendarsInterface";


// специфічна функція для календаря на тиджень, щоб уникнути лишньої ітерації, в загальні хелперси не виносити
export const getCurrentWeekNumber = (dates: CalendarForWeekInterface[]): number | null => {
    const today = moment();
    const currentDateString = today.format("YYYY-MM-DD");

    const containsCurrentDate = dates.some((date) => date.date === currentDateString);

    if (containsCurrentDate) {
        return today.day() === 0 ? 7 : today.day(); // неділя = 0
    }

    return null;
};