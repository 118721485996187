import {AdministrationUsers} from "./Types";
import {getProfile} from "../../../Auth/Api/Users/Profile";
import {ProfileInterface} from "../../../Auth/Api/Users/Profile/ProfileInterface";

//Установка профиля пользователя
export const setProfile = (profile: ProfileInterface | {}) => {
    return {
        type: AdministrationUsers.SET_PROFILE,
        payload: profile
    }
}

//Получение профиля пользователя
export const getProfileAction = () => async (dispatch: Function) => {
    let profile = await getProfile();
    if (profile?.data) {
        dispatch(setProfile(profile.data));
    }
};

export const resetProfileAction = () => (dispatch: Function) => {
    dispatch(setProfile({}));
};