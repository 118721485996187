import useBranch from "./useBranch";
import useLynxTariffPlan from "./useLynxTariffPlan";


export {
    useBranch,
    useLynxTariffPlan,
}
const hooks = {
    __esModule: true,
    useBranch,
    useLynxTariffPlan,
}

export default hooks;