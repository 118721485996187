import {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import "./FileUpload.css";
import {addAlert} from "../../../../Store/Alert/Actions";
import {FileUploadPropsInterface} from "./FileUploadInterface";


export default function FileUpload(props: FileUploadPropsInterface) {
    const {
        name,
        value,
        className,
        disabled,
        required,

        initComponent,
        changeHandler,
        beforeChangeHandler,
        afterChangeHandler,

        maxFiles,
        dropzoneText,
        acceptFiles,
        maxSize,
        minSize,
    } = props;


    const [files, setFiles] = useState<any[]>([]);


    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            });
        }
    }, [initComponent, required, beforeChangeHandler, afterChangeHandler]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (changeHandler) {
            changeHandler({
                name,
                value: files,
            });
        }
    }, [changeHandler, files]); // eslint-disable-line react-hooks/exhaustive-deps


    const onDrop = (acceptedFiles: any[]) => {
        // перевірка на кількість файлів
        if (maxFiles && (files.length + acceptedFiles.length) > maxFiles) {
            addAlert({
                type: "warning",
                message: `Ви не можете додати більше, ніж ${maxFiles} файлів.`,
            });
            return;
        }

        const uniqueFiles: any[] = [];
        const duplicates: any[] = [];

        acceptedFiles.forEach((file: any) => {
            if (!files.some(existingFile => existingFile.name === file.name)) {
                uniqueFiles.push(file);
            } else {
                duplicates.push(file);
            }
        });

        // повідомлення про дублікати
        if (duplicates.length > 0) {
            duplicates.forEach(file => {
                addAlert({
                    type: "warning",
                    message: `Файл "${file.name}" вже додано.`,
                });
            });
        }

        // додаємо нові унікальні файли
        if (uniqueFiles.length > 0) {
            setFiles(prevFiles => [...prevFiles, ...uniqueFiles]);
        }
    };

    const onDropRejected = (rejectedFiles: any[]) => {
        rejectedFiles.forEach(item => {
            item.errors.forEach((error: any) => {
                if (error.code === "file-too-large") {
                    addAlert({
                        type: "warning",
                        message: `Розмір файлу "${item.file.name}" перевищує ${maxSize} байт`
                    });
                }

                if (error.code === "file-too-small") {
                    addAlert({
                        type: "warning",
                        message: `Розмір файлу "${item.file.name}" менше, ніж ${minSize} байт`
                    });
                }
            });
        });
    }

    const removeFile = (fileToRemove: any) => {
        setFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    };

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop,
        onDropRejected,
        disabled,
        maxSize,
        minSize,
        multiple: true,
        accept: acceptFiles,
    });

    const fileUploadClassName = ["lynx-file-upload"];
    disabled && fileUploadClassName.push("disabled");
    className && fileUploadClassName.push(className);


    return (
        <div className={fileUploadClassName.join(" ")}>
            <div {...getRootProps({className: "lynx-file-upload__dropzone"})}>
                <input {...getInputProps()} data-testid="dropzone-input"/>
                <div className="lynx-file-upload__dropzone-text">
                    {dropzoneText ? dropzoneText : "Перетягніть файл або натисніть, щоб вибрати"}
                </div>
            </div>
            {!!files.length &&
                <div className="lynx-file-upload__files">
                    <div className="lynx-file-upload__files-title">Файли:</div>
                    <ul className="lynx-file-upload__files-list">
                        {files.map(file => (
                            <li className="lynx-file-upload__files-item" key={file.name}>
                                <span className="lynx-file-upload__files-item-text">
                                    <i className="fa-duotone fa-file"></i>&nbsp;
                                    {file.name} - {((file.size / 1024) / 1024).toFixed(2)} MБ
                                </span>
                                {!disabled &&
                                    <div
                                        className="lynx-file-upload__files-item-btn"
                                        onClick={() => removeFile(file)}
                                        data-testid={`remove-btn-${file.name}`}
                                    >
                                        <i className="fa-solid fa-xmark fa-xs"></i>
                                    </div>
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </div>
    );
}