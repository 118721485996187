import "./CalendarPage.css";

import {useLynxPermission} from "../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle} from "../../../../Core/hooks";

import BreadCrumbContainer from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import CalendarsContainer from "../../../Components/Calendars/CalendarsContainer";

import {CalendarFiltersInterface} from "../../../Components/Calendars/CalendarsContainer/CalendarsContainerInterface";


export default function CalendarPage() {
    usePageTitle("Календар");
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();


    const onFilterHandler = (filters: CalendarFiltersInterface) => {
        setSearchParams({
            branchId: filters.branchId,
            employees: filters.employees,
            year: filters.date.year,
            period: filters.date.period,
            value: filters.date.value,
        });
    };


    return (
        <>
            <div className="lynx-calendar-page">
                <div className="lynx-calendar-page__bread-crumb">
                    <BreadCrumbContainer>
                        <BreadCrumb title="Календар"/>
                    </BreadCrumbContainer>
                </div>

                {permission.isView("visits") &&
                    <CalendarsContainer
                        searchParams={searchParams}
                        onFilterHandler={onFilterHandler}
                    />
                }
            </div>
        </>
    );
}