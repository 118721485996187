import {useEffect, useState} from "react";
import moment from "moment/moment";

import {
    deleteVisitAction,
    editVisitAction,
    viewVisitAction
} from "./VisitsGridHelpers";
import {useLynxPermission} from "../../../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, useReloadComponent} from "../../../../../../Core/hooks";
import {getVisits} from "../../../../../Api/CRM/RecordManagement/Visits/Visits";
import {setReloadComponent} from "../../../../../../Core/Store/Reload/Actions";

import VisitModal from "../VisitModal";
import Grid from "../../../../../../Core/Components/Tables/Grids/Grid";
import VisitFilters from "../VisitFilters";

import {GridTitleInterface} from "../../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {VisitInterface} from "../../../../../Api/CRM/RecordManagement/Visits/Visits/VisitsInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {
    GridPaginationInterface
} from "../../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {VisitFiltersInterface} from "../VisitFilters/VisitFiltersInterface";
import {Actions} from "../../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {VisitsGridPropsInterface} from "./VisitsGridInterface";


export default function VisitsGrid(props: VisitsGridPropsInterface) {
    const {
        showFilters,
        filters = {},
        formControl,
        isHideNavigation,
        isHidePadding,
    } = props;

    const isReload = useReloadComponent("VisitsGrid");
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();

    const [visits, setVisits] = useState<any>({});
    const [visitData, setVisitData] = useState<VisitInterface>();
    const [pagination, setPagination] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState<formMode>(formMode.view);


    const gridTitle: GridTitleInterface[] = [
        {
            title: "Id",
            code: "id",
            width: "60px"
        },
        {
            title: "Клієнт",
            code: "clientName",
            width: "270px",
        },
        {
            title: "Дата візиту",
            code: "visitDate",
            width: "120px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY"),
        },
        {
            title: "Час початку візиту",
            code: "timeStart",
            width: "140px",
            handler: (value: string) => moment(value, "HH:mm:ss").format("HH:mm"),
        },
        {
            title: "Тривалість",
            code: "duration",
            width: "90px",
            handler: (value: string) => moment(value, "HH:mm:ss").format("HH:mm"),
        },
        {
            title: "Стан візиту",
            code: "statusName",
            width: "250px",
        },
        {
            title: "Місце обслуговування",
            code: "workplaceName",
            width: "250px",
        },
        {
            title: "Співробіник",
            code: "employeeName"
        },
        {
            title: "Ким створено",
            code: "userName"
        },
    ];


    useEffect(() => {
        if (isReload) {
            getVisits({filters: {...filters, ...searchParams}, pagination})
                .then(response => {
                    if (response?.status === "success") {
                        setVisits(response);
                    }

                    setReloadComponent("VisitsGrid", false);
                });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    // гарантуємо, що при наступному монтуванні isReload = true
    useEffect(() => () => setReloadComponent("VisitsGrid", true), []);


    const onFilterHandler = (data: VisitFiltersInterface) => {
        setSearchParams(data);
        setReloadComponent("VisitsGrid", true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setReloadComponent("VisitsGrid", true);
        setPagination(pagination);
    };

    const onSuccessHandler = () => {
        setReloadComponent("VisitsGrid", true);
        setReloadComponent("ClientLastVisitBlock", true);
        setIsModalOpen(false)
    };

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "viewVisit",
            isShow: permission.isView("visits"),
            functions: {setModalMode, setIsModalOpen, setVisitData},
            action: viewVisitAction,
        },
        {
            title: "Редагувати",
            name: "editVisit",
            classNameItem: "lynx-button-action-warning",
            isShow: permission.isEdit("visits"),
            functions: {setModalMode, setIsModalOpen, setVisitData},
            action: editVisitAction,
        },
        {
            title: "Видалити",
            name: "deleteVisit",
            classNameItem: "lynx-button-action-danger",
            isShow: permission.isDelete("visits"),
            functions: {onSuccessHandler},
            action: deleteVisitAction,
        },
    ];

    const gridMarkup = (
        <Grid
            data={(permission.isView("visits") && visits?.data) ? visits?.data : []}
            title={gridTitle}
            pagination={visits?.pagination || {}}
            onLoadDataHandler={onLoadDataHandler}
            actions={actions}
            isHideNavigation={isHideNavigation}
            isHidePadding={isHidePadding}
        />
    );


    return (
        <>
            {showFilters &&
                <div className="row">
                    <div className="col-12 order-2 col-xl-9 order-xl-1">
                        {gridMarkup}
                    </div>
                    <div className="col-12 order-1 col-xl-3 order-xl-2">
                        <VisitFilters searchParams={searchParams} onFilterHandler={onFilterHandler}/>
                    </div>
                </div>
            }

            {!showFilters && gridMarkup}


            <VisitModal
                isOpen={isModalOpen}
                mode={modalMode}
                visitData={visitData}
                formControl={formControl}
                onAfterCloseHandle={() => setIsModalOpen(false)}
                onAfterSuccessHandle={onSuccessHandler}
            />
        </>
    );
}