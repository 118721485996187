import moment from "moment/moment";
import "./TimeyableIntervalMessageForm.css";
import NamedContent from "../../../../../../../Core/Components/DataVisualization/NamedContent";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import {TimetableIntervalMessageFormPropsInterface} from "./TimetableIntervalMessageFormInterface";


export default function TimetableIntervalMessageForm(props: TimetableIntervalMessageFormPropsInterface) {
    const {
        messages,
        onAfterCloseHandler,
    } = props;


    const messagesToArray = Object.entries(messages).map(([date, message]) => ({
        name: moment(date, 'YYYYMMDD').format('DD.MM.YYYY'),
        content: <span>{message}</span>,
    }));


    return (
        <div className="lynx-timetable-interval-message-form">
            <NamedContent items={messagesToArray} firstColumnWidth="75px"/>
            <FormStickyContainer>
                <Button
                    name="close"
                    title="Закрити"
                    className="lynx-btn-primary"
                    onClick={onAfterCloseHandler}
                />
            </FormStickyContainer>
        </div>
    );
}
