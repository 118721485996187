import React from 'react';
import './MainMenu.css';
import {MainMenuPropsInterface} from "./MainMenuInterface";
import FirstLevel from "../FirstLevel";
import {Scrollbars} from "react-custom-scrollbars-2";

export default function MainMenu(props: MainMenuPropsInterface) {

    const {items} = props;

    return (
        <Scrollbars style={{height: `calc(100vh - 60px - 75px)`}}>
            <div className="lynx-menu-nav">
                <ul>
                    {items?.map((item, index) => {
                        return <FirstLevel
                            key={index}
                            link={item.link}
                            icon={item.icon}
                            title={item.title}
                            items={item.items ? item.items : null}
                            handler={item.handler}
                        />
                    })}
                </ul>
            </div>
        </Scrollbars>
    );
}

