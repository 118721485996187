import React from 'react';
import "./TabsLinkGeneralContainer.css"
import {TabsLinkGeneralContainerInterface} from "./TabsLinkGeneralContainerInterface";

export default function TabsLinkGeneralContainer({children}: TabsLinkGeneralContainerInterface) {
    return (
        <div className="lynx-tabs-link-general-container">
            <div className="lynx-tabs-link-general-container__left">{children[0]}</div>
            <div className="lynx-tabs-link-general-container__right">{children[1]}</div>
        </div>
    );
};