import {useEffect, useState} from "react";
import './JobPositionForm.css'

import {useLynxPermission} from "../../../../../../Modules/AdministrationUsers/hooks";
import {
    createJobPosition,
    deleteJobPosition,
    editJobPosition,
    getJobPositions,
} from "../../../../../Api/Administration/Directories/JobPositions";
import {addAlert} from "../../../../../../Core/Store/Alert/Actions";

import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Checkbox from "../../../../../../Core/Components/Forms/Elements/Checkbox";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";
import Fieldset from "../../../../../../Core/Components/Forms/Elements/Fieldset";
import FormHR from "../../../../../../Core/Components/Forms/Elements/FormHR";

import {JobPositionFormInterface} from "./JobPositionFormInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {JobPositionInterface} from "../../../../../Api/Administration/Directories/JobPositions/JobPositionsInterface";


const JobPositionForm = (props: JobPositionFormInterface) => {
    const {
        onAfterSuccessHandle,
        jobPositionData,
        branches,
        branchId: branchIdProp,
        mode,
    } = props;

    const initialStateForm = {
        isMultiple: {
            disabled: jobPositionData?.isManageBranch,
        },
        parentId: {
            disabled: jobPositionData?.isManageBranch,
            required: !jobPositionData?.isManageBranch,
        }
    };

    const [permission] = useLynxPermission();
    const [jobPositions, setJobPositions] = useState<JobPositionInterface[]>([]);
    const [jobPosition, setJobPosition] = useState<any>({});
    const [branchId, setBranchId] = useState(branchIdProp);
    const [form, setForm] = useState(initialStateForm);


    useEffect(() => {
        branchId && getJobPositions({filters: {branchId}}).then((response) => {
            if (response?.data) {
                jobPositionData?.id
                    ? setJobPositions(response.data.filter(jobPosition => jobPosition.id !== jobPositionData.id))
                    : setJobPositions(response.data);
            }
        });
    }, [branchId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (jobPositionData) {
            setJobPosition(jobPositionData);
            setForm(initialStateForm);
        }
    }, [jobPositionData]); // eslint-disable-line react-hooks/exhaustive-deps


    const onIsManageBranchAfterChangeHandler = (obj: any) => {
        const isManageBranch = obj.value;

        if (isManageBranch) {
            setJobPosition((prevState: any) => ({
                ...prevState,
                isMultiple: false,
                isManageBranch: true,
                parentId: null,
            }));

            setForm((prevState: any) => ({
                ...prevState,
                isMultiple: {
                    disabled: true,
                },
                parentId: {
                    disabled: true,
                    required: false,
                },
            }));

            return;
        }

        setJobPosition((prevState: any) => ({
            ...prevState,
            isMultiple: jobPositionData?.isMultiple,
            isManageBranch: false,
            parentId: jobPositionData?.parentId,
        }));

        setForm((prevState: any) => ({
            ...prevState,
            isMultiple: {
                disabled: false,
            },
            parentId: {
                disabled: false,
                required: true,
            },
        }));
    };

    const afterBranchIdChangeHandler = ({value}: {value: number}) => setBranchId(value);

    const onDeleteHandler = () => {
        if (jobPositionData && 'id' in jobPositionData) {
            deleteJobPosition(jobPositionData.id)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }
    };

    const onSubmitHandler = (data: any, name: string) => {
        const params = {
            ...data,
            isManageBranch: data.isManageBranch ? 1 : 0,
            isMultiple: data.isMultiple ? 1 : 0,
        }
        if (name === 'create') {
            createJobPosition(params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }

        if (name === 'save') {
            editJobPosition(params.id, params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }
    };


    return (
        <>
            <Form onSubmit={onSubmitHandler} mode={mode} data={{...jobPosition, branchId}}>
                <Fieldset title="Інформація про філію"/>

                <div className="row">
                    <div className="col-12">
                        <DropDown
                            name="branchId"
                            title="Філія"
                            options={prepareDropDownOptions(branches, "id", "name")}
                            required={true}
                            disabled
                            afterChangeHandler={afterBranchIdChangeHandler}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Checkbox
                            name="isManageBranch"
                            title="Управляючий філією"
                            disabled={jobPositionData?.isManageBranch}
                            afterChangeHandler={onIsManageBranchAfterChangeHandler}
                        />
                    </div>
                </div>

                <FormHR type="dashed"/>
                <Fieldset title="Інформація про посаду"/>

                <div className="row">
                    <div className="col-12 col-sm-6">
                        <Input
                            type="text"
                            name="name"
                            title="Назва посади"
                            required={true}
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <DropDown
                            name="parentId"
                            title="Підпорядковується"
                            options={prepareDropDownOptions(jobPositions, "id", "name")}
                            required={form.parentId?.required}
                            disabled={form.parentId?.disabled}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Checkbox
                            name="isMultiple"
                            title="Декілька людей на цій посаді"
                            disabled={form.isMultiple?.disabled}
                        />
                    </div>
                </div>

                <br/>

                <FormStickyContainer>
                    {mode === formMode.create ? (
                        <Button
                            type="submit"
                            name="create"
                            title="Створити"
                            className="lynx-btn-primary"
                        />
                    ) : (
                        <div className="lynx-job-position-form__btn-container">
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                            {permission.isDelete('branch') && !jobPositionData?.isManageBranch &&
                                <Button
                                    onClick={onDeleteHandler}
                                    name="delete"
                                    icon="fa-duotone fa-trash-can"
                                />
                            }
                        </div>
                    )}
                </FormStickyContainer>
            </Form>
        </>
    );
};

export default JobPositionForm;
