import {useEffect, useState} from "react";
import "./GroupPermissionPages.css";

import {getPermissionGroups} from "../../../Api/Permissions/GroupPermission";
import {usePageTitle, useReloadPage} from "../../../../../Core/hooks";
import {setReloadPage} from "../../../../../Core/Store/Reload/Actions";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import CardVisualisation from "../../../../../Core/Components/DataVisualization/CardVisualisation";
import GroupPermissionFormContainer
    from "../../../Components/Permissions/GroupPermissionForm/GroupPermissionFormContainer";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {
    ShortPermissionGroupInterface,
} from "../../../Api/Permissions/GroupPermission/GroupPermissionInterface";


export default function GroupPermissionPages() {
    usePageTitle("Групи прав");
    const isReload = useReloadPage("GroupPermissionPages");

    const [mode, setMode] = useState<formMode>();
    const [permissionGroups, setPermissionGroups] = useState<ShortPermissionGroupInterface[]>([]);
    const [permissionGroupId, setPermissionGroupId] = useState<number | string>();
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        return () => setReloadPage("GroupPermissionPages", true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isReload) {
            getPermissionGroups().then(response => {
                if (response?.data) {
                    setReloadPage("GroupPermissionPages", false);
                    setPermissionGroups(response?.data);
                }
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <BreadCrumbContainer>
            <BreadCrumb title="Групи прав" tree={['Адміністрування']}/>
            <div className="lynx-feeds-page-buttons">
                <Button
                    name="addGroup"
                    title="Додати групу"
                    className="lynx-btn-primary"
                    onClick={() => {
                      setMode(formMode.create);
                      setIsModalOpen(true);
                    }}/>
            </div>
        </BreadCrumbContainer>

        <br/>

        <div className="row lynx-group-permission-pages__items">
            {permissionGroups && permissionGroups.map((item) => {
                return (
                    <div key={item.id} className="col-12 col-md-6 col-xl-4 col-xxl-3">
                        <CardVisualisation
                            title={item.name}
                            description={`Усього користувачів у ролі: ${item.counterUser}`}
                            items={item.items}
                        >
                            <Button
                                name="edit"
                                title="Редагувати"
                                className="lynx-btn-primary"
                                onClick={() => {
                                    setPermissionGroupId(item.id);
                                    setMode(formMode.edit);
                                    setIsModalOpen(true);
                                }}
                            />
                        </CardVisualisation>
                    </div>
                )
            })}

        </div>

        {mode &&
            <ModalSimple
                isOpen={isModalOpen}
                body={
                    <GroupPermissionFormContainer
                        mode={mode}
                        setMode={(mode: formMode) => setMode(mode)}
                        permissionGroupId={permissionGroupId}
                        setPermissionGroupId={(id: (number | string)) => setPermissionGroupId(id)}
                        onSuccessHandler={() => setIsModalOpen(false)}
                    />}
                title={mode === formMode.create ? "Створення групи прав" : "Редагування групи прав"}
                onAfterCloseHandle={() => setIsModalOpen(false)}
            />
        }
    </>
}