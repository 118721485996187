import moment from "moment";
import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {TimetableTypesEnum} from "../../../../../../Api/Directories/TimetableTypes/TimetableTypesInterface";


export const dateRangeValidation = (startDate: string, endDate: string) => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const currentDate = moment();

    currentDate.add(2, 'months');

    if (!endDateMoment.isSameOrBefore(currentDate)) {
        const maxDate = currentDate.format("DD.MM.YYYY");
        return `Максимальна дата створення графіку - ${maxDate}`;
    }

    if (startDateMoment.isAfter(endDateMoment)) {
        return "Кінцева дата має бути більшою за початкову";
    }
};

export const timeRangeValidation = (params: any) => {
    const {workDayPeriodStart, workDayPeriodEnd} = params;

    if (workDayPeriodStart >= workDayPeriodEnd) {
        return "Кінець робочих годин має бути більшим за початок робочих годин";
    }

    // закоментована частина функції для перевірки чи не виходить вибраний час за межі графіку роботи філії
    // поки не реалізується, так як планується більш складний функціонал + бек
    // після розкоментування додати в параметри branchTimetable: TimetableInterface[]

    // if (!weekdays.length) {
    //     return;
    // }
    //
    // // ітерація по вибраним дням тижня
    // for (let i = 0; i < weekdays.length; i++) {
    //     const workDayId = weekdays[i];
    //
    //     const weekDayTimetable = branchTimetable.find(weekDayTimetable => weekDayTimetable.id === workDayId);
    //
    //     if (!weekDayTimetable) {
    //         continue;
    //     }
    //
    //     // перевіряємо чи робочий день співробітника не потрапляє на вихідний філії
    //     if (weekDayTimetable.isWeekend) {
    //         return "Неможливо створити графік за шаблоном для вихідного дня філії";
    //     }
    //
    //     // гарантуємо, що workDayStart та workDayEnd будуть string, так як перевірка буде тільки для робочого дня
    //     const workDayStart = weekDayTimetable.workDayStart as string;
    //     const workDayEnd = weekDayTimetable.workDayEnd as string;
    //
    //     if (workDayPeriodStart < workDayStart) {
    //         return "Робочий день співробітника не може закінчуватися раніше за робочий день філії";
    //     }
    //
    //     if (workDayPeriodEnd > workDayEnd) {
    //         return "Робочий день співробітника не може закінчуватися пізніше за робочий день філії"
    //     }
    // }
};

export const isSuccessfulFormValidated = (params: any) => {
    let notValidFormMessage: string | undefined;

    // перша валідація по даті
    notValidFormMessage = dateRangeValidation(params.dateStart, params.dateEnd);

    // якщо валідація по даті нічого не поверне, то валідуємо по часу робочого дня
    if (!notValidFormMessage && params.dayTypeId === TimetableTypesEnum.Workday) {
        notValidFormMessage = timeRangeValidation(params);
    }

    // якщо валідація по часу нічого не поверне, то перевіряємо чи вибраний принаймні один день тижня
    if (!notValidFormMessage && !params.weekdays.length) {
        notValidFormMessage = "Виберіть щонайменше один день тижня";
    }

    // виводимо повідомлення і явно повертаємо false, так як форма не пройшла валідацію
    if (notValidFormMessage) {
        addAlert({
            type: "warning",
            message: notValidFormMessage,
        });

        return false;
    }

    return true; // явно повертаємо true, якщо форма пройшла валідацію і відсутнє повідомлення
};