import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export enum TimetableTypesEnum {
    Workday = 1,
    DayOff = 2,
    Vacation = 3,
    SickDay = 4,
    UnpaidLeave = 5,
    BusinessTrip = 6,
}

export interface TimetableTypesInterface {
    id: TimetableTypesEnum,
    name: string,
}

export interface TimetableTypesResponseInterface extends ResponseInterface {
    data?: TimetableTypesInterface[],
}
