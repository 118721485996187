import {getClientPaymentTypes} from "../../../../../Api/Directories/Payments/ClientPayments/ClientPaymentTypes";
import {ClientPaymentTypesType} from "./Types";
import {
    ClientPaymentTypeInterface
} from "../../../../../Api/Directories/Payments/ClientPayments/ClientPaymentTypes/ClientPaymentTypesInterface";


export const setClientPaymentTypes = (clientPaymentTypes: ClientPaymentTypeInterface[]) => {
    return {
        type: ClientPaymentTypesType.GET_CLIENT_PAYMENT_TYPES,
        payload: clientPaymentTypes,
    };
};

export const getClientPaymentTypesAction = () => async (dispatch: Function) => {
    const clientPaymentTypes = await getClientPaymentTypes();
    if (clientPaymentTypes?.data) {
        dispatch(setClientPaymentTypes(clientPaymentTypes?.data));
    }
};