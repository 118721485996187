import {request} from "../../../../Core/Utils/Request/Request";
import {
    EditApplicationSettingsRequestInterface,
    ApplicationSettingsRequestInterface,
    ApplicationSettingsResponseInterface
} from "./ApplicationSettingsInterface";
import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export const getApplicationSettings = async (
    params?: ApplicationSettingsRequestInterface
): Promise<ApplicationSettingsResponseInterface> => {
    return await request({
        url: "v1/application-settings/filters",
        method: "post",
        data: params,
    });
};


export const editApplicationSettings = async (
    id: number | string, params?: EditApplicationSettingsRequestInterface
): Promise<ResponseInterface> => {
    return await request({
        url: `v1/application-settings/${id}`,
        method: "patch",
        data: params,
    });
};

