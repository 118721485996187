export const Conf = {
    SET_CONF: 'SET_CONF'
};
export enum screenType {
    desktop = 'desktop',
    tablet = 'tablet',
    mobile = 'mobile'
}

export interface ConfReducersInterface {
    screenType: screenType
}