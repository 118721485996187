import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getEmployees} from "../../../../../../Api/Administration/Employees";
import {
    destroyEmployeeTimetable
} from "../../../../../../Api/Administration/PersonnelManagement/EmployeeTimetable";
import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {getTimetableTypesAction} from "../../../../../../Store/Directories/TimetableTypes/Actions";
import {getBranchesAction} from "../../../../../../Store/Branches/Actions";

import Form from "../../../../../../../Core/Components/Forms/Form";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import DropDown, {prepareDropDownOptions} from "../../../../../../../Core/Components/Forms/Elements/DropDown";
import DateTime from "../../../../../../../Core/Components/Forms/Elements/DateTime";

import {StoreInterface} from "../../../../../../../Config/Redux/StoreInterface";
import {
    DestroyEmployeeTimetableFormPropsInterface,
} from "./DestroyEmployeeTimetableFormInterface";
import {EmployeeInterface} from "../../../../../../Api/Administration/Employees/EmployeesInterface";


const DestroyEmployeeTimetableForm = (props: DestroyEmployeeTimetableFormPropsInterface) => {
    const {
        branchId,
        onAfterSuccessHandler,
    } = props;


    const timetableTypes = useSelector((store: StoreInterface) => store.shortCache.timetableTypes);
    const branches = useSelector((store: StoreInterface) => store.shortCache.branches);
    const dispatch = useDispatch();

    const [branchEmployees, setBranchEmployees] = useState<EmployeeInterface[]>([]);


    useEffect(() => {
        !timetableTypes?.length && dispatch(getTimetableTypesAction());
        !branches?.length && dispatch(getBranchesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        branchId && getEmployees({filters: {branchId}})
            .then(response => {
                response?.data && setBranchEmployees(response?.data);
            });
    }, [branchId]);


    const onSubmitHandler = (data: any) => {
        if (data.dateStart >= data.dateEnd) {
            addAlert({
                type: "warning",
                message: "Кінцевий день не може бути менше за початковий",
            });
            return;
        }

        destroyEmployeeTimetable(data).then(async (response) => {
            if (response?.status === "success") {
                addAlert({
                    type: "success",
                    message: response.message,
                });

                onAfterSuccessHandler && onAfterSuccessHandler({});
            }
        });
    };


    return (
        <Form onSubmit={onSubmitHandler} data={{branchId}}>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="branchId"
                        title="Філія"
                        options={branches}
                        required
                        disabled
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="employeeId"
                        options={prepareDropDownOptions(branchEmployees, "id", "userName")}
                        title="Співробітник"
                        required
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <DateTime
                        name="dateStart"
                        title="Дата з"
                        required
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DateTime
                        name="dateEnd"
                        title="Дата по"
                        required
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="dayTypeIds"
                        title="Тип дня"
                        isMulti
                        options={prepareDropDownOptions(timetableTypes, "id", "name")}
                    />
                </div>
            </div>

            <br/>

            <FormStickyContainer>
                <Button
                    type="submit"
                    name="delete"
                    title="Видалити"
                    className="lynx-btn-primary"
                />
            </FormStickyContainer>
        </Form>
    )
};

export default DestroyEmployeeTimetableForm;
