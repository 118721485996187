import {useEffect, useRef, useState} from "react";
import "./EChartsReact.css";
import {useDebounce, useResizeObserverRef} from "rooks";
import lodash from "lodash";
import * as echarts from "echarts";

import {getColorArray} from "../../../../Utils/Colors/Colors";
import {pick} from "./EChartsReactHelpers";

import type {ECharts, EChartsType} from "echarts";
import {EChartsReactPropsInterface} from "./EChartsReactInterface";


export default function EChartsReact(props: EChartsReactPropsInterface) {
    const {
        className,
        title,
        style = {},
        isPaddingOut,
        option,
        opts = {},
        theme,
        notMerge = false,
        lazyUpdate = false,
        showLoading,
        loadingOption = null,
        onChartReady,
        onEvents,
        shouldSetOption,
        autoResize = true,
        quantityColor,
    } = props;


    const getOption = () => {
        let defaultOption = {
            color: getColorArray(quantityColor),
            dataZoom: [
                {
                    type: "slider",
                },
                {
                    type: "inside",
                },
            ],
            grid: {
                left: 40,
                right: 20,
                bottom: 90,
                containLabel: false
            },
        };

        return lodash.merge({}, defaultOption, option);
    };

    const debouncedResize = useDebounce(resize, 200);
    const [chartRefWrapper] = useResizeObserverRef(() => debouncedResize());

    const prevProps = useRef(props);
    const [isInitialRender, setIsInitialRender] = useState(true);


    const chartRef = useRef(null);
    const chartEl: HTMLDivElement | null = chartRef?.current;


    useEffect(() => {
        chartEl && renderNewEcharts();

        return () => dispose();
    }, [chartEl]); // eslint-disable-line react-hooks/exhaustive-deps

    // update echarts
    useEffect(() => {
        // якщо shouldSetOption поверне false, то не оновлюємо
        if (shouldSetOption && !shouldSetOption(prevProps.current, props)) {
            return;
        }

        // Змінюючи наступні властивості, потрібно видалити екземпляр, а потім створити новий.
        // 1. При перемиканні теми
        // 2. При зміні opts
        // 3. Під час модифікації onEvents це може скасувати всі раніше івенти
        if (
            !lodash.isEqual(prevProps.current.theme, theme) ||
            !lodash.isEqual(prevProps.current.opts, opts) ||
            !lodash.isEqual(prevProps.current.onEvents, onEvents)
        ) {
            dispose();

            renderNewEcharts().then();
            return;
        }

        // якщо змінились властивості - оновити діаграму
        const pickKeys = ["option", "notMerge", "lazyUpdate", "showLoading", "loadingOption"];
        if (!lodash.isEqual(pick(props, pickKeys), pick(prevProps.current, pickKeys))) {
            updateEChartsOption();
        }

        // якщо змінились style або className - оновити розмір
        if (!lodash.isEqual(prevProps.current.style, style) || !lodash.isEqual(prevProps.current.className, className)) {
            resize();
        }
    }, [shouldSetOption, theme, opts, onEvents, style]); // eslint-disable-line react-hooks/exhaustive-deps


    async function initEchartsInstance(): Promise<ECharts | null> {
        return new Promise((resolve) => {
            if (!chartEl) {
                resolve(null);
                return;
            }

            // створити тимчасовий екземпляр echart
            echarts.init(chartEl, theme, {...opts});
            const echartsInstance = getEchartsInstance();

            echartsInstance.on("finished", () => {
                // отримати остаточну ширину та висоту
                if (!chartEl) {
                    return;
                }

                setIsInitialRender(false);

                // утилізувати тимчасовий екземпляр echart
                echarts.dispose(chartEl);

                // відтворити екземпляр echart
                // використовуємо кінцеву ширину та висоту, лише якщо вони спочатку не були надані як параметри
                const optsWithUpdSize = {
                    width: "auto",
                    height: "auto",
                    ...opts,
                };

                resolve(echarts.init(chartEl, theme, optsWithUpdSize));
            });
        });
    }

    // повернути існуючий об’єкт echart
    function getEchartsInstance(): ECharts {
        return echarts.getInstanceByDom(chartEl as HTMLDivElement) as EChartsType;
    }

    // утилізувати екземпляр echarts
    function dispose() {
        if (chartEl) {
            echarts.dispose(chartEl);
        }
    }

    // відобразити новий екземпляр echarts
    async function renderNewEcharts() {
        // 1. ініціалізація echarts instance
        await initEchartsInstance();

        // 2. оновлення echarts instance
        const echartsInstance = updateEChartsOption();

        // 3. bind events
        bindEvents(echartsInstance, onEvents || {});

        // 4. on chart ready
        onChartReady && onChartReady(echartsInstance);
    }

    // bind the events
    function bindEvents(instance: any, events: EChartsReactPropsInterface["onEvents"]) {
        function _bindEvent(eventName: string, func: Function) {
            if (eventName && func) {
                instance.on(eventName, (param: any) => {
                    func(param, instance);
                });
            }
        }

        // loop and bind
        for (const eventName in events) {
            if (Object.prototype.hasOwnProperty.call(events, eventName)) {
                _bindEvent(eventName, events[eventName]);
            }
        }
    }

    // render the echarts
    function updateEChartsOption() {
        if (!chartEl) {
            return;
        }

        // 1. отримати або ініціалувати об’єкт echarts
        const echartInstance = getEchartsInstance();
        // 2. встановити опцію echarts
        echartInstance.setOption(getOption(), notMerge, lazyUpdate);
        // 3. встановити маску завантаження
        if (showLoading) echartInstance.showLoading(loadingOption);
        else echartInstance.hideLoading();

        return echartInstance;
    }

    // resize echarts
    function resize() {
        if (!autoResize || !chartEl || isInitialRender) {
            return;
        }

        // 1. отримати об’єкт echarts
        const echartsInstance = getEchartsInstance();

        // 2. викликати зміну розміру екземпляра echarts, якщо не початковий розмір
        // зміна розміру не повинна відбуватися під час першого рендеру, оскільки це скасує початкову анімацію echarts
        echartsInstance.resize({
            width: "auto",
            height: "auto",
        });
    }

    const eChartClassName = ["lynx-echarts-react"];
    className && eChartClassName.push(className);
    isPaddingOut && eChartClassName.push("lynx-echarts-react--padding-out");

    return (
        <div
            ref={chartRefWrapper}
            className={eChartClassName.join(" ")}
        >
            {title && <h2 className="lynx-echarts-react__title">{title}</h2>}
            <div
                className="lynx-echarts-react__inner"
                ref={chartRef}
                style={{
                    height: 400, // дефолтна висота діаграм
                    ...style,
                }}
            />
        </div>
    );
}

