import {useState} from "react";

import TabsLinkContent from "../../../../../../Core/Components/TabsLink/TabsLinkContent";
import TabsLinkNavigation from "../../../../../../Core/Components/TabsLink/TabsLinkNavigation";
import FormFlexible from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormFlexible";
import FormTabsContainer from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsContainer";
import FormTabsItem from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsItem";
import WorkplacesTable from "../Workplaces/WorkplacesTable";
import OfficeForm from "../OfficeForm";

import {OfficeFormContainerPropsInterface} from "./OfficeFormContainerInterface";
import {formMode as formModeEnum} from "../../../../../../Core/Components/Forms/Form/FormInterface";


const OfficeFormContainer = ({onAfterSuccessHandle, officeId: officeIdProp, mode}: OfficeFormContainerPropsInterface) => {
    const [formMode, setFormMode] = useState<formModeEnum>(mode);
    const [officeId, setOfficeId] = useState(officeIdProp);

    return <>
        <FormFlexible>
            <FormTabsContainer>
                <TabsLinkNavigation
                    isTabsContent
                    tabs={[
                        {
                            id: "1",
                            content:
                                <FormTabsItem>
                                    <i title="Кабінет" className="fa-duotone fa-house"/>
                                </FormTabsItem>
                        },
                        {
                            id: "2",
                            content: formMode === formModeEnum.create
                                ? <></>
                                : <FormTabsItem>
                                    <i title="Робочі місця" className="fa-duotone fa-chair-office"/>
                                </FormTabsItem>
                        }
                    ]}
                    name="officeForm"
                />
            </FormTabsContainer>


            <TabsLinkContent tabFor="officeForm" tabs={[
                {
                    id: "1",
                    component:
                        <OfficeForm
                            officeId={officeId}
                            mode={formMode}
                            onAfterSuccessHandle={onAfterSuccessHandle}
                            setFormMode={setFormMode}
                            setOfficeId={setOfficeId}
                        />
                },
                {
                    id: "2",
                    component: formMode !== formModeEnum.create && officeId &&
                        <WorkplacesTable officeId={officeId} officeFormMode={mode}/>
                },
            ]}/>
        </FormFlexible>
    </>;
};

export default OfficeFormContainer;
