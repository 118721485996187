import {request} from "../../../../../Core/Utils/Request/Request";
import {
    EmployeesJobPositionRequestInterface,
    EmployeesJobPositionResponseInterface,
    EmployeesJobPositionsRequestInterface,
    EmployeesJobPositionsResponseInterface,
} from "./EmployeeJobPositionsInterface";


export const getEmployeesJobPositions = async (params?: EmployeesJobPositionsRequestInterface): Promise<EmployeesJobPositionsResponseInterface> => {
    return await request({
        url: "/v1/directory/employee-job-positions/filters",
        method: "post",
        data: params,
    });
};

export const findEmployeesJobPositionById = async (id: number | string): Promise<EmployeesJobPositionResponseInterface> => {
    return await request({
        url: `/v1/directory/employee-job-positions/${id}`,
        method: "get"
    });
};

export const createEmployeesJobPosition = async (params: EmployeesJobPositionRequestInterface): Promise<EmployeesJobPositionResponseInterface> => {
    return await request({
        url: "/v1/directory/employee-job-positions",
        method: "post",
        data: params
    });
};

export const editEmployeesJobPosition = async (id: number | string, params: EmployeesJobPositionRequestInterface): Promise<EmployeesJobPositionResponseInterface> => {
    return await request({
        url: `/v1/directory/employee-job-positions/${id}`,
        method: "patch",
        data: params
    });
};

export const deleteEmployeesJobPosition = async (id: number | string): Promise<EmployeesJobPositionResponseInterface> => {
    return await request({
        url: `/v1/directory/employee-job-positions/${id}`,
        method: "delete"
    });
};