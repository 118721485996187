export const findParentByClassName = (element: HTMLElement, className: string) => {
    let parent = element.parentElement;

    while (parent) {
        if (parent.classList.contains(className)) {
            return parent;
        }
        parent = parent.parentElement;
    }

    return null;
};