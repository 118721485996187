import {createStore, applyMiddleware} from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from "./Reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'longTermCache',
        'shortCache',
        'users',
        'controlPanelMenu'
    ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(persistedReducer, composeWithDevTools(
    applyMiddleware(
        thunk
    )))
let persistor = persistStore(store)

const dispatch = store.dispatch;
export { store, persistor, dispatch}