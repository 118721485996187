import ClientLastVisitBlock from "../../ClientBlocks/ClientLastVisitBlock";
import ClientVisitsBlock from "../../ClientBlocks/ClientVisitsBlock";
import {ClientVisitsTabPropsInterface} from "./ClientVisitsTabInterface";


export default function ClientVisitsTab({clientId}: ClientVisitsTabPropsInterface) {
    return (
        <div className="row">
            <div className="col-xl-4">
                <div className="lynx-client__block">
                    <ClientLastVisitBlock clientId={clientId}/>
                </div>
            </div>
            <div className="col-xl-8">
                <div className="lynx-client__block">
                    <ClientVisitsBlock clientId={clientId}/>
                </div>
            </div>
        </div>
    );
}