import {addAlert} from "../../Core/Store/Alert/Actions";
import {useLynxTariffPlanInterface} from "./hookInterface";
import {TariffPlanPermissionsInterface} from "../Api/Permissions/TariffPlanPermissions/TariffPlanPermissionsInterface";

export default function useLynxTariffPlan(): useLynxTariffPlanInterface {

    const tariffPlanPermissionsStorage = localStorage.getItem("tariffPlanPermission");

    let tariffPlanPermissions: TariffPlanPermissionsInterface;
    if (tariffPlanPermissionsStorage) {
        const parsedTariffPlanPermissionsStorage = JSON.parse(tariffPlanPermissionsStorage);

        // властивості без ключів будуть повертатись пустим масивом - замінюємо на пустий об'єкт
        for (const tariffPlanPermissionsKey in parsedTariffPlanPermissionsStorage) {
            if (Array.isArray(parsedTariffPlanPermissionsStorage[tariffPlanPermissionsKey])) {
                parsedTariffPlanPermissionsStorage[tariffPlanPermissionsKey] = {};
            }
        }

        tariffPlanPermissions = parsedTariffPlanPermissionsStorage;
    } else {
        addAlert({
            type: "error",
            message: "Відсутні права тарифного плану",
        });
    }

    return {
        isAvailable: (key: string): boolean => !!tariffPlanPermissions?.available[key],
        getCount: (key: string): number | null => tariffPlanPermissions?.count[key] ?? null,
    };
}
