import React from 'react';
import ModalSimple from "../../../../../../Core/Components/Modal/ModalSimple";
import VisitForm from "../VisitForm";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {VisitModalPropsInterface} from "./VisitModalInterface";


export default function VisitModal(props: VisitModalPropsInterface) {
    const {
        isOpen,
        mode,
        visitData,
        clientId,
        formControl,
        onAfterSuccessHandle,
        onAfterCloseHandle,
    } = props;


    let modeTitle = "";
    switch (mode) {
        case formMode.view:
            modeTitle = "Перегляд візита клієнта";
            break;
        case formMode.create:
            modeTitle = "Створення нового візита клієнта";
            break;
        case formMode.edit:
            modeTitle = "Редагування візита клієнта";
            break;
        default:
            return null;
    }


    return (
        <ModalSimple
            isOpen={isOpen}
            body={
                <VisitForm
                    visitData={visitData}
                    clientId={clientId}
                    mode={mode}
                    formControlProp={formControl}
                    onAfterSuccessHandle={onAfterSuccessHandle}
                />}
            title={modeTitle}
            onAfterCloseHandle={onAfterCloseHandle}
        />
    );
};