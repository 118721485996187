import {
    CustomerGroupRequestInterface,
    CustomerGroupResponseInterface,
    CustomerGroupsResponseInterface,
} from "./CustomerGroupsInterface";
import {request} from "../../../../../Core/Utils/Request/Request";


export const getCustomerGroups = async (): Promise<CustomerGroupsResponseInterface> => {
    return await request({
        url: '/v1/crm/marketing/customer-groups',
        method: 'get',
    });
};

export const findCustomerGroupById = async (id: number | string)
    : Promise<CustomerGroupResponseInterface> => {
    return await request({
        url: `/v1/crm/marketing/customer-groups/${id}`,
        method: 'get'
    });
};

export const createCustomerGroup = async (params: CustomerGroupRequestInterface)
    : Promise<CustomerGroupResponseInterface> => {
    return await request({
        url: '/v1/crm/marketing/customer-groups/create',
        method: 'post',
        data: params
    });
};

export const editCustomerGroup = async (id: number | string, params: CustomerGroupRequestInterface)
    : Promise<CustomerGroupResponseInterface> => {
    return await request({
        url: `/v1/crm/marketing/customer-groups/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteCustomerGroup = async (id: number | string): Promise<CustomerGroupResponseInterface> => {
    return await request({
        url: `/v1/crm/marketing/customer-groups/${id}`,
        method: 'delete'
    });
};