import React from 'react';
import './Button.css';
import {ButtonsPropsInterface} from './ButtonInterface';

const Button = (props: ButtonsPropsInterface) => {

    const {className, disabled, name, title, valuesForms, clickHandler, onClick, icon} = props;

    let classButtons = ['lynx-button'];
    className && classButtons.push(className);
    disabled && classButtons.push('disabled');

    return (
        <button
            data-testid={'button-' + name}
            className={classButtons.join(' ')}
            data-name={name}
            disabled={!!disabled}
            onClick={() => {
                clickHandler && clickHandler(valuesForms, name)
                !clickHandler && onClick && onClick();
            }}
        >
            {icon && <i className={icon}/>} {title}
        </button>

    );
}

export default Button;