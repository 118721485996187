export const ReloadTypes = {
    RELOAD_PAGE: "RELOAD_PAGE",
    RELOAD_COMPONENT: "RELOAD_COMPONENT",
};

export interface ReloadInterface {
    [key: string]: boolean,
}

export interface ReloadsStateInterface {
    reloadPage: ReloadInterface,
    reloadComponent: ReloadInterface,
}