import {ResponseInterface} from "../../../../../Core/Utils/Request/ResponseInterface";


export enum StateIdEnum {
    Created = 1,
    Confirmed = 2,
    AwaitingPayment = 3,
    HasBeenPaid = 4,
    InProgress = 5,
    Done = 6,
    Rejected = 7,
    ClarificationOfInformation = 8,
    Archived = 9,
}

export interface StateInterface {
    id: number,
    name: string,
}

export interface StatesResponseInterface extends ResponseInterface {
    data?: StateInterface[],
}
