import 'dotenv';

const AuthConfig = {
    redirectNoAuthUser: {
        link: `${process.env.REACT_APP_SERVICE_AUTH_URL}/applications`,
        isExternal: true
    },
    successAuth: {
        link: '/loading-process'
    },
    redirectToProfile: {
        link: `${process.env.REACT_APP_SERVICE_AUTH_URL}/profile`,
    },
}

export default AuthConfig;