import {ControlPanelMenuActions, ControlPanelMenuState} from './Types'
import {AnyAction} from "redux";

const initialState: ControlPanelMenuState = {
    tinyMenu: false,
    isMobileMenuOpen: false,
};

const controlPanelMenu = (state: ControlPanelMenuState = initialState, action: AnyAction) => {
    const {type} = action;

    switch (type) {
        case ControlPanelMenuActions.TOGGLE_MENU :
            return {
                ...state,
                tinyMenu: !state.tinyMenu
            };
        case ControlPanelMenuActions.TOGGLE_MOBILE_MENU :
            return {
                ...state,
                isMobileMenuOpen: !state.isMobileMenuOpen
            };
    }

    return state;
}
export default controlPanelMenu;