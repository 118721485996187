import {AdministrationUsers, AdministrationUsersReducersInterface} from './Types'
import {AnyAction} from "redux";

const initialState: Partial<AdministrationUsersReducersInterface> = {};


const profile = (state: Partial<AdministrationUsersReducersInterface> = initialState, action: AnyAction): Partial<AdministrationUsersReducersInterface> => {
    const {type, ...rest} = action;

    switch (type) {
        case AdministrationUsers.SET_PROFILE :
            return rest.payload;
        default:
            return state;
    }
}
export default profile;