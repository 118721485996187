import React, {useState} from "react";
import moment from "moment";
import "./FeedTypeServicesPerformedCertificate.css";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {setReloadPage} from "../../../../../Core/Store/Reload/Actions";

import ClientModalView from "../../../CRM/Clients/ClientModalView";
import ServicesPerformedCertificateModal
    from "../../../Documents/ServicesPerformedCertificates/ServicesPerformedCertificateModal";

import {FeedTypeServicesPerformedCertificatePropsInterface} from "./FeedTypeServicesPerformedCertificateInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";


function FeedTypeServicesPerformedCertificate(props: FeedTypeServicesPerformedCertificatePropsInterface) {
    const {
        servicesPerformedCertificate,
    } = props;

    const [permission] = useLynxPermission();
    const [isModalClientViewOpen, setIsModalClientViewOpen] = useState(false);
    const [isModalServicesPerformedCertificateOpen, setIsModalServicesPerformedCertificateOpen] = useState(false);
    const [mode, setMode] = useState<formMode>();


    const onClientClickHandler = () => {
        if (!permission.isView("client")) {
            addAlert({
                type: "warning",
                message: "Відсутні права для перегляду клієнта"
            })
            return;
        }

        setIsModalClientViewOpen(true);
    };

    const onVisitClickHandler = () => {
        if (permission.isEdit("servicesPerformedCertificate")) {
            setMode(formMode.edit);
            setIsModalServicesPerformedCertificateOpen(true);
            return;
        }

        if (permission.isView("servicesPerformedCertificate")) {
            setMode(formMode.view);
            setIsModalServicesPerformedCertificateOpen(true);
            return;
        }

        addAlert({
            type: "warning",
            message: "Відсутні права для перегляду акту наданих послуг"
        });
    };

    const onSuccessHandler = () => {
        setReloadPage("FeedsPage", true);
        setIsModalServicesPerformedCertificateOpen(false);
    };


    return (
        <>
            <div className="lynx-feed-type-services-performed-certificate">
                <div className="lynx-feed-type-services-performed-certificate-data__list">
                    <div className="lynx-feed-type-services-performed-certificate-data__item
                                    lynx-feed-type-services-performed-certificate-data__item--width-parent">
                        <div className="lynx-feed-type-services-performed-certificate-data__title">Клієнт</div>
                        <div className="lynx-feed-type-services-performed-certificate-data__link"
                             onClick={onClientClickHandler}>
                            <i className="fa-duotone fa-link"></i> {servicesPerformedCertificate.clientName}
                        </div>
                    </div>
                    <div className="lynx-feed-type-services-performed-certificate-data__item">
                        <div className="lynx-feed-type-services-performed-certificate-data__title">Номер акту</div>
                        <div className="lynx-feed-type-services-performed-certificate-data__desc">
                            {servicesPerformedCertificate.code}
                        </div>
                    </div>
                    <div className="lynx-feed-type-services-performed-certificate-data__item">
                        <div className="lynx-feed-type-services-performed-certificate-data__title">Дата</div>
                        <div className="lynx-feed-type-services-performed-certificate-data__desc">
                            {moment(servicesPerformedCertificate.date).format("DD.MM.YYYY")}
                        </div>
                    </div>
                    <div className="lynx-feed-type-services-performed-certificate-data__item">
                        <div className="lynx-feed-type-services-performed-certificate-data__title">Філія</div>
                        <div className="lynx-feed-type-services-performed-certificate-data__desc">
                            {servicesPerformedCertificate.branchName}
                        </div>
                    </div>
                    <div className="lynx-feed-type-services-performed-certificate-data__item
                                    lynx-feed-type-services-performed-certificate-data__item--status">
                        <div className="lynx-feed-type-services-performed-certificate-data__title">Статус</div>
                        <div className="lynx-feed-type-services-performed-certificate-data__desc
                                        lynx-feed-type-services-performed-certificate-data__desc--status">
                            {servicesPerformedCertificate.stateName}
                        </div>
                    </div>
                </div>
                <div className="lynx-feed-type-services-performed-certificate-data__item
                                lynx-feed-type-services-performed-certificate-data__item--width-parent">
                    <div
                        className="lynx-feed-type-services-performed-certificate-data__link"
                        onClick={onVisitClickHandler}
                    >
                        <i className="fa-duotone fa-link"></i> Переглянути акт наданих послуг
                    </div>
                </div>
            </div>

            {isModalClientViewOpen &&
                <ClientModalView
                    isOpen={isModalClientViewOpen}
                    clientId={servicesPerformedCertificate.clientId}
                    onAfterCloseHandle={() => setIsModalClientViewOpen(false)}
                />
            }

            {mode &&
                <ServicesPerformedCertificateModal
                    isOpen={isModalServicesPerformedCertificateOpen}
                    mode={mode}
                    data={{
                        id: servicesPerformedCertificate.id
                    }}
                    onAfterCloseHandle={() => setIsModalServicesPerformedCertificateOpen(false)}
                    onAfterSuccessHandle={onSuccessHandler}
                />
            }
        </>
    );
}

export default FeedTypeServicesPerformedCertificate;