import React, {useEffect, useState} from "react";

import {useLynxPermission} from "../../../../../../../Modules/AdministrationUsers/hooks";
import {
    createWorkplaceAction,
    deleteWorkplaceAction,
    editWorkplaceAction,
    viewWorkplaceAction
} from "./WorkplacesTableHelpers";
import {getFilteredWorkplaces} from "../../../../../../Api/Directories/Workplaces";

import Grid from "../../../../../../../Core/Components/Tables/Grids/Grid";
import ModalSimple from "../../../../../../../Core/Components/Modal/ModalSimple";
import WorkplaceForm from "../WorkplaceForm";

import {
    GridPaginationInterface
} from "../../../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {Actions} from "../../../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {GridTitleInterface} from "../../../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {WorkplacesTablePropsInterface} from "./WorkplacesTableInterface";


export default function WorkplacesTable({officeId, officeFormMode}: WorkplacesTablePropsInterface) {
    const [permission] = useLynxPermission();
    const [pagination, setPagination] = useState({});

    const [workplaces, setWorkplaces] = useState<any>({});
    const [
        workplaceId,
        setWorkplaceId
    ] = useState<number | string>();

    const [isReload, setIsReload] = useState(true);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);

    const widthForm = "sm";

    const gridTitle: GridTitleInterface[] = [
        {
            title: "id",
            code: "id",
            width: "70px",
        },
        {
            title: "Кабінет",
            code: "name"
        },
    ];

    useEffect(() => {
        if (isReload) {
            getFilteredWorkplaces({filters: {officeId}, pagination})
                .then(response => {
                    if (response?.status === "success") {
                        setWorkplaces(response);
                    }

                    setIsReload(false);
                });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps


    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setIsReload(true);
        setPagination(pagination);
    };

    const onSuccessHandler = () => {
        setIsReload(true);
        setIsModalCreateOpen(false);
        setIsModalEditOpen(false);
    };

    const navActions: Actions[] = [
        {
            title: "Додати робоче місце",
            name: "createWorkplace",
            isShow: permission.isCreate("office") && officeFormMode !== formMode.view,
            functions: {setIsModalCreateOpen},
            action: createWorkplaceAction,
        },
    ];

    const itemActions: Actions[] = [
        {
            title: "Переглянути",
            name: "viewWorkplace",
            isShow: permission.isView("office"),
            functions: {setIsModalViewOpen, setWorkplaceId},
            action: viewWorkplaceAction,
        },
        {
            title: "Редагувати",
            name: "editWorkplace",
            classNameItem: "lynx-button-action-warning",
            isShow: permission.isEdit("office") && officeFormMode !== formMode.view,
            functions: {setIsModalEditOpen, setWorkplaceId},
            action: editWorkplaceAction,
        },
        {
            title: "Видалити",
            name: "deleteWorkplace",
            classNameItem: "lynx-button-action-danger",
            isShow: permission.isDelete("employee") && officeFormMode !== formMode.view,
            functions: {onSuccessHandler},
            action: deleteWorkplaceAction,
        },
    ];


    return (
        <>
            {permission.isView("office") &&
                <Grid
                    data={workplaces?.data || []}
                    title={gridTitle}
                    pagination={workplaces?.pagination || {}}
                    onLoadDataHandler={onLoadDataHandler}
                    actions={itemActions}
                    navActions={navActions}
                    isHidePadding
                />
            }

            <ModalSimple
                isOpen={isModalViewOpen}
                body={
                    <WorkplaceForm
                        workplaceId={workplaceId}
                        mode={formMode.view}
                    />
                }
                widthClass={widthForm}
                title="Перегляд робочого місця"
                onAfterCloseHandle={() => setIsModalViewOpen(false)}
            />

            <ModalSimple
                isOpen={isModalCreateOpen}
                body={
                    <WorkplaceForm
                        onAfterSuccessHandle={onSuccessHandler}
                        mode={formMode.create}
                        officeId={officeId}
                    />}
                widthClass={widthForm}
                title="Створення робочого місця"
                onAfterCloseHandle={() => setIsModalCreateOpen(false)}
            />

            <ModalSimple
                isOpen={isModalEditOpen}
                body={
                    <WorkplaceForm
                        onAfterSuccessHandle={onSuccessHandler}
                        workplaceId={workplaceId}
                        mode={formMode.edit}
                    />
                }
                widthClass={widthForm}
                title="Редагування робочого місця"
                onAfterCloseHandle={() => setIsModalEditOpen(false)}
            />
        </>
    );
}