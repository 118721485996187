import "./FieldsetWithElements.css";
import {FieldsetWithElementsPropsInterface} from "./FieldsetWithElementsInterface";


export default function FieldsetWithElements (props: FieldsetWithElementsPropsInterface) {
    const {
        title,
        element,
        elementInTheEnd,
        children,
    } = props;

    const fieldsetWithElementsClass = ["lynx-fieldset-with-elements"];
    elementInTheEnd && fieldsetWithElementsClass.push("lynx-fieldset-with-elements--end");

    return (
        <div className={fieldsetWithElementsClass.join(" ")}>
            <div className="lynx-fieldset-with-elements__title">{title}</div>
            {element && element}
            {children && children}
        </div>
    );
}