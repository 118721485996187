import {useRef} from "react";
import "./FormStickyContainer.css";
import {useFullScreenParentModal} from "../../../../hooks";
import {useMediaMatch} from "rooks";
import {FormStickyContainerPropsInterface} from "./FormStickyContainerInterface";


export default function FormStickyContainer({children}: FormStickyContainerPropsInterface) {
    const ref = useRef<HTMLDivElement | null>(null);
    const isMobile = useMediaMatch('(max-width: 768px)');
    const {isFullScreenParentModal} = useFullScreenParentModal(ref?.current);

    const classNames = ["lynx-form-sticky-container"];
    !isMobile && isFullScreenParentModal && classNames.push("lynx-form-sticky-container--full-screen")

    return (
        <div className={classNames.join(" ")} ref={ref}>
            {children}
        </div>
    );
};