import {createBrowserHistory} from 'history';

const customHistory = createBrowserHistory();

let {push, replace} = customHistory;

let openLink = function (link: string){
    window.open(link)
};

export {
    customHistory,
    push,
    replace,
    openLink
}