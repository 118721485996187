import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLynxSearchParams, useReloadPage} from "../../../../Core/hooks";
import {useLynxTariffPlan} from "../../../hooks";
import {setReloadPage} from "../../../../Core/Store/Reload/Actions";
import {useLynxPermission} from "../../../../Modules/AdministrationUsers/hooks";
import {getFeedTagsAction} from "../../../Store/Directories/FeedTags/Actions";
import {addAlert} from "../../../../Core/Store/Alert/Actions";
import BreadCrumb from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import BreadCrumbContainer from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import Feed from "../../../Components/Feeds/Feed";
import Button from "../../../../Core/Components/Forms/Elements/Button";
import './FeedsPage.css';
import FeedFilters from "../../../Components/Feeds/FeedFilters";
import {getFeeds} from "../../../Api/Feeds/Feed";
import {FeedInterface} from "../../../Api/Feeds/Feed/FeedInterface";
import ModalSimple from "../../../../Core/Components/Modal/ModalSimple";
import CreateFeedForm from "../../../Components/Feeds/CreateFeedForm";
import {StoreInterface} from "../../../../Config/Redux/StoreInterface";
import {FeedTypeIdEnum} from "../../../Components/Feeds/Feed/FeedInterface";

export default function FeedsPage() {
    const feedTags = useSelector((store: StoreInterface) => store.shortCache.feedTags)
    const dispatch = useDispatch();
    const [permission] = useLynxPermission();
    const {isAvailable} = useLynxTariffPlan();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    const isReload = useReloadPage("FeedsPage", true);
    const [feeds, setFeeds] = useState<FeedInterface[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        perPage: 50,
        total: 0,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadMoreBtnVisible, setIsLoadMoreBtnVisible] = useState(false);
    const [isLoadMore, setIsLoadMore] = useState(false);


    // гарантуємо, що при наступному монтуванні isReload = true
    useEffect(() => () => setReloadPage("FeedsPage", true), []);

    useEffect(() => {
        if (isReload) {
            getFeeds({filters: searchParams, pagination})
                .then(async (response) => {
                    if (response?.data) {
                        const {
                            currentPage,
                            totalPages,
                            total
                        } = response.pagination;

                        if (total === 0) {
                            setIsLoadMoreBtnVisible(false);
                        }

                        if (currentPage !== totalPages) {
                            setIsLoadMoreBtnVisible(true);
                        }

                        if (currentPage === totalPages) {
                            setIsLoadMoreBtnVisible(false);

                            totalPages > 1 && addAlert({
                                type: "info",
                                message: "Вітаємо, тепер Ви в курсі всіх подій!"
                            });
                        }

                        setPagination(response.pagination);

                        setFeeds((prevFeeds) => {
                            if (isLoadMore) {
                                setIsLoadMore(false);
                                return [...prevFeeds, ...response.data]
                            }
                            return response.data;
                        });
                    }

                    setReloadPage("FeedsPage", false);
                });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getFeedTagsAction());
    }, [dispatch]);


    const onFilterHandler = (data: any) => {
        setSearchParams(data);
        // приведення pagination до дефолтних параметрів
        setPagination({
            currentPage: 1,
            perPage: 50,
            total: 0,
        });
        setReloadPage("FeedsPage", true);
    };

    const onToggleHandler = () => {
        setIsModalOpen(!isModalOpen)
    }

    const onSuccessHandler = () => {
        // приведення pagination до дефолтних параметрів
        setPagination({
            currentPage: 1,
            perPage: 50,
            total: 0,
        });
        setSearchParams({});
        setIsModalOpen(false);
        setReloadPage("FeedsPage", true);
    }

    const onLoadMoreBtnClick = () => {
        setIsLoadMore(true);
        setPagination(prevPagination => ({
            ...prevPagination,
            currentPage: (prevPagination.currentPage + 1),
        }));
        setReloadPage("FeedsPage", true);
    };


    return <>
        <BreadCrumbContainer>
            <BreadCrumb title="Стрічка подій"/>
            <div className="lynx-feeds-page-buttons">
                {permission.isCreate('feed') &&
                    <Button
                        name="addFeed"
                        title="Додати пост"
                        className="lynx-btn-primary"
                        onClick={onToggleHandler}
                    />
                }
                &nbsp;
                <Button name="settingsFeed" icon="fa-duotone fa-gears" className="lynx-btn-white"/>
            </div>
        </BreadCrumbContainer>
        <br/>
        <div className="row">
            <div className="col-12 order-2 col-xl-9 order-xl-1">
                {feeds && feeds.map((item: FeedInterface) => {
                    if (item.feedTypeId === FeedTypeIdEnum.Visit && isAvailable("clientVisitNotification")) {
                        return null;
                    }

                    if (item.feedTypeId === FeedTypeIdEnum.ServicesPerformedCertificate
                        && isAvailable("servicesPerformedCertificateNotification")) {
                        return null;
                    }

                    return <Feed
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        description={item.description}
                        created={item.created}
                        author={item.author}
                        avatar={item.avatar}
                        authorId={item.authorId}
                        tags={item.tags}
                        feedTypeId={item.feedTypeId}
                        feedTypeName={item.feedTypeName}
                        visit={item.visit}
                        servicesPerformedCertificate={item.servicesPerformedCertificate}
                    />
                })}
                {isLoadMoreBtnVisible &&
                    <div className="justify-content-center d-grid">
                        <Button
                            className="lynx-btn-primary"
                            name="loadMore"
                            title="Завантажити ще"
                            onClick={onLoadMoreBtnClick}
                        />
                    </div>
                }
            </div>
            <div className="col-12 order-1 col-xl-3 order-xl-2">
                <FeedFilters
                    searchParams={searchParams}
                    onFilterHandler={onFilterHandler}
                    feedTags={feedTags}/>
            </div>
        </div>
        <ModalSimple
            isOpen={isModalOpen}
            body={<CreateFeedForm onAfterSuccessHandle={onSuccessHandler} feedTags={feedTags}/>}
            title="Новий пост"
            onAfterCloseHandle={onToggleHandler}
        />
    </>
}
