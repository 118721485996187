import {GendersInterface} from "../../../Api/Directories/Genders/GendersInterface";
import {GendersType} from "./Types";
import {getGenders} from "../../../Api/Directories/Genders";


export const setGenders = (genders: GendersInterface[] | undefined) => {
    return {
        type: GendersType.GET_GENDERS,
        payload: genders,
    };
};

export const getGendersAction = () => async (dispatch: Function) => {
    const genders = await getGenders();
    if (genders.status === 'success') {
        dispatch(setGenders(genders?.data));
    }
};