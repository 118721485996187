import {useEffect, useState} from "react";
import "./UserSettingNotifications.css";

import {editUserSettings, getUserSettings} from "../../../../Api/UserSettings";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";

import UserSettingsGroupForm from "../../UserSettingsGroupForm";
import FieldsetWithElements from "../../../../../Core/Components/Forms/Elements/FieldsetWithElements";
import Checkbox from "../../../../../Core/Components/Forms/Elements/Checkbox";

import {
    UserSettingGroupIdsEnum,
    UserSettingInterface,
    UserSettingKeysEnum,
} from "../../../../Api/UserSettings/UserSettingsInterface";


export default function UserSettingNotifications() {
    const [settings, setSettings] = useState<UserSettingInterface[]>();


    useEffect(() => {
        getUserSettings({filters: {groupId: UserSettingGroupIdsEnum.Notifications}})
            .then(response => {
                response?.data && setSettings(response.data);
            });
    }, []);


    const onCheckboxCheck = ({name, value}: {
        name: UserSettingKeysEnum,
        value: number,
    }) => {
        const setting = settings?.find(setting => {
            return setting.keyName === name;
        });
        if (!setting) return;

        let params: { [key: string]: any };
        switch (name) {
            case UserSettingKeysEnum.EmailNotifications:
                params = {value};
        }

        editUserSettings(setting.id, params).then(response => {
            if (response?.data) {
                addAlert({
                    type: "success",
                    message: response.message,
                });
            }
        });
    };


    return (
        <>
            <div className="lynx-user-setting-notifications">
                <div className="lynx-user-setting-notifications__left">
                    <UserSettingsGroupForm settings={settings}>
                        {settings?.map(setting => {
                            return (
                                <FieldsetWithElements
                                    title={setting.title}
                                    elementInTheEnd
                                    key={setting.keyName}
                                >
                                    <Checkbox
                                        name={UserSettingKeysEnum.EmailNotifications}
                                        afterChangeHandler={onCheckboxCheck}
                                        className="lynx-user-setting-notifications__checkbox"
                                    />
                                </FieldsetWithElements>
                            );
                        })}
                    </UserSettingsGroupForm>
                </div>
                <div className="lynx-user-setting-notifications__right"></div>
            </div>
        </>
    );
}