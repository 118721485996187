import {AnyAction} from "redux";
import {ClientPaymentFormsStateType, ClientPaymentFormsType} from "./Types";

const initialStates: ClientPaymentFormsStateType = [];

const clientPaymentForms = (
    states: ClientPaymentFormsStateType = initialStates, action: AnyAction
): ClientPaymentFormsStateType => {
    const {type, payload} = action;
    switch (type) {
        case ClientPaymentFormsType.GET_CLIENT_PAYMENT_FORMS:
            return [...payload];
        default:
            return states;
    }
};

export default clientPaymentForms;