import {RefObject, useEffect} from "react";

const useScrollListener = (ref: RefObject<HTMLElement | null>, callback: (e: Event) => void) => {
    const handleScroll = (e: Event) => callback && callback(e);

    useEffect(() => {
        const element = ref?.current;

        element && element.addEventListener("scroll", handleScroll);

        return () => {
            element && element.removeEventListener("scroll", handleScroll);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useScrollListener;
