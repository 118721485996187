import {useEffect, useState} from "react";
import moment from "moment";
import "./ClientDocumentsGrid.css";

import {getClientDocuments} from "../../../Api/Documents/ClientDocuments";
import {
    createServiceAgreementAction,
    createServicesPerformedCertificateAction, deleteDocumentAction,
    editDocumentAction, viewDocumentAction,
} from "./ClientDocumentsGridHelpers";
import {useReloadComponent} from "../../../../Core/hooks";
import {useLynxPermission} from "../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../Core/Store/Reload/Actions";

import FieldsetWithElements from "../../../../Core/Components/Forms/Elements/FieldsetWithElements";
import ButtonActions from "../../../../Core/Components/ButtonActions";
import FormHR from "../../../../Core/Components/Forms/Elements/FormHR";
import ServicesPerformedCertificateModal from "../ServicesPerformedCertificates/ServicesPerformedCertificateModal";
import ServiceAgreementModal from "../ServiceAgreements/ServiceAgreementModal";
import Grid from "../../../../Core/Components/Tables/Grids/Grid";

import {formMode} from "../../../../Core/Components/Forms/Form/FormInterface";
import {
    ClientDocumentType,
    ClientDocumentTypeIdEnum
} from "../../../Api/Documents/ClientDocuments/ClientDocumentsInterface";
import {ClientDocumentsGridPropsInterface} from "./ClientDocumentsGridInterface";
import {Actions} from "../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {GridTitleInterface} from "../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {useLynxTariffPlan} from "../../../hooks";


export default function ClientDocumentsGrid(props: ClientDocumentsGridPropsInterface) {
    const {
        clientId,
        formControl,
        isHideNavigation,
        isHidePadding,
    } = props;


    const isReload = useReloadComponent("ClientDocumentsGrid");
    const [permission] = useLynxPermission();
    const {isAvailable} = useLynxTariffPlan();

    const [clientDocuments, setClientDocuments] = useState<ClientDocumentType[] | []>([]);
    const [servicesPerformedCertificateId, setServicesPerformedCertificateId] = useState<number | string | null>(null);
    const [serviceAgreementId, setServiceAgreementId] = useState<number | string | null>(null);

    const [isServiceAgreementModalOpen, setIsServiceAgreementModalOpen] = useState(false);
    const [isServicesPerformedCertificateModalOpen, setIsServicesPerformedCertificateModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState<formMode>(formMode.view);


    const gridTitle: GridTitleInterface[] = [
        {
            title: "Id",
            code: "id",
            width: "60px"
        },
        {
            title: "Номер документа",
            code: "code",
            width: "140px",
        },
        {
            title: "Дата документа",
            code: "date",
            width: "120px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY"),
        },
        {
            title: "Стан",
            code: "stateName",
            width: "200px",
        },
        {
            title: "Клієнт",
            code: "clientName",
            width: "270px",
        },
        {
            title: "Співробіник",
            code: "employeeName"
        },
        {
            title: "Філія",
            code: "branchName",
            width: "250px",
        },
    ];


    useEffect(() => {
        if (isReload && clientId) {
            getClientDocuments(clientId).then(response => {
                if (response?.data) {
                    setReloadComponent("ClientDocumentsGrid", false);
                    setClientDocuments(response.data);
                }
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    // гарантуємо, що при наступному монтуванні isReload = true
    useEffect(() => () => setReloadComponent("ClientDocumentsGrid", true), []);


    const onSuccessHandler = () => {
        setReloadComponent("ClientDocumentsGrid", true);
        setServicesPerformedCertificateId(null);
        setServiceAgreementId(null);
    };

    const createActions: Actions[] = [
        {
            title: "Створити акт",
            name: "createServicesPerformedCertificate",
            isShow: permission.isCreate("servicesPerformedCertificate"),
            functions: {setModalMode, setIsServicesPerformedCertificateModalOpen},
            action: createServicesPerformedCertificateAction,
        },
        {
            title: "Створити договір",
            name: "createServiceAgreement",
            isShow: permission.isCreate("serviceAgreement"),
            classNameItem: "lynx-button-action-info",
            functions: {setModalMode, setIsServiceAgreementModalOpen},
            action: createServiceAgreementAction,
        },
    ];

    const gridActions: Actions[] = [
        {
            title: "Переглянути",
            name: "viewVisit",
            functions: {
                setModalMode,
                setIsServiceAgreementModalOpen,
                setIsServicesPerformedCertificateModalOpen,
                setServicesPerformedCertificateId,
                setServiceAgreementId,
            },
            action: viewDocumentAction,
        },
        {
            title: "Редагувати",
            name: "editVisit",
            classNameItem: "lynx-button-action-warning",
            isShow: (data) => {
                switch (data.documentTypeId) {
                    case ClientDocumentTypeIdEnum.ServiceAgreement:
                        return permission.isEdit("serviceAgreement");
                    case ClientDocumentTypeIdEnum.ServicePerformedCertificate:
                        return permission.isEdit("servicesPerformedCertificate");
                    default:
                        return false;
                }
            },
            functions: {
                setModalMode,
                setIsServiceAgreementModalOpen,
                setIsServicesPerformedCertificateModalOpen,
                setServicesPerformedCertificateId,
                setServiceAgreementId,
            },
            action: editDocumentAction,
        },
        {
            title: "Видалити",
            name: "deleteVisit",
            classNameItem: "lynx-button-action-danger",
            isShow: (data) => {
                switch (data.documentTypeId) {
                    case ClientDocumentTypeIdEnum.ServiceAgreement:
                        return permission.isDelete("serviceAgreement");
                    case ClientDocumentTypeIdEnum.ServicePerformedCertificate:
                        return permission.isDelete("servicesPerformedCertificate");
                    default:
                        return false;
                }
            },
            functions: {onSuccessHandler},
            action: deleteDocumentAction,
        },
    ];


    return (
        <>
            <FieldsetWithElements title="Список документів" elementInTheEnd>
                {(permission.isCreate("servicesPerformedCertificate") || permission.isCreate("serviceAgreement"))
                    && (isAvailable("servicesPerformedCertificate") || isAvailable("serviceAgreement"))
                    && <div className="lynx-client-documents-grid__buttons">
                        <ButtonActions
                            title="Створити"
                            actions={createActions}
                        />
                    </div>
                }
            </FieldsetWithElements>
            <FormHR type="dashed" isPaddingOut/>

            <Grid
                data={clientDocuments}
                title={gridTitle}
                actions={gridActions}
                isHideNavigation={isHideNavigation}
                isHidePadding={isHidePadding}
            />


            {isServicesPerformedCertificateModalOpen &&
                <ServicesPerformedCertificateModal
                    isOpen={isServicesPerformedCertificateModalOpen}
                    mode={modalMode}
                    data={{
                        id: servicesPerformedCertificateId ?? undefined,
                        clientId
                    }}
                    formControl={formControl}
                    onAfterCloseHandle={() => setIsServicesPerformedCertificateModalOpen(false)}
                    onAfterSuccessHandle={onSuccessHandler}
                />
            }

            {isServiceAgreementModalOpen &&
                <ServiceAgreementModal
                    isOpen={isServiceAgreementModalOpen}
                    mode={modalMode}
                    serviceAgreementId={serviceAgreementId}
                    clientId={clientId}
                    formControl={formControl}
                    onAfterCloseHandle={() => setIsServiceAgreementModalOpen(false)}
                    onAfterSuccessHandle={onSuccessHandler}
                />
            }
        </>
    );
}