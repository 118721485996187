import {useEffect} from "react";

import {setReloadPage} from "../../../../../Core/Store/Reload/Actions";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import TabsLinkNavigation from "../../../../../Core/Components/TabsLink/TabsLinkNavigation";
import TabsLinkContent from "../../../../../Core/Components/TabsLink/TabsLinkContent";
import TabsLinkNavigationGeneralItem
    from "../../../../../Core/Components/TabsLink/TabsLinkNavigationItem/TabsLinkNavigationGeneralItem";
import TabsLinkGeneralContainer
    from "../../../../../Core/Components/TabsLink/TabsLinkContainers/TabsLinkGeneralContainer";
import ApplicationSettingsSecurity
    from "../../../../Components/Administration/ApplicationSettings/ApplicationSettingsGroups/ApplicationSettingsSecurity";

import {
    ApplicationSettingGroupIdsEnum,
} from "../../../../Api/Administration/ApplicationSettings/ApplicationSettingsInterface";
import {usePageTitle} from "../../../../../Core/hooks";


export default function ApplicationSettingsPage() {
    usePageTitle("Налаштування застосунку");

    useEffect(() => () => setReloadPage("ApplicationSettingsPage", true), []);


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Налаштування застосунку"/>
            </BreadCrumbContainer>

            <br/>

            <TabsLinkGeneralContainer>
                <TabsLinkNavigation
                    isTabsContent
                    tabs={[
                        {
                            id: ApplicationSettingGroupIdsEnum.Security,
                            content:
                                <TabsLinkNavigationGeneralItem
                                    icon="fa-duotone fa-shield-halved"
                                    text="Безпека"
                                />
                        },
                        {
                            id: "2",
                            content:
                                <TabsLinkNavigationGeneralItem
                                    icon="fa-duotone fa-chart-network"
                                    text="TabsItem2"
                                />
                        },
                        {
                            id: "3",
                            content:
                                <TabsLinkNavigationGeneralItem
                                    icon="fa-duotone fa-key"
                                    text="TabsItem3"
                                />
                        }
                    ]}
                    name="applicationSettings"
                />

                <TabsLinkContent tabFor="applicationSettings" tabs={[
                    {
                        id: ApplicationSettingGroupIdsEnum.Security,
                        component: <ApplicationSettingsSecurity/>
                    },
                    {
                        id: "2",
                        component: <div style={{background: "white", padding: "20px"}}>component 2</div>
                    },
                    {
                        id: "3",
                        component: <div style={{background: "white", padding: "20px"}}>component 3</div>
                    },
                ]}/>
            </TabsLinkGeneralContainer>
        </>
    );
}