import React from 'react';
import './ToggleMenu.css';
import {useDispatch, useSelector} from "react-redux";
import {toggleMenu, toggleMobileMenu} from "../../../../../Store/ControlPanelMenu/Actions";
import {StoreInterface} from "../../../../../../Config/Redux/StoreInterface";
import {screenType as screenTypeEnum} from "../../../../../Store/Conf/Types";

export default function ToggleMenu() {
    const dispatch = useDispatch();
    const screenType = useSelector((store: StoreInterface) => store.system.conf.screenType);
    const isMobileMenuOpen = useSelector((store: StoreInterface) => store.controlPanelMenu.isMobileMenuOpen);

    const onClickHandler = () => {
        dispatch(screenType === screenTypeEnum.mobile ? toggleMobileMenu() : toggleMenu());
    };

    let iconClassName = "icofont-navigation-menu";
    if (screenType === screenTypeEnum.mobile && isMobileMenuOpen) iconClassName = "fa-solid fa-xmark fa-lg";


    return (
        <div className="lynx-header-item" onClick={() => onClickHandler()}>
            <div className="lynx-toggle-menu">
                <i className={iconClassName}/>
            </div>
        </div>
    );
}
