import {AnyAction} from "redux";
import {FeedTagsType, FeedTagsStateType} from "./Types";

const initialState: FeedTagsStateType = [];

/**
 *
 * @param state
 * @param action
 */

const feedTags = (state: FeedTagsStateType = initialState, action: AnyAction): FeedTagsStateType => {
    const {type, payload} = action;
    switch (type) {
        case FeedTagsType.GET_FEED_TAGS:
            return [...payload];
        default:
            return state;
    }
};

export default feedTags;