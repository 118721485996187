import './Branch.scss';
import {BranchInterface} from "./BranchInterface";

const Branch = ({name, address, count}: BranchInterface) => {
    return <>
        <div className="lynx-branch-container">
            <div className="lynx-branch-container__image">
                <i className="fa-duotone fa-apartment fa-lg"/>
            </div>
            <div className="lynx-branch-container__info">
                <h6 className="lynx-branch-container__title">{name}</h6>
                <div className="lynx-branch-container__address">{address}</div>
            </div>
            <div className="lynx-branch-container__count" title="Співробітників">{count}</div>
        </div>
    </>
}

export default Branch
