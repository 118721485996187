import {ColorResult} from "react-color";
import {InitComponentInterface} from "../../Form/FormInterface";
import {HelperInfoPropsInterface} from "../../../HelperInfo/HelperInfoInterface";


export enum circleSizeEnum {
    xs = 18,
    sm = 22,
    md = 26,
    lg = 30,
    xl = 34,
}

export interface ColorPickerPropsInterface {
    initComponent?(object: InitComponentInterface): void,
    changeHandler?(object: {}): void,
    beforeChangeHandler?(data: any): void,
    afterChangeHandler?(data: any): void,
    onHover?(color: ColorResult, event: MouseEvent): void;

    name: string,
    title: string | number,
    colors: string[],
    value?: string,
    required?: boolean,
    disabled?: boolean,
    className?: string,
    width?: string,
    circleSize?: circleSizeEnum,
    tooltip?: HelperInfoPropsInterface,
}