import chroma from "chroma-js";
import {CSSObjectWithLabel, StylesConfig} from "react-select";
import {DropDownOption} from "../DropDown/DropDownInterface";

export const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: "' '",
        display: "block",
        marginRight: 8,
        minHeight: 12,
        minWidth: 12,
    },
});

export const getOptionStyles = (
    styles: CSSObjectWithLabel,
    {data, isDisabled}: {
        data: DropDownOption,
        isDisabled: boolean,
    }
) => {
    if (!data.color) {
        return styles;
    }

    return {
        ...styles,
        ...dot(data.color),
        cursor: isDisabled ? "not-allowed" : "default",
    };
};

export const getControlStyles = ((styles: CSSObjectWithLabel) => ({...styles, backgroundColor: "white"}));

export const colourStylesSingle: StylesConfig<DropDownOption> = {
    control: getControlStyles,
    option: getOptionStyles,
    input: (styles) => ({...styles, ...dot()}),
    singleValue: (styles, {data}) => ({...styles, ...dot(data.color)}),
};

export const colourStylesMulti: StylesConfig<DropDownOption, true> = {
    control: getControlStyles,
    option: getOptionStyles,
    multiValue: (styles, {data}) => {
        if (!data.color) return styles;
        const color = chroma(data.color);

        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, {data}) => {
        return {
            ...styles,
            color: data.color,
        }
    },
    multiValueRemove: (styles, {data}) => ({
        ...styles,
        color: data.color,
        ":hover": {
            backgroundColor: data.color,
            color: "white",
        },
    }),
};