import "./ShortInfoItem.css"
import {animateValue, getTypeValue} from "./ShortInfoItemHelpers";
import {ShortInfoItemPropsInterface, ValueTypeEnum} from "./ShortInfoItemInterface"
import {useEffect, useState} from "react";
import {formatTime} from "../../../../Utils/Time/TimeFormatting/TimeFormatting";


export default function ShortInfoItem({itemData}: ShortInfoItemPropsInterface) {
    const {
        icon,
        targetValue: targetValueProp,
        description,
        isShow,
        animationDuration,
        startValue,
        partsCount,
        className,
        classNameIcon,
    } = itemData;

    const [targetValueState, setTargetValueState] = useState<any>();

    useEffect(() => {
        const valueType = getTypeValue(targetValueProp);

        // якщо getTypeValue не повертає ValueTypeEnum, то записуємо значення без анімації
        if (!valueType) {
            setTargetValueState(targetValueProp);
            return;
        }

        animateValue({
            targetValue: targetValueProp,
            valueType,
            setValue: setTargetValueState,
            animationDuration,
            startValue,
            partsCount,
        });
    }, [targetValueProp]); // eslint-disable-line react-hooks/exhaustive-deps


    const itemClassName = ["lynx-short-info-item"];
    className && itemClassName.push(className);

    const iconClassName = ["lynx-short-info-item__icon"];
    classNameIcon && iconClassName.push(classNameIcon);

    let targetValue;
    if (getTypeValue(targetValueState) === ValueTypeEnum.Time) {
        targetValue = formatTime(targetValueState);
    } else {
        targetValue = targetValueState;
    }

    if (typeof isShow === "boolean" && !isShow) {
        return null;
    }

    if (typeof isShow === "function" && !isShow(itemData)) {
        return null;
    }

    return (
        <div className={itemClassName.join(" ")}>
            <div className="lynx-short-info-item__content">
                <div className={iconClassName.join(" ")}>
                    <i className={icon ? icon : "fa-sharp fa-solid fa-building"}/>
                </div>
                <div className="lynx-short-info-item__info">
                    {targetValueState && <h6 className="lynx-short-info-item__title">{targetValue}</h6>}
                    {description && <div className="lynx-short-info-item__description">{description}</div>}
                </div>
            </div>
        </div>
    );
};