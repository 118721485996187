import {useEffect, useState} from "react";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import ClientPaymentForm from "../ClientPaymentForm";
import {formMode as formModeEnum} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {ClientPaymentModalPropsInterface} from "./ClientPaymentModalInterface";


export default function ClientPaymentModal(props: ClientPaymentModalPropsInterface) {
    const {
        isOpen,
        mode,
        clientPaymentId,
        formControl,
        data,
        onAfterSuccessHandle,
        onAfterCloseHandle,
    } = props;


    const [formMode, setFormMode] = useState<formModeEnum>(mode);

    useEffect(() => setFormMode(mode), [mode]);


    let modeTitle = "";
    switch (formMode) {
        case formModeEnum.view:
            modeTitle = "Перегляд платежу клієнта";
            break;
        case formModeEnum.create:
            modeTitle = "Створення нового платежу клієнта";
            break;
        case formModeEnum.edit:
            modeTitle = "Редагування платежу клієнта";
            break;
        default:
            return null;
    }


    return (
        <ModalSimple
            isOpen={isOpen}
            body={
                <ClientPaymentForm
                    clientPaymentId={clientPaymentId}
                    formControlProp={formControl}
                    data={data}
                    mode={formMode}
                    setFormMode={mode => setFormMode(mode)}
                    onAfterSuccessHandle={onAfterSuccessHandle}
                />}
            title={modeTitle}
            onAfterCloseHandle={onAfterCloseHandle}
        />
    );
};