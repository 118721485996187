import ModalSimple from "../../../../../../Core/Components/Modal/ModalSimple";
import EmployeeForm from "../EmployeeForm";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {EmployeeModalPropsInterface} from "./EmployeeModalInterface";

export default function EmployeeModal(props: EmployeeModalPropsInterface) {
    const {
        isOpen,
        mode,
        employeeId,
        onAfterSuccessHandler,
        onAfterCloseHandle,
    } = props;


    return (
        <>
            {mode === "view" &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <EmployeeForm
                            employeeId={employeeId}
                            mode={formMode.view}
                        />
                    }
                    title="Перегляд співробітника"
                    onAfterCloseHandle={onAfterCloseHandle}
                />
            }

            {mode === "create" &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <EmployeeForm
                            onAfterSuccessHandle={onAfterSuccessHandler}
                            mode={formMode.create}
                        />
                    }
                    title="Створення нового співробітника"
                    onAfterCloseHandle={onAfterCloseHandle}
                />
            }

            {mode === "edit" &&
                <ModalSimple
                    isOpen={isOpen}
                    body={
                        <EmployeeForm
                            onAfterSuccessHandle={onAfterSuccessHandler}
                            employeeId={employeeId}
                            mode={formMode.edit}
                        />
                    }
                    title="Редагування співробітника"
                    onAfterCloseHandle={onAfterCloseHandle}
                />
            }
        </>
    );
};