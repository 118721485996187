import {AnyAction} from "redux";
import {ServiceAgreementStatesStateType, ServiceAgreementStatesType} from "./Types";

const initialStates: ServiceAgreementStatesStateType = [];

const serviceAgreementStates = (
    states: ServiceAgreementStatesStateType = initialStates, action: AnyAction
): ServiceAgreementStatesStateType => {
    const {type, payload} = action;
    switch (type) {
        case ServiceAgreementStatesType.GET_SERVICE_AGREEMENT_STATES:
            return [...payload];
        default:
            return states;
    }
};

export default serviceAgreementStates;