import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {useBranch} from "../../../../../hooks";
import {getBranchesAction} from "../../../../../Store/Branches/Actions";

import ExpandableLink from "../../../../../../Core/Components/ExpandableLink";
import FiltersForm from "../../../../../../Core/Components/Forms/Filters/FiltersForm";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";

import {OfficeFiltersPropsInterface} from "./OfficeFiltersInterface";


export default function OfficeFilters(props: OfficeFiltersPropsInterface) {
    const {searchParams, onFilterHandler} = props;

    const {availableBranches} = useBranch();

    const dispatch = useDispatch();

    
    useEffect(() => {
        dispatch(getBranchesAction());
    }, [dispatch]);


    return (
        <FiltersForm code="offices" searchParams={searchParams} filterHandler={onFilterHandler}>
            <div className="row">
                <ExpandableLink title="Основні фільтри" isVisibleDefault>
                    <div className="col-12">
                        <DropDown
                            options={prepareDropDownOptions(availableBranches, "id", "name")}
                            name="branchId"
                            title="Філія"
                        />
                    </div>
                </ExpandableLink>
            </div>
        </FiltersForm>
    );
};
