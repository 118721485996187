import "./ProfileNavigationContainer.css";
import {profileNavigationConfig} from "../../../../../../../../Config/ControlPanel/ProfileNavigation/ProfileNavigationConfig";
import FormHR from "../../../../../../Forms/Elements/FormHR";
import ProfileNavigationBlockItem from "../ProfileNavigationItem";
import ProfileNavigationHeader from "../ProfileNavigationHeader";
import {
    ProfileNavigationContainerPropsInterface,
} from "./ProfileNavigationContainerInterface";


export default function ProfileNavigationContainer({profile}: ProfileNavigationContainerPropsInterface) {
    return (
        <div className="lynx-profile-navigation-container">
            <div className="lynx-profile-navigation-container__header-wrap">
                <ProfileNavigationHeader profile={profile}/>
            </div>

            {profileNavigationConfig.map((block, blockIndex) => {
                return (
                    <div key={blockIndex}>
                        <FormHR type="dashed" isPaddingOut/>
                        <div className="lynx-profile-navigation-container__block">
                            {block.map(item => <ProfileNavigationBlockItem key={item.title} item={item}/>)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};