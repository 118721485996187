import React from 'react';
import {NavLink} from 'react-router-dom';
import {FirstLevelPropsInterface} from "./FirstLevelInterface";
import NestedLevel from "../NestedLevel";

export default function FirstLevel(props: FirstLevelPropsInterface) {

    const {link, icon, title, items, handler} = props;

    if (items) {
        return <NestedLevel
            link={link}
            title={title}
            icon={icon}
            items={items}
            level={1}
        />
    } else {
        return <li>
            {handler && <div onClick={handler}><i className={icon}/><span>{title}</span></div>}
            {!handler &&
                <NavLink to={link}>
                    <i className={icon}/><span>{title}</span>
                </NavLink>
            }
        </li>
    }
}
