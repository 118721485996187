import {request} from "../../../../Core/Utils/Request/Request";
import {
    NumberOfVisitsRequestInterface,
    NumberOfVisitsResponseInterface,
} from "./NumberOfVisitsInterface";


export const getNumberOfVisits = async (
    params?: NumberOfVisitsRequestInterface
): Promise<NumberOfVisitsResponseInterface> => {
    return await request({
        url: "/v1/reports/visits/filters",
        method: "post",
        data: params,
    });
};