import React, {useEffect, useState} from "react";
import moment from "moment";

import {useLynxSearchParams, usePageTitle} from "../../../../../Core/hooks";
import {useLynxTariffPlan} from "../../../../hooks";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {deleteEmployeeAction, editEmployeeAction, viewEmployeeAction} from "./EmployeesPageHelpers";
import {getEmployees} from "../../../../Api/Administration/Employees";

import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import EmployeeForm from "../../../../Components/Administration/PersonnelManagement/Employees/EmployeeForm";
import EmployeeFilters from "../../../../Components/Administration/PersonnelManagement/Employees/EmployeeFilters";

import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";


export default function EmployeesPage() {
    const [permission] = useLynxPermission();
    const {getCount} = useLynxTariffPlan();
    const maxEmployees = getCount("employeesCount");
    const [searchParams, setSearchParams] = useLynxSearchParams();
    const [pagination, setPagination] = useState({});
    usePageTitle("Співробітники");

    const [employees, setEmployees] = useState<any>({});
    const [employeeId, setEmployeeId] = useState<number | string>();

    const [isReload, setIsReload] = useState(true);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);
    const [afterSuccessCreateTitle, setAfterSuccessCreateTitle] = useState(false);


    const gridTitle: GridTitleInterface[] = [
        {
            title: "Id",
            code: "id",
            width: "60px"
        },
        {
            title: "Співробітник",
            code: "userName"
        },
        {
            title: "Опис",
            code: "description",
            width: "120px",
        },
        {
            title: "Опублікований на сайті",
            code: "isPublishedOnSite",
            width: "180px",
            handler: (value: boolean) => value ? "Так" : "Ні",
        },
        {
            title: "Адреса",
            code: "address",
            width: "250px",
        },
        {
            title: "Створено",
            code: "createdAt",
            width: "130px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
    ];

    useEffect(() => {
        if (isReload) {
            getEmployees({filters: searchParams, pagination})
                .then(response => {
                    if (response?.status === "success") {
                        setEmployees(response);
                    }

                    setIsReload(false);
                });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAfterSuccessCreateTitle(false);
    }, [isModalCreateOpen]);


    const onFilterHandler = (data: any) => {
        setSearchParams(data);
        setIsReload(true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setIsReload(true);
        setPagination(pagination);
    };

    const onSuccessCreateHandler = () => {
        setIsReload(true);
        setAfterSuccessCreateTitle(true);
    };

    const onSuccessDeleteHandler = () => setIsReload(true);

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "viewEmployee",
            isShow: permission.isView("employee"),
            functions: {setIsModalViewOpen, setEmployeeId},
            action: viewEmployeeAction,
        },
        {
            title: "Редагувати",
            name: "editEmployee",
            classNameItem: "lynx-button-action-warning",
            isShow: permission.isEdit("employee"),
            functions: {setIsModalEditOpen, setEmployeeId},
            action: editEmployeeAction,
        },
        {
            title: "Видалити",
            name: "deleteEmployee",
            classNameItem: "lynx-button-action-danger",
            isShow: permission.isDelete("employee"),
            functions: {onSuccessDeleteHandler},
            action: deleteEmployeeAction,
        },
    ];


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Співробітники" tree={["Адміністрування", "Управління персоналом"]}/>

                {permission.isCreate("employee")
                    && maxEmployees && maxEmployees >= employees.length
                    && <div className="lynx-employees-page__button">
                        <Button
                            name="addEmployee"
                            title="Додати"
                            className="lynx-btn-primary"
                            onClick={() => setIsModalCreateOpen(true)}
                        />
                    </div>
                }

            </BreadCrumbContainer>

            <br/>

            {permission.isView("employee") &&
                <div className="row">
                    <div className="col-12 order-2 col-xl-9 order-xl-1">
                        <Grid
                            data={employees?.data || []}
                            title={gridTitle}
                            pagination={employees?.pagination || {}}
                            onLoadDataHandler={onLoadDataHandler}
                            actions={actions}
                        />
                    </div>
                    <div className="col-12 order-1 col-xl-3 order-xl-2">
                        <EmployeeFilters searchParams={searchParams} onFilterHandler={onFilterHandler}/>
                    </div>
                </div>
            }

            <ModalSimple
                isOpen={isModalViewOpen}
                body={<EmployeeForm employeeId={employeeId} mode={formMode.view}/>}
                title="Перегляд співробітника"
                onAfterCloseHandle={() => setIsModalViewOpen(false)}
            />

            <ModalSimple
                isOpen={isModalCreateOpen}
                body={
                    <EmployeeForm
                        onAfterSuccessHandle={onSuccessCreateHandler}
                        mode={formMode.create}
                    />
                }
                title={afterSuccessCreateTitle ? "Редагування співробітника" : "Створення нового співробітника"}
                onAfterCloseHandle={() => setIsModalCreateOpen(false)}
            />

            <ModalSimple
                isOpen={isModalEditOpen}
                body={
                    <EmployeeForm
                        onAfterSuccessHandle={() => setIsReload(true)}
                        employeeId={employeeId}
                        mode={formMode.edit}
                    />
                }
                title="Редагування співробітника"
                onAfterCloseHandle={() => setIsModalEditOpen(false)}
            />
        </>
    );
};