import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../Config/Redux/StoreInterface";
import {findParentByClassName} from "../Utils/Helpers/DomHelpers/DomHelpers";


const useFullScreenParentModal = (element: HTMLElement | null) => {
    const fullScreen = useSelector((store: StoreInterface) => store.longTermCache.fullScreenModal);
    const [isFullScreenParentModal, setIsFullScreenParentModal] = useState(false);

    useEffect(() => {
        if (!element) return;

        const firstParentModal = findParentByClassName(element, "lynx-modal");

        setIsFullScreenParentModal(!!firstParentModal?.classList.contains("lynx-modal--full-screen"));
    }, [fullScreen, element]);

    return {
        isFullScreenParentModal,
    };
};

export default useFullScreenParentModal;