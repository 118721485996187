import {UserSettingInterface} from "../../../Api/UserSettings/UserSettingsInterface";
import {UserSettingsGroupFormPropsInterface} from "./UserSettingsGroupFormInterface";
import Form from "../../../../Core/Components/Forms/Form";


export default function UserSettingsGroupForm(props: UserSettingsGroupFormPropsInterface) {
    const {
        children,
        settings
    } = props;


    const prepareSettingsData = (settings: UserSettingInterface[]) => {
        let settingsData: { [p: string]: any } = {};

        settings.forEach(setting => {
            settingsData = {
                ...settingsData,
                [setting.keyName]: setting.value,
            };
        });
        
        return settingsData;
    };


    return (
        <Form data={!!settings?.length ? prepareSettingsData(settings) : {}}>
            {children}
        </Form>
    );
}