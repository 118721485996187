import {request} from "../../../../Core/Utils/Request/Request";
import {
    CreateServiceAgreementRequestInterface,
    EditServiceAgreementRequestInterface,
    ServiceAgreementResponseInterface,
    ServiceAgreementsRequestInterface,
    ServiceAgreementsResponseInterface,
} from "./ServiceAgreementsInterface";
import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export const getServiceAgreements = async (
    params?: ServiceAgreementsRequestInterface
): Promise<ServiceAgreementsResponseInterface> => {
    return await request({
        url: "/v1/documents/service-agreements/filters",
        method: "post",
        data: params,
    });
};

export const findServiceAgreementById = async (
    id: number | string
): Promise<ServiceAgreementResponseInterface> => {
    return await request({
        url: `/v1/documents/service-agreements/${id}`,
        method: "get"
    });
};

export const createServiceAgreement = async (
    params: CreateServiceAgreementRequestInterface
): Promise<ServiceAgreementResponseInterface> => {
    return await request({
        url: "/v1/documents/service-agreements",
        method: "post",
        data: params
    });
};

export const editServiceAgreement = async (
    id: number | string, params: EditServiceAgreementRequestInterface
): Promise<ServiceAgreementResponseInterface> => {
    return await request({
        url: `/v1/documents/service-agreements/${id}`,
        method: "patch",
        data: params
    });
};

export const deleteServiceAgreement = async (
    id: number | string
): Promise<ResponseInterface> => {
    return await request({
        url: `/v1/documents/service-agreements/${id}`,
        method: "delete"
    });
};
