import {Conf, ConfReducersInterface} from './Types'
import {AnyAction} from "redux";

const initialState: Partial<ConfReducersInterface> = {};


const conf = (state: Partial<ConfReducersInterface> = initialState, action: AnyAction): Partial<ConfReducersInterface> => {
    const {type, ...rest} = action;

    switch (type) {
        case Conf.SET_CONF :
            return rest.payload;
        default:
            return state;
    }
}
export default conf;