import {useDispatch, useSelector} from "react-redux";
import {usePageTitle} from "../../../../Core/hooks";
import {getBranchesAction} from "../../../Store/Branches/Actions";
import BreadCrumbContainer from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Button from "../../../../Core/Components/Forms/Elements/Button";
import {useEffect, useState} from "react";
import Branch from "../../../Components/Branches/Branch/Branch";
import {useLynxPermission} from "../../../../Modules/AdministrationUsers/hooks";
import ModalSimple from "../../../../Core/Components/Modal/ModalSimple";
import BranchFormContainer from "../../../Components/Branches/BranchFormContainer";
import {formMode} from "../../../../Core/Components/Forms/Form/FormInterface";
import {onSuccessHandleInterface} from "./BranchPageInterface";
import {StoreInterface} from "../../../../Config/Redux/StoreInterface";


const BranchPage = () => {
    const [permission] = useLynxPermission();
    usePageTitle("Філії");
    const dispatch = useDispatch();

    const branchList = useSelector((store: StoreInterface) => store.shortCache.branches);
    const [branchId, setBranchId] = useState<number | string>();

    const [isReload, setReload] = useState(true);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isSuccessCreate, setIsSuccessCreate] = useState(false);


    useEffect(() => {
        if (isReload) {
            dispatch(getBranchesAction());
            setReload(false);
        }
    }, [isReload, dispatch]);

    useEffect(() => {
        setIsSuccessCreate(false);
    }, [isModalCreateOpen]);


    const onBranchClickHandler = (branchId: number | string) => {
        setBranchId(branchId);
        setIsModalEditOpen(true);
    };

    const onSuccessHandler = (params: onSuccessHandleInterface) => {
        const operation = params?.operation;
        const branchId = params?.branchId;

        if (operation === "delete") {
            setIsModalEditOpen(false);
            setIsModalCreateOpen(false);
        }

        if (operation === "create") {
            setIsSuccessCreate(true);
            setBranchId(branchId);
        }

        setReload(true);
    };


    return <>
        <BreadCrumbContainer>
            <BreadCrumb title="Філії" tree={['Адміністрування']}/>
            <div className="lynx-feeds-page-buttons">
                {permission.isCreate('branch') &&
                    <Button
                        name="createBranch"
                        title="Створити філію"
                        className="lynx-btn-primary"
                        onClick={() => setIsModalCreateOpen(true)}
                    />
                }
            </div>
        </BreadCrumbContainer>

        <br/>

        <div className="row">
            {branchList && branchList.map(branch => (
                <div className="col-12 col-md-6 col-xl-4 col-xxl-3" data-testid={'branch-card-' + branch.id}
                     key={branch.id} onClick={() => permission.isEdit('branch') && onBranchClickHandler(branch.id)}>
                    <Branch
                        name={branch.name}
                        count={branch.employerCount}
                        address={branch.address}
                    />
                </div>
            ))}
        </div>

        <ModalSimple
            isOpen={isModalCreateOpen}
            body={
                <BranchFormContainer
                    onAfterSuccessHandle={onSuccessHandler}
                    mode={isSuccessCreate ? formMode.edit : formMode.create}
                    branchId={isSuccessCreate ? branchId : 0}
                />
            }
            title={isSuccessCreate ? "Редагування філії" : "Створення нової філії"}
            onAfterCloseHandle={() => setIsModalCreateOpen(false)}
        />

        <ModalSimple
            isOpen={isModalEditOpen}
            body={
                <BranchFormContainer
                    branchId={branchId}
                    mode={formMode.edit}
                    onAfterSuccessHandle={onSuccessHandler}
                />
            }
            title="Редагування філії"
            onAfterCloseHandle={() => setIsModalEditOpen(!isModalEditOpen)}
        />
    </>;
};

export default BranchPage;
