import {request} from "../../../../Core/Utils/Request/Request";
import {
    ClientDocumentsResponseInterface,
} from "./ClientDocumentsInterface";


export const getClientDocuments = async (id: number | string): Promise<ClientDocumentsResponseInterface> => {
    return await request({
        url: `/v1/documents/client/${id}`,
        method: "get",
    });
};
