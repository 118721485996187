import Input from "../../../../Core/Components/Forms/Elements/Input";
import DropDown from "../../../../Core/Components/Forms/Elements/DropDown";
import DateTime from '../../../../Core/Components/Forms/Elements/DateTime';
import FiltersForm from "../../../../Core/Components/Forms/Filters/FiltersForm";
import ExpandableLink from "../../../../Core/Components/ExpandableLink";
import {FeedFiltersPropsInterface} from "./FeedFiltersInterface";

export default function FeedFilters(props: FeedFiltersPropsInterface) {
    const {searchParams, feedTags, onFilterHandler} = props;

    return <>
        <FiltersForm code="feeds" searchParams={searchParams} filterHandler={onFilterHandler}>
            <div className="row feed-filters__inner">
                <ExpandableLink title="Основні фільтри" isVisibleDefault>
                    <div className="col-12">
                        <Input type="text" name="title" title="Заголовок"/>
                    </div>
                    <div className="col-12">
                        <DropDown
                            name="tags"
                            options={feedTags}
                            title="Список тегів"
                            isMulti={true}
                        />
                    </div>
                    <div className="col-12">
                        <DateTime name="created" title="Дата публікації"/>
                    </div>
                </ExpandableLink>
            </div>
        </FiltersForm>
    </>
}