import {useEffect, useState} from "react";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import ServicesPerformedCertificateFormContainer from "../ServicesPerformedCertificateFormContainer";
import {formMode as formModeEnum} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {ServicesPerformedCertificateModalPropsInterface} from "./ServicesPerformedCertificateModalInterface";


export default function ServicesPerformedCertificateModal(props: ServicesPerformedCertificateModalPropsInterface) {
    const {
        isOpen,
        mode,
        formControl,
        data,
        onAfterSuccessHandle,
        onAfterCloseHandle,
    } = props;

    const [formMode, setFormMode] = useState<formModeEnum>(mode);

    useEffect(() => setFormMode(mode), [mode]);


    let modeTitle = "";
    switch (mode) {
        case formModeEnum.view:
            modeTitle = "Перегляд акту";
            break;
        case formModeEnum.create:
            modeTitle = "Створення нового акту";
            break;
        case formModeEnum.edit:
            modeTitle = "Редагування акту";
            break;
        default:
            return null;
    }


    return (
        <ModalSimple
            isOpen={isOpen}
            body={
                <ServicesPerformedCertificateFormContainer
                    formControlProp={formControl}
                    data={data}
                    mode={formMode}
                    setFormMode={mode => setFormMode(mode)}
                    onAfterSuccessHandle={onAfterSuccessHandle}
                />}
            title={modeTitle}
            onAfterCloseHandle={onAfterCloseHandle}
            widthClass="lg"
            fullScreenModal={{key: "ServicesPerformedCertificateModal"}}
        />
    );
};