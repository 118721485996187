import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import lodash from "lodash";
import Big from "big.js";
import moment from "moment";
import "./ClientPaymentForm.css";

import {useBranch} from "../../../../hooks";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {getLiveSearchClient} from "../../../../Api/CRM/Clients";
import {
    getClientPaymentStatesAction,
} from "../../../../Store/Directories/Payments/ClientPayments/ClientPaymentStates/Actions";
import {
    getClientPaymentTypesAction,
} from "../../../../Store/Directories/Payments/ClientPayments/ClientPaymentTypes/Actions";
import {
    getClientPaymentFormsAction,
} from "../../../../Store/Directories/Payments/ClientPayments/ClientPaymentForms/Actions";
import {createClientPayment, editClientPayment, findClientPaymentById} from "../../../../Api/Payments/ClientPayments";
import {getServicesPerformedCertificates} from "../../../../Api/Documents/ServicesPerformedCertificates";
import {validateDecimalValue} from "../../../../../Core/Utils/Validations/NumericalValidations/NumericalValidations";

import Form from "../../../../../Core/Components/Forms/Form";
import Input from "../../../../../Core/Components/Forms/Elements/Input";
import FormStickyContainer from "../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import DropDown, {prepareDropDownOptions} from "../../../../../Core/Components/Forms/Elements/DropDown";
import LiveSearch, {prepareLiveSearchOptions} from "../../../../../Core/Components/Forms/Elements/LiveSearch";
import FormFieldset from "../../../../../Core/Components/Forms/Elements/FormFieldset/FormFieldset";
import Fieldset from "../../../../../Core/Components/Forms/Elements/Fieldset";
import FormHR from "../../../../../Core/Components/Forms/Elements/FormHR";
import DateTime from "../../../../../Core/Components/Forms/Elements/DateTime";

import {LiveSearchOptionInterface} from "../../../../../Core/Components/Forms/Elements/LiveSearch/LiveSearchInterface";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {
    formMode,
    FormControlStateInterface
} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {StateIdEnum} from "../../../../Api/Directories/ServiceAgreements/States/StatesInterface";
import {ClientPaymentFormPropsInterface} from "./ClientPaymentFormInterface";
import {
    ClientPaymentTypeIdEnum
} from "../../../../Api/Directories/Payments/ClientPayments/ClientPaymentTypes/ClientPaymentTypesInterface";
import {
    ServicesPerformedCertificateInterface
} from "../../../../Api/Documents/ServicesPerformedCertificates/ServicesPerformedCertificatesInterface";


const ClientPaymentForm = (props: ClientPaymentFormPropsInterface) => {
    const {
        mode,
        setFormMode,
        clientPaymentId,
        onAfterSuccessHandle,
        formControlProp,
        data,
    } = props;

    const {selectedEmployeeWorkingBranch} = useBranch();

    const {
        clientPaymentStates,
        clientPaymentForms,
        clientPaymentTypes,
    } = useSelector((store: StoreInterface) => store.shortCache);
    const dispatch = useDispatch();


    const [clientCertificates, setClientCertificates] = useState<ServicesPerformedCertificateInterface[] | []>([]);
    const [selectedCertificatePrice, setSelectedCertificatePrice] = useState<number | null>(null);

    const [formData, setFormData] = useState<{ [key: string]: any }>(data ?? {});
    const prevAmount = useRef("0");
    const [formControlState, setFormControlState] = useState<FormControlStateInterface>({
        fields: {
            code: {
                required: mode !== formMode.create,
                disabled: true,
            },
            typeId: {
                required: true,
            },
            formId: {
                required: true,
            },
            stateId: {
                required: true,
                disabled: mode === formMode.create,
            },
            clientId: {
                required: true,
            },
            certificateId: {
                disabled: true,
            },
            date: {
                disabled: true,
            },
            amount: {
                required: true,
            },
        }
    });


    useEffect(() => {
        !clientPaymentForms?.length && dispatch(getClientPaymentFormsAction());
        !clientPaymentStates?.length && dispatch(getClientPaymentStatesAction());
        !clientPaymentTypes?.length && dispatch(getClientPaymentTypesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mode && updateFormControlState({
            fields: {
                code: {required: mode !== formMode.create},
                stateId: {disabled: mode === formMode.create},
            },
        });

        mode === formMode.create && setFormData(prevState => ({
            ...prevState,
            stateId: StateIdEnum.Created,
            date: moment().toISOString(),
        }));
    }, [mode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (clientPaymentId && mode !== formMode.create) {
            findClientPaymentById(clientPaymentId).then(response => {
                if (response?.data) {
                    setFormData(response.data);
                }

                if (response?.data?.certificatePrice) {
                    setSelectedCertificatePrice(response.data.certificatePrice);
                    prevAmount.current = response.data.amount.toString();
                }
            });
        }
    }, [clientPaymentId]); // eslint-disable-line react-hooks/exhaustive-deps

    // обробка поля certificateId
    useEffect(() => {
        const isClientIdFilled = !!formData.clientId;
        const isTypeIdFilled = !!formData.typeId;

        if (isClientIdFilled && isTypeIdFilled) {
            getServicesPerformedCertificates({filters: {clientId: formData.clientId}}).then(response => {
                response?.data && setClientCertificates(response.data);
            });
        } else {
            setFormData(prevState => ({
                ...prevState,
                certificateId: null,
            }));
        }

        updateFormControlState({
            fields: {
                certificateId: {
                    disabled: formControlProp?.fields?.certificateId ? true : !(isClientIdFilled && isTypeIdFilled),
                    required: formData.typeId === ClientPaymentTypeIdEnum.AccordingToTheCertificate,
                },
            },
        });
    }, [formData.clientId, formData.typeId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!formData.amount || formData.amount === prevAmount.current) return;

        let amount = prevAmount.current;
        const formDataAmountToString = formData.amount.toString();

        if (formDataAmountToString === "" || formDataAmountToString === "0") amount = "0";
        else if (validateDecimalValue(formDataAmountToString)) {
            const validAmount = formDataAmountToString.replace(",", ".");
            amount = validAmount;
            prevAmount.current = validAmount;
        }

        setFormData(prevState => {
            return ({
                ...prevState,
                amount,
            });
        });
    }, [formData.amount]);

    // заповнення поля amount при create та наявному certificateId(перевірка в updateAmountOnCertificate)
    useEffect(() => {
        if (mode === formMode.create) updateAmountOnCertificate();
    }, [formData.certificateId, clientCertificates]); // eslint-disable-line react-hooks/exhaustive-deps

    // для зміни formControl ззовні через проп
    useEffect(() => {
        formControlProp && updateFormControlState(formControlProp);
    }, [formControlProp]);

    function updateFormControlState(updatedState: FormControlStateInterface) {
        setFormControlState(prevState => {
            return lodash.merge({}, prevState, updatedState);
        });
    }

    function updateAmountOnCertificate() {
        if (!formData.certificateId || !clientCertificates.length) {
            return;
        }

        const selectedCertificate = clientCertificates.find(certificate => certificate.id === formData.certificateId);

        setSelectedCertificatePrice(selectedCertificate ? selectedCertificate.price : 0);
        setFormData(prevState => ({
            ...prevState,
            amount: selectedCertificate?.price,
        }));
    }

    const loadClientOptions = async (inputValue: string): Promise<LiveSearchOptionInterface[] | []> => {
        return getLiveSearchClient(inputValue).then(response => {
            return prepareLiveSearchOptions(response?.data, "id", "clientName");
        });
    };

    const handleAmountDecreaseClick = (percent: number) => {
        if (selectedCertificatePrice === undefined || selectedCertificatePrice === null) return;

        const priceBig = new Big(selectedCertificatePrice);
        const percentBig = new Big(percent);

        setFormData(prevState => ({
            ...prevState,
            amount: Number(priceBig.times(percentBig).div(100).toFixed(2)),
        }));
    };

    const onBlurAmountHandler = (data: any) => {
        setFormData(prevState => ({
            ...prevState,
            amount: Number(data.value),
        }));
    };

    const onSubmitHandler = (data: any, name: string) => {
        const params = {
            branchId: selectedEmployeeWorkingBranch?.id,
            typeId: data.typeId,
            formId: data.formId,
            clientId: data.clientId,
            certificateId: data.certificateId,
            amount: data.amount,
            stateId: data.stateId,
        };

        if (name === "create") {
            createClientPayment(params).then(async (response) => {
                if (response?.data) {
                    setFormData(prevState => ({
                        ...prevState,
                        code: response.data?.code,
                        id: response.data?.id,
                    }));
                    addAlert({
                        type: "success",
                        message: response.message
                    });
                    setFormMode(formMode.edit);
                    onAfterSuccessHandle && onAfterSuccessHandle();
                }
            });
        }

        if (name === "save") {
            editClientPayment(data.id, params).then(async (response) => {
                if (response?.status === "success") {
                    addAlert({
                        type: "success",
                        message: response.message
                    });
                    onAfterSuccessHandle && onAfterSuccessHandle();
                }
            });
        }
    };


    return (
        <Form
            onSubmit={onSubmitHandler}
            data={formData}
            mode={mode}
            controlState={formControlState}
            mapStateForm={params => params.form.data && setFormData(params.form.data)}
        >
            <FormFieldset name="client">
                <Fieldset title="Інформація про клієнта"/>

                <LiveSearch
                    name="clientId"
                    loadOptions={loadClientOptions}
                    visibleLabel={"clientName" in formData ? formData.clientName : ""}
                    title="Клієнт"
                />

                <FormHR type="dashed"/>
            </FormFieldset>

            <FormFieldset name="agreement">
                <Fieldset title="Інформація про платіж"/>

                <div className="row">
                    <div className="col-12 col-sm-4">
                        <Input
                            type="text"
                            name="code"
                            title="Номер платежу"
                        />
                    </div>
                    <div className="col-12 col-sm-4">
                        <DateTime
                            name="date"
                            title="Дата платежу"
                        />
                    </div>
                    <div className="col-12 col-sm-4">
                        <DropDown
                            options={prepareDropDownOptions(clientPaymentStates, "id", "name")}
                            name="stateId"
                            title="Стан"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6">
                        <DropDown
                            options={prepareDropDownOptions(clientPaymentTypes, "id", "name")}
                            name="typeId"
                            title="Тип платежу"
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <DropDown
                            options={prepareDropDownOptions(clientPaymentForms, "id", "name")}
                            name="formId"
                            title="Тип оплати"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <DropDown
                            options={prepareDropDownOptions(clientCertificates, "id", "code")}
                            name="certificateId"
                            title="Акт наданих послуг"
                            afterChangeHandler={updateAmountOnCertificate}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6">
                        <Input
                            type="text"
                            name="amount"
                            title="Сума платежу"
                            blurHandler={onBlurAmountHandler}
                        />
                    </div>
                    {mode !== formMode.view &&
                        <div className="col-12 col-sm-6 lynx-client-payment-form__advance-buttons">
                            {[30, 50, 70, 100].map(percent => {
                                return (
                                    <Button
                                        key={percent}
                                        name={`${percent}`}
                                        title={`${percent}%`}
                                        className="lynx-btn-primary lynx-client-payment-form__advance-btn"
                                        onClick={() => handleAmountDecreaseClick(percent)}
                                        disabled={!formData.certificateId
                                            || formData.typeId !== ClientPaymentTypeIdEnum.Advance}
                                    />
                                );
                            })}
                        </div>
                    }
                </div>
            </FormFieldset>

            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create ? (
                            <Button
                                type="submit"
                                name="create"
                                title="Створити"
                                className="lynx-btn-primary"
                            />
                        ) : (
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                        )}
                    </FormStickyContainer>
                </>
            }
        </Form>
    );
}

export default ClientPaymentForm;
