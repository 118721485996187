import React, {useState} from 'react';
import './ToggleBranchButton.css';
import {useSelector} from "react-redux";

import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import ToggleBranchList from "../ToggleBranchList";

import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";


export default function ToggleBranchButton() {
    const selectedBranch = useSelector(
        (store: StoreInterface) => store.longTermCache.users.selectedEmployeeWorkingBranch
    );
    const employeeWorkingBranches = useSelector(
        (store: StoreInterface) => store.users.employeeWorkingBranches
    );
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const toggleButtonClassName = ["lynx-toggle-branch-button"];
    employeeWorkingBranches.length > 1 && toggleButtonClassName.push("lynx-toggle-branch-button--hover");

    return (
        <>
            {employeeWorkingBranches.length !== 0 &&
                <div className={toggleButtonClassName.join(" ")} onClick={toggleModal}>
                    <i className="fa-duotone fa-apartment fa-lg"/>
                    <span className="lynx-toggle-branch-button__title">{selectedBranch?.name}</span>
                </div>
            }

            {employeeWorkingBranches.length > 1 &&
                <ModalSimple
                    isOpen={isModalOpen}
                    body={
                        <ToggleBranchList
                            employeeWorkingBranches={employeeWorkingBranches}
                            onAfterChangeHandler={toggleModal}/>
                    }
                    onAfterCloseHandle={toggleModal}
                    widthClass="xs"
                />
            }
        </>
    );
}
