import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";
import {ServiceAgreementInterface} from "../ServiceAgreements/ServiceAgreementsInterface";
import {
    ServicesPerformedCertificateInterface
} from "../ServicesPerformedCertificates/ServicesPerformedCertificatesInterface";


export enum ClientDocumentTypeIdEnum {
    ServiceAgreement = 1,
    ServicePerformedCertificate = 2,
}

export type ClientDocumentType = ServiceAgreementInterface | ServicesPerformedCertificateInterface;

export interface ClientDocumentsResponseInterface extends ResponseInterface {
    data?: ClientDocumentType[] | [],
}
