import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import './СlientForm.css'

import {createClient, editClient} from "../../../../Api/CRM/Clients";
import {getGendersAction} from "../../../../Store/Directories/Genders/Actions";
import {getClientSourcesAction} from "../../../../Store/Directories/ClientSources/Actions";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {useBranch} from "../../../../hooks";

import RadioGroup, {prepareRadioGroupList} from "../../../../../Core/Components/Forms/Elements/RadioGroup";
import Input from "../../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import DropDown from "../../../../../Core/Components/Forms/Elements/DropDown";
import DateTime from "../../../../../Core/Components/Forms/Elements/DateTime";
import FormStickyContainer from "../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Form from "../../../../../Core/Components/Forms/Form";

import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {ClientFormInterface} from "./СlientFormInterface";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";


export default function ClientForm({onAfterSuccessHandle, clientData, mode}: ClientFormInterface) {
    const {
        selectedEmployeeWorkingBranch,
        availableBranches,
    } = useBranch();

    const genders = useSelector((store: StoreInterface) => store.longTermCache.genders);
    const clientSources = useSelector((store: StoreInterface) => store.shortCache.clientSources);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientSourcesAction());

        if (genders.length === 0) {
            dispatch(getGendersAction());
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps


    const onSubmitHandler = (data: any, name: string) => {
        const params = {
            branchId: data.branchId,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            birthday: data.birthday,
            numberPhone: data.numberPhone,
            genderId: data.genderId,
            clientSourceId: data.clientSourceId,
            address: data.address,
        }

        if (name === 'create') {
            createClient(params)
                .then(response => {
                    if (onAfterSuccessHandle && response?.data) {
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                        onAfterSuccessHandle({
                            data: {
                                clientId: response?.data.id,
                                clientName: response?.data.clientName,
                            },
                            operation: "create"
                        });
                    }
                });
        }

        if (name === 'save') {
            editClient(data.id, params)
                .then(response => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                        onAfterSuccessHandle();
                    }
                });
        }
    };


    return <>
        <Form
            onSubmit={onSubmitHandler}
            data={mode === formMode.edit ? clientData : {branchId: selectedEmployeeWorkingBranch?.id}}
        >
            <div className="row">
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="branchId"
                        title="Філія першого звернення"
                        options={availableBranches}
                        required
                        disabled={mode === formMode.edit}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <RadioGroup
                        name="genderId"
                        title="Стать"
                        required={true}
                        direction="column"
                        radioList={prepareRadioGroupList(genders, "id", "name")}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="firstName"
                        title="Ім'я"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DateTime
                        name="birthday"
                        title="Дата народження"
                        required={true}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="lastName"
                        title="Прізвище"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="numberPhone"
                        title="Номер телефону"
                        required={true}
                        tooltip={{
                            content: <>
                                <div>Номер телефону має складатись з <b>12</b> символів(без +).</div>
                                <div>Наприклад, 380124567890.</div>
                            </>,
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="middleName"
                        title="По батькові"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="clientSourceId"
                        title="Джерело залучення"
                        options={clientSources}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="address"
                        title="Адреса клієнта"
                    />
                </div>
                <div className="col-12 col-sm-6">
                </div>
            </div>

            <>
                <br/>
                <FormStickyContainer>
                    {mode === formMode.create ? (

                        <Button
                            type="submit"
                            name="create"
                            title="Додати"
                            className="lynx-btn-primary"
                        />
                    ) : (
                        <Button
                            type="submit"
                            name="save"
                            title="Зберегти"
                            className="lynx-btn-primary"
                        />

                    )}
                </FormStickyContainer>
            </>

        </Form>
    </>
};
