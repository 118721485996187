import './ClientSourceForm.css'

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {
    createClientSource,
    editClientSource,
} from "../../../../../Api/Administration/Directories/ClientSources/ClientSources";

import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import Checkbox from "../../../../../../Core/Components/Forms/Elements/Checkbox";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";

import {ClientSourceFormInterface} from "./ClientSourceFormInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";


export default function ClientSourceForm({onAfterSuccessHandle, clientSourceData, mode}: ClientSourceFormInterface) {
    const onSubmitHandler = (data: any, name: string) => {
        if (name === 'create') {
            createClientSource(data)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        })
                    }
                });
        }

        if (name === 'save' && clientSourceData) {
            const params = {
                name: data.name,
                code: data.code,
            }

            editClientSource(data.id, params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        })
                    }
                });
        }
    };

    return (
        <Form onSubmit={onSubmitHandler} data={clientSourceData} mode={mode}>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="name"
                        title="Назва"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="code"
                        title="Код"
                        required={true}
                    />
                </div>
            </div>

            {clientSourceData?.isSystem &&
                <div className="row">
                    <div className="col-12">
                        <Checkbox
                            name="isSystem"
                            title="Системне джерело"
                            disabled={true}
                            value={true}
                        />
                    </div>
                </div>
            }

            {mode !== formMode.view &&
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create ? (
                            <Button
                                type="submit"
                                name="create"
                                title="Створити"
                                className="lynx-btn-primary"
                            />
                        ) : (
                            <div className="button-container">
                                <Button
                                    type="submit"
                                    name="save"
                                    title="Зберегти"
                                    className="lynx-btn-primary"
                                />
                            </div>
                        )}
                    </FormStickyContainer>
                </>
            }
        </Form>
    );
};
