import {getWorkplaces} from "../../../Api/Directories/Workplaces/Workplaces";
import {WorkplacesType} from "./Types";
import {WorkplaceInterface} from "../../../Api/Directories/Workplaces/WorkplacesInterface";


export const setWorkplaces = (workplaces: WorkplaceInterface[]) => {
    return {
        type: WorkplacesType.GET_WORKPLACES,
        payload: workplaces,
    };
};

export const getWorkplacesAction = () => async (dispatch: Function) => {
    const workplaces = await getWorkplaces();
    if (workplaces?.status === 'success' && workplaces.data) {
        dispatch(setWorkplaces(workplaces?.data));
    }
};