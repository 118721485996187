import React, {ReactElement} from 'react';
import lodash from "lodash";
import Input from "../Elements/Input/Input";
import {
    FormFunctionInterface,
    InitComponentInterface,
    FormPropsInterface,
    FormStateInterface,
    formMode,
    InitFieldsetInterface,
    MapStateFormInterface,
} from "./FormInterface";
import Button from "../Elements/Button";
// import FileUpload from "../Elements/FileUpload/FileUpload";
import DropDown from "../Elements/DropDown";
import {addAlert} from "../../../Store/Alert/Actions";
import {ValidationError} from "yup";
import Checkbox from "../Elements/Checkbox/Checkbox";
import Textarea from "../Elements/Textarea";
import DateTime from "../Elements/DateTime";
import RadioGroup from "../Elements/RadioGroup";
import Dialer from "../Elements/Dialer";
import LiveSearch from "../Elements/LiveSearch";
import ColorPicker from "../Elements/ColorPicker";
import FormFieldset from "../Elements/FormFieldset/FormFieldset";
import DocumentReader from '../../Documents/DocumentReader';
import { FormContext } from './FormContext';
import ColoredDropDown from "../Elements/ColoredDropDown";
import FileUpload from "../Elements/FileUpload/FileUpload";

class Form extends React.Component<FormPropsInterface, FormStateInterface> {
    static defaultProps: Partial<FormPropsInterface> = {
        isSubmitEmptyFields: false,
    };

    constructor(props: FormPropsInterface) {
        super(props);
        let values: {} = {};
        let fields: {} = {};
        let controlState: {} = {
            fields: {},
            fieldset: {},
        };

        const {hiddenValues, mapStateForm} = this.props;
        //Нужно для передачи скрытых полей формы
        if (hiddenValues) {
            values = hiddenValues;
        }
        mapStateForm && mapStateForm(this.setMapStateForm());

        this.state = {
            values: Object.assign(values, this.props.data),
            fields,
            controlState: Object.assign(controlState, this.props.controlState),
        }
    }

    //Передается в компонент и вызывается при создании каждого компонента формы
    initComponent = (object: InitComponentInterface) => {
        //Общая информация об объекте
        const {name} = object;
        let {fields, values} = this.state

        fields[name] = object;
        values[name] = object.value

        // this.onChangeHandlerObject(object);

        //Обновление общих состояний
        this.setState((prevState) => ({
            ...prevState,
            fields,
            values
        }))
    }

    initFieldset = (object: InitFieldsetInterface) => {
        const {name, visible} = object;

        this.setState((prevState) => ({
            ...prevState,
            controlState: {
                ...prevState.controlState,
                fieldset: {
                    ...prevState.controlState.fieldset,
                    [name]: {
                        visible: visible,
                    },
                },
            },
        }));
    };

    // функція для передавання будь-яких даних та ф-й в батьківський компонент через проп mapStateForm
    setMapStateForm = (params?: any): MapStateFormInterface => {
        const initState: MapStateFormInterface = {
            mode: this.props.mode,
            form: {
                data: this.props.data,
                controlState: this.props.controlState,
            },
            handlers: {
                onSubmitHandler: this.onSubmitHandler,
            },
            actions: {
                clear: this.clear,
                validated: this.validated,
            }
        };

        // використовується глибоке злиття (!!!lodash.merge мутує цільовий об'єкт)
        return lodash.merge({}, initState, params);
    };

    //Обработка change до изменение state
    beforeChangeHandler = (object: any, event: object = {}) => {
        if (object.beforeChangeHandler) {
            object.beforeChangeHandler(object, event);
        }
    }

    //Обработка change после изменение state
    afterChangeHandler = (object: any, event: object = {}) => {
        if (object.afterChangeHandler) {
            object.afterChangeHandler(object, event);
        }
    }

    //Получение значений которые объявлены как скрытые или по умолчанию,
    // но при этом на них нужно как-то реагировать в интерфейсе
    onChangeHandlerObject = (object: any) => {
        const {name, value}: any = object;

        const {values, fields} = this.state;
        const oldValue = this.state.values[name] ? this.state.values[name] : null;

        this.beforeChangeHandler({...fields[name], ...{value: oldValue}})

        values[name] = value
        this.setState((prevState) => ({
            ...prevState,
            values
        }))

        this.props.mapStateForm && this.props.mapStateForm(this.setMapStateForm({
            form: {
                data: values,
            },
        }));

        this.afterChangeHandler({...fields[name], ...object});
    }

    // //Получение значений которые вводит пользователь через события DOM
    // onChangeHandlerEvent = (event: React.FormEvent<HTMLInputElement>) => {
    //     const name: any = event.currentTarget.dataset.name;
    //     const {values, fields} = this.state;
    //
    //     this.beforeChangeHandler(fields[name], event)
    //
    //     values[name] = event.currentTarget.value
    //     this.setState((prevState) => ({
    //         ...prevState,
    //         values
    //     }))
    //
    //     this.afterChangeHandler(values[name], event);
    // }

    recursiveMap: any = (children: React.ReactNode, fn: any) => {
        return React.Children.map(children, child => {
            if (!React.isValidElement(child)) {
                return child;
            }

            if (child.props.children) {
                //@ts-ignore
                child = React.cloneElement(child, {children: this.recursiveMap(child.props.children, fn)});
            }

            return fn(child);
        });
    }

    //События которые должны произойти до нажатия кнопки отправить
    onSubmitHandler = async (event: React.FormEvent, name: string) => {
        if (!this.props.isSubmitEmptyFields) {
            for (const key in event) {
                // @ts-ignore
                if (!event[key] && event[key] !== 0) {
                    // @ts-ignore
                    delete event[key];
                }
            }
        }

        let valid = await this.validated();
        let {onSubmit} = this.props;

        if (!valid && onSubmit) {
            let field: {} = {};
            Object.keys(event).forEach((key) => {
                // @ts-ignore
                if (event[key] !== undefined) {
                    // @ts-ignore
                    field[key] = event[key];
                }
            });

            const outFunction: FormFunctionInterface = {
                clear: this.clear
            }
            onSubmit(field, name, outFunction)
        } else {
            addAlert({
                type: 'error',
                message: 'Форма заповнена не вірно'
            });
        }
    }

    clear = async () => {
        // const {values} = this.state;

        // Object.keys(values).forEach((key: any) => {
        //     values[key] = null;
        // });

        this.setState((prevState) => ({
            ...prevState,
            values: []
        }))
    }

    validated = async () => {
        return await this.validationFields(this.state.fields, this.state.values);
    };

    //Получение ошибок валидации из библиотеки yup
    getValidationErrors(err: any) {
        const validationErrors: any = {};

        err.inner.forEach((error: { path: string | number; message: any; }) => {
            validationErrors[error.path] = error.message;
        });

        return validationErrors;
    }

    //Проверка валидности полей в форме: required, возвращает поля в которых есть ошибки
    async validationFields(fields: {}, values: {}) {

        let errorFields: {} = {};

        //Использует контекст родителя
        Object.keys(fields).forEach((key) => {
            // @ts-ignore
            if (fields[key].required && !values[key] && values[key] !== 0) {
                // @ts-ignore
                // errorFields[key] = fields[key];
                errorFields[key] = "Не заповнено обов'язкове поле";
            }
        });

        if (this.props.validate) {
            const yupValidation = await this.props.validate
                .validate(values, {abortEarly: false})
                .then(function (valid: boolean): boolean {
                    return valid;
                }).catch(function (err: any): ValidationError {
                    return err;
                });

            if (yupValidation !== true && yupValidation instanceof ValidationError) {
                errorFields = Object.assign(this.getValidationErrors(yupValidation), errorFields)
            }
        }

        return Object.keys(errorFields).length > 0 ? errorFields : false;
    }

    componentDidUpdate(prevProps: FormPropsInterface) {
        if (!lodash.isEqual(prevProps.data, this.props.data)) {
            const {data} = this.props;

            this.setState((prevState) => {
                return {
                    ...prevState,
                    // values: Object.assign({}, prevState.values, data),
                    values: Object.assign(prevState.values, data),
                    // якщо не мутувати, то при редагуванні посади та графіку філії не будуть підтягуватись дані
                    // баг не вирішений
                    // для перевірки багу можна використовувати колбек setState, який закоментований нижче
                }
            }
            // , () => {
            //     console.log(this.state.values) // оновлений стейт
            // }
            );
        }

        if (!lodash.isEqual(prevProps.controlState, this.props.controlState)) {
            const {controlState} = this.props;

            this.setState((prevState) => ({
                ...prevState,
                controlState: Object.assign({}, prevState.controlState, controlState),
            }));

            this.props.mapStateForm && this.props.mapStateForm(this.setMapStateForm({
                form: {
                    actionsForm: {controlState},
                },
            }))
        }

        if (prevProps.mode !== this.props.mode) {
            this.props.mapStateForm && this.props.mapStateForm(this.setMapStateForm({
                mode: this.props.mode,
            }));
        }
    }

    renderChildren(children: React.ReactElement | null = null) {
        let {values, controlState} = this.state;

        return this.recursiveMap(children || this.props.children, (child: ReactElement) => {

            // Fieldset
            if (child?.type === FormFieldset) {
                let props: any = {initFieldset: this.initFieldset};
                if (controlState?.fieldset.hasOwnProperty(child.props.name)) {
                    props.visible = controlState?.fieldset[child.props.name].visible;
                }

                return React.cloneElement(child, props);
            }

            //Checkbox
            if (child && (
                child.type === Checkbox
                || child.type === DateTime
                || child.type === DropDown
                || child.type === ColoredDropDown
                || child.type === Input
                || child.type === RadioGroup
                || child.type === Dialer
                || child.type === Textarea
                || child.type === LiveSearch
                || child.type === ColorPicker
                || child.type === DocumentReader
                || child.type === FileUpload
            )) {
                let props: any = {
                    initComponent: this.initComponent,
                    changeHandler: this.onChangeHandlerObject,
                };

                if (controlState.fields.hasOwnProperty(child.props.name)) {
                    if (controlState.fields[child.props.name].visible === false
                        || controlState.fields[child.props.name].visible === 0) {
                        return null;
                    }

                    props.disabled = controlState.fields[child.props.name].disabled;
                    props.required = controlState.fields[child.props.name].required;
                    props.visible = controlState.fields[child.props.name].visible;
                }

                if (this.props.mode === formMode.view) {
                    props = {
                        ...props,
                        disabled: true,
                    };
                }

                if (values[child.props.name]
                    || values[child.props.name] === null
                    || values.hasOwnProperty(child.props.name)) {
                    props.value = values[child.props.name]
                }

                return React.cloneElement(child, props);
            }

            //Buttons
            if (child && child.type === Button && child.props.type && child.props.type === 'submit') {
                return React.cloneElement(child, {
                    clickHandler: this.onSubmitHandler,
                    valuesForms: values,
                });
            }
            return child;
        })
    }

    render() {
        return <>
            <FormContext.Provider value={{
                renderChildren: this.renderChildren.bind(this),
                setMapStateForm: this.setMapStateForm.bind(this),
            }}>
                {this.renderChildren()}
            </FormContext.Provider>
        </>

    }

}

export default Form
