import {FeedTagInterface} from "../../../Api/Feeds/FeedTags/FeedTagsInterface";
import {FeedTagsType} from "./Types";
import {getFeedTags} from "../../../Api/Feeds/FeedTags";


export const setFeedTags = (genders: FeedTagInterface[] | undefined) => {
    return {
        type: FeedTagsType.GET_FEED_TAGS,
        payload: genders,
    };
};

export const getFeedTagsAction = () => async (dispatch: Function) => {
    const feedTags = await getFeedTags();
    if (feedTags.status === 'success') {
        dispatch(setFeedTags(feedTags?.data));
    }
};