import {AnyAction} from "redux";
import {GendersType, GendersStateType} from "./Types";

const initialState: GendersStateType = [];

/**
 *
 * @param state
 * @param action
 */

const genders = (state: GendersStateType = initialState, action: AnyAction): GendersStateType => {
    const {type, payload} = action;
    switch (type) {
        case GendersType.GET_GENDERS:
            return [...payload];
        default:
            return state;
    }
};

export default genders;