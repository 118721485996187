import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import lodash from "lodash";
import "./ServicesPerformedCertificateForm.css"

import {
    createServicesPerformedCertificate,
    editServicesPerformedCertificate,
    findServicesPerformedCertificateById,
} from "../../../../Api/Documents/ServicesPerformedCertificates";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {getLiveSearchClient} from "../../../../Api/CRM/Clients";
import {getEmployees} from "../../../../Api/Administration/Employees";
import {useForm} from "../../../../../Core/hooks";
import {useBranch, useLynxTariffPlan} from "../../../../hooks";
import {
    getServicesPerformedCertificatesStatesAction
} from "../../../../Store/Documents/ServicesPerformedCertificates/Actions";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {getVisits} from "../../../../Api/CRM/RecordManagement/Visits/Visits";

import Form from "../../../../../Core/Components/Forms/Form";
import Input from "../../../../../Core/Components/Forms/Elements/Input";
import FormStickyContainer from "../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import DropDown, {prepareDropDownOptions} from "../../../../../Core/Components/Forms/Elements/DropDown";
import LiveSearch, {prepareLiveSearchOptions} from "../../../../../Core/Components/Forms/Elements/LiveSearch";
import Fieldset from "../../../../../Core/Components/Forms/Elements/Fieldset";
import FormHR from "../../../../../Core/Components/Forms/Elements/FormHR";
import FormFieldset from "../../../../../Core/Components/Forms/Elements/FormFieldset/FormFieldset";
import ClientPaymentModal from "../../../Payments/ClientPayments/ClientPaymentModal";

import {ServicesPerformedCertificateFormPropsInterface} from "./ServicesPerformedCertificateFormInterface";
import {LiveSearchOptionInterface} from "../../../../../Core/Components/Forms/Elements/LiveSearch/LiveSearchInterface";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {
    formMode,
    FormControlStateInterface
} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {StateIdEnum} from "../../../../Api/Directories/ServicesPerformedCertificates/States/StatesInterface";
import {EmployeeInterface} from "../../../../Api/Administration/Employees/EmployeesInterface";
import {
    ClientPaymentTypeIdEnum
} from "../../../../Api/Directories/Payments/ClientPayments/ClientPaymentTypes/ClientPaymentTypesInterface";
import {VisitInterface} from "../../../../Api/CRM/RecordManagement/Visits/Visits/VisitsInterface";


export default function ServicesPerformedCertificateForm(props: ServicesPerformedCertificateFormPropsInterface) {
    const {
        mode,
        setFormMode,
        setData: setDataProp,
        onAfterSuccessHandle,
        formControlProp,
        data,
    } = props;

    const {
        selectedEmployeeWorkingBranch,
        availableBranches,
    } = useBranch();

    const [permission] = useLynxPermission();
    const {isAvailable} = useLynxTariffPlan();

    const servicesPerformedCertificatesStates = useSelector(
        (store: StoreInterface) => store.shortCache.servicesPerformedCertificatesStates
    ); // статуси актів
    const dispatch = useDispatch();

    const [branchEmployees, setBranchEmployees] = useState<EmployeeInterface[]>([]);
    const [visits, setVisits] = useState<VisitInterface[] | []>([]);
    const [isClientPaymentModalOpen, setIsClientPaymentModalOpen] = useState(false);

    // simple form
    const {mapStateForm, setMapStateForm} = useForm();
    const prevMapStateFormData = useRef(mapStateForm?.form.data); // для глибокого порівняння об'єктів в useEffect
    const [formData, setFormData] = useState<{ [key: string]: any }>(data ?? {});
    const [formControlState, setFormControlState] = useState<FormControlStateInterface>({
        fields: {
            code: {
                required: mode !== formMode.create,
                disabled: true,
            },
            stateId: {
                required: true,
                disabled: mode === formMode.create,
            },
            clientId: {
                required: true,
                disabled: false,
            },
            branchId: {
                required: true,
                disabled: false,
            },
            employeeId: {
                required: true,
                disabled: false,
            },
            visitIds: {
                required: false,
                disabled: false,
            },
        },
    });


    useEffect(() => {
        !servicesPerformedCertificatesStates?.length && dispatch(getServicesPerformedCertificatesStatesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mode && updateFormControlState({
            fields: {
                code: {required: mode !== formMode.create},
                stateId: {disabled: mode === formMode.create},
            },
        });

        mode === formMode.create && setFormData({
            branchId: selectedEmployeeWorkingBranch?.id,
            stateId: StateIdEnum.Created,
        });
    }, [mode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data?.id && mode !== formMode.create) {
            findServicesPerformedCertificateById(data.id).then(response => {
                if (response?.data) {
                    setFormData(response.data);
                    setDataProp(response.data);
                }
            });
        }
    }, [data?.id, mode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!lodash.isEqual(prevMapStateFormData.current, mapStateForm?.form.data)) {
            mapStateForm?.form.data && setFormData(prevState => {
                prevMapStateFormData.current = mapStateForm?.form.data;

                return {
                    ...prevState,
                    ...mapStateForm?.form.data,
                };
            });
        }
    }, [mapStateForm?.form.data]);

    // для зміни formControl ззовні через проп
    useEffect(() => {
        formControlProp && updateFormControlState(formControlProp);
    }, [formControlProp]);

    useEffect(() => {
        toggleFieldsDisabled(!formData.branchId);
        formData.branchId && fetchBranchDataById(formData.branchId);
    }, [formData.branchId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formData.clientId && formData.employeeId) {
            getVisits({
                filters: {
                    clientId: formData.clientId,
                    employeeId: formData.employeeId,
                }
            }).then(response => {
                response?.data && setVisits(response?.data);
            });
        } else {
            setVisits([]);
        }
    }, [formData.clientId, formData.employeeId]);


    function fetchBranchDataById(branchId: number | string) {
        getEmployees({filters: {branchId}})
            .then(response => {
                response?.data && setBranchEmployees(response?.data);
            });
    }

    // змінює disabled полів, які залежать від branchId
    function toggleFieldsDisabled(disabled: boolean) {
        updateFormControlState({
            fields: {
                employeeId: {disabled: formControlProp?.fields?.employeeId?.disabled ?? disabled},
                visitId: {disabled: formControlProp?.fields?.visitId?.disabled ?? disabled},
            },
        });
    }

    function updateFormControlState(updatedState: FormControlStateInterface) {
        setFormControlState(prevState => {
            return lodash.merge({}, prevState, updatedState);
        });
    }

    const loadClientOptions = async (inputValue: string): Promise<LiveSearchOptionInterface[] | []> => {
        return getLiveSearchClient(inputValue).then(response => {
            return prepareLiveSearchOptions(response?.data, "id", "clientName");
        });
    };

    const onAfterBranchIdHandler = ({value}: { value: number | string }) => {
        setFormData(prevState => ({
            ...prevState,
            employeeId: null,
            visitId: null,
            branchId: value ? value : null,
        }));

        toggleFieldsDisabled(!value);

        updateFormControlState({
            fields: {
                employeeId: {disabled: !value},
                visitId: {disabled: !value},
            },
        });
    };

    const onSubmitHandler = (data: any, name: string) => {
        const params = {
            stateId: data.stateId,
            branchId: data.branchId,
            employeeId: data.employeeId,
            visitIds: data.visitIds,
            clientId: data.clientId,
        };

        // створення
        if (name === "create") {
            createServicesPerformedCertificate(params).then(async (response) => {
                if (response?.data) {
                    setFormData(response.data);
                    setDataProp(response.data);
                    addAlert({
                        type: "success",
                        message: response.message
                    });
                    setFormMode(formMode.edit);
                }

                onAfterSuccessHandle && onAfterSuccessHandle();
            });
        }

        // редагування
        if (name === "save") {
            editServicesPerformedCertificate(data.id, params)
                .then(response => {
                    if (response?.data) {
                        setDataProp(response.data);
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }

                    onAfterSuccessHandle && onAfterSuccessHandle();
                });
        }
    };


    return (
        <>
            <Form
                onSubmit={onSubmitHandler}
                data={formData}
                mode={mode}
                controlState={formControlState}
                mapStateForm={setMapStateForm}
            >
                <FormFieldset name="client">
                    <Fieldset title="Інформація про клієнта"/>

                    <LiveSearch
                        name="clientId"
                        loadOptions={loadClientOptions}
                        visibleLabel={"clientName" in formData ? formData.clientName : ""}
                        title="Клієнт"
                    />
                    <FormHR type="dashed"/>
                </FormFieldset>

                <FormFieldset name="certificate">
                    <Fieldset title="Інформація про акт"/>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <Input
                                type="text"
                                name="code"
                                title="Номер акту"
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <DropDown
                                options={prepareDropDownOptions(servicesPerformedCertificatesStates, "id", "name")}
                                name="stateId"
                                title="Стан"
                            />
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <DropDown
                                options={prepareDropDownOptions(availableBranches, "id", "name")}
                                name="branchId"
                                title="Філія"
                                afterChangeHandler={onAfterBranchIdHandler}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <DropDown
                                name="employeeId"
                                options={prepareDropDownOptions(branchEmployees, "id", "userName")}
                                title="Співробітник"
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <DropDown
                                options={prepareDropDownOptions(visits, "id", "id")}
                                name="visitIds"
                                title="Візити(ID)"
                                isMulti
                            />
                        </div>
                    </div>
                </FormFieldset>

                {mode !== formMode.view &&
                    <>
                        <br/>
                        <FormStickyContainer>
                            {mode === formMode.create ? (
                                <Button
                                    type="submit"
                                    name="create"
                                    title="Створити"
                                    className="lynx-btn-primary"
                                />
                            ) : (
                                <div className="lynx-services-performed-certificate-form__button-container">
                                    <Button
                                        type="submit"
                                        name="save"
                                        title="Зберегти"
                                        className="lynx-btn-primary"
                                    />

                                    {permission.isCreate("clientPayment")
                                        && isAvailable("clientPayment")
                                        && <Button
                                            name="addClientPayment"
                                            title="Створити платіж"
                                            className="lynx-btn-primary"
                                            onClick={() => setIsClientPaymentModalOpen(true)}
                                        />
                                    }
                                </div>
                            )}
                        </FormStickyContainer>
                    </>
                }
            </Form>

            {isClientPaymentModalOpen &&
                <ClientPaymentModal
                    isOpen={isClientPaymentModalOpen}
                    mode={formMode.create}
                    formControl={{
                        fields: {
                            clientId: {disabled: true},
                            typeId: {disabled: true},
                            certificateId: {disabled: true},
                        },
                    }}
                    data={{
                        clientId: formData.clientId,
                        clientName: formData.clientName,
                        typeId: ClientPaymentTypeIdEnum.AccordingToTheCertificate,
                        certificateId: formData.id,
                        amount: formData.price,
                    }}
                    onAfterCloseHandle={() => setIsClientPaymentModalOpen(false)}
                />
            }
        </>
    );
};
