import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {
    createEmployee,
    editEmployee,
    findEmployeeById,
    getEmployees
} from "../../../../../Api/Administration/Employees/";
import {getUsers} from "../../../../../../Modules/AdministrationUsers/Api/Users/Users/";
import {addAlert} from "../../../../../../Core/Store/Alert/Actions";

import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import Textarea from "../../../../../../Core/Components/Forms/Elements/Textarea";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";
import Checkbox from "../../../../../../Core/Components/Forms/Elements/Checkbox";
import TabsLinkContent from "../../../../../../Core/Components/TabsLink/TabsLinkContent";
import TabsLinkNavigation from "../../../../../../Core/Components/TabsLink/TabsLinkNavigation";
import FormFlexible from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormFlexible";
import FormTabsContainer from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsContainer";
import FormTabsItem from "../../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsItem";
import EmployeeJobPositionsTable from "../EmployeeJobPositions/EmployeeJobPositionsTable";

import {EmployeeFormPropsInterface} from "./EmployeeFormInterface";
import {formMode as formModeEnum} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {UserInterface} from "../../../../../../Modules/AdministrationUsers/Api/Users/Users/UserInterface";
import {
    TabsLinkSelectorInterface
} from "../../../../../../Core/Components/TabsLink/TabsLinkContent/TabsLinkContentInterface";
import {
    TabsLinkNavigationType
} from "../../../../../../Core/Components/TabsLink/TabsLinkNavigation/TabsLinkNavigationInterface";
import {EmployeeInterface} from "../../../../../Api/Administration/Employees/EmployeesInterface";


const EmployeeForm = (props: EmployeeFormPropsInterface) => {
    const {
        onAfterSuccessHandle,
        employeeId: employeeIdProp,
        mode,
    } = props;

    const tabsLink: TabsLinkSelectorInterface = useSelector((store: {
        system: { tabsLink: TabsLinkNavigationType[] }
    }) => store.system.tabsLink);

    const [employee, setEmployee] = useState<EmployeeInterface>();
    const [formMode, setFormMode] = useState<formModeEnum>(mode);
    const [employeeId, setEmployeeId] = useState<number | string | undefined>(employeeIdProp);
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [employees, setEmployees] = useState<EmployeeInterface[]>();


    useEffect(() => {
        if (formMode === formModeEnum.create) {
            getEmployees().then(response => response?.data && setEmployees(response?.data));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formMode === formModeEnum.create) {
            employees && getUsers({filters: {}, pagination: {}}).then((response) => {
                if (response?.data) {
                    const nonEmployeeUsers = response.data.filter(user => (
                        !employees.some(employee => user.id === employee.userId)
                    ));

                    setUsers(nonEmployeeUsers);
                }
            });
        } else {
            getUsers({filters: {}, pagination: {}}).then((response) => {
                response?.data && setUsers(response.data);
            });
        }
    }, [employees]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Number(tabsLink.employeeForm?.id) === 1 && employeeId) {
            findEmployeeById(employeeId).then(response => response?.data && setEmployee(response.data));
        }
    }, [tabsLink.employeeForm?.id]);  // eslint-disable-line react-hooks/exhaustive-deps


    const onSubmitHandler = (data: any, name: string) => {
        const params = {
            ...data,
            isPublishedOnSite: data.isPublishedOnSite ? 1 : 0,
        };

        if (name === "createEmployee") {
            createEmployee(params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response?.data) {
                        setEmployeeId(response.data.id);
                        setEmployee(response.data);
                        setFormMode(formModeEnum.edit);
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                        onAfterSuccessHandle();
                    }
                });
        }

        if (name === "saveEmployee") {
            editEmployee(params.id, params)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === "success") {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }
    };


    return <>
        <FormFlexible>
            <FormTabsContainer>
                <TabsLinkNavigation
                    isTabsContent
                    tabs={[
                        {
                            id: "1",
                            content:
                                <FormTabsItem>
                                    <i title="Основна інформація" className="fa-duotone fa-house"/>
                                </FormTabsItem>
                        },
                        {
                            id: "2",
                            content: formMode === formModeEnum.create
                                ? <></>
                                : <FormTabsItem>
                                    <i title="Філіали/Посади" className="fa-duotone fa-user-doctor"/>
                                </FormTabsItem>
                        }
                    ]}
                    name="employeeForm"
                />
            </FormTabsContainer>


            <TabsLinkContent tabFor="employeeForm" tabs={[
                {
                    id: "1",
                    component:
                        <Form onSubmit={onSubmitHandler} data={employee} mode={formMode}>
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <DropDown
                                        options={prepareDropDownOptions(users, "id", "name")}
                                        name="userId"
                                        title="Користувач"
                                        required
                                        disabled={formMode === formModeEnum.edit}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        type="text"
                                        name="address"
                                        title="Адреса проживання"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Textarea
                                        name="description"
                                        title="Коротка інформація про співробітника (використовується на сайті)"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Checkbox
                                        name="isPublishedOnSite"
                                        title="Публікувати співробітника на сайті"
                                    />
                                </div>
                            </div>

                            {formMode !== formModeEnum.view &&
                                <>
                                    <br/>
                                    <FormStickyContainer>
                                        {formMode === formModeEnum.create ? (
                                            <Button
                                                type="submit"
                                                name="createEmployee"
                                                title="Створити"
                                                className="lynx-btn-primary"
                                            />
                                        ) : (
                                            <Button
                                                type="submit"
                                                name="saveEmployee"
                                                title="Зберегти"
                                                className="lynx-btn-primary"
                                            />
                                        )}
                                    </FormStickyContainer>
                                </>
                            }
                        </Form>
                },
                {
                    id: "2",
                    component: employee && <EmployeeJobPositionsTable employeeData={employee} mode={mode}/>
                },
            ]}/>
        </FormFlexible>
    </>;
};

export default EmployeeForm;
