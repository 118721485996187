import "./ToggleBranchList.css";
import {useDispatch} from "react-redux";

import {
    setSelectedEmployeeWorkingBranch
} from "../../../../Store/SelectedEmployeeWorkingBranch/Actions";

import {ToggleBranchListPropsInterface} from "./ToggleBranchListInterface";
import {EmployeeWorkingBranch} from "../../../../Api/Administration/Employees/EmployeesInterface";


export default function ToggleBranchList({employeeWorkingBranches, onAfterChangeHandler}: ToggleBranchListPropsInterface) {
    const dispatch = useDispatch();

    const switchBranch = (item: EmployeeWorkingBranch) => {
        dispatch(setSelectedEmployeeWorkingBranch(item));
        onAfterChangeHandler && onAfterChangeHandler();
    };


    return (
        <div className="lynx-toggle-branch-list">
            <h2 className="lynx-toggle-branch-list__title">Виберіть активну філію</h2>
            {employeeWorkingBranches.map((item) => {
                return (
                    <div className="lynx-toggle-branch-list-item" key={item.id} onClick={() => switchBranch(item)}>
                        <div className="lynx-toggle-branch-list-item__icon">
                            <i className="fa-duotone fa-apartment fa-lg"/>
                        </div>
                        <h6 className="ynx-toggle-branch-list-item__title">{item.name}</h6>
                    </div>
                )
            })}
        </div>
    );
}
