import React from 'react';
import './NotFound.css';


class NotFound extends React.Component {

    render() {
        return (<div className="pageNotFound">
            Page Not Found
        </div>);
    }
}

export default NotFound;