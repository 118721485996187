import React from "react";
import {
    FormFunctionInterface,
    FormPropsInterface,
} from "../../Form/FormInterface";


export enum SubmitNameEnum {
    ApplyFilter = "applyFilter",
    SaveFilter = "saveFilter",
    ClearFilter = "clearFilter",
}

export interface FiltersFormPropsInterface {
    searchParams: {},

    filterHandler(data: any): void,

    onSubmitHandler?(data?: any, name?: string, object?: FormFunctionInterface): void,

    code: string,
    children?: React.ReactNode,
    formPropsFilters?: Omit<FormPropsInterface, "onSubmit">,
}
