import {getEmployeeWorkingBranches} from "../../Api/Administration/Employees";
import {EmployeeWorkingBranchesTypes} from "./Types";
import {EmployeeWorkingBranch} from "../../Api/Administration/Employees/EmployeesInterface";


export const setEmployeeWorkingBranches = (employeeWorkingBranches: EmployeeWorkingBranch[] | []) => {
    return {
        type: EmployeeWorkingBranchesTypes.GET_EMPLOYEE_WORKING_BRANCHES,
        payload: employeeWorkingBranches,
    };
};

export const getEmployeeWorkingBranchesAction = () => async (dispatch: Function) => {
    const employeeWorkingBranches = await getEmployeeWorkingBranches();
    if (employeeWorkingBranches?.data) {
        dispatch(setEmployeeWorkingBranches(employeeWorkingBranches.data));
    }
};