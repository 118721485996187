import React, {useEffect, useState} from "react";
import "./ServiceForm.css";

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {
    createService,
    editService,
} from "../../../../../Api/Administration/ServiceManagement/Services";
import {getServiceGroups} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups";

import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";
import Textarea from "../../../../../../Core/Components/Forms/Elements/Textarea";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import ServiceGroupsModal from "../../ServiceGroups/ServiceGroupsModal";

import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {ServiceFormPropsInterface} from "./ServiceFormInterface";
import {
    ServiceGroupInterface
} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";
import {AfterSuccessHandleParamsInterface} from "../../ServiceGroups/ServiceGroupForm/ServiceGroupFormInterface";


export default function ServiceForm(props: ServiceFormPropsInterface) {
    const {
        onAfterSuccessHandle,
        serviceData,
        mode,
    } = props;

    const [serviceGroups, setServiceGroups] = useState<ServiceGroupInterface[] | []>([]);
    const [serviceGroupId, setServiceGroupId] = useState<number | string | null>(null);
    const [isServiceGroupModalOpen, setIsServiceGroupModalOpen] = useState(false);


    useEffect(() => {
        getServiceGroups({pagination: {perPage: 99999}}).then(response => {
                if (response?.data) {
                    setServiceGroups(response.data);
                }
            });
    }, [serviceGroupId]); // eslint-disable-line react-hooks/exhaustive-deps


    const onSubmitHandler = (data: any, name: string) => {
        if (name === 'create') {
            createService(data)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }

        if (name === 'save') {
            editService(data.id, data)
                .then(async (response) => {
                    if (onAfterSuccessHandle && response.status === 'success') {
                        onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }
    };

    const formData = {
        ...serviceData,
        groupId: serviceGroupId ? serviceGroupId : serviceData?.groupId,
    };


    return <>
        <Form onSubmit={onSubmitHandler} data={formData} mode={mode}>
            <div className="row">
                <div className="col-12">
                    <Input
                        type="text"
                        name="name"
                        title="Назва послуги"
                        required={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="code"
                        title="Код послуги"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <div className="lynx-service-form__service-group-wrapper">
                        <DropDown
                            name="groupId"
                            title="Група послуги"
                            required={true}
                            options={prepareDropDownOptions(serviceGroups, "id", "name")}
                        />
                        <button
                            className="lynx-service-form__create-group-btn"
                            title="Додати нову групу"
                            onClick={() => setIsServiceGroupModalOpen(true)}
                        >
                            <i className="fa-solid fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-4">
                    <Input
                        type="text"
                        name="price"
                        title="Основна вартість послуги, грн"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <Input
                        type="text"
                        name="minimalPrice"
                        title="Мінімальна ціна, грн"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <Input
                        type="text"
                        name="maximalPrice"
                        title="Максимальна ціна, грн"
                        required={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Textarea
                        name="description"
                        title="Опис послуги"
                    />
                </div>
            </div>

            {mode !== formMode.view && (
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create &&
                            <Button
                                type="submit"
                                name="create"
                                title="Створити"
                                className="lynx-btn-primary"
                            />
                        }

                        {mode === formMode.edit &&
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                        }
                    </FormStickyContainer>
                </>
            )}
        </Form>

        {isServiceGroupModalOpen &&
            <ServiceGroupsModal
                isOpen={isServiceGroupModalOpen}
                mode={formMode.create}
                onAfterCloseHandle={() => setIsServiceGroupModalOpen(false)}
                onAfterSuccessHandle={(params: AfterSuccessHandleParamsInterface) => {
                    setServiceGroupId(params?.data?.id);
                    setIsServiceGroupModalOpen(false);
                }}
            />
        }
    </>
};
