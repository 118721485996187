import {ClientDocumentsBlockPropsInterface} from "./ClientDocumentsBlockInterface";
import ClientDocumentsGrid from "../../../../../Documents/ClientDocumentsGrid";


const ClientDocumentsBlock = ({clientId}: ClientDocumentsBlockPropsInterface) => {
    const formControl = {
        fieldset: {client: {visible: false}}
    };


    return (
       <ClientDocumentsGrid
           clientId={clientId}
           formControl={formControl}
           isHideNavigation
           isHidePadding
       />
    );
};

export default ClientDocumentsBlock;