import useLynxPermission from './useLynxPermission'

export {
    useLynxPermission
}
const hooks = {
    __esModule: true,
    useLynxPermission
}

export default hooks;