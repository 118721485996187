import {BoundariesInterface, ElementDirectionReturnInterface, PositionEnum} from "./hooksInterface";


const useElementDirection = (boundaries: BoundariesInterface | null): ElementDirectionReturnInterface | null => {
    if (!boundaries) return null;

    const {
        top, left, bottom, right,
    } = boundaries;

    let directionX: PositionEnum.Right | PositionEnum.Left;
    let directionY: PositionEnum.Top | PositionEnum.Bottom;

    // праворуч вистачає місця
    if (right > 0) directionX = PositionEnum.Right;
    // праворуч не вистачає, але вистачає ліворуч
    else if (left > 0) directionX = PositionEnum.Left;
    // не вистачає ні праворуч, ні ліворуч
    else {
        // тоді визначаємо, де буде менше перетинати viewport
        if (right >= left) directionX = PositionEnum.Right;
        else directionX = PositionEnum.Left;
    }

    // внизу вистачає місця
    if (bottom > 0) directionY = PositionEnum.Bottom;
    // внизу не вистачає, але вистачає вверху
    else if (top > 0) directionY = PositionEnum.Top;
    // не вистачає ні внизу, ні вверху
    else {
        // тоді визначаємо, де буде менше перетинати viewport
        if (bottom >= top) directionY = PositionEnum.Bottom;
        else directionY = PositionEnum.Top;
    }

    return {directionX, directionY};
};

export default useElementDirection;