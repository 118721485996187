import React, {RefObject, useEffect, useRef, useState} from "react";
import {Scrollbars} from "react-custom-scrollbars-2";
import moment from "moment";
import "./CalendarForWeekGrid.css";

import {CalendarGridContext} from "../../CalendarComponents/CalendarContainer/CalendarContainerContext";
import {getCurrentWeekNumber} from "./CalendarForWeekGridHelpers";

import CalendarItem from "../../CalendarComponents/CalendarItem";
import CalendarContainer from "../../CalendarComponents/CalendarContainer";

import {CalendarForWeekGridPropsInterface} from "./CalendarForWeekInterface";


export default function CalendarForWeekGrid(props: CalendarForWeekGridPropsInterface) {
    const {
        calendarForWeek,
        branchId,
        employeesTimetable,
        date,
    } = props;


    // для скролу до currentDate, якщо дата є в тижні
    const [calendarRef, setCalendarRef] = useState<RefObject<HTMLDivElement> | null>(null);

    // для скролу до current time
    const gridItemsRef = useRef<HTMLDivElement>(null);

    // для синхронізації скролів блоку timeline з блоком спіробітників
    const [scrollPositionTop, setScrollPositionTop] = useState<number>(0);

    // для синхронізації скролів блоку з датами з блоком спіробітників
    const datesContainerRef = useRef<HTMLDivElement>(null);
    const [scrollPositionLeft, setScrollPositionLeft] = useState<number>(0);

    // змінні css з CalendarContainer.css
    const [calendarItemWidth, setCalendarItemWidth] = useState(0);

    const [scrollScrollbars, setScrollScrollbars] = useState<React.UIEvent<any, UIEvent>>()


    useEffect(() => {
        // отримуємо значення змінних CSS
        const root = document.documentElement;
        setCalendarItemWidth(parseInt(getComputedStyle(root).getPropertyValue("--calendarItemWidth"), 10));
    }, []);

    // синхронізуємо скрол блоку з датами з блоком спіробітників
    useEffect(() => {
        datesContainerRef?.current?.scrollTo({
            left: scrollPositionLeft,
        });
    }, [scrollPositionLeft]);

    // скролимо до поточної дати, якщо в тижні є поточна дата
    useEffect(() => {
        const numberEmployees = calendarForWeek[0].employees.length;
        const widthCalendar = calendarRef?.current?.offsetWidth;
        const scrollBarGridItemsRef = gridItemsRef?.current?.parentElement;
        const weekNumber = getCurrentWeekNumber(calendarForWeek);

        if (scrollBarGridItemsRef && widthCalendar && weekNumber) {
            scrollBarGridItemsRef.scrollTo({
                left: (calendarItemWidth * numberEmployees + 3) * (weekNumber - 1), // +3 для pixel perfect
            });
        }
    }, [calendarRef?.current?.offsetWidth, calendarForWeek]); // eslint-disable-line react-hooks/exhaustive-deps


    const getCalendarRef = (ref: RefObject<HTMLDivElement>) => {
        setCalendarRef(ref);
    };


    return (
        <CalendarGridContext.Provider value={{
            scrollScrollbars,
        }}>
            <CalendarContainer
                scrollPositionTop={scrollPositionTop}
                calendarScrollbarChildRef={gridItemsRef}
                timelineClassName="lynx-calendar-week-grid__timeline"
                getCalendarRef={getCalendarRef}
                date={date}
            >
                <div className="lynx-calendar-week-grid-items__wrapper">
                    <div
                        className="lynx-calendar-week-grid-items__group-list"
                        ref={datesContainerRef}
                    >
                        {!!calendarForWeek?.length && calendarForWeek.map(weekDay => (
                            <div
                                key={weekDay.date}
                                className="lynx-calendar-week-grid-items__group-item"
                                style={{minWidth: `calc(${weekDay.employees.length} * var(--calendarItemWidth))`}}
                            >
                                <span className="lynx-calendar-week-grid-items__group-text">
                                    {moment(weekDay.date).format("DD.MM.YYYY")}
                                </span>
                            </div>
                        ))}
                    </div>

                    <Scrollbars
                        className="lynx-calendar-week-grid-items__scrollbars"
                        onScroll={setScrollScrollbars}
                        onUpdate={value => {
                            setScrollPositionTop(value.scrollTop);
                            setScrollPositionLeft(value.scrollLeft);
                        }}
                        style={{borderRadius: "5px"}} // borderRadius тільки інлайн(для верхнього правого бордера)
                    >
                        <div
                            className="lynx-calendar-week-grid-items"
                            ref={gridItemsRef}
                        >
                            {!!calendarForWeek?.length && calendarForWeek.map(weekDay => {
                                return (
                                    <div
                                        key={weekDay.date}
                                        className="lynx-calendar-week-grid-items__inner"
                                    >
                                        {weekDay.employees.map(employee => (
                                            <CalendarItem
                                                key={employee.employeeId}
                                                employee={employee}
                                                date={weekDay.date}
                                                branchId={branchId}
                                                employeesTimetable={employeesTimetable}
                                            />
                                        ))}
                                    </div>
                                )
                            })}
                        </div>
                    </Scrollbars>
                </div>
            </CalendarContainer>
        </CalendarGridContext.Provider>
    );
}

