import {createFeed} from "../../../Api/Feeds/Feed";
import {addAlert} from "../../../../Core/Store/Alert/Actions";
import {sortFeedTags} from "./CreateFeedFormHelpers";
import Form from "../../../../Core/Components/Forms/Form";
import Input from "../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../Core/Components/Forms/Elements/Button";
import Textarea from "../../../../Core/Components/Forms/Elements/Textarea";
import DropDown from "../../../../Core/Components/Forms/Elements/DropDown";
import FormStickyContainer from "../../../../Core/Components/Forms/Elements/FormStickyContainer";
import {CreateFeedFormPropsInterface} from "./CreateFeedFormInterface";
import {CreateFeedRequestInterface} from "../../../Api/Feeds/Feed/FeedInterface";
import {FeedTagsIdEnum} from "../../../Api/Feeds/FeedTags/FeedTagsInterface";


export default function CreateFeedForm({onAfterSuccessHandle, feedTags}: CreateFeedFormPropsInterface) {
    const onSubmitHandler = async (data: CreateFeedRequestInterface) => {
        const response = await createFeed(data);
        if (onAfterSuccessHandle && response.status === "success") {
            onAfterSuccessHandle();
            addAlert({
                type: "success",
                message: response.message,
            });
        }
    };

    // id тегів, які потрібно відображати під час створення feed
    const availableTags = [
        FeedTagsIdEnum.News,
        FeedTagsIdEnum.Renewal,
        FeedTagsIdEnum.Important,
    ];


    return <>
        <Form onSubmit={onSubmitHandler}>
            <div className="row">
                <div className="col-6">
                    <Input
                        type="text"
                        name="title"
                        title="Заголовок"
                        required={true}
                    />
                </div>
                <div className="col-6">
                    <DropDown
                        name="tags"
                        options={sortFeedTags(feedTags, availableTags)}
                        title="Теги"
                        isMulti={true}
                        required={true}
                    />
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-12">
                    <Textarea name="description" title="Опис" required={true}/>
                </div>
            </div>
            <br/>

            <>
                <br/>
                <FormStickyContainer>
                    <Button
                        type="submit"
                        name="send"
                        title="Створити"
                        className="lynx-btn-primary"
                    />
                </FormStickyContainer>
            </>

        </Form>
    </>
};
