import {AnyAction} from "redux";
import {ClientSourcesStateType, ClientSourcesType} from "./Types";

const initialState: ClientSourcesStateType = [];

/**
 *
 * @param state
 * @param action
 */

const clientSources = (state: ClientSourcesStateType = initialState, action: AnyAction): ClientSourcesStateType => {
    const {type, payload} = action;
    switch (type) {
        case ClientSourcesType.GET_CLIENT_SOURCES:
            return [...payload];
        default:
            return state;
    }
};

export default clientSources;