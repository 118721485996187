import {request} from "../../../../../Core/Utils/Request/Request";
import {
    OfficeRequestInterface,
    OfficeResponseInterface,
    OfficesRequestInterface,
    OfficesResponseInterface
} from "./OfficesInterface";


export const getOffices = async (params?: OfficesRequestInterface): Promise<OfficesResponseInterface> => {
    return await request({
        url: "/v1/directory/offices/filters",
        method: "post",
        data: params,
    });
};

export const findOfficeById = async (id: number | string): Promise<OfficeResponseInterface> => {
    return await request({
        url: `/v1/directory/offices/${id}`,
        method: "get"
    });
};

export const createOffice = async (params: OfficeRequestInterface): Promise<OfficeResponseInterface> => {
    return await request({
        url: "/v1/directory/offices",
        method: "post",
        data: params
    });
};

export const editOffice = async (id: number | string, params: OfficeRequestInterface): Promise<OfficeResponseInterface> => {
    return await request({
        url: `/v1/directory/offices/${id}`,
        method: "patch",
        data: params
    });
};

export const deleteOffice = async (id: number | string): Promise<OfficeResponseInterface> => {
    return await request({
        url: `/v1/directory/offices/${id}`,
        method: "delete"
    });
};
