import {useEffect, useRef} from "react";
import "./CalendarContainer.css";
import CalendarTimeline from "../../CalendarComponents/CalendarTimeline";
import {CalendarContainerPropsInterface} from "./CalendarContainerInterface";


export default function CalendarContainer(props: CalendarContainerPropsInterface) {
    const {
        date,
        children,
        calendarScrollbarChildRef,
        getCalendarRef,
        scrollPositionTop,
        timelineClassName,
    } = props;

    const calendarRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        getCalendarRef && getCalendarRef(calendarRef);
    }, [calendarRef]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="lynx-calendar-container" ref={calendarRef}>
            <CalendarTimeline
                date={date}
                calendarRef={calendarRef}
                calendarScrollbarChildRef={calendarScrollbarChildRef}
                scrollPosition={scrollPositionTop}
                className={timelineClassName}
            />

            {children}
        </div>
    );
}