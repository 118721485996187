import {styleVars} from "../../Config/Utils/Style/Vars";


// генерація випадкового кольору
export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

// генерація масиву кольорів
export const getColorArray = (quantity: number = 4) => {
    const predefinedColors = [
        styleVars.colorPrimary,
        styleVars.colorSuccess,
        styleVars.colorWarning,
        styleVars.colorDanger,
    ];
    const colorsForLines = [];
    for (let i = 0; i < quantity; i++) {
        if (i < predefinedColors.length) {
            // Додаємо визначені кольори
            colorsForLines.push(predefinedColors[i]);
        } else {
            // Генеруємо випадковий колір для інших ліній
            const randomColor = getRandomColor();
            colorsForLines.push(randomColor);
        }
    }
    return colorsForLines;
}