import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./EmployeeTimetableFormForDay.css"

import {addAlert} from "../../../../../../../Core/Store/Alert/Actions";
import {
    createEmployeeTimetable, deleteEmployeeTimetable,
    editEmployeeTimetable
} from "../../../../../../Api/Administration/PersonnelManagement/EmployeeTimetable";
import {getBranchesAction} from "../../../../../../Store/Branches/Actions";
import {useLynxPermission} from "../../../../../../../Modules/AdministrationUsers/hooks";
import {getTimetableTypesAction} from "../../../../../../Store/Directories/TimetableTypes/Actions";
import {getEmployees} from "../../../../../../Api/Administration/Employees";

import Form from "../../../../../../../Core/Components/Forms/Form";
import Button from "../../../../../../../Core/Components/Forms/Elements/Button";
import FormStickyContainer from "../../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import DropDown, {prepareDropDownOptions} from "../../../../../../../Core/Components/Forms/Elements/DropDown";
import DateTime from "../../../../../../../Core/Components/Forms/Elements/DateTime";

import {StoreInterface} from "../../../../../../../Config/Redux/StoreInterface";
import {TimetableTypesEnum,} from "../../../../../../Api/Directories/TimetableTypes/TimetableTypesInterface";
import {formMode} from "../../../../../../../Core/Components/Forms/Form/FormInterface";
import {EmployeeTimetableFormForDayPropsInterface} from "./EmployeeTimetableFormForDayInterface";
import {EmployeeInterface} from "../../../../../../Api/Administration/Employees/EmployeesInterface";


export default function EmployeeTimetableFormForDay(props: EmployeeTimetableFormForDayPropsInterface) {
    const {
        mode,
        branchId,
        employeeDayTimetableDataProp,
        onAfterSuccessHandler,
    } = props;


    const initialStateForm = {
        workDayPeriodStart: {
            disabled: employeeDayTimetableDataProp?.dayTypeId !== TimetableTypesEnum.Workday,
            required: employeeDayTimetableDataProp?.dayTypeId === TimetableTypesEnum.Workday,
        },
        workDayPeriodEnd: {
            disabled: employeeDayTimetableDataProp?.dayTypeId !== TimetableTypesEnum.Workday,
            required: employeeDayTimetableDataProp?.dayTypeId === TimetableTypesEnum.Workday,
        },
    };

    const [permission] = useLynxPermission();

    const branches = useSelector((store: StoreInterface) => store.shortCache.branches);
    const timetableTypes = useSelector((store: StoreInterface) => store.shortCache.timetableTypes);
    const times = useSelector((store: StoreInterface) => store.longTermCache.times);
    const dispatch = useDispatch();

    const [employeeDayTimetableDataState, setEmployeeDayTimetableDataState] = useState(employeeDayTimetableDataProp);
    const [form, setForm] = useState<any>(initialStateForm);
    const [branchEmployees, setBranchEmployees] = useState<EmployeeInterface[]>([]);


    useEffect(() => {
        setEmployeeDayTimetableDataState(employeeDayTimetableDataProp);
        dispatch(getBranchesAction());
        !timetableTypes?.length && dispatch(getTimetableTypesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setEmployeeDayTimetableDataState(employeeDayTimetableDataProp);
    }, [employeeDayTimetableDataProp]);

    useEffect(() => {
        branchId && getEmployees({filters: {branchId}})
            .then(response => {
                response?.data && setBranchEmployees(response?.data);
            });
    }, [branchId]);


    const afterChangeDayTypeIdHandler = ({value}: { value: number }) => {
        if (value === TimetableTypesEnum.Workday) {
            setForm((prevState: any) => ({
                ...prevState,
                workDayPeriodStart: {
                    disabled: false,
                    required: true
                },
                workDayPeriodEnd: {
                    disabled: false,
                    required: true
                },
            }));


            setEmployeeDayTimetableDataState((prevState) => ({
                    ...prevState,
                    dayTypeId: value,
                    // якщо переключити назад на робочий день, то записуються години, які прийшли з пропсами
                    workDayPeriodStart: employeeDayTimetableDataProp?.workDayPeriodStart,
                    workDayPeriodEnd: employeeDayTimetableDataProp?.workDayPeriodEnd,
                }
            ));
        } else {
            setForm((prevState: any) => ({
                ...prevState,
                workDayPeriodStart: {
                    disabled: true,
                    required: false,
                },
                workDayPeriodEnd: {
                    disabled: true,
                    required: false,
                },
            }));

            setEmployeeDayTimetableDataState((prevState: any) => ({
                    ...prevState,
                    dayTypeId: value,
                    workDayPeriodStart: null,
                    workDayPeriodEnd: null,
                }
            ));
        }
    };

    const onSubmitHandler = (data: any, name: string) => {
        const {workDayPeriodStart, workDayPeriodEnd} = data;

        if (data.dayTypeId === TimetableTypesEnum.Workday && workDayPeriodStart >= workDayPeriodEnd) {
            addAlert({
                type: "warning",
                message: "Кінець робочих годин має бути більшим за початок робочих годин",
            });
            return;
        }

        if (name === "create") {
            createEmployeeTimetable(data).then(async (response) => {
                if (response?.status === "success") {
                    addAlert({
                        type: "success",
                        message: response.message,
                    });
                    onAfterSuccessHandler && onAfterSuccessHandler();
                }
            });
        }

        if (name === "save") {
            editEmployeeTimetable(data.id, data).then(async (response) => {
                if (response?.status === "success") {
                    addAlert({
                        type: "success",
                        message: response.message,
                    });
                    onAfterSuccessHandler && onAfterSuccessHandler();
                }
            });
        }
    };

    const onDeleteHandler = () => {
        employeeDayTimetableDataProp?.id && deleteEmployeeTimetable(employeeDayTimetableDataProp.id)
            .then(response => {
                if (response?.status === "success") {
                    addAlert({
                        type: "success",
                        message: response.message
                    });
                    onAfterSuccessHandler && onAfterSuccessHandler();
                }
            });
    };

    // блокувати дату при редагуванні та коли форма на створення відкрилась після кліку по комірці таблиці
    const disabledDate = mode === formMode.edit || (mode === formMode.create && !!employeeDayTimetableDataProp?.date);


    return (
        <div className="lynx-employee-timetable-form-for-day">
            <Form onSubmit={onSubmitHandler} data={{branchId, ...employeeDayTimetableDataState}} mode={mode}>

                <div className="row">
                    <div className="col-12 col-sm-6">
                        <DropDown
                            options={prepareDropDownOptions(branches, "id", "name")}
                            name="branchId"
                            title="Філія"
                            required
                            disabled
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <DropDown
                            name="employeeId"
                            options={prepareDropDownOptions(branchEmployees, "id", "userName")}
                            title="Співробітник"
                            required
                            disabled={!!employeeDayTimetableDataState?.employeeId}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6">
                        <DateTime
                            name="date"
                            title="Дата"
                            required
                            disabled={disabledDate}
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <DropDown
                            options={prepareDropDownOptions(timetableTypes, "id", "name")}
                            name="dayTypeId"
                            title="Тип дня"
                            required
                            afterChangeHandler={afterChangeDayTypeIdHandler}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <DropDown
                            name="workDayPeriodStart"
                            title="Початок періоду робочих годин"
                            options={prepareDropDownOptions(times, "time", "time")}
                            required={form.workDayPeriodStart.required}
                            disabled={form.workDayPeriodStart.disabled}
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <DropDown
                            name="workDayPeriodEnd"
                            title="Кінець періоду робочих годин"
                            options={prepareDropDownOptions(times, "time", "time")}
                            required={form.workDayPeriodStart.required}
                            disabled={form.workDayPeriodStart.disabled}
                        />
                    </div>
                </div>

                {mode !== formMode.view &&
                    <>
                        <br/>
                        <FormStickyContainer>
                            {mode === formMode.create ? (
                                <Button
                                    type="submit"
                                    name="create"
                                    title="Створити"
                                    className="lynx-btn-primary"
                                />
                            ) : (
                                <div className="button-container">
                                    <Button
                                        type="submit"
                                        name="save"
                                        title="Зберегти"
                                        className="lynx-btn-primary"
                                    />
                                    {permission.isDelete('branch') && <Button
                                        onClick={onDeleteHandler}
                                        name="delete"
                                        icon="fa-duotone fa-trash-can"
                                    />
                                    }
                                </div>
                            )}
                        </FormStickyContainer>
                    </>
                }
            </Form>
        </div>
    );
}
