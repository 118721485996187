import {useEffect, useState} from "react";

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {
    createServiceGroup,
    editServiceGroup,
    findServiceGroupById,
    getServiceGroups
} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups";
import {getColorArray} from "../../../../../../Core/Utils/Colors/Colors";

import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import DropDown from "../../../../../../Core/Components/Forms/Elements/DropDown";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";
import ColorPicker from "../../../../../../Core/Components/Forms/Elements/ColorPicker";

import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {ServiceGroupFormPropsInterface} from "./ServiceGroupFormInterface";
import {
    ServiceGroupInterface
} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";
import {circleSizeEnum} from "../../../../../../Core/Components/Forms/Elements/ColorPicker/ColorPickerInterface";


export default function ServiceGroupForm(props: ServiceGroupFormPropsInterface) {
    const {
        onAfterSuccessHandle,
        data,
        setFormMode,
        mode,
    } = props;

    const [
        serviceGroups, setServiceGroups
    ] = useState<ServiceGroupInterface[] | []>([]);
    const [groupData, setGroupData] = useState<any>(null);


    useEffect(() => {
        getServiceGroups({pagination: {perPage: 99999}}).then(response => {
            if (response?.data?.length) {
                const filteredServiceGroups = response.data.filter(item => {
                    return item.id !== data?.serviceGroupId;
                });

                setServiceGroups(filteredServiceGroups);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        data?.serviceGroupId && findServiceGroupById(data.serviceGroupId)
            .then(response => response?.data && setGroupData(response.data));
    }, [data?.serviceGroupId]);


    const onSubmitHandler = (data: any, name: string) => {
        if (name === 'create') {
            createServiceGroup(data)
                .then(response => {
                    if (response?.data) {
                        onAfterSuccessHandle && onAfterSuccessHandle({data: {id: response.data.id}});
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                        setFormMode(formMode.edit);
                    }
                });
        }

        if (name === 'save') {
            const params = {
                name: data.name,
                parentGroupId: data.parentGroupId,
                color: data.color,
                code: data.code,
            };

            editServiceGroup(data.id, params)
                .then(response => {
                    if (response?.data) {
                        onAfterSuccessHandle && onAfterSuccessHandle();
                        addAlert({
                            type: "success",
                            message: response.message,
                        });
                    }
                });
        }
    };


    return (
        <Form onSubmit={onSubmitHandler} data={groupData} mode={mode}>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="name"
                        title="Назва"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DropDown
                        options={serviceGroups}
                        name="parentGroupId"
                        title="Посилання на батьківську групу"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="code"
                        title="Код групи послуги"
                        required={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <ColorPicker
                        colors={getColorArray()}
                        circleSize={circleSizeEnum.xl}
                        name="color"
                        title="Колір"
                        required={true}
                    />
                </div>
            </div>

            {mode !== formMode.view && (
                <>
                    <br/>
                    <FormStickyContainer>
                        {mode === formMode.create &&
                            <Button
                                type="submit"
                                name="create"
                                title="Створити"
                                className="lynx-btn-primary"
                            />
                        }

                        {mode === formMode.edit &&
                            <Button
                                type="submit"
                                name="save"
                                title="Зберегти"
                                className="lynx-btn-primary"
                            />
                        }
                    </FormStickyContainer>
                </>
            )}
        </Form>
    )
};
