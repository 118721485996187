import React, {useEffect, useState} from "react";
import moment from "moment";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle} from "../../../../../Core/hooks";
import {
    viewDeletedServiceAction,
    restoreDeletedServiceAction,
} from "./DeletedServicesPageHelpers";
import {getDeletedServices} from "../../../../Api/Administration/ServiceManagement/DeletedServices";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import ServiceFilters from "../../../../Components/Administration/ServiceManagement/Services/ServiceFilters";
import DeletedServicesForm
    from "../../../../Components/Administration/ServiceManagement/DeletedServices/DeletedServicesForm";

import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {
    ServiceInterface,
    ServicesResponseInterface,
} from "../../../../Api/Administration/ServiceManagement/Services/ServicesInterface";


export default function DeletedServicesPage() {
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    usePageTitle("Видалені послуги");

    const [deletedServices, setDeletedServices] = useState<ServicesResponseInterface | null>(null);
    const [deletedServiceData, setDeletedServiceData] = useState<ServiceInterface>();

    const [pagination, setPagination] = useState({});
    const [isReload, setReload] = useState(true);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);


    const gridTitles: GridTitleInterface[] = [
        {
            title: "ID",
            code: "id",
            width: "50px",
        },
        {
            title: "Назва послуги",
            code: "name",
            width: "270px",
        },
        {
            title: "Код послуги",
            code: "code",
            width: "150px",
        },
        {
            title: "Опис послуги",
            code: "description",
            width: "270px",
        },
        {
            title: "Назва групи",
            code: "groupName",
            width: "270px",
        },
        {
            title: "Основна вартість послуги, грн",
            code: "price",
            width: "220px",
        },
        {
            title: "Мінімальна ціна, грн",
            code: "minimalPrice",
            width: "160px",
        },
        {
            title: "Максимальна ціна, грн",
            code: "maximalPrice",
            width: "170px",
        },
        {
            title: "Створено",
            code: "createdAt",
            width: "130px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
        {
            title: "Ким створено",
            code: "userName",
            width: "200px",
        },
        {
            title: "Видалено",
            code: "deletedAt",
            width: "130px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
    ];

    useEffect(() => {
        if (isReload) {
            getDeletedServices({filters: searchParams, pagination}).then(response => {
                if (response?.data) {
                    setReload(false);
                    setDeletedServices(response);
                }
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps


    const onSuccessHandler = () => {
        setReload(true);
        setIsModalViewOpen(false);
    };

    const onFilterHandler = (data: any) => {
        setSearchParams(data);
        setReload(true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setReload(true);
        setPagination(pagination);
    };

    const actions: Actions[] = [
        {
            title: "Переглянути",
            name: "view",
            functions: {setIsModalViewOpen, setDeletedServiceData},
            isShow: permission.isView("deletedService"),
            action: viewDeletedServiceAction,
        },
        {
            title: "Відновити",
            name: "restore",
            functions: {onSuccessHandler},
            classNameItem: "lynx-button-action-warning",
            isShow: permission.isEdit("deletedService"),
            action: restoreDeletedServiceAction,
        },
    ];


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Видалені послуги" tree={["Адміністрування", "Управління послугами"]}/>
            </BreadCrumbContainer>

            <br/>

            {permission.isView("deletedService") &&
                <div className="row">
                    <div className="col-12 order-2 col-xl-9 order-xl-1">
                        <Grid
                            data={deletedServices?.data || []}
                            title={gridTitles}
                            pagination={deletedServices?.pagination}
                            onLoadDataHandler={onLoadDataHandler}
                            actions={actions}
                        />
                    </div>
                    <div className="col-12 order-1 col-xl-3 order-xl-2">
                        <ServiceFilters onFilterHandler={onFilterHandler} searchParams={searchParams}/>
                    </div>
                </div>
            }

            <ModalSimple
                isOpen={isModalViewOpen}
                body={
                    <DeletedServicesForm
                        deletedServiceData={deletedServiceData}
                        onAfterSuccessHandle={onSuccessHandler}
                    />}
                title="Перегляд видаленої послуги"
                onAfterCloseHandle={() => setIsModalViewOpen(false)}
            />
        </>
    );
};