import {request} from "../../../../Core/Utils/Request/Request";
import {BranchesResponseInterface, BranchPostInterface, BranchResponseInterface} from "./BranchInterface";

export const getBranches = async (): Promise<BranchesResponseInterface> => {
    return await request({
        url: '/v1/branches',
        method: 'get',
    });
}

export const findBranchById = async (id: number | string): Promise<BranchResponseInterface> => {
    return await request({
        url: `/v1/branches/${id}`,
        method: 'get'
    });
};

export const createBranch = async (params: BranchPostInterface): Promise<BranchResponseInterface> => {
    return await request({
        url: '/v1/branches',
        method: 'post',
        data: params
    });
};

export const editBranch = async (id: number | string, params: BranchPostInterface): Promise<BranchResponseInterface> => {
    return await request({
        url: `/v1/branches/${id}`,
        method: 'patch',
        data: params
    });
};

export const deleteBranch = async (id: number | string): Promise<BranchResponseInterface> => {
    return await request({
        url: `/v1/branches/${id}`,
        method: 'delete'
    });
};
