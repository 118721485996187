import React from 'react';
import './Container.css';
import {useSelector} from "react-redux";
import Content from "../Content";
import Header from "../Headers/Header";
import Footer from "../Footer";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {screenType} from "../../../../Store/Conf/Types";
import {ContainerPropsInterface} from "./ContainerInterface";


export default function Container({headerChildren}: ContainerPropsInterface) {
    let screen = useSelector((store: StoreInterface) => store.system.conf.screenType);
    let isTinyMenu = useSelector((store: StoreInterface) => store.controlPanelMenu.tinyMenu);

    let lynxContent;

    if (screen === screenType.mobile) {
        lynxContent = 'lynx-container lynx-container--mobile';
    } else {
        lynxContent = isTinyMenu ? 'lynx-container lynx-container--tiny' : 'lynx-container';
    }


    return (
        <div className={lynxContent}>
            <Header headerChildren={headerChildren}/>
            <Content/>
            <Footer/>
        </div>
    );
}