import {DropDownOption, StructureOptionInterface} from "./DropDownInterface";

export const prepareDropDownOptions = (arr: any[] | [], keyId: string, keyName: string, keyColor?: string) => {
    if (arr.length !== 0) {
        return arr.map(item => {
            const newItem: DropDownOption = {
                id: item[keyId],
                name: item[keyName],
            };

            keyColor && item[keyColor] && (newItem.color = item[keyColor]);

            return newItem;
        });
    }

    return [];
};

export const structureDropDownOptions = (
    currentOptionId: number,
    options: DropDownOption[] | [],
    structure: StructureOptionInterface[],
) => {
    const newOptions = [];

    const currentOption = options.find(opt => currentOptionId === opt.id);
    currentOption && newOptions.push(currentOption);

    const structureOption = structure.find(({optionId}) => currentOptionId === optionId);

    if (!!options?.length && structureOption) {
        const filteredArray = options.filter(option => structureOption.permittedOptions.some(opt => opt === option.id));
        newOptions.push(...filteredArray);
    }

    return newOptions;
};