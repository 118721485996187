import {useEffect, useState} from "react";
import ModalSimple from "../../../../../Core/Components/Modal/ModalSimple";
import ServiceAgreementForm from "../ServiceAgreementForm";
import {formMode as formModeEnum} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {ServiceAgreementModalPropsInterface} from "./ServiceAgreementModalInterface";


export default function ServiceAgreementModal(props: ServiceAgreementModalPropsInterface) {
    const {
        isOpen,
        mode,
        serviceAgreementId,
        formControl,
        clientId,
        onAfterSuccessHandle,
        onAfterCloseHandle,
    } = props;


    const [formMode, setFormMode] = useState<formModeEnum>(mode);

    useEffect(() => setFormMode(mode), [mode]);


    let modeTitle = "";
    switch (mode) {
        case formModeEnum.view:
            modeTitle = "Перегляд договору";
            break;
        case formModeEnum.create:
            modeTitle = "Створення нового договору";
            break;
        case formModeEnum.edit:
            modeTitle = "Редагування договору";
            break;
        default:
            return null;
    }


    return (
        <ModalSimple
            isOpen={isOpen}
            body={
                <ServiceAgreementForm
                    serviceAgreementId={serviceAgreementId}
                    formControlProp={formControl}
                    clientId={clientId}
                    mode={formMode}
                    setFormMode={mode => setFormMode(mode)}
                    onAfterSuccessHandle={onAfterSuccessHandle}
                />}
            title={modeTitle}
            onAfterCloseHandle={onAfterCloseHandle}
        />
    );
};