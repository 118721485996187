export const formatTime = (
    time: string,
    returnValue: {
        hours?: boolean,
        minutes?: boolean,
        seconds?: boolean,
    } = {}
) => {
    const finishReturnValue = {
        hours: true,
        minutes: true,
        seconds: false,
        ...returnValue
    };

    const [hours, minutes, seconds] = time.split(":").map(Number);

    if (hours === 0 && minutes === 0 && seconds === 0) {
        return "0год";
    }

    const formattedTime: string[] = [];

    if (finishReturnValue.hours && hours > 0) {
        formattedTime.push(`${hours} год`);
    }

    if ((finishReturnValue.minutes && minutes > 0)) {
        formattedTime.push(`${minutes.toString().padStart(2, '0')} хв`);
    }

    if ((finishReturnValue.seconds && seconds > 0)) {
        hours > 0 && minutes === 0 && formattedTime.push("00 хв");

        formattedTime.push(`${seconds.toString().padStart(2, '0')} сек`);
    }

    return formattedTime.join(" ");
};