import IRequestConfig from '../../Core/Utils/Request/IRequestConfig';

const config: IRequestConfig = {
    baseURL: '/api',
    timeout: 100000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true
}

export default config;