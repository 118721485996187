import {AnyAction} from "redux";
import {ClientPaymentStatesStateType, ClientPaymentStatesType} from "./Types";

const initialStates: ClientPaymentStatesStateType = [];

const clientPaymentStates = (
    states: ClientPaymentStatesStateType = initialStates, action: AnyAction
): ClientPaymentStatesStateType => {
    const {type, payload} = action;
    switch (type) {
        case ClientPaymentStatesType.GET_CLIENT_PAYMENT_STATES:
            return [...payload];
        default:
            return states;
    }
};

export default clientPaymentStates;