import {useEffect, useState} from "react";

import {useLynxPermission} from "../../../../../../Modules/AdministrationUsers/hooks";
import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {getServiceGroups} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups";
import {restoreDeletedService} from "../../../../../Api/Administration/ServiceManagement/DeletedServices";

import Form from "../../../../../../Core/Components/Forms/Form";
import Input from "../../../../../../Core/Components/Forms/Elements/Input";
import Button from "../../../../../../Core/Components/Forms/Elements/Button";
import DropDown, {prepareDropDownOptions} from "../../../../../../Core/Components/Forms/Elements/DropDown";
import Textarea from "../../../../../../Core/Components/Forms/Elements/Textarea";
import FormStickyContainer from "../../../../../../Core/Components/Forms/Elements/FormStickyContainer";

import {DeletedServiceFormPropsInterface} from "./DeletedServicesFormInterface";
import {
    ServiceGroupInterface
} from "../../../../../Api/Administration/ServiceManagement/ServiceGroups/ServiceGroupsInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";


export default function DeletedServicesForm(props: DeletedServiceFormPropsInterface) {
    const {
        onAfterSuccessHandle,
        deletedServiceData,
    } = props;

    const [permission] = useLynxPermission();

    const [
        serviceGroups, setServiceGroups
    ] = useState<ServiceGroupInterface[] | []>([]);


    useEffect(() => {
        getServiceGroups().then(response => {
            if (response?.status === "success" && response.data) {
                setServiceGroups(response.data);
            }
        });
    }, []);


    const onSubmitHandler = (data: any) => {
        restoreDeletedService(data.id).then(response => {
            if (onAfterSuccessHandle && response?.status === "success") {
                onAfterSuccessHandle();
                addAlert({
                    type: "success",
                    message: response.message,
                });
            }
        });
    };


    return (
        <Form onSubmit={onSubmitHandler} data={deletedServiceData} mode={formMode.view}>
            <div className="row">
                <div className="col-12">
                    <Input
                        type="text"
                        name="name"
                        title="Назва послуги"
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Input
                        type="text"
                        name="code"
                        title="Код послуги"
                        required
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <DropDown
                        name="groupId"
                        title="Група послуги"
                        required
                        options={prepareDropDownOptions(serviceGroups, "id", "name")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-4">
                    <Input
                        type="text"
                        name="price"
                        title="Основна вартість послуги, грн"
                        required
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <Input
                        type="text"
                        name="minimalPrice"
                        title="Мінімальна ціна, грн"
                        required
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <Input
                        type="text"
                        name="maximalPrice"
                        title="Максимальна ціна, грн"
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Textarea
                        name="description"
                        title="Опис послуги"
                    />
                </div>
            </div>

            {permission.isEdit("deletedService") && (
                <>
                    <br/>
                    <FormStickyContainer>
                        <Button
                            type="submit"
                            name="restore"
                            title="Відновити"
                            className="lynx-btn-primary"
                        />
                    </FormStickyContainer>
                </>
            )}
        </Form>
    )
};
