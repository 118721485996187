import React from 'react';
import './Spinner.css'
import {ApplicationState} from "../../Store/Application/Types";
import {useSelector} from "react-redux";

export default function Spinner() {
    const isLoader = useSelector((store: { application: ApplicationState }) => store.application.loader);

    return isLoader ? <div className="lynx-spinner-container">
        <img src="/assets/images/spinner.gif" className="lynx-spinner-container__images" alt="Lynx spinner"/>
    </div> : null
}
