import {useEffect} from 'react';


export default function usePageTitle (title: string, unmount = true)  {
    useEffect(() => {
        const previousTitle = document.title;
        document.title = title;

        return () => {
            if (unmount) {
                document.title = previousTitle;
            }
        };
    }, [title, unmount]);
};