import React, {useEffect, useState} from "react";
import moment from "moment/moment";

import {useLynxPermission} from "../../../../hooks";
import {
    addUserToPermissionGroupAction,
    deleteUserToPermissionGroupAction,
} from "./UsersGroupPermissionGridHelpers";
import {deleteUserPermissionGroup, getUsersPermissionGroup} from "../../../../Api/Permissions/UsersPermissionGroup";
import {setReloadPage} from "../../../../../../Core/Store/Reload/Actions";
import {addAlert} from "../../../../../../Core/Store/Alert/Actions";

import Grid from "../../../../../../Core/Components/Tables/Grids/Grid";
import UserGroupPermissionForm from "../UserGroupPermissionForm";
import ModalSimple from "../../../../../../Core/Components/Modal/ModalSimple";
import PreparedPrompt from "../../../../../../Core/Components/Modal/PreparedPrompt";

import {UsersGroupPermissionGridPropsInterface} from "./UsersGroupPermissionGridInterface";
import {GridTitleInterface} from "../../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {Actions} from "../../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {
    UserPermissionGroupInterface
} from "../../../../Api/Permissions/UsersPermissionGroup/UsersPermissionGroupInterface";


export default function UsersGroupPermissionGrid(props: UsersGroupPermissionGridPropsInterface) {
    const {
        data,
        mode,
    } = props;

    const [permission] = useLynxPermission();

    const [isReload, setIsReload] = useState(true);
    const [isModalAddUserOpen, setIsModalAddUserOpen] = useState(false);
    const [users, setUsers] = useState<UserPermissionGroupInterface[] | []>([]);

    const [userId, setUserId] = useState<number | string>();
    const [isPromptOpen, setIsPromptOpen] = useState(false);


    const title: GridTitleInterface[] = [
        {
            title: "ID",
            code: "id",
            width: "50px"
        },
        {
            title: "Користувач (ПІБ)",
            code: "userName",
            width: "300px",
        },
        {
            title: "Email",
            code: "userEmail",
            width: "300px",
        },
        {
            title: "Дата додавання",
            code: "date",
            width: "150px",
            handler: (value: string) => moment(value).format("DD.MM.YYYY"),
        },
    ];


    useEffect(() => {
        if (data.permissionGroupId && isReload) {
            getUsersPermissionGroup({
                filters: {permissionGroupId: data.permissionGroupId},
                pagination: {perPage: 99999},
            }).then(response => {
                if (response?.data) {
                    setUsers(response.data);
                    setIsReload(false);
                }
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps


    const actions: Actions[] = [
        {
            title: "Видалити",
            name: "deleteUserToPermissionGroup",
            isShow: permission.isDelete("permissionGroup") && mode !== formMode.view,
            functions: {setIsPromptOpen, setUserId},
            action: deleteUserToPermissionGroupAction,
        },
    ];

    const navActions: Actions[] = [
        {
            title: "Додати користувача",
            name: "addUserToPermissionGroup",
            isShow: permission.isCreate("permissionGroup") && mode !== formMode.view,
            functions: {setIsModalAddUserOpen},
            action: addUserToPermissionGroupAction,
        },
    ];

    const onSuccessHandle = () => {
        setIsModalAddUserOpen(false);
        setIsReload(true);
        setReloadPage("GroupPermissionPages", true);
    };

    const onApproveDelete = async (id: number | string) => {
        const response = await deleteUserPermissionGroup(id);

        if (response?.status === "success") {
            addAlert({
                type: response.status,
                message: response.message,
            });
            onSuccessHandle();
            setIsPromptOpen(false);
        }
    };


    return (
        <>
            <Grid
                data={users}
                isHidePadding
                title={title}
                actions={actions}
                navActions={navActions}
            />

            {isModalAddUserOpen &&
                <ModalSimple
                    isOpen={isModalAddUserOpen}
                    title="Додавання користувача до групи прав"
                    body={
                        <UserGroupPermissionForm
                            data={data}
                            mode={mode}
                            onAfterSuccessHandle={onSuccessHandle}
                        />
                    }
                    widthClass="xs"
                    onAfterCloseHandle={() => setIsModalAddUserOpen(false)}
                />
            }

            {userId &&
                <PreparedPrompt
                    isOpen={isPromptOpen}
                    code="delete"
                    onCancel={() => setIsPromptOpen(false)}
                    onApprove={() => onApproveDelete(userId)}
                />
            }
        </>
    );
}

