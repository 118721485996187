import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLynxTariffPlan} from "../../hooks";
import Dashboard from "../../../Core/Components/ControlPanel/Dashboard";
import {signOut} from "../../../Modules/Auth/Utils/Sign";
import {configLoader} from "../../../Core/Utils/ConfigLoader";
import {useLynxPermission} from "../../../Modules/AdministrationUsers/hooks";
import {filterMenu} from "../../../Core/Components/ControlPanel/Menu/Menu/MenuHelpers";
import {getBranchesAction} from "../../Store/Branches/Actions";
import {resetProfileAction} from "../../../Modules/AdministrationUsers/Store/Profile/Actions";
import ToggleBranchButton from "../../Components/Branches/ToggleBranch/ToggleBranchButton";
import {StoreInterface} from "../../../Config/Redux/StoreInterface";
import {HeaderChildrenInterface} from "../../../Core/Components/ControlPanel/Containers/Headers/Header/HeaderInterface";

export default function DashboardPage() {
    const [permission] = useLynxPermission();
    const {isAvailable} = useLynxTariffPlan();
    const dispatch = useDispatch();
    const branches = useSelector((store: StoreInterface) => store.shortCache.branches);


    useEffect(() => {
        dispatch(getBranchesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const isShowPage = (code: string): boolean => isAvailable(code) && permission.isView(code);


    const menu = {
        mainMenu: [
            {
                link: '/',
                title: 'Стрічка подій',
                icon: 'fa-duotone fa-newspaper',
                isShow: isShowPage("feed"),
            },
            // {
            //     link: '/1',
            //     title: 'Статистика',
            //     icon: 'fa-duotone fa-chart-line'
            // },
            {
                link: '/calendar',
                title: 'Календар',
                icon: 'fa-duotone fa-calendar-days',
                isShow: isShowPage("visits"),
            },
            {
                link: '/crm',
                title: 'CRM',
                icon: 'fa-duotone fa-chart-network',
                items: [
                    {
                        link: '/crm/clients',
                        title: 'Клієнти',
                        isShow: isShowPage("client"),
                    },
                    {
                        link: '/crm/record-management',
                        title: 'Управління записом',
                        items: [
                            {
                                link: '/crm/record-management/visits',
                                title: 'Візити',
                                isShow: isShowPage("visits"),
                            },
                        ]
                    },
                    {
                        link: '/marketing',
                        title: 'Маркетинг',
                        items: [
                            {
                                link: '/crm/marketing/customer-groups',
                                title: 'Групи клієнтів',
                                isShow: isShowPage("customerGroup"),
                            },
                        ]
                    },
                ]
            },
            {
                link: '/documents',
                title: 'Документи',
                icon: 'fa-duotone fa-file',
                items: [
                    {
                        link: '/documents/services-performed-certificates',
                        title: 'Акти наданих послуг',
                        isShow: isShowPage("servicesPerformedCertificate"),
                    },
                    {
                        link: '/documents/service-agreement',
                        title: 'Договір про надання послуг',
                        isShow: isShowPage("serviceAgreement"),
                    },
                ]
            },
            {
                link: '/administration',
                title: 'Адміністрування',
                icon: 'fa-duotone fa-screwdriver-wrench',
                items: [
                    // в базі users залишається, але для користувача фактично users = employees
                    // {
                    //     link: '/administration/users',
                    //     title: 'Користувачі',
                    //     isShow:isShowPage("user"),
                    // },
                    {
                        link: '/administration/group-permission',
                        title: 'Групи прав',
                        isShow: isShowPage("permissionGroup"),
                    },
                    {
                        link: '/administration/branches',
                        title: 'Філії',
                        isShow: isShowPage("branch"),
                    },
                    // {
                    //     link: '/1',
                    //     title: 'Інтеграція',
                    // },
                    // {
                    //     link: '/1',
                    //     title: 'Друк документів',
                    // },
                    {
                        link: '/administration/service-management',
                        title: 'Управління послугами',
                        items: [
                            {
                                link: '/administration/service-management/service-groups',
                                title: 'Групи послуг',
                                isShow: isShowPage("serviceGroup"),
                            },
                            {
                                link: '/administration/service-management/services',
                                title: 'Послуги',
                                isShow: isShowPage("service"),
                            },
                            {
                                link: '/administration/service-management/deleted-services',
                                title: 'Видалені послуги',
                                isShow: isShowPage("deletedService"),
                            },
                        ]
                    },
                    {
                        link: '/administration/personnel-management',
                        title: 'Управління персоналом',
                        items: [
                            {
                                link: '/administration/personnel-management/employees',
                                title: 'Співробітники',
                                isShow: isShowPage("employee"),
                            },
                            {
                                link: '/administration/personnel-management/employee-timetable',
                                title: 'Графік роботи',
                                isShow: isShowPage("employeeTimetable"),
                            },
                            {
                                link: '/administration/personnel-management/job-positions',
                                title: 'Посади',
                                isShow: isShowPage("jobPosition"),
                            },
                            {
                                link: '/administration/personnel-management/workplaces',
                                title: 'Робочі місця',
                                isShow: isShowPage("office"),
                            },
                        ]
                    },
                    {
                        link: '/administration/directories',
                        title: 'Довідники',
                        items: [
                            {
                                link: '/administration/directories/client-sources',
                                title: 'Джерела клієнтів',
                                isShow: isShowPage("clientSource"),
                            },
                        ]
                    },
                    {
                        link: '/administration/application-settings',
                        title: 'Налаштування застосунку',
                        isShow: isShowPage("applicationSettings"),
                    },
                ],
            },
            {
                link: '/reports',
                title: 'Звіти',
                icon: 'fa-duotone fa-chart-line',
                items: [
                    {
                        link: '/reports/number-of-visits',
                        title: 'Кількість відвідувань',
                        isShow: isShowPage("visitReport"),
                    },
                    {
                        link: '/reports/client-portrait',
                        title: 'Портрет клієнта',
                        isShow: isShowPage("clientReport"),
                    },
                ],
            },
            {
                link: '/payments',
                title: 'Платежі',
                icon: 'fa-duotone fa-credit-card',
                items: [
                    {
                        link: '/payments/client-payments',
                        title: 'Платежі клієнтів',
                        isShow: isShowPage("clientPayment"),
                    },
                ],
            },
            {
                link: '/user-settings',
                title: 'Налаштування користувача',
                icon: 'fa-duotone fa-user-gear',
            },
            {
                link: '/profile',
                title: 'Мій профіль',
                icon: 'fa-duotone fa-id-card-clip',
                handler: () => {
                    const {redirectToProfile} = configLoader().authConfig;
                    window.location.href = redirectToProfile.link;
                }
            },
        ],
        bottomMenu: [
            {
                link: '/applications',
                icon: 'icofont-cube',
                handler: () => {
                    const {redirectNoAuthUser} = configLoader().authConfig;
                    window.location.href = redirectNoAuthUser.link;
                }
            },
            {
                link: '/logout',
                icon: 'icofont-exit',
                handler: async () => {
                    await signOut();
                    dispatch(resetProfileAction());
                }
            },
        ]
    }
    let brandData = {
        link: '/',
        logo: '\\assets\\images\\logo\\logo.png',
        title: 'Project',
    }

    const headerChildren: HeaderChildrenInterface = {
        toLeft: [],
    };
    // якщо клініка має тільки одну філії - не відображаємо кнопку
    branches.length > 1 && headerChildren.toLeft!.push(<ToggleBranchButton/>);


    return <>
        <Dashboard
            menu={{
                mainMenu: filterMenu(menu.mainMenu),
                bottomMenu: menu.bottomMenu,
            }}
            brand={brandData}
            headerChildren={headerChildren}
        />
    </>;
}
