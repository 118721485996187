import {NavLink} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {toggleMobileMenu} from "../../../../../Store/ControlPanelMenu/Actions";
import MobileNestedLevel from "../MobileNestedLevel";
import {MobileFirstLevelPropsInterface} from "./MobileFirstLevelInterface";

export default function MobileFirstLevel(props: MobileFirstLevelPropsInterface) {
    const {
        link,
        icon,
        title,
        items,
        handler,
    } = props;


    const dispatch = useDispatch();


    if (items) {
        return <MobileNestedLevel
            link={link}
            title={title}
            icon={icon}
            items={items}
            level={1}
        />
    } else {
        return <li>
            {handler && <div onClick={handler}><i className={icon}/><span>{title}</span></div>}
            {!handler &&
                <NavLink to={link} onClick={() => dispatch(toggleMobileMenu())}>
                    <i className={icon}/><span>{title}</span>
                </NavLink>
            }
        </li>
    }
}
