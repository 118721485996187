import React from 'react';
import "./TextBox.css"
import {TextBoxPropsInterface} from "./TextBoxInterface"


export default function TextBox({icon, title, iconClassColor}: TextBoxPropsInterface) {
    const iconClassName = [icon];
    iconClassColor && iconClassName.push(`lynx-text-box__icon--${iconClassColor}`)

    return (
        <div className="lynx-text-box">
            <div className="lynx-text-box__content">
                <div className="lynx-text-box__icon">
                    <i className={iconClassName.join(" ")}/>
                </div>
                <h6 className="lynx-text-box__title">{title}</h6>
            </div>
        </div>
    );
};