import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {deleteService, findServiceById} from "../../../../Api/Administration/ServiceManagement/Services";
import {ServiceInterface} from "../../../../Api/Administration/ServiceManagement/Services/ServicesInterface";


export async function viewServiceAction(data: ServiceInterface, functions: any) {
    const {setIsModalViewOpen, setServiceData} = functions;
    if (!setIsModalViewOpen || !setServiceData) {
        return;
    }

    setIsModalViewOpen(true);

    const response = await findServiceById(data.id);
    if (response?.status === "success" && response.data) {
        setServiceData(response.data)
    }
}

export async function editServiceAction(data: ServiceInterface, functions: any) {
    const {setIsModalEditOpen, setServiceData} = functions;
    if (!setIsModalEditOpen || !setServiceData) {
        return;
    }

    setIsModalEditOpen(true);

    const response = await findServiceById(data.id);
    if (response?.status === "success" && response.data) {
        setServiceData(response.data)
    }
}

export async function deleteServiceAction(data: ServiceInterface, functions: any) {
    const {onSuccessHandler} = functions;
    if (!onSuccessHandler) {
        return;
    }

    const response = await deleteService(data.id);
    if (response?.status === "success") {
        onSuccessHandler();
        addAlert({
            type: response.status,
            message: response.message,
        });
    }
}