import React, {useRef, useState} from "react";
import {Scrollbars} from "react-custom-scrollbars-2";
import "./CalendarForDayGrid.css";

import {CalendarGridContext} from "../../CalendarComponents/CalendarContainer/CalendarContainerContext";

import CalendarItem from "../../CalendarComponents/CalendarItem";
import CalendarContainer from "../../CalendarComponents/CalendarContainer";

import {CalendarForDayGridPropsInterface} from "./CalendarForDayGridInterface";


export default function CalendarForDayGrid(props: CalendarForDayGridPropsInterface) {
    const {
        calendarForDay,
        branchId,
        date,
        employeesTimetable,
    } = props;


    // для скролу до current time, якщо isCurrentDate = true
    const employeesWrapperRef = useRef<HTMLDivElement>(null);
    // для синхронізації скролів блоку timeline з блоком співробітників
    const [scrollPositionTop, setScrollPositionTop] = useState<number>(0);

    const [scrollScrollbars, setScrollScrollbars] = useState<React.UIEvent<any, UIEvent>>()


    return (
        <CalendarGridContext.Provider value={{
            scrollScrollbars,
        }}>
            <CalendarContainer
                date={date}
                calendarScrollbarChildRef={employeesWrapperRef}
                scrollPositionTop={scrollPositionTop}
            >
                <div className="lynx-calendar-day-grid-items__wrapper">
                    <Scrollbars
                        className="lynx-calendar-day-grid-items__scrollbars"
                        onScroll={setScrollScrollbars}
                        onUpdate={value => setScrollPositionTop(value.scrollTop)}
                        style={{borderRadius: "5px"}} // borderRadius тільки інлайн(для верхнього правого бордера)
                    >
                        <div className="lynx-calendar-day-grid-items" ref={employeesWrapperRef}>
                            {!!calendarForDay?.length && calendarForDay.map(employee => (
                                <CalendarItem
                                    key={employee.employeeId}
                                    employee={employee}
                                    date={date.value}
                                    branchId={branchId}
                                    employeesTimetable={employeesTimetable}
                                />
                            ))}
                        </div>
                    </Scrollbars>
                </div>
            </CalendarContainer>
        </CalendarGridContext.Provider>
    );
}