import {RefObject, useEffect, useState} from "react";
import {useWindowScrollPosition, useWindowSize} from "rooks";
import useElementDirection from "./useElementDirection";
import {
    BoundariesInterface,
    PositionDataInterface,
    PositionEnum,
} from "./hooksInterface";


export default function useBoundaryCheck(
    relativePositionRef: RefObject<HTMLElement>, // елемент відносно якого треба визначити відстань
    absolutePositionRef: RefObject<HTMLElement>, // елемент, який треба зпозиціонувати
    onPositionCheck: (positionInfo: PositionDataInterface) => void,
    additionalDeps: any[] = [], // додаткові залежності
) {
    // для відслідковування зміни розміру екрану та для визначення відстані до меж viewport
    const {innerHeight, innerWidth} = useWindowSize();

    // для відслідковування зміни позиції relativePositionRef при скролі window
    const position = useWindowScrollPosition(); // default config: { wait = 100, passive = true }

    // потрібно для кроку 3
    const [boundaries, setBoundaries] = useState<BoundariesInterface | null>(null);

    const relativeElementRect = relativePositionRef?.current?.getBoundingClientRect();
    const absoluteElementRect = absolutePositionRef?.current?.getBoundingClientRect();

    //  розміри елемента, який відносно якого треба спозиціонувати
    const relativeElementWidth = relativeElementRect?.width;
    const relativeElementHeight = relativeElementRect?.height;

    // розміри елемента, який треба спозиціонувати
    const absoluteElementWidth = absoluteElementRect?.width;
    const absoluteElementHeight = absoluteElementRect?.height;

    // 3 - визначаємо в яку сторону потрібно відкрити dropdown, також є в залежностях в 2му useEffect
    const elementDirection = useElementDirection(boundaries);


    useEffect(() => {
        if (
            !relativeElementRect
            || !absoluteElementRect
            || !innerWidth
            || !innerHeight
            || !absoluteElementWidth
            || !absoluteElementHeight
        ) return;

        // 1 - відстань до меж вьюпорта елемента, відносно якого треба зробити перевірку
        const distanceRelativeElementToTopViewport = relativeElementRect.top;
        const distanceRelativeElementToRightViewport = innerWidth - relativeElementRect.right;
        const distanceRelativeElementToBottomViewport = innerHeight - relativeElementRect.bottom;
        const distanceRelativeElementToLeftViewport = relativeElementRect.left;

        // 2 - визначаємо відстань до меж вьюпорта з урахуванням розмірів елемента, якого треба буде спозиціонувати та записуємо в стейт
        const fullDistanceToTopViewport = distanceRelativeElementToTopViewport - absoluteElementHeight;
        const fullDistanceToRightViewport = distanceRelativeElementToRightViewport - absoluteElementWidth;
        const fullDistanceToBottomViewport = distanceRelativeElementToBottomViewport - absoluteElementHeight;
        const fullDistanceToLeftViewport = distanceRelativeElementToLeftViewport - absoluteElementWidth;

        setBoundaries({
            top: fullDistanceToTopViewport,
            right: fullDistanceToRightViewport,
            bottom: fullDistanceToBottomViewport,
            left: fullDistanceToLeftViewport,
        });
    }, [absolutePositionRef?.current, relativePositionRef?.current, innerHeight, innerWidth, position.scrollY, ...additionalDeps]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (
            !elementDirection
            || !relativeElementRect
            || !absoluteElementWidth
            || !absoluteElementHeight
            || !relativeElementWidth
            || !relativeElementHeight
            || !absolutePositionRef
        ) return;

        // 4 - визначаємо координати для dropdown, враховуючи з якої сторони має відкриватись dropdown
        let relativeElementRectX: number;
        let relativeElementRectY: number;

        elementDirection.directionX === PositionEnum.Right
            ? relativeElementRectX = relativeElementRect.right
            : relativeElementRectX = relativeElementRect.left - absoluteElementWidth;
        elementDirection.directionY === PositionEnum.Bottom
            ? relativeElementRectY = relativeElementRect.bottom
            : relativeElementRectY = relativeElementRect.top - absoluteElementHeight;

        onPositionCheck({
            coordinates: {
                top: relativeElementRectY,
                left: relativeElementRectX,
            },
            openingDirections: elementDirection,
            relativeElementData: {
                width: relativeElementWidth,
                height: relativeElementHeight,
            },
            absoluteElementData: {
                ref: absolutePositionRef,
            }
        });
    }, [boundaries]); // eslint-disable-line react-hooks/exhaustive-deps
}