import React from 'react';
import "./ClientHeader.css";
import {ClientHeaderPropsInterface} from "./ClientHeaderInterface";


export default function ClientHeader({client}: ClientHeaderPropsInterface) {
    const {
        avatar,
        firstName,
        lastName,
        birthday,
        age,
        numberPhone,
    } = client;

    const formattedDate = birthday.split('-').reverse().join('.');


    return (
        <div className="lynx-client-header">
            <div className="lynx-client-header__left">
                <div>
                    <img className="lynx-client-header__img" src={avatar} alt={`${lastName} ${firstName}`}/>
                </div>
                <div className="lynx-client-header__desc">
                    <div className="lynx-client-header__title-wrap">
                        <h3 className="lynx-client-header__title">{lastName} {firstName}</h3>
                        <span>({formattedDate} - {age} років)</span>
                    </div>
                    <div className="lynx-client-header__contacts">
                        <a href={`tel:+${numberPhone}`}><i className="fa-duotone fa-phone"/></a>
                        <a href={`https://t.me/+${numberPhone}`}
                           target="_blank"
                           rel="noreferrer">
                            <i className="fa-brands fa-telegram"/>
                        </a>
                        <a href={`https://wa.me/+${numberPhone}`}
                           target="_blank"
                           rel="noreferrer">
                            <i className="fa-brands fa-whatsapp"/>
                        </a>
                    </div>
                </div>
            </div>
            {/*<div className="lynx-client-header__right">*/}
            {/*    <Button*/}
            {/*        name="button2"*/}
            {/*        title="Button2"*/}
            {/*        className="lynx-button"*/}
            {/*    />*/}
            {/*    <Button*/}
            {/*        name="button1"*/}
            {/*        title="Button1"*/}
            {/*        className="lynx-btn-primary"*/}
            {/*    />*/}
            {/*    <ButtonActions*/}
            {/*        actions={[]}*/}
            {/*        className="lynx-button-action__button-md"*/}
            {/*        icon="fa-solid fa-ellipsis"*/}
            {/*    />*/}
            {/*</div>*/}

        </div>
    );
};