import React, {useEffect} from "react";
import {CirclePicker, ColorResult} from "react-color";
import "./ColorPicker.css"
import {circleSizeEnum, ColorPickerPropsInterface} from "./ColorPickerInterface";
import HelperInfo from "../../../HelperInfo";


export default function ColorPicker(props: ColorPickerPropsInterface) {
    const {
        disabled,
        required,
        name,
        title,
        value,
        colors,
        width,
        circleSize = circleSizeEnum.md,
        className,
        tooltip,
        initComponent,
        changeHandler,
        onHover,
        beforeChangeHandler,
        afterChangeHandler,
    } = props;

    useEffect(() => {
        if (initComponent) {
            initComponent({
                name,
                value,
                required,
                beforeChangeHandler,
                afterChangeHandler
            });
        }
    }, [initComponent, required]); // eslint-disable-line react-hooks/exhaustive-deps


    const colorPickerClass = ["lynx-color-picker"];
    disabled && colorPickerClass.push("disabled");
    required && colorPickerClass.push("required");
    className && colorPickerClass.push(className);

    const onChangeHandler = (color: ColorResult) => {
        changeHandler && changeHandler({
            name,
            value: color?.hex,
        });
    };


    return (
        <div className={colorPickerClass.join(" ")}>
            <div className="place-title">
                <label htmlFor={name} className="lynx-color-picker__title">{title}</label>
                {tooltip && <HelperInfo {...tooltip}/>}
            </div>
            <CirclePicker
                data-testid={"color-picker-" + name}
                onChange={onChangeHandler}
                color={value}
                colors={colors}
                onSwatchHover={onHover}
                width={width ? width : "100%"}
                circleSize={circleSize}
                circleSpacing={circleSize / 2}
            />
        </div>
    );
};