import {IAlert} from "../../Components/Alert/Alert/IAlert";

/**
 * Константы для Reducers, а также это типы actions которые будут обработаны системой
 */
export const AlertActions = {
    ADD_ALERT: 'ADD_ALERT',
    REMOVE_ALERT: 'REMOVE_ALERT',
    CLEAR_ALERT: 'CLEAR_ALERT'
};

/**
 * Состояние State, Props, Reducers
 */
interface Alerts {
    alerts: IAlert[]
}

export interface AlertState extends Alerts {
}

export interface AlertProps extends Alerts {
}

export interface AlertReducer extends Alerts {
}
