import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./CalendarTimeline.css";

import {getTimesAction} from "../../../../Store/Directories/Times/Actions";
import {getCurrentTime} from "../../CalendarsHelpers";

import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {CalendarTimelinePropsInterface} from "./CalendarTimelineInterface";
import {DateTypeEnum} from "../../../../../Core/Components/DatePickerSwitcher/DatePickerSwitcherInterface";


export default function CalendarTimeline(props: CalendarTimelinePropsInterface) {
    const {
        date,
        calendarRef,
        calendarScrollbarChildRef,
        scrollPosition,
        className,
    } = props;

    const dispatch = useDispatch();
    const times = useSelector((store: StoreInterface) => store.longTermCache.times);

    // для визначення висоти timeline для current time
    const timelineRef = useRef<HTMLDivElement>(null);
    const [heightTimeline, setHeightTimeline] = useState<number>();
    const [currentTimePosition, setCurrentTimePosition] = useState<number>();

    // для timeline
    const [currentTime, setCurrentTime] = useState({
        time: getCurrentTime().currentTime,
        partTimeOfDay: getCurrentTime().partTimeOfDay,
    });

    // для синхронізації скролів блоку timeline з блоком спіробітників
    const timelineContainerRef = useRef<HTMLDivElement>(null);

    // змінні css з CalendarContainer.css
    const [calendarHeaderHeight, setCalendarHeaderHeight] = useState(0);


    useEffect(() => {
        !times.length && dispatch(getTimesAction());

        // визначення висоти timeline для current time
        setHeightTimeline(timelineRef.current?.getBoundingClientRect().height);

        // отримуємо значення змінних CSS
        const root = document.documentElement;
        setCalendarHeaderHeight(parseInt(getComputedStyle(root).getPropertyValue("--calendarHeaderHeight"), 10));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // запускаємо інтервал оновлення поточного часу, якщо це поточна дата
    useEffect(() => {
        if (!showCurrentTime()) {
            return;
        }

        const interval = setInterval(() => {
            setCurrentTime({
                time: getCurrentTime().currentTime,
                partTimeOfDay: getCurrentTime().partTimeOfDay,
            });
        }, 10000);

        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // скролимо до поточного часу, якщо це поточна дата
    useEffect(() => {
        const heightCalendar = calendarRef?.current?.offsetHeight;
        const scrollBarEmployeesDiv = calendarScrollbarChildRef?.current?.parentElement;

        if (currentTimePosition && scrollBarEmployeesDiv && heightCalendar && !!calendarHeaderHeight) {
            scrollBarEmployeesDiv.scrollTop = (currentTimePosition - (heightCalendar / 2 - calendarHeaderHeight));
        }
    }, [currentTimePosition, calendarHeaderHeight]); // eslint-disable-line react-hooks/exhaustive-deps

    // записуємо значення top для current time в блоці timeline
    useEffect(() => {
        heightTimeline && showCurrentTime && setCurrentTimePosition(heightTimeline * currentTime.partTimeOfDay);
    }, [heightTimeline, date]); // eslint-disable-line react-hooks/exhaustive-deps

    // синхронізуємо скрол блоку timeline з блоком спіробітників
    useEffect(() => {
        timelineContainerRef?.current && (timelineContainerRef.current.scrollTop = scrollPosition);
    }, [scrollPosition]);


    function showCurrentTime() {
        const {period, value, year} = date;
        const {currentDate, currentWeek, currentYear} = getCurrentTime();

        const isDayPeriod = period === DateTypeEnum.Day;
        const isWeekPeriod = period === DateTypeEnum.Week;

        const isShowCurrentTimeByCurrentDay = isDayPeriod && currentDate === value;
        const isShowCurrentTimeByCurrentWeek = isWeekPeriod && currentWeek === value && currentYear === year;

        return isShowCurrentTimeByCurrentDay || isShowCurrentTimeByCurrentWeek;
    }

    const timelineWrapperClassName = ["lynx-calendar-timeline__wrapper"];
    className && timelineWrapperClassName.push(className);


    return (
        <div className={timelineWrapperClassName.join(" ")} ref={timelineContainerRef}>
            <div className="lynx-calendar-timeline" ref={timelineRef}>
                {showCurrentTime() && heightTimeline && currentTime.time &&
                    <div className="lynx-calendar-timeline-current-time"
                         style={{top: `${currentTimePosition}px`}}>
                        <div className="lynx-calendar-timeline-current-time__time">
                            {currentTime.time}
                        </div>
                        <div className="lynx-calendar-timeline-current-time__line"/>
                    </div>
                }

                {times?.map(({time}) => {
                    return (
                        <div className="lynx-calendar-timeline-item" key={time}>
                            <div
                                className="lynx-calendar-timeline-item__time"
                                key={time}
                            >
                                {time.slice(0, 5)}
                            </div>
                            <div className="lynx-calendar-timeline-item__line"/>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}