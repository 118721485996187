import {SearchGridPropsInterface} from "./SearchGridInterface";
import './SearchGrid.css';

export default function SearchGrid(props: SearchGridPropsInterface) {
    const {searchHandler} = props;


    return <div className="lynx-search-grid">
        <span className="lynx-search-grid__icon">
            <i className="fa-duotone fa-magnifying-glass"></i>
        </span>
        <input className="lynx-search-grid__input" type="text" placeholder="Пошук по таблиці" onChange={searchHandler}/>
    </div>
}