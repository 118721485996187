import "./ProfileNavigationHeader.css";
import {ProfileNavigationHeaderPropsInterface} from "./ProfileNavigationHeaderInterface";
import Avatar from "../../../../../../Avatar";


export default function ProfileNavigationHeader({profile}: ProfileNavigationHeaderPropsInterface) {
    return (
        <div className="lynx-profile-navigation-header">
            {profile?.avatar
                ? <img
                    className="lynx-profile-navigation-header__avatar"
                    src={profile.avatar}
                    alt="Профіль користувача"/>
                : <Avatar
                    seed={profile.id}
                    caller="ProfileNavigationHeader"
                    className="lynx-profile-navigation-header__default-avatar"
                />
            }
            <div>
                <div className="lynx-profile-navigation-header__title">{profile.lastName} {profile.firstName}</div>
                <div className="lynx-profile-navigation-header__email">
                    <i className="fa-duotone fa-envelope fa-sm"/>
                    <span>{profile.email}</span>
                </div>
            </div>
        </div>
    );
};