import moment from "moment";

export const getDayOfWeekNumber = (dateString: string): number => {
    const date = moment(dateString, "YYYY-MM-DD");
    return date.isoWeekday();
};

export const isTimeInRange = (workDayStart: string, workDayEnd: string, timeString: string) => {
    const startTime = moment(workDayStart, "HH:mm:ss");
    const endTime = moment(workDayEnd, "HH:mm:ss").subtract(15, "minutes"); // Віднімаємо 15 хвилин від endTime
    const time = moment(timeString, "HH:mm:ss");

    return time.isBetween(startTime, endTime, null, "[]"); // Включаючи початковий час та (endTime - 15 хвилин)
};

export const getCurrentTime = () => {
    const currentTimeMoment = moment();

    const partTimeOfDay = (currentTimeMoment.minute() / 60 + currentTimeMoment.hour()) / 24;

    return {
        currentTime: currentTimeMoment.format("HH:mm"),
        currentDate: currentTimeMoment.format("YYYY-MM-DD"),
        currentWeek: currentTimeMoment.isoWeek(),
        currentYear: currentTimeMoment.year(),
        partTimeOfDay,
    };
};

export const getWeekNumberByDate = (date: string): number => {
    const momentDate = moment(date, "YYYY-MM-DD");
    return momentDate.isoWeek();
};