import {Dispatch, SetStateAction, useState} from "react";
import {MapStateFormInterface} from "../Components/Forms/Form/FormInterface";


export default function useForm() {
    const [mapStateForm, setMapStateForm] = useState<MapStateFormInterface>();

    return {
        mapStateForm,
        setMapStateForm: setMapStateForm as Dispatch<SetStateAction<MapStateFormInterface>>,
    };
};