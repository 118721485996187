import {getCustomerGroups} from "../../../../Api/CRM/Marketing/CustomerGroups/CustomerGroups";
import {CustomerGroupsType} from "./Types";
import {CustomerGroupInterface} from "../../../../Api/CRM/Marketing/CustomerGroups/CustomerGroupsInterface";

export const setCustomerGroups = (customerGroups: CustomerGroupInterface[] | []) => {
    return {
        type: CustomerGroupsType.GET_CUSTOMER_GROUP,
        payload: customerGroups,
    };
};

export const getCustomerGroupsAction = () => async (dispatch: Function) => {
    const customerGroups = await getCustomerGroups();
    if (customerGroups.status === 'success' && customerGroups.data) {
        dispatch(setCustomerGroups(customerGroups?.data));
    }
};