import "./ShortInfoContainer.css"
import ShortInfoItem from "../ShortInfoItem";
import {ShortCardContainerPropsInterface} from "./ShortInfoContainerInterface"
import {ItemDataInterface} from "../ShortInfoItem/ShortInfoItemInterface";

export default function ShortInfoContainer(props: ShortCardContainerPropsInterface) {
    const {
        itemsData,
        classNameContainer,
    } = props;

    const shortInfoContainerClassName = ["lynx-short-info-container"];
    classNameContainer && shortInfoContainerClassName.push(classNameContainer);


    return (
        <ul className="lynx-short-info-container">
            {itemsData.map((itemData: ItemDataInterface) =>
                <li key={itemData.description} className="lynx-short-info-container__item">
                    <ShortInfoItem itemData={itemData}/>
                </li>
            )}
        </ul>
    );
};