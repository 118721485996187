import React from 'react';
import './NotificationBell.css';

export default function NotificationBell() {
    return (
        <div className="lynx-header-item">
            <div className="lynx-toggle-notification-panel">
                <i className="icofont-alarm"/>
            </div>
        </div>
    );
}

