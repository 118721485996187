import React, {useRef, useState} from "react";
import moment from "moment";
import "./CalendarUnfoldedCard.css";

import {addAlert} from "../../../../../../Core/Store/Alert/Actions";
import {useLynxPermission} from "../../../../../../Modules/AdministrationUsers/hooks";
import {setReloadComponent} from "../../../../../../Core/Store/Reload/Actions";
import {updateVisitState} from "../../../../../Api/CRM/RecordManagement/Visits/Visits/Visits";
import {
    createServicesPerformedCertificateAction,
} from "../../../../Documents/ClientDocumentsGrid/ClientDocumentsGridHelpers";
import {createClientPaymentAction} from "./CalendarUnfoldedCardHelpers";

import Fieldset from "../../../../../../Core/Components/Forms/Elements/Fieldset";
import FormHR from "../../../../../../Core/Components/Forms/Elements/FormHR";
import ClientModalView from "../../../../CRM/Clients/ClientModalView";
import VisitModal from "../../../../CRM/RecordManagement/Visits/VisitModal";
import FieldsetWithElements from "../../../../../../Core/Components/Forms/Elements/FieldsetWithElements";
import ColoredDropDown from "../../../../../../Core/Components/Forms/Elements/ColoredDropDown";
import ServicesPerformedCertificateModal
    from "../../../../Documents/ServicesPerformedCertificates/ServicesPerformedCertificateModal";
import ButtonActions from "../../../../../../Core/Components/ButtonActions";
import ClientPaymentModal from "../../../../Payments/ClientPayments/ClientPaymentModal";

import {CalendarUnfoldedCardPropsInterface} from "./CalendarUnfoldedCardInterface";
import {formMode} from "../../../../../../Core/Components/Forms/Form/FormInterface";
import {Actions} from "../../../../../../Core/Components/ButtonActions/ButtonActionsInterface";

import {testSources} from "../CalendarShortCard/CalendarShortCard";


export default function CalendarUnfoldedCard(props: CalendarUnfoldedCardPropsInterface) {
    const {
        visit,
        closeCardHandler,
    } = props;

    const {
        clientId,
        employeeName,
        statusId,
        workplaceName,
        endTime,
        startTime,
        client,
        id: visitId,
        branchId,
        employeeId,
    } = visit;


    const [permission] = useLynxPermission();
    const cardRef = useRef(null);

    const [isModalClientViewOpen, setIsModalClientViewOpen] = useState(false);
    const [isModalVisitOpen, setIsModalVisitOpen] = useState(false);
    const [isServicesPerformedCertificateModalOpen, setIsServicesPerformedCertificateModalOpen] = useState(false);
    const [isClientPaymentModalOpen, setIsClientPaymentModalOpen] = useState(false);
    const [mode, setModalMode] = useState<formMode>();


    const actions: Actions[] = [
        {
            title: "Створити акт",
            name: "createServicePerformedCertificate",
            isShow: permission.isCreate("servicesPerformedCertificate"),
            functions: {setModalMode, setIsServicesPerformedCertificateModalOpen},
            action: createServicesPerformedCertificateAction,
        },
        {
            title: "Створити платіж",
            name: "createClientPayment",
            isShow: permission.isCreate("clientPayment"),
            functions: {setModalMode, setIsClientPaymentModalOpen},
            action: createClientPaymentAction,
        },
    ];

    const onStatusVisitChange = ({value}: { value: number }) => {
        updateVisitState(visitId, {statusId: value}).then((response) => {
            if (response?.data) {
                addAlert({
                    type: "success",
                    message: response.message,
                });
                onSuccessHandler();
            }
        });
    };

    const onClientClickHandler = () => {
        if (!permission.isView("client")) {
            addAlert({
                type: "warning",
                message: "Відсутні права для перегляду клієнта"
            })
            return;
        }

        setIsModalClientViewOpen(true);
    };

    const onVisitClickHandler = () => {
        if (permission.isEdit("client")) setModalMode(formMode.edit);
        else if (permission.isView("client")) setModalMode(formMode.view);
        else {
            addAlert({
                type: "warning",
                message: "Відсутні права для перегляду візиту"
            })
            return;
        }

        setIsModalVisitOpen(true);
    };

    const getClientAge = (birthday: string) => {
        return {
            age: moment().diff(moment(birthday, "YYYY-MM-DD"), "years"),
            birthday: moment(birthday, "YYYY-MM-DD").format("DD.MM.YYYY"),
        }
    };

    const onSuccessHandler = () => {
        setReloadComponent("CalendarsContainer", true);
    };


    return (
        <>
            <div className="lynx-calendar-unfolded-card" ref={cardRef}>
                <div className="lynx-calendar-unfolded-card__header">
                    <span className="lynx-calendar-unfolded-card__header-left">
                        <i className="fa-duotone fa-clock fa-lg lynx-calendar-unfolded-card__icon-duration"/>
                        <span className="lynx-calendar-unfolded-card__header-text">
                          {startTime} - {endTime}
                        </span>
                    </span>
                    <span
                        onClick={closeCardHandler}
                        className="lynx-calendar-unfolded-card__header-right"
                    >
                        <i className="fa-solid fa-xmark"/>
                    </span>
                </div>

                <FormHR isPaddingOut type="dashed"/>
                <Fieldset title="Клієнт"/>

                <div className="lynx-calendar-unfolded-card__list">
                    <div className="lynx-calendar-unfolded-card__item">
                        <div
                            onClick={onClientClickHandler}
                            className="lynx-calendar-unfolded-card__link"
                        >
                            <i className="fa-duotone fa-link"></i>
                            <span>{client.lastName} {client.firstName} {client.middleName}</span>
                        </div>
                    </div>
                    <div className="lynx-calendar-unfolded-card__item">
                        <div className="lynx-calendar-unfolded-card__desc">
                            {getClientAge(client.birthday).birthday} - {getClientAge(client.birthday).age} років
                        </div>
                    </div>
                </div>

                <FormHR isPaddingOut type="dashed"/>
                <FieldsetWithElements title="Візит" elementInTheEnd>
                    <div className="lynx-calendar-unfolded-card__fieldset-visit">
                         <span
                             className="lynx-calendar-unfolded-card__fieldset-visit-btn"
                             onClick={onVisitClickHandler}
                         >
                            <i className="fa-duotone fa-pen-to-square fa-lg"/>
                        </span>
                        {permission.isCreate("servicesPerformedCertificate") &&
                            <ButtonActions
                                actions={actions}
                                className="lynx-button-action__button-sm"
                                icon="fa-solid fa-ellipsis"
                            />
                        }
                    </div>
                </FieldsetWithElements>

                <div className="lynx-calendar-unfolded-card__list">
                    <div className="lynx-calendar-unfolded-card__item">
                        <span className="lynx-calendar-unfolded-card__title">Статус</span>
                        <span className="lynx-calendar-unfolded-card__desc">
                           <div style={{
                               maxWidth: "250px",
                           }}>
                                <ColoredDropDown
                                    name="statusId"
                                    options={testSources}
                                    value={statusId}
                                    isClearable={false}
                                    changeHandler={onStatusVisitChange}
                                />
                           </div>
                        </span>
                    </div>
                    <div className="lynx-calendar-unfolded-card__item">
                        <span className="lynx-calendar-unfolded-card__title">Співробітник</span>
                        <span className="lynx-calendar-unfolded-card__desc">{employeeName}</span>
                    </div>
                    <div className="lynx-calendar-unfolded-card__item">
                        <span className="lynx-calendar-unfolded-card__title">Робоче місце</span>
                        <span className="lynx-calendar-unfolded-card__desc">{workplaceName}</span>
                    </div>
                </div>
            </div>

            {(isModalVisitOpen && mode) &&
                <VisitModal
                    isOpen={isModalVisitOpen}
                    mode={mode}
                    visitData={visit}
                    onAfterCloseHandle={() => setIsModalVisitOpen(false)}
                    onAfterSuccessHandle={onSuccessHandler}
                />
            }

            {isModalClientViewOpen &&
                <ClientModalView
                    isOpen={isModalClientViewOpen}
                    clientId={clientId}
                    onAfterCloseHandle={() => setIsModalClientViewOpen(false)}
                />
            }

            {(isServicesPerformedCertificateModalOpen && mode) &&
                <ServicesPerformedCertificateModal
                    isOpen={isServicesPerformedCertificateModalOpen}
                    mode={mode}
                    data={{
                        clientId,
                        branchId,
                        employeeId,
                        visitIds: [visitId],
                    }}
                    formControl={{
                        fieldset: {client: {visible: false}},
                        fields: {
                            branchId: {disabled: true},
                            employeeId: {disabled: true},
                        }
                    }}
                    onAfterCloseHandle={() => setIsServicesPerformedCertificateModalOpen(false)}
                />
            }

            {(isClientPaymentModalOpen && mode) &&
                <ClientPaymentModal
                    isOpen={isClientPaymentModalOpen}
                    mode={mode}
                    formControl={{
                        fieldset: {client: {visible: false}},
                    }}
                    data={{
                        clientId,
                    }}
                    onAfterCloseHandle={() => setIsClientPaymentModalOpen(false)}
                />
            }
        </>
    );
}