import {request} from "../../../../Core/Utils/Request/Request";
import {
    CreateClientPaymentRequestInterface,
    EditClientPaymentRequestInterface,
    ClientPaymentResponseInterface,
    ClientPaymentsRequestInterface,
    ClientPaymentsResponseInterface,
} from "./ClientPaymentsInterface";
import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export const getClientPayments = async (
    params?: ClientPaymentsRequestInterface
): Promise<ClientPaymentsResponseInterface> => {
    return await request({
        url: "/v1/payments/filters",
        method: "post",
        data: params,
    });
};

export const findClientPaymentById = async (
    id: number | string
): Promise<ClientPaymentResponseInterface> => {
    return await request({
        url: `/v1/payments/${id}`,
        method: "get",
    });
};

export const createClientPayment = async (
    params: CreateClientPaymentRequestInterface
): Promise<ClientPaymentResponseInterface> => {
    return await request({
        url: "/v1/payments",
        method: "post",
        data: params,
    });
};

export const editClientPayment = async (
    id: number | string, params: EditClientPaymentRequestInterface
): Promise<ClientPaymentResponseInterface> => {
    return await request({
        url: `/v1/payments/${id}`,
        method: "patch",
        data: params,
    });
};

export const deleteClientPayment = async (
    id: number | string
): Promise<ResponseInterface> => {
    return await request({
        url: `/v1/payments/${id}`,
        method: "delete",
    });
};
