import {ResponseInterface} from "../../../../Core/Utils/Request/ResponseInterface";


export enum ApplicationSettingGroupIdsEnum {
    Security = 1,
}

export enum ApplicationSettingKeysEnum {
    EmergencyBlockingOfTheClinic ="emergency_blocking_of_the_clinic",
}

export interface ApplicationSettingInterface {
    id: number | string,
    title: string,
    keyName: ApplicationSettingKeysEnum,
    value: number,
    groupId: ApplicationSettingGroupIdsEnum,
    groupName: string,
}

export interface EditApplicationSettingsRequestInterface {
    value?: number,
}

export interface ApplicationSettingsRequestInterface {
    filters?: {
        groupId?: ApplicationSettingGroupIdsEnum,
    }
}

export interface ApplicationSettingsResponseInterface extends ResponseInterface {
    data?: ApplicationSettingInterface[],
}