import {request} from "../../../../../Core/Utils/Request/Request";
import {
    BuildEmployeeTimetableRequestInterface,
    BuildEmployeeTimetableResponseInterface,
    DestroyEmployeeTimetableRequestInterface,
    DestroyEmployeeTimetableResponseInterface,
    EmployeeTimetableRequestInterface,
    EmployeeTimetableResponseInterface,
    EmployeeTimetablesRequestInterface,
    EmployeeTimetablesResponseInterface
} from "./EmployeeTimetableInterface";

export const getEmployeeTimetables = async (
    params?: EmployeeTimetablesRequestInterface
): Promise<EmployeeTimetablesResponseInterface> => {
    return await request({
        url: "/v1/employee-timetable/filters",
        method: "post",
        data: params,
    });
};

export const findEmployeeTimetableById = async (id: number | string): Promise<EmployeeTimetableResponseInterface> => {
    return await request({
        url: `/v1/employee-timetable/${id}`,
        method: "get"
    });
};

export const createEmployeeTimetable = async (
    params: EmployeeTimetableRequestInterface
): Promise<EmployeeTimetableResponseInterface> => {
    return await request({
        url: "/v1/employee-timetable",
        method: "post",
        data: params
    });
};

export const editEmployeeTimetable = async (
    id: number | string, params: EmployeeTimetableRequestInterface
): Promise<EmployeeTimetableResponseInterface> => {
    return await request({
        url: `/v1/employee-timetable/${id}`,
        method: "patch",
        data: params
    });
};

export const deleteEmployeeTimetable = async (id: number | string): Promise<EmployeeTimetableResponseInterface> => {
    return await request({
        url: `/v1/employee-timetable/${id}`,
        method: "delete"
    });
};

// Створення графіку по шаблону
export const buildEmployeeTimetable = async (
    params: BuildEmployeeTimetableRequestInterface
): Promise<BuildEmployeeTimetableResponseInterface> => {
    return await request({
        url: `/v1/employee-timetable/build`,
        method: "post",
        data: params
    });
};

// Видалення інтервалу графіку
export const destroyEmployeeTimetable = async (
    params: DestroyEmployeeTimetableRequestInterface
): Promise<DestroyEmployeeTimetableResponseInterface> => {
    return await request({
        url: `/v1/employee-timetable/destroy`,
        method: "post",
        data: params
    });
};
