import {useState} from 'react';
import './Footer.css';
import {useSelector} from "react-redux";
import {configLoader} from "../../../../Utils/ConfigLoader";
import ModalSimple from "../../../Modal/ModalSimple";
import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {screenType} from "../../../../Store/Conf/Types";


export default function Footer() {
    let screen = useSelector((store: StoreInterface) => store.system.conf.screenType);
    let isTinyMenu = useSelector((store: StoreInterface) => store.controlPanelMenu.tinyMenu);
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);

    const supportConfig = configLoader().supportConfig;

    let lynxFooter;
    if (screen === screenType.mobile) {
        lynxFooter = 'lynx-footer lynx-footer--mobile'
    } else {
        lynxFooter = isTinyMenu ? 'lynx-footer lynx-footer--tiny' : 'lynx-footer';
    }

    const serviceModalToggle = () => setIsServiceModalOpen(!isServiceModalOpen);


    return (
        <>
            <div className={lynxFooter}>
                <div className="lynx-footer__copyright">
                    2017 - {new Date().getFullYear()} ©<a href="https://jetdents.com" target="_blank"
                                                          rel="noreferrer noopener">Lynx</a>
                </div>
                <div className="lynx-footer__menu">
                    {screen !== screenType.mobile && <a href="https://jetdents.com">Посібник користувача</a>}
                    <button onClick={serviceModalToggle}>Техпідтримка</button>
                </div>
            </div>

            {supportConfig.form &&
                <ModalSimple
                    body={supportConfig.form()}
                    isOpen={isServiceModalOpen}
                    title="Технічна підтримка"
                    onAfterCloseHandle={serviceModalToggle}
                    widthClass="xs"
                />
            }

        </>
    );
}
