import {Outlet} from "react-router-dom";
import {useCookies} from "react-cookie";
import {configLoader} from "../../ConfigLoader";

export default function PrivateOutlet() {
    const [cookies] = useCookies(['token']);

    const isAuth = !!cookies.token;
    const {redirectNoAuthUser} = configLoader().authConfig;

    if (!isAuth) {
        window.location.href = redirectNoAuthUser.link;
        return <></>;
    }

    return <Outlet/>
}
