import {
    EditPermissionGroupItemRequestInterface,
    PermissionGroupPermissionItemInterface,
    PermissionGroupStructureItemInterface,
    PermissionsEnum,
} from "../../../../Api/Permissions/GroupPermission/GroupPermissionInterface";


export const flattenArrayOfObject = (
    permission: PermissionGroupPermissionItemInterface[],
    structure: PermissionGroupStructureItemInterface[],
) => {

    let objectForForm: { [key: string]: any } = {};
    let objectWithCodeId: {
        sections: { [key: string]: number | string },
        featureFlags: { [key: string]: number | string }
    } = {
        sections: {},
        featureFlags: {},
    };

    permission?.forEach((item) => {
        // записуємо id permission кожного окремого розділу
        objectWithCodeId.sections[`${item.code}`] = item.id;

        // створюємо ключ-значення permission для data Form
        for (const key in item) {
            if (key === PermissionsEnum.isView
                || key === PermissionsEnum.isCreate
                || key === PermissionsEnum.isEdit
                || key === PermissionsEnum.isDelete) {
                objectForForm[`${item.code}-${key}`] = (item[key] ? 1 : 0);
            }
        }

        // створюємо ключ-значення featureFlag для data Form
        if (item.featureFlags?.length) {
            item.featureFlags.forEach((featureFlag: any) => {
                objectForForm[`${item.code}-featureFlag-${featureFlag.codeFlags}`] = 1;
            });
        }
    });

    // записуємо id кожного featureFlag
    structure?.forEach((item) => {
        if (item.featureFlags?.length) {
            item.featureFlags.forEach((featureFlag: any) => {
                objectWithCodeId.featureFlags[`${featureFlag.code}`] = featureFlag.id;
            });
        }
    });


    return {
        formData: objectForForm,
        codeId: objectWithCodeId,
    };
};

export const restoreArrayOfObject = (
    flatObject: { [key: string]: any },
    codeIdData: {
        sections: { [key: string]: number | string },
        featureFlags: { [key: string]: number | string }
    }
) => {
    const array: any[] = [];

    // ітерація по об'єкту, який містить дані з code та id розділу групи прав
    for (const code in codeIdData.sections) {
        let permissionSection: { [key: string]: any } = {
            id: codeIdData.sections[code],
            featureFlags: [],
        };

        // ітерація по об'єкту, який поверне Form при submit
        for (const key in flatObject) {
            // parts[0] - code розділу
            // parts[1] - PermissionsEnum або featureFlag
            // parts[2] - code featureFlag, якщо це featureFlag, якщо ні - відсутнє
            const parts = key.split("-");

            if (parts[0] === code) {
                // якщо featureFlag
                if (parts[1] === "featureFlag" && flatObject[key]) {
                    permissionSection.featureFlags.push(codeIdData.featureFlags[`${parts[2]}`]);

                    continue;
                }

                // якщо не featureFlag
                permissionSection = {
                    ...permissionSection,
                    [`${parts[1]}`]: flatObject[key],
                }

            }
        }

        array.push(permissionSection as EditPermissionGroupItemRequestInterface);
    }

    return array;
};