import {useState} from "react";
import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useLynxTariffPlan} from "../../../../hooks";

import ServicesPerformedCertificateForm from "../ServicesPerformedCertificateForm";
import TabsLinkContent from "../../../../../Core/Components/TabsLink/TabsLinkContent";
import FormFlexible from "../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormFlexible";
import FormTabsContainer from "../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsContainer";
import FormTabsItem from "../../../../../Core/Components/Forms/FormVisualizations/FormTabs/FormTabsItem";
import TabsLinkNavigation from "../../../../../Core/Components/TabsLink/TabsLinkNavigation";
import ClientPaymentsGrid from "../../../Payments/ClientPayments/ClientPaymentsGrid";
import CertificateServicesForm from "../CertificateServicesForm";

import {
    ServicesPerformedCertificateFormContainerPropsInterface,
} from "./ServicesPerformedCertificateFormContainerInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {
    ClientPaymentTypeIdEnum
} from "../../../../Api/Directories/Payments/ClientPayments/ClientPaymentTypes/ClientPaymentTypesInterface";
import {
    ServicesPerformedCertificateInterface
} from "../../../../Api/Documents/ServicesPerformedCertificates/ServicesPerformedCertificatesInterface";


export default function ServicesPerformedCertificateFormContainer(
    props: ServicesPerformedCertificateFormContainerPropsInterface
) {
    const {
        mode,
        setFormMode,
        onAfterSuccessHandle,
        formControlProp,
        data,
    } = props;


    const [permission] = useLynxPermission();
    const {isAvailable} = useLynxTariffPlan();
    const [certificateData, setCertificateData] = useState<Partial<ServicesPerformedCertificateInterface>>(data ?? {});


    return <>
        <FormFlexible>
            <FormTabsContainer>
                <TabsLinkNavigation
                    isTabsContent
                    tabs={[
                        {
                            id: "servicePerformedCertificate",
                            content:
                                <FormTabsItem>
                                    <i title="Акт наданих послуг" className="fa-duotone fa-file-invoice"/>
                                </FormTabsItem>
                        },
                        {
                            id: "services",
                            content: mode !== formMode.create
                                && certificateData?.id
                                && permission.isView("service")
                                && <FormTabsItem>
                                    <i title="Послуги" className="fa-duotone fa-list-alt"/>
                                </FormTabsItem>
                        },
                        {
                            id: "clientPayments",
                            content: mode !== formMode.create
                                && certificateData?.id
                                && permission.isView("clientPayment")
                                && isAvailable("clientPayment")
                                && <FormTabsItem>
                                    <i title="Платежі клієнта" className="fa-duotone fa-money-check-dollar-pen"/>
                                </FormTabsItem>
                        }
                    ]}
                    name="officeForm"
                />
            </FormTabsContainer>


            <TabsLinkContent tabFor="officeForm" tabs={[
                {
                    id: "servicePerformedCertificate",
                    component:
                        <ServicesPerformedCertificateForm
                            formControlProp={formControlProp}
                            data={certificateData}
                            setData={setCertificateData}
                            mode={mode}
                            setFormMode={mode => setFormMode(mode)}
                            onAfterSuccessHandle={onAfterSuccessHandle}
                        />
                },
                {
                    id: "services",
                    component: certificateData?.id
                        && mode
                        && <CertificateServicesForm
                            servicesPerformedCertificateId={certificateData.id}
                            mode={mode}
                            onAfterSuccessHandle={onAfterSuccessHandle}
                        />

                },
                {
                    id: "clientPayments",
                    component: certificateData?.id &&
                        <ClientPaymentsGrid
                            filters={{
                                certificateId: certificateData?.id,
                            }}
                            formControl={{
                                fields: {
                                    clientId: {disabled: true},
                                    typeId: {disabled: true},
                                    certificateId: {disabled: true},
                                },
                            }}
                            data={{
                                clientId: certificateData?.clientId,
                                clientName: certificateData?.clientName,
                                typeId: ClientPaymentTypeIdEnum.AccordingToTheCertificate,
                                certificateId: certificateData?.id,
                            }}
                            isHideFilters
                            isHidePadding
                        />
                },
            ]}/>
        </FormFlexible>
    </>;
}
