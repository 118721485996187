import React, {useEffect, useState} from 'react';
import moment from "moment";
import "./ClientsPage.css";

import {useLynxPermission} from "../../../../../Modules/AdministrationUsers/hooks";
import {useLynxSearchParams, usePageTitle, useReloadPage} from "../../../../../Core/hooks";
import {openLink} from "../../../../../Config/Routing/customHistory";
import {addAlert} from "../../../../../Core/Store/Alert/Actions";
import {deleteClient, findClientById, getClients} from "../../../../Api/CRM/Clients";
import {setReloadComponent, setReloadPage} from "../../../../../Core/Store/Reload/Actions";

import BreadCrumbContainer
    from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumbContainer";
import BreadCrumb from "../../../../../Core/Components/ControlPanel/Containers/BreadCrumbs/BreadCrumb";
import Grid from "../../../../../Core/Components/Tables/Grids/Grid";
import Button from "../../../../../Core/Components/Forms/Elements/Button";
import ClientsFilters from "../../../../Components/CRM/Clients/ClientsFilters/ClientsFilters";
import ClientModalView from "../../../../Components/CRM/Clients/ClientModalView";
import ClientModal from "../../../../Components/CRM/Clients/ClientModal";

import {GridTitleInterface} from "../../../../../Core/Components/Tables/Grids/Grid/GridInterface";
import {
    GridPaginationInterface
} from "../../../../../Core/Components/Tables/Grids/GridPagination/GridPaginationInterface";
import {
    UsersFilterInterface
} from "../../../../../Modules/AdministrationUsers/Components/Users/UsersFilters/UsersFiltersInterface";
import {ClientInterface, ClientsResponseInterface} from "../../../../Api/CRM/Clients/ClientsInterface";
import {formMode} from "../../../../../Core/Components/Forms/Form/FormInterface";
import {Actions} from "../../../../../Core/Components/ButtonActions/ButtonActionsInterface";
import {OnSuccessHandlerInterface} from "./ClientsPageInterface";


export default function ClientsPage() {
    const [permission] = useLynxPermission();
    const [searchParams, setSearchParams] = useLynxSearchParams();
    const isReload = useReloadPage("clients");
    usePageTitle("Клієнти");

    const [clients, setClients] = useState<ClientsResponseInterface | null>(null); // !! замість any записати потрібний тип !!
    const [clientData, setClientData] = useState<ClientInterface | null>(null);
    const [clientId, setClientId] = useState<number | string>('');
    const [modalMode, setModalMode] = useState<formMode>(formMode.view);
    const [pagination, setPagination] = useState({});

    const [isModalViewOpen, setIsModalViewOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const gridTitles: GridTitleInterface[] = [
        {
            title: 'ID',
            code: 'id',
            width: '70px',
        },
        {
            title: 'Ім’я',
            code: 'firstName',
            width: '120px',
        },
        {
            title: 'Прізвище',
            code: 'lastName',
            width: '140px',
        },
        {
            title: 'По батькові',
            code: 'middleName',
            width: '140px',
        },
        {
            title: 'Дата народження',
            code: 'birthday',
            width: '130px',
            handler: (value: string) => moment(value).format("DD.MM.YYYY"),
        },
        {
            title: 'Номер телефону',
            code: 'numberPhone',
            width: '150px',
        },
        {
            title: 'Стать',
            code: 'genderName',
            width: '90px',
        },
        {
            title: 'Джерело залучення',
            code: 'clientSourceName',
            width: '170px',
        },
        {
            title: 'Філія першого звернення',
            code: 'branchName',
            width: '190px',
        },
        {
            title: 'Адреса клієнта',
            code: 'address',
            width: '420px',
        },
        {
            title: 'Перше відвідування',
            code: 'firstVisit',
            width: '170px',
            handler: (value: string) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
        {
            title: 'Останнє відвідування',
            code: 'lastVisit',
            width: '170px',
            handler: (value: string) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
    ];

    useEffect(() => {
        if (isReload) {
            getClients({filters: searchParams, pagination}).then(response => {
                if (response.status === 'success') {
                    setClients(response);
                }

                setReloadPage("clients", false);
            });
        }
    }, [isReload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => () => setReloadPage("clients", true), []);


    const onFilterHandler = (data: UsersFilterInterface) => {
        setSearchParams(data);
        setReloadPage("clients", true);
    };

    const onLoadDataHandler = (pagination: GridPaginationInterface) => {
        setReloadPage("clients", true);
        setPagination(pagination);
    };

    const onSuccessHandler = (params: OnSuccessHandlerInterface = {}) => {
        setReloadPage("clients", true);
        setIsModalOpen(false);

        if (params.operation && params.operation === "create" && params.data?.clientId) {
            setClientId(clientId);
            setReloadComponent("clientModalView", true);
            setIsModalViewOpen(true);
        }
    };

    const actions: Actions[] = [
        {
            title: 'Переглянути',
            name: 'view',
            action({id}: any) {
                setClientId(id);
                setReloadComponent("clientModalView", true);
                setIsModalViewOpen(true);
            }
        },
        {
            title: 'Відкрити в новому вікні',
            name: 'newWindow',
            classNameItem: "lynx-button-action-info",
            action(data: any) {
                const clientUrl = `${window.location.origin}/crm/clients/${data.id}`;
                openLink(clientUrl);
            }
        },
        {
            title: 'Редагувати',
            name: 'create',
            classNameItem: "lynx-button-action-warning",
            isShow: (): boolean => permission.isEdit('client'),
            action: async ({id}: any) => {
                setModalMode(formMode.edit)
                setIsModalOpen(true);

                const response = await findClientById(id);
                if (response?.data) {
                    setClientData(response.data);
                }
            },
        },
        {
            title: 'Видалити',
            name: 'delete',
            classNameItem: "lynx-button-action-danger",
            isShow: (): boolean => permission.isDelete('client'),
            action: async ({id}: any) => {
                const response = await deleteClient(id);

                if (response?.status === "success") {
                    onSuccessHandler({});
                    addAlert({
                        type: "success",
                        message: response.message,
                    });
                }
            }
        },
    ];


    return (
        <>
            <BreadCrumbContainer>
                <BreadCrumb title="Клієнти" tree={['CRM']}/>
                <div className="lynx-clients-page__buttons">
                    {permission.isCreate('client') &&
                        <>
                            <Button
                                name="addClient"
                                title="Додати клієнта"
                                className="lynx-btn-primary"
                                onClick={() => {
                                    setModalMode(formMode.create)
                                    setIsModalOpen(true)
                                }}
                            />
                        </>
                    }
                </div>
            </BreadCrumbContainer>

            <br/>

            <div className="row">
                <div className="col-12 order-2 col-xl-9 order-xl-1">
                    <Grid
                        data={clients?.data || []}
                        title={gridTitles}
                        pagination={clients?.pagination}
                        onLoadDataHandler={onLoadDataHandler}
                        actions={actions}
                    />
                </div>
                <div className="col-12 order-1 col-xl-3 order-xl-2">
                    <ClientsFilters onFilterHandler={onFilterHandler} searchParams={searchParams}/>
                </div>
            </div>

            <ClientModalView
                isOpen={isModalViewOpen}
                clientId={clientId}
                onAfterCloseHandle={() => setIsModalViewOpen(false)}
            />

            <ClientModal
                isOpen={isModalOpen}
                mode={modalMode}
                clientData={clientData}
                onAfterCloseHandle={() => setIsModalOpen(false)}
                onAfterSuccessHandle={onSuccessHandler}
            />
        </>
    );
};