import {
    ReloadTypes,
} from "./Types";
import {dispatch} from "../../../Config/Redux/Store";


// Page
export const setReloadPageAction = (key: string, value: boolean) => {
    return {
        type: ReloadTypes.RELOAD_PAGE,
        payload: {
            key,
            value,
        },
    };
};

export const setReloadPage = (key: string, value: boolean) => {
    dispatch(setReloadPageAction(key, value));
};


// Component
export const setReloadComponentAction = (key: string, value: boolean) => {
    return {
        type: ReloadTypes.RELOAD_COMPONENT,
        payload: {
            key,
            value,
        },
    };
};

export const setReloadComponent = (key: string, value: boolean) => {
    dispatch(setReloadComponentAction(key, value));
};
