import {useEffect, useState} from "react";
import {useMediaMatch} from "rooks";
import moment from "moment";
import "./CalendarFilters.css";

import {useBranch} from "../../../hooks";
import {getEmployees} from "../../../Api/Administration/Employees";

import DropDown, {prepareDropDownOptions} from "../../../../Core/Components/Forms/Elements/DropDown";
import DatePickerSwitcher from "../../../../Core/Components/DatePickerSwitcher";

import {EmployeeInterface} from "../../../Api/Administration/Employees/EmployeesInterface";
import {CalendarFiltersPropsInterface} from "./CalendarFiltersInterface";
import {
    DateTypeEnum,
    OnChangeDatePickerSwitcherParamsInterface,
} from "../../../../Core/Components/DatePickerSwitcher/DatePickerSwitcherInterface";


export default function CalendarFilters({filters, setFilters}: CalendarFiltersPropsInterface) {
    const {availableBranches, selectedEmployeeWorkingBranch} = useBranch();

    const isMobile = useMediaMatch("(max-width: 850px)");

    const [employees, setEmployees] = useState<EmployeeInterface[]>([]);
    const [isFiltersOpen, setIsFiltersOpen] = useState(!isMobile);

    let defaultDate = moment();
    let defaultSelectedDataType = DateTypeEnum.Day;
    if (filters.date.period === DateTypeEnum.Day && typeof filters.date.value === "string") {
        defaultDate = moment(filters.date.value, "YYYY-MM-DD");
    } else if (filters.date.period === DateTypeEnum.Week) {
        defaultSelectedDataType = DateTypeEnum.Week;
        defaultDate = moment().year(filters.date.year).week(Number(filters.date.value));
    }

    useEffect(() => {
        selectedEmployeeWorkingBranch?.id && setFilters({
            ...filters,
            branchId: selectedEmployeeWorkingBranch.id,
        });
    }, [selectedEmployeeWorkingBranch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        filters.branchId && getEmployees({filters: {branchId: filters.branchId}}).then(response => {
            response?.data && setEmployees(response.data);
        });
    }, [filters.branchId]);

    useEffect(() => {
        setIsFiltersOpen(!isMobile);
    }, [isMobile]);


    const onDropDownEmployeesHandler = ({value}: { value: (number | string)[] | [] }) => {
        setFilters({
            ...filters,
            employees: value,
        });
    };

    const onDropDownBranchesHandler = ({value}: { value: (number | string) }) => {
        if (value) {
            setFilters({
                ...filters,
                branchId: value,
            });
        } else {
            setFilters({
                ...filters,
                branchId: null,
                employees: [],
            });

            setEmployees([]);
        }
    };

    const onDatePickerSwitcherChange = (params: OnChangeDatePickerSwitcherParamsInterface) => {
        setFilters({
            ...filters,
            date: params,
        });
    };

    const filtersClassName = [];
    filtersClassName.push(isMobile ? "lynx-calendar-filters--mobile" : "lynx-calendar-filters");
    !isFiltersOpen && filtersClassName.push("lynx-calendar-filters--hidden");


    return (
        <div className="lynx-calendar-filters__wrapper">
            {isMobile &&
                <div
                    className="lynx-calendar-filters__toggle"
                    onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                >
                    <i className="fa-duotone fa-filter-list"/> Управління календарем
                </div>
            }
            <div className={filtersClassName.join(" ")}>
                <DatePickerSwitcher
                    defaultSelectedDataType={defaultSelectedDataType}
                    defaultDate={defaultDate}
                    timeframeButtons={[DateTypeEnum.Day, DateTypeEnum.Week]}
                    onChange={onDatePickerSwitcherChange}
                    className="lynx-calendar-filters__switcher"
                />

                <div className="lynx-calendar-filters__right">
                    <DropDown
                        name="branchId"
                        options={prepareDropDownOptions(availableBranches, "id", "name")}
                        required
                        placeholder="Філія"
                        value={filters.branchId ? filters.branchId : ""}
                        changeHandler={onDropDownBranchesHandler}
                        className="lynx-calendar-filters__dropdown"
                    />
                    <DropDown
                        name="employeeId"
                        options={prepareDropDownOptions(employees, "id", "userName")}
                        required
                        isMulti
                        value={filters.employees}
                        placeholder="Співробітники"
                        changeHandler={onDropDownEmployeesHandler}
                        className="lynx-calendar-filters__dropdown"
                    />
                </div>
            </div>
        </div>
    );
}