import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import "./ClientsFilters.css"

import {useBranch} from "../../../../hooks";
import {getBranchesAction} from "../../../../Store/Branches/Actions";
import {getGendersAction} from "../../../../Store/Directories/Genders/Actions";
import {getClientSourcesAction} from "../../../../Store/Directories/ClientSources/Actions";

import ExpandableLink from "../../../../../Core/Components/ExpandableLink";
import Input from "../../../../../Core/Components/Forms/Elements/Input";
import DateTime from '../../../../../Core/Components/Forms/Elements/DateTime';
import FiltersForm from "../../../../../Core/Components/Forms/Filters/FiltersForm";
import DropDown from "../../../../../Core/Components/Forms/Elements/DropDown";

import {StoreInterface} from "../../../../../Config/Redux/StoreInterface";
import {ClientsFiltersPropsInterface} from "./ClientsFiltersInterface";


export default function ClientsFilters(props: ClientsFiltersPropsInterface) {
    const {searchParams, onFilterHandler} = props;

    const {
        availableBranches,
    } = useBranch();

    const genders = useSelector((store: StoreInterface) => store.longTermCache.genders)
    const clientSources = useSelector((store: StoreInterface) => store.shortCache.clientSources)
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getBranchesAction());
        dispatch(getClientSourcesAction());

        if (genders?.length === 0) {
            dispatch(getGendersAction());
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <FiltersForm code="clients" searchParams={searchParams} filterHandler={onFilterHandler}>
            <div className="row">
                <div className="clients-filters__inner">
                    <ExpandableLink title="Основні фільтри" isVisibleDefault>
                        <div className="col-12">
                            <Input type="text" name="firstName" title="Ім'я"/>
                        </div>
                        <div className="col-12">
                            <Input type="text" name="lastName" title="Прізвище"/>
                        </div>
                        <div className="col-12">
                            <Input type="tel" name="numberPhone" title="Номер телефону"/>
                        </div>
                        <div className="col-12">
                            <DateTime name="birthday" title="Дата народження"/>
                        </div>
                    </ExpandableLink>

                    <ExpandableLink title="Додаткові фільтри">
                        <div className="col-12">
                            <DropDown
                                name="genderId"
                                title="Стать"
                                options={genders}
                            />
                        </div>
                        <div className="col-12">
                            <DropDown
                                name="clientSourceId"
                                title="Джерело залучення"
                                options={clientSources}
                            />
                        </div>
                        <div className="col-12">
                            <div className="col-12">
                                <DropDown
                                    name="branchId"
                                    title="Філія першого звернення"
                                    options={availableBranches}
                                />
                            </div>
                        </div>
                    </ExpandableLink>
                </div>
            </div>
        </FiltersForm>
    );
};
